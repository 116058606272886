import { Typography } from '@components';
import { Grid } from '@suid/material';

interface StatusBadgeProps {
  text: string;
  color: string | ((text: string) => string);
  size: 'small' | 'medium';
}

export const StatusBadge = (props: StatusBadgeProps) => {
  const getColor = (text: string) => {
    if (typeof props.color === 'string') {
      return props.color;
    }
    return props.color(text);
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      backgroundColor={getColor(props.text)}
      class={`py-[4px] ${
        props.size === 'medium' ? 'px-[8px]' : 'px-[4px]'
      } rounded-[4px]`}
    >
      <Typography variant="body1" class="text-white">
        {props.text}
      </Typography>
    </Grid>
  );
};
