import { v3Client, v3FileUploadClient, v3ODataClient } from '@api/apiClient';
import { ToastType } from '@components';
import { createError } from '@utils/errorUtils';
import { handleToast } from '@utils/utils';
import { CommonFileUploadFiles } from '@components/FileUpload/CommonFileUpload';

import {
  ATGUserDataType,
  StoreGoogleCredentialType,
  UserDetailsType,
  UserPastChargesType,
  UserPendingChargesType,
} from './types';
import { setUserDetailStore, userDetailStore } from './store';

export const getUserDetailsApi = async (id: number) => {
  try {
    const response: unknown = await v3Client.get('/User/Details', {
      params: { id },
    });
    return response as UserDetailsType;
  } catch (e: unknown) {
    const error = createError(e, 'Unable to fetch user details');
    handleToast(ToastType.Error, error.message);
  }
};

export const fetchUserDetailDocumentsApi = async (id: string) => {
  try {
    const response: unknown = await v3Client.get(`/Document/User/${id}`);
    return response as CommonFileUploadFiles[];
  } catch (e: unknown) {
    const error = createError(e, 'Failed to fetch user documents list.');
    handleToast(ToastType.Error, error.message);
  }
};

export const uploadUserDetailDocumentApi = async (
  userId: number,
  file: File,
) => {
  try {
    const formData = new FormData();
    formData.append(`${file.name}`, file);
    const res = await v3FileUploadClient.post(
      `/Document/User/${userId}`,
      formData,
    );
    return res.data as unknown as {
      url: string;
    };
  } catch (e: unknown) {
    const error = createError(e, 'Document failed to upload');
    handleToast(ToastType.Error, error.message);
  }
};

export const deleteUserDetailDocumentApi = async (payload: FormData) => {
  try {
    const urlEncodedData = new URLSearchParams();
    for (const pair of payload.entries()) {
      urlEncodedData.append(pair[0], String(pair[1]));
    }
    await v3FileUploadClient.post('/Document/DeleteFile', urlEncodedData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  } catch (error: unknown) {
    throw new Error('Failed to delete user document');
  }
};

export const getATGUserDataApi = async () => {
  try {
    const response: unknown = await v3Client.get('/Data');
    return response as ATGUserDataType;
  } catch (e: unknown) {
    const error = createError(e, 'Failed to get roles and permissions info');
    handleToast(ToastType.Error, error.message);
  }
};

export const userProfilePhotoUploadApi = async (file: FormData) => {
  try {
    const response: { success: boolean; data: { photo: string } } =
      await v3FileUploadClient.post(
        `/User/UploadPhoto/${userDetailStore.userDetails.id}`,
        file,
      );
    if (response.success) {
      setUserDetailStore('userDetails', 'picture', response.data.photo);
      handleToast(
        ToastType.Success,
        'Profile Photo Updated. You look amazing.',
      );
      return true;
    }
  } catch (e: unknown) {
    const error = createError(e, 'Failed to upload image.');
    handleToast(ToastType.Error, error.message);
  }
};

export const fetchPendingChargesApi = async (id: string) => {
  try {
    const response: unknown = await v3Client.get(`/User/Adjustments/${id}`, {
      params: { id },
    });
    return response as UserPendingChargesType[];
  } catch (e: unknown) {
    const error = createError(e, 'Failed to get pending charges data.');
    handleToast(ToastType.Error, error.message);
  }
};

export async function fetchPastChargesApi(userId: string) {
  try {
    const response = await v3ODataClient.get(
      `/UserAdjustments?%24format=json&%24top=30&%24filter=UserId+eq+${userId}&%24count=true`,
    );
    if ('value' in response) {
      return response.value as UserPastChargesType[];
    }
    return [];
  } catch (e: unknown) {
    const error = createError(e, 'Failed to fetch past charges data.');
    handleToast(ToastType.Error, error.message);
  }
}

export const saveUserAdjustmentsApi = async (
  userId: number,
  payload: Record<string, unknown>[],
) => {
  try {
    const url = `/User/Adjustments/${userId}`;
    const response: { success: boolean } = await v3Client.post(url, {
      Id: userId,
      adjustments: payload,
    });
    return response.success;
  } catch (e) {
    const error = createError(e, 'Failed to save user adustments');
    handleToast(ToastType.Error, error.message);
  }
};

export const saveUserDetailsApi = async (data: UserDetailsType) => {
  try {
    const response: { success: boolean } = await v3Client.put(
      `/User/${data.id}`,
      data,
    );
    return response.success;
  } catch (e) {
    const error = createError(e, 'Information failed to save');
    handleToast(ToastType.Error, error.message);
  }
};

export const storeGoogleCredentialApi = async (
  payload: StoreGoogleCredentialType,
) => {
  const url = `/User/StoreGoogleCredential/${payload.userId}`;
  try {
    const response: { success: boolean } = await v3Client.post(url, payload);
    return response.success;
  } catch (e) {
    const error = createError(e, 'Failed to save google credentials');
    handleToast(ToastType.Error, error.message);
  }
};
