/* eslint-disable complexity,@typescript-eslint/no-unused-vars */
import {
  BasicModal,
  Button,
  Card,
  FlagPopup,
  Notification,
  ToastType,
} from '@components';
import { flagImgs, softDeleteFlagTypes } from '@components/FlagPopup/constants';
import { SpecialFlagTypeEnum } from '@components/FlagPopup/types';
import { CheckboxInput, SelectField } from '@components/forms';
import { carrierUrls } from '@constants/urls';
import { A, useParams, useSearchParams } from '@solidjs/router';
import { isFeatureFlagEnabled } from '@store/featureFlags/store';
import { featureFlags } from '@store/featureFlags/featureFlags';
import { closeModal, openModal } from '@store/modals';
import {
  addCommentInOrderStore,
  carrierStore,
  customerStore,
  fetchNearbyCarriers,
  fetchUserInfoUserModel,
  orderStore,
  postOrderComment,
  requestPickup,
  saveOrderDetails,
  setActiveCarrierTab,
  setOrderStore,
  updateCarrierStoreContactsWithLoadCarrier,
  updateLoadPropertyAtIndex,
  updateOrderState,
  updateVendorPropertyAtIndex,
} from '@store/orders';
import {
  CommitQuoteResult,
  FlagType,
  TabState,
  TrackingProviders,
  UserInfoModel,
} from '@store/orders/types';
import { userStore } from '@store/user';
import { LocalShipping, OpenInNew } from '@suid/icons-material';
import { Box, Grid, Button as SButton, Stack } from '@suid/material';
import { getEligibleEntries, handleToast } from '@utils/utils';
import { getLoadColor } from '@views/order/components/header/constants';
import { AssignLoad, SelectPlaylist } from '@views/order/components/models';
import {
  ADD_PLAYLIST_ID,
  ASSIGN_LOAD_ID,
  loadStatusItems,
  NEARBY_CARRIER_MODAL_ID,
} from '@views/order/constants';
import { size } from 'lodash';
import { DateTime } from 'luxon';
import {
  Accessor,
  batch,
  createEffect,
  createSignal,
  Index,
  Match,
  onCleanup,
  onMount,
  Show,
  Switch,
} from 'solid-js';
import { unwrap } from 'solid-js/store';
import { LTLTrackingData } from '@store/ltl/types';
import { fetchReferenceNumbers } from '@store/ltl/store';
import { LastUsedTopStopModel } from '@typeDefinitions/stopTypes';
import { VendorLineItem } from '@typeDefinitions/lineItemTypes';
import { ILoadEditViewModel } from '@typeDefinitions/loadTypes';
import { FlagViewModel } from '@typeDefinitions/flagTypes';

import LoadWarnings from '../../carrierAndDriverDetails/LoadWarnings';
import { GetRates } from '../GetRates';
import LoadInformationActionButtons from '../LoadInformationActionButtons';
import { LTLInfo } from '../LTLInfo';
import { NearbyCarrier } from '../NearbyCarrier';
import { statusSelectSmallStyle } from '../styles';
import { AssignedTo } from './AssignedTo';
import { CarrierAutoComplete } from './CarrierAutocomplete';
import { CarrierContact } from './CarrierContact';
import { CarrierDetailActionButtons } from './CarrierDetailsActions';
import { CoveredBy } from './CoveredBy';
import { DeprecatedDriverDetails } from './DeprecatedDriverDetails'; //CLEAN UP IN TICKET VNEXT-2998
import { DriverDetails } from './DriverDetails';
import {
  FlagComments,
  FlagNotificationSubTextContent,
  FlagNotificationTextContent,
} from './FlagComponents';
import { LoadDetails } from './LoadDetails';
import { LoadDetailsRow } from './LoadDetailsRow';
import { ProfileFallout } from './ProfileFallout';
import ShareAndUnShareCapacity from './ShareAndUnShareCapacity';
import { loadInfoStore, setLoadInfoStore } from './store';
import { onFlagChange } from './utils';

type PropsT = {
  load: ILoadEditViewModel;
  tabIndex: number;
};

export const LoadInfo = (props: PropsT) => {
  const editFlagModalId = `EditFlagID-${props.tabIndex}`;
  const addPlaylistModalId = `${ADD_PLAYLIST_ID}-${props.tabIndex}`;
  const nearByCarriersModalId = `${NEARBY_CARRIER_MODAL_ID}-${props.tabIndex}`;

  const [smallScreen, setSmallScreen] = createSignal(false);
  const [isCarrierSelected, setIsCarrierSelected] = createSignal(false);
  const [pickupLoading, setPickupLoading] = createSignal(false);
  const [loadWarnings, setLoadWarnings] = createSignal<Array<string>>([]);
  const [query] = useSearchParams();
  const [trackingData, setTrackingData] = createSignal<LTLTrackingData | null>(
    null,
  );

  const params = useParams();
  const isCreate = !params.id;

  createEffect(() => {
    if (Boolean(props.load.carrierId)) {
      setIsCarrierSelected(true);
    } else {
      setIsCarrierSelected(false);
    }
  });

  const onWindowResize = () => {
    if (window.innerWidth <= 1440) {
      setSmallScreen(true);
      setOrderStore('isSmallScreen', true);
    } else {
      setSmallScreen(false);
      setOrderStore('isSmallScreen', false);
    }
  };

  onMount(() => {
    onWindowResize();
    updateCarrierStoreContactsWithLoadCarrier(props.tabIndex);
    window.addEventListener('resize', onWindowResize);
  });

  onCleanup(() => {
    window.removeEventListener('resize', onWindowResize);
  });

  const checkVisibilityProvider = () => {
    for (const key in TrackingProviders) {
      if (
        TrackingProviders[key as keyof typeof TrackingProviders] ===
        customerStore.customer.trackingVisibilityProvider
      ) {
        return key;
      }
    }
  };

  createEffect(async () => {
    if (isCreate) {
      const response: UserInfoModel = await fetchUserInfoUserModel(
        userStore.user.id,
      );
      setLoadInfoStore('coveredBy', response);
      setLoadInfoStore('assignedTo', response);
      setLoadInfoStore('assignedToButton', 'Assign Load');
      updateLoadPropertyAtIndex({
        coveredBy: response.name,
        coveredById: response.id,
        assignedToUser: response.name,
        assignedToUserId: response.id,
      });
    }
    if (
      !Boolean(props.load.assignedToGroup) &&
      !Boolean(props.load.assignedToUser)
    ) {
      setLoadInfoStore('assignedToButton', 'Assign Load');
    }
  });

  const editFlag = (index: number) => {
    setLoadInfoStore('flagIndex', index);
    openModal(editFlagModalId);
    setLoadInfoStore(
      'flag',
      (orderStore.order.loads[props.tabIndex].flags ?? [])[index],
    );
  };

  const handleFalveySuccessflow = async (
    commitQuoteResult: CommitQuoteResult,
  ) => {
    let vendorName = '';
    const falveyVendorIndex = (orderStore.order.vendors || []).findIndex(
      (v) => v.name === 'Falvey Shippers Insurance',
    );
    if (falveyVendorIndex !== -1 && orderStore.order.vendors) {
      vendorName = orderStore.order.vendors[falveyVendorIndex]
        .description as string;

      const vendorUpdateLineItems =
        size(orderStore.order.vendors) > 0
          ? [
              ...(orderStore.order.vendors[orderStore.activeTab.index]
                .lineItems as VendorLineItem[]),
            ]
          : [];
      const vendorLineItemIndex = vendorUpdateLineItems.findIndex(
        (lineItem) => lineItem.description === 'Certificate #',
      );

      vendorUpdateLineItems[vendorLineItemIndex] = {
        ...vendorUpdateLineItems[vendorLineItemIndex],
        description: `Certificate ${commitQuoteResult.certificateNumber}`,
        operationType: 'Update',
      };

      updateVendorPropertyAtIndex(
        {
          description: vendorName.concat(
            `\nPolicy Number: ${commitQuoteResult.policyNumber}`,
          ),
          lineItems: vendorUpdateLineItems,
        },
        falveyVendorIndex,
      );
      const res = await postOrderComment({
        objectId: orderStore.order.id,
        type: 'Order',
        comment: `Certificate # ${commitQuoteResult.certificateNumber} and Policy Number: ${commitQuoteResult.policyNumber} has been generated for Falvey Shippers Insurance. \n Falvey COI Link: ${commitQuoteResult.certificatePDFLink}`,
      });
      addCommentInOrderStore(res);
      //Note: Have to save order or else vendor data will delete
      await saveOrderDetails();
    }
  };

  const submitPickupRequest = async () => {
    try {
      setPickupLoading(true);

      const resp = await requestPickup(props.load);
      setPickupLoading(false);

      const { errors, certificatePDFLink } = resp.commitQuoteResult;

      if (certificatePDFLink) {
        await handleFalveySuccessflow(resp.commitQuoteResult);
        window.open(certificatePDFLink, '_blank');
      } else if (Boolean(errors) && errors.length > 0) {
        if (errors[0] === 'Falvey Insurance already exists for this Order.') {
          handleToast(ToastType.Error, errors[0]);
        } else {
          handleToast(
            ToastType.Error,
            'There was an error sending the request for insurance to Falvey. The LTL Team has been notified and is resolving the issue.',
          );
          setOrderStore(
            'vendorFalveyInsuranceError',
            `There was an error sending your request for insurance to Falvey. The LTL Team has been notified of the issue and will manually update the insurance information below. Please reach out to ltl@armstrongtransport.com to confirm insurance has been added for ${
              orderStore.order.loads[orderStore.activeTab.index].loadNum
            }.`,
          );
          const falveyVendorIndex = (orderStore.order.vendors || []).findIndex(
            (v) => v.name === 'Falvey Shippers Insurance',
          );
          setActiveCarrierTab({
            index: falveyVendorIndex,
            type: 'vendor',
          } as TabState);
        }
      }
    } catch (error) {
      setPickupLoading(false);
      handleToast(ToastType.Error, (error as Error).message);
    }
  };

  const getNearbyCarriersDetails = async () => {
    const eligibleStops = getEligibleEntries<LastUsedTopStopModel>(
      props.load.stops,
    );
    if (
      eligibleStops.length > 0 &&
      eligibleStops[0].zip &&
      Boolean(eligibleStops[0].stopDateTime) &&
      DateTime.fromISO(eligibleStops[0].stopDateTime as string, {
        zone: 'utc',
      }).startOf('day') >= DateTime.now().startOf('day').minus({ days: 1 })
    ) {
      await fetchNearbyCarriers({
        zip: eligibleStops[0].zip.split('-')[0],
        startDate: DateTime.fromISO(eligibleStops[0].stopDateTime as string, {
          zone: 'utc',
        }).toISO() as string,
        equipment: props.load.equipment ?? '',
      });
    }
  };

  createEffect(async () => {
    if (!carrierStore.carrier[orderStore.activeTab.index]?.name) {
      await getNearbyCarriersDetails();
    }

    if (isFeatureFlagEnabled(featureFlags.readFromNewLtlTables)()) {
      await fetchReferenceNumbers(props.load.id);
    }
  });

  onMount(async () => {
    if (
      orderStore.order.status == 'Unassigned' &&
      (orderStore.order.loads[orderStore.activeTab.index]?.stops?.length ??
        0) !== 0 &&
      orderStore.nearbyCarriers.length == 0
    ) {
      await getNearbyCarriersDetails();
    }
  });

  return (
    <Card
      contentClass="bg-[#F2F6F8]"
      startTitle={
        <Show when={!isCreate}>
          <div class="text-lg">{`Load ${orderStore.order.id}-${
            orderStore.order.loads[props.tabIndex].sequence === 0
              ? props.tabIndex + 1
              : orderStore.order.loads[props.tabIndex].sequence
          }`}</div>
          <Show
            when={
              Boolean(orderStore.order.loads[props.tabIndex].billedDate) ||
              orderStore.order.loads[props.tabIndex].paidDate
            }
          >
            <Box displayRaw="flex" gap={1}>
              <Show when={orderStore.order.loads[props.tabIndex].billedDate}>
                <p class="text-sm font-medium leading-6 tracking-[.02em]">
                  Invoiced Date{' '}
                  {orderStore.order.loads[props.tabIndex].billedDate}
                </p>
              </Show>
              <Show when={orderStore.order.loads[props.tabIndex].paidDate}>
                <p class="text-sm font-medium leading-6 tracking-[.02em]">
                  Paid Date {orderStore.order.loads[props.tabIndex].paidDate}
                </p>
              </Show>
            </Box>
          </Show>
        </Show>
      }
      startTitleAction={
        <LoadInformationActionButtons
          load={props.load}
          tabIndex={props.tabIndex}
          currentFlagIndex={() => loadInfoStore.flagIndex}
          setCurrentFlagIndex={(idx) => setLoadInfoStore('flagIndex', idx)}
          setFlagValue={(flagType) => {
            setLoadInfoStore('flag', {
              description: '',
              flagType,
            });
          }}
          onAddFlag={() => openModal(editFlagModalId)}
        />
      }
      loading={orderStore.loading || carrierStore.loading}
    >
      <Stack class="overflow-x-hidden">
        <Show when={(props.load.flags ?? []).length > 0}>
          <div class="flex flex-col gap-y-2">
            <Index each={props.load.flags}>
              {(item: Accessor<FlagViewModel>, index: number) => {
                // We can only have one "Needs Approval" per order.
                if (item().flagType === FlagType.NeedsApproval) {
                  return <></>;
                }

                return (
                  <Notification
                    type={item().flagType}
                    text={<FlagNotificationTextContent item={item} />}
                    subText={<FlagNotificationSubTextContent item={item} />}
                    onClick={() => editFlag(index)}
                    commentLength={<FlagComments item={item} />}
                    cleared={
                      Boolean(item().cleared) &&
                      (softDeleteFlagTypes[
                        item().flagType as unknown as SpecialFlagTypeEnum
                      ] ||
                        false)
                    }
                    disputed={
                      (item().disputedOrderFlags ?? []).find((f) => {
                        return (
                          f.clearedDate === undefined || f.clearedDate === null
                        );
                      }) !== undefined
                    }
                    imgIcon={flagImgs[item().flagType]}
                  />
                );
              }}
            </Index>
          </div>
        </Show>
        <div class="flex gap-2 flex-wrap mt-2">
          <Box flex={1} maxWidth={'170px'} minWidth={'120px'}>
            <SelectField
              backgroundColor={getLoadColor(props.load.status)}
              label="Load Status"
              disableUnderline
              renderValue={(value) => {
                return value;
              }}
              menuItems={loadStatusItems}
              value={props.load.status}
              variant="filled"
              onChange={(e) =>
                updateLoadPropertyAtIndex({
                  status: e.target.value,
                })
              }
              sxProps={statusSelectSmallStyle}
              width="100%"
              labelStyle={{ fontSize: '14px' }}
              disabled={orderStore.isReadOnly}
              bottomAlignCaret
            />
          </Box>
          <CoveredBy load={props.load} tabIndex={props.tabIndex} />
          <AssignedTo load={props.load} tabIndex={props.tabIndex} />
          <Stack justifyContent="center">
            <Switch>
              <Match
                when={props.load.mode === 'TL' || props.load.mode === 'Partial'}
              >
                <ShareAndUnShareCapacity
                  load={props.load}
                  tabIndex={props.tabIndex}
                />
              </Match>
              <Match
                when={
                  props.load.mode == 'LTL' &&
                  props.load.status === 'Assigned' &&
                  props.load.id &&
                  props.load.ltlquoteId !== null &&
                  props.load.ltlquoteId !== undefined &&
                  userStore.user.permissions?.includes('Rate LTL')
                }
              >
                <Button
                  size="medium"
                  startIcon={<LocalShipping />}
                  disabled={pickupLoading()}
                  isLoading={pickupLoading()}
                  variant="contained"
                  label="Submit Pickup Request"
                  onClick={submitPickupRequest}
                />
              </Match>
              <Match
                when={
                  (userStore.user.permissions?.includes('Rate LTL') ?? false) &&
                  props.load.mode === 'LTL' &&
                  (props.load.quoteID === undefined ||
                    props.load.quoteID === null ||
                    props.load.quoteID === '') &&
                  props.load.status !== 'Dispatched' &&
                  !Boolean(query.copyOrderId)
                }
              >
                <GetRates load={props.load} />
              </Match>
            </Switch>
          </Stack>
        </div>
        <LTLInfo
          useNewTables={isFeatureFlagEnabled(
            featureFlags.readFromNewLtlTables,
          )()}
        />
        <div class="mt-4">
          <Show when={smallScreen()}>
            <LoadDetailsRow load={props.load} tabIndex={props.tabIndex} />
          </Show>
          <Grid container spacing={4}>
            <Grid item xs={smallScreen() ? 12 : 6} md={smallScreen() ? 8 : 6}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <div class="text-base font-normal text-[#123B50] tracking-[0.00938em]">
                  Carrier Details
                </div>
                <ProfileFallout
                  load={props.load}
                  tabIndex={props.tabIndex}
                  disabled={orderStore.isReadOnly}
                />
              </Stack>
              <Stack
                flex={3}
                gap={2}
                class="border border-solid rounded-lg border-gray-500 p-2"
                backgroundColor="#FFFBF6"
                sx={{
                  boxShadow:
                    '0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.20)',
                }}
              >
                <CarrierDetailActionButtons
                  load={props.load}
                  tabIndex={props.tabIndex}
                  isCarrierSelected={isCarrierSelected()}
                />
                <div class="flex flex-col gap-y-2">
                  <LoadWarnings
                    loadWarnings={loadWarnings}
                    setLoadWarnings={setLoadWarnings}
                  />
                  {carrierStore.carrier[orderStore.activeTab.index]?.name ||
                  orderStore.nearbyCarriers.length <= 0 ? null : (
                    <Notification
                      type="warning"
                      onClick={() => openModal(nearByCarriersModalId)}
                      text={`Armstrong has ${orderStore.nearbyCarriers.length} carriers dropping off loads near your first pickup.`}
                      cleared={false}
                    />
                  )}
                  {Boolean(
                    customerStore.customer.trackingVisibilityProvider,
                  ) && (
                    <Notification
                      type="warning"
                      text={
                        checkVisibilityProvider() == null
                          ? 'This customer requires tracking on their loads'
                          : `This customer prefers ${checkVisibilityProvider()} for tracking`
                      }
                      cleared={false}
                    />
                  )}
                </div>
                <div class="flex gap-x-2">
                  <div class="w-full">
                    <CarrierAutoComplete
                      load={props.load}
                      tabIndex={props.tabIndex}
                      disabled={orderStore.isReadOnly}
                      size="medium"
                    />
                  </div>
                  <Stack direction="column-reverse">
                    <A
                      href={carrierUrls.profile(Number(props.load.carrierId))}
                      target="_blank"
                    >
                      <SButton
                        sx={{ maxWidth: '105px' }}
                        variant="contained"
                        class="!text-armstrong-sm"
                        startIcon={<OpenInNew />}
                      >
                        Profile
                      </SButton>
                    </A>
                  </Stack>
                </div>
                <Box class="flex gap-x-2 items-center border-gray-500 border w-fit px-2 rounded-md bg-white">
                  <CheckboxInput
                    label="Signed Rate Confirmation"
                    checked={props.load.signedRateCon}
                    onChange={(checked) => {
                      updateLoadPropertyAtIndex({
                        signedRateCon: checked,
                        signedConfirmationDate:
                          props.load.signedConfirmationDate ??
                          DateTime.now().toISO(),
                      });
                    }}
                    disabled={!isCarrierSelected() || orderStore.isReadOnly}
                    class="!text-xs"
                    size="small"
                  />
                  {props.load.signedRateCon && (
                    <div class="text-xs mb-1 font-medium">
                      {`- ${DateTime.fromISO(
                        props.load.signedConfirmationDate?.toString() ??
                          DateTime.now().toISO(),
                        { zone: 'utc' },
                      )
                        .setZone('local')
                        .toFormat('MMM dd, yyyy hh:mm a')}`}
                    </div>
                  )}
                </Box>
                <CarrierContact
                  load={props.load}
                  tabIndex={props.tabIndex}
                  isCarrierSelected={isCarrierSelected()}
                />
              </Stack>
            </Grid>
            <Grid item xs={smallScreen() ? 12 : 6} md={smallScreen() ? 4 : 6}>
              <Grid container spacing={4}>
                <Grid item xs={smallScreen() ? 12 : 6}>
                  {isFeatureFlagEnabled(featureFlags.readFromNewLtlTables)() ? (
                    <DriverDetails
                      load={props.load}
                      tabIndex={props.tabIndex}
                      trackingData={trackingData()}
                    />
                  ) : (
                    <DeprecatedDriverDetails //REMOVE IN TICKET VNEXT-2998
                      load={props.load}
                      tabIndex={props.tabIndex}
                    />
                  )}
                </Grid>
                <Show when={!smallScreen()}>
                  <Grid item xs={6}>
                    <LoadDetails load={props.load} tabIndex={props.tabIndex} />
                  </Grid>
                </Show>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Stack>
      <FlagPopup
        modalId={editFlagModalId}
        flag={loadInfoStore.flag}
        onClose={() => {
          setLoadInfoStore('flagIndex', null);
          closeModal(editFlagModalId);
          setLoadInfoStore('flag', undefined);
        }}
        onChange={(flag, action) =>
          onFlagChange(
            flag,
            action,
            props.load,
            props.tabIndex,
            editFlagModalId,
          )
        }
        onDispute={(needsApprovalFlag) => {
          const loads = [...unwrap(orderStore.order.loads)];

          if (
            typeof loadInfoStore.flagIndex === 'number' &&
            loads[props.tabIndex].flags !== undefined
          ) {
            loads[props.tabIndex] = {
              ...loads[props.tabIndex],
              flags: [...(loads[props.tabIndex].flags ?? [])],
            };
            // @ts-expect-error flags can't be undefined here.
            loads[props.tabIndex].flags[loadInfoStore.flagIndex] = {
              // @ts-expect-error flags can't be undefined here.
              ...loads[props.tabIndex].flags[loadInfoStore.flagIndex],
              disputedOrderFlags: needsApprovalFlag.disputedOrderFlags,
            };
          }

          updateOrderState({
            needsApprovalFlag,
            loads,
          });
        }}
        loading={loadInfoStore.isLoading}
        isLTL={props.load.mode === 'LTL'}
      />
      <BasicModal
        id={`${ASSIGN_LOAD_ID}-${props.tabIndex}`}
        title="Assign load"
        width={'1000px'}
        showClose={false}
        onClose={() => closeModal(`${ASSIGN_LOAD_ID}-${props.tabIndex}`)}
        footer={false}
      >
        <AssignLoad
          onClose={() => closeModal(`${ASSIGN_LOAD_ID}-${props.tabIndex}`)}
          load={props.load}
          tabIndex={props.tabIndex}
        />
      </BasicModal>
      <BasicModal
        id={addPlaylistModalId}
        title=""
        width={'800px'}
        onClose={() => closeModal(addPlaylistModalId)}
        footer={false}
        header={false}
      >
        <SelectPlaylist
          carrierId={carrierStore.carrier[props.tabIndex]?.id}
          tabIndex={props.tabIndex}
          onClose={() => closeModal(addPlaylistModalId)}
          modalId={addPlaylistModalId}
          load={props.load}
        />
      </BasicModal>
      <BasicModal
        id={nearByCarriersModalId}
        title="Nearby Carriers"
        footer={false}
        width="90VW"
        showClose={true}
      >
        <NearbyCarrier />
      </BasicModal>
    </Card>
  );
};
