import { Button } from '@components';
import { Box, Grid } from '@suid/material';
import { FalveyInsuranceQuote, LtlQuoteViewModel } from '@store/orders/types';
import { Show } from 'solid-js';
import { useNavigate } from '@solidjs/router';
import CheckCircleOutlinedIcon from '@suid/icons-material/CheckCircleOutlined';
import AddCircleOutlineOutlinedIcon from '@suid/icons-material/AddCircleOutlineOutlined';
import { featureFlags } from '@store/featureFlags/featureFlags';
import { isFeatureFlagEnabled } from '@store/featureFlags/store';
import { openModal } from '@store/modals';

export const QuoteActionDetails = (props: {
  quote: LtlQuoteViewModel;
  insuranceQuote?: FalveyInsuranceQuote;
  customerId: number;
  isInsuranceRequest: boolean;
}) => {
  const navigate = useNavigate();
  const handleSelectQuote = () => {
    const path = `/Order/Details?ltlQuoteId=${props.quote.ltlQuoteId}&quoteId=${props.quote.id}`;
    navigate(path);
  };

  return (
    <Grid container spacing={1} class="mt-auto mb-0">
      <Box
        class={`flex w-full mt-4 ${
          props.insuranceQuote ? 'justify-center' : 'justify-around'
        }`}
      >
        <Show when={!props.isInsuranceRequest}>
          <Button
            variant="contained"
            startIcon={<CheckCircleOutlinedIcon />}
            class="!bg-[#248670] min-w-[150px]"
            label="Select Quote"
            onClick={handleSelectQuote}
          />
        </Show>
        <Show when={props.isInsuranceRequest && props.insuranceQuote}>
          <Button
            variant="contained"
            startIcon={<CheckCircleOutlinedIcon />}
            class="!bg-[#248670] min-w-[150px]"
            label="Select Insurance Quote"
            onClick={() => openModal(props.quote.id.toString())}
          />
        </Show>
        <Show
          when={
            !props.insuranceQuote && isFeatureFlagEnabled(featureFlags.falvey)()
          }
        >
          <Button
            variant="contained"
            class="!ml-2 !bg-[#066da1] min-w-[150px]"
            label="Add Insurance"
            startIcon={<AddCircleOutlineOutlinedIcon />}
            disabled={props.customerId === 0}
            onClick={() => openModal(props.quote.id.toString())}
          />
        </Show>
      </Box>
    </Grid>
  );
};
