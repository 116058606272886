import { BasicModal, BasicTable, Button, Typography } from '@components';
import { Column } from '@components/BasicTable/BasicTable';
import { FalveyInsuranceQuoteInLoad } from '@store/ltl/types';
import { closeModal, openModal } from '@store/modals';
import {
  LtlQuoteLoadItemViewModel,
  LtlQuoteViewModelLegacy,
  orderStore,
  updateLoadPropertyAtIndex,
} from '@store/orders';
import {
  isFalveyInsuranceCommitted,
  updateCustomerAndVendorPay,
} from '@store/orders/helper';
import ControlPointRoundedIcon from '@suid/icons-material/ControlPointRounded';
import RequestPageIcon from '@suid/icons-material/RequestPage';
import SyncIcon from '@suid/icons-material/Sync';
import { Box, Grid, Stack } from '@suid/material';
import { formatDateString } from '@utils/dateFormat';
import { formatAmount } from '@utils/utils';
import { InsuranceUpdateAndConfirmationForm } from '@views/ltl/components/InsuranceUpdateAndConfirmation';
import { Show } from 'solid-js';
import InfoOutlined from '@suid/icons-material/InfoOutlined';
import { featureFlags } from '@store/featureFlags/featureFlags';
import { isFeatureFlagEnabled } from '@store/featureFlags/store';

import downArrow from '../../../../../assets/downArrow.svg';
import upArrow from '../../../../../assets/upArrow.svg';
import { classes } from './classes';
import { TerminalInfoModal } from './TerminalInfoModal';
import { getAccessorialString, LTLHeaderText } from './helpers';

interface LTLQuoteProps {
  canRateLtl: boolean;
  loading?: boolean;
  ltlQuote?: LtlQuoteViewModelLegacy;
  status?: string;
}

const DeprecatedFalveyLTLQuote = (props: LTLQuoteProps) => {
  //REMOVE IN TICKET VNEXT-2998
  const columns: Column<LtlQuoteLoadItemViewModel>[] = [
    {
      name: 'Qty',
      key: 'quantity',
    },
    {
      name: 'Weight',
      key: 'weight',
    },
    {
      name: 'Class',
      key: 'class',
    },
    {
      name: 'NMFC',
      key: 'nmfc',
    },
    {
      name: 'Dimension',
      key: 'dimensions',
    },
    {
      name: 'Hazmat',
      key: 'hazmat',
      renderCell: (rowData) => <span>{String(rowData.hazmat)}</span>,
    },
  ];

  const formatCurrency = (value: number | undefined) => {
    if (typeof value === 'number' && isFinite(value) && value > 0) {
      return `$${value.toFixed(2)}`;
    }
    return '$ --.--';
  };

  const getVendorInsurance = () => {
    return getInsurancePricingFromLoad()?.quotePremium ?? 0;
  };

  const getAgentPriceWithInsurace = () => {
    let totalAgentCost = 0;
    if (props.ltlQuote?.ltlInsurance) {
      totalAgentCost += props.ltlQuote.ltlInsurance.quotePremium;
    } else {
      totalAgentCost += getVendorInsurance();
    }
    return formatAmount(
      totalAgentCost + (props.ltlQuote?.carrierTotal as number),
    );
  };

  const getCustomerInsurance = () => {
    const insurancePricing = getInsurancePricingFromLoad();
    return (
      (insurancePricing?.quotePremium ?? 0) +
      (insurancePricing?.quoteServiceFee ?? 0)
    );
  };

  const getCustomerPriceWithInsurace = () => {
    let totalCustomerCost = 0;
    if (props.ltlQuote?.ltlInsurance) {
      const { quotePremium, quoteServiceFee } = props.ltlQuote.ltlInsurance;
      totalCustomerCost = quotePremium + quoteServiceFee;
    } else {
      totalCustomerCost = getCustomerInsurance();
    }
    return formatAmount(
      totalCustomerCost + (props.ltlQuote?.customerTotal as number),
    );
  };

  const getInsuranceRate = () => {
    return props.ltlQuote?.ltlInsurance
      ? formatAmount(props.ltlQuote.ltlInsurance.quotePremium)
      : '--';
  };

  const getInsuranceAndFees = () => {
    if (props.ltlQuote?.ltlInsurance) {
      const { quotePremium, quoteServiceFee } = props.ltlQuote.ltlInsurance;
      return formatAmount(quotePremium + quoteServiceFee);
    }
  };

  const renderFalveyInsuranceSection = () => {
    const declaredValue = props.ltlQuote?.ltlInsurance?.shippingSumInsured;
    const isAssigned = ['Assigned', 'Unassigned', 'Can Get'].includes(
      props.status as string,
    );
    const isDeclaredValuePresent = Boolean(declaredValue);

    const amountJsx = (
      <Typography variant="body1" component={'span'} class="!mr-2">
        {formatAmount(declaredValue)}
      </Typography>
    );

    const buttonsJsx = (
      <Show
        when={declaredValue}
        fallback={
          <Button
            label="Add Insurance"
            variant="contained"
            startIcon={<ControlPointRoundedIcon />}
            class="!bg-[#026EA1]"
            onClick={() => openModal('falvey-insurance')}
          />
        }
      >
        {amountJsx}
        <Show when={!isFalveyInsuranceCommitted()}>
          <Button
            label="Update"
            variant="contained"
            startIcon={<SyncIcon />}
            class="!bg-[#026EA1]"
            onClick={() => openModal('falvey-insurance')}
          />
        </Show>
      </Show>
    );
    if (isAssigned && isFeatureFlagEnabled(featureFlags.falvey)()) {
      return [LTLHeaderText('Declared Value'), buttonsJsx];
    }

    if (isDeclaredValuePresent) {
      return [LTLHeaderText('Declared Value'), amountJsx];
    }

    return [];
  };

  // To get insurance pricing from load after dispatch
  const getInsurancePricingFromLoad = () => {
    return props.ltlQuote?.ltlInsurance
      ? props.ltlQuote.ltlInsurance
      : undefined;
  };

  return (
    <>
      <div>
        <div class="flex items-center space-x-2">
          <div class="text-[#123b50] text-xl font-medium">
            LTL Quote - {props.ltlQuote?.quoteId}
          </div>
          <div class="text-[#666666] text-sm flex space-x-1">
            <div class="font-medium"> Created By:</div>
            <div>{props.ltlQuote?.customerName}</div>
          </div>
          <div class="text-[#666666] text-sm flex space-x-1">
            <div class="font-medium"> Date Created:</div>
            <div>
              {formatDateString(props.ltlQuote?.quoteDate, 'MM/dd/yyyy')}
            </div>
          </div>
        </div>
        <Grid container>
          <Grid item xs={3}>
            {LTLHeaderText(props.ltlQuote?.carrierName as string)}
            <img
              class="max-w-40"
              src={`https://dev.atgfr8.com${props.ltlQuote?.logoImageSource}`}
              alt={props.ltlQuote?.carrierName}
            />
          </Grid>
          <Grid item xs={3}>
            {LTLHeaderText('Accessorials')}
            <Stack class={classes.AccessorialsRow}>
              <Grid container>
                <Grid item xs={1.5}>
                  <img src={upArrow} alt="Up Arrow" />
                </Grid>
                <Grid item xs={10.5}>
                  <Typography variant="body1" class="!text-xs">
                    {getAccessorialString(
                      props.ltlQuote?.pickupAccessorials as string[],
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={1.5}>
                  <img src={downArrow} alt="Down Arrow" />
                </Grid>{' '}
                <Grid item xs={10.5}>
                  <Typography variant="body1" class="!text-xs">
                    {getAccessorialString(
                      props.ltlQuote?.deliveryAccessorials as string[],
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Typography variant="body1" class="!text-xs">
                {getAccessorialString(
                  props.ltlQuote?.shipmentAccessorials as string[],
                )}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={3}>
            {LTLHeaderText('Max Carrier Liabillity')}
            <div class="my-[5px] text-[#666666]">
              <span class="text-[#000000DE] font-bold text-xs"> New: </span>
              {formatCurrency(props.ltlQuote?.maxNewLiability)}
            </div>
            <div class="my-[5px] text-[#666666] ">
              <span class="text-[#000000DE] font-bold text-xs"> Used: </span>
              {formatCurrency(props.ltlQuote?.maxUsedLiability)}
            </div>
            <div class="flex space-x-1 items-center">
              <InfoOutlined fontSize="small" sx={{ color: '#026ea1' }} />
              <div
                class="text-[#026ea1] font-medium cursor-pointer text-xs"
                onClick={() => openModal('terminal-info-modal')}
              >
                View Terminal Info
              </div>
            </div>
          </Grid>
          <Grid item xs={3}>
            {LTLHeaderText('Cost')}
            <div class="flex">
              <Stack>
                <div class="font-semibold text-sm">Agent</div>
                <div class="text-[#666666] text-xs ml-4">Carrier Rate</div>
                <Show when={props.ltlQuote?.ltlInsurance?.shippingSumInsured}>
                  <div class="text-[#666666] text-xs ml-4">Insurance Rate</div>
                </Show>
              </Stack>
              <Stack class="!ml-auto">
                <span class="text-[#248670] font-medium">
                  {getAgentPriceWithInsurace()}
                </span>
                <span class="text-xs ml-auto text-[#666666]">
                  {formatAmount(props.ltlQuote?.carrierTotal as number)}
                </span>
                <Show when={props.ltlQuote?.ltlInsurance?.shippingSumInsured}>
                  <span class="text-xs ml-auto text-[#666666]">
                    {getInsuranceRate()}
                  </span>
                </Show>
              </Stack>
            </div>
            {Boolean(props.ltlQuote?.customerTotal) && (
              <Box class="flex mt-2">
                <Stack>
                  <div class="font-semibold text-sm">Customer</div>
                  <Show when={props.ltlQuote?.ltlInsurance?.shippingSumInsured}>
                    <div class="text-[#666666] text-sm ml-4">
                      Insurance & Fees
                    </div>
                  </Show>
                </Stack>
                <Stack class="!ml-auto">
                  <span class="text-[#26856f] font-medium">
                    {getCustomerPriceWithInsurace()}
                  </span>
                  <Show when={props.ltlQuote?.ltlInsurance?.shippingSumInsured}>
                    <span class="text-sm ml-auto text-[#666666]">
                      {getInsuranceAndFees()}
                    </span>
                  </Show>
                </Stack>
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={3}>
            {LTLHeaderText('Transit Days')}
            <Typography variant="body1">
              {props.ltlQuote?.transitDays}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            {LTLHeaderText(' Est. Transit Days')}
            <Typography variant="body1">
              {props.ltlQuote?.serviceType}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            {LTLHeaderText('Movement Type')}
            <Typography variant="body1">
              {props.ltlQuote?.movementType ?? '--'}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            {renderFalveyInsuranceSection()}
          </Grid>
        </Grid>
        <div class="pt-5">
          <BasicTable<LtlQuoteLoadItemViewModel>
            noEmptyRender
            columns={columns}
            rows={
              orderStore.order.loads[orderStore.activeTab.index].ltlQuote
                ?.ltlQuoteLoadItems ?? []
            }
          />
        </div>
        <div class="flex justify-end my-2">
          {props.canRateLtl && (
            <button
              onClick={() => {
                openModal('ltl-quoting-modal');
              }}
              class={classes.requoteShipmentBtn}
            >
              <RequestPageIcon />
              Requote Shipment
            </button>
          )}
        </div>
      </div>
      <BasicModal id={'falvey-insurance'} width={'650px'} footer={false}>
        <InsuranceUpdateAndConfirmationForm
          items={
            props.ltlQuote?.ltlQuoteLoadItems?.map((ltlQuoteItem, ind) => {
              let loadItem;
              if (orderStore.order.loads[orderStore.activeTab.index].items) {
                loadItem =
                  orderStore.order.loads[orderStore.activeTab.index].items![
                    ind
                  ];
              }
              return {
                ...ltlQuoteItem,
                description: loadItem?.description,
              };
            }) ?? []
          }
          onConfirmation={(val, type, declaredVal) => {
            updateCustomerAndVendorPay(
              val as FalveyInsuranceQuoteInLoad,
              type as string,
              declaredVal as number,
            );
            closeModal('falvey-insurance');
            if (props.ltlQuote && val) {
              const ltlInsurance = Boolean(props.ltlQuote.ltlInsurance)
                ? props.ltlQuote.ltlInsurance
                : {};
              updateLoadPropertyAtIndex({
                ltlQuote: {
                  ...props.ltlQuote,
                  ltlInsurance: {
                    ...ltlInsurance,
                    shippingSumInsured: declaredVal as number,
                    quotePremium: val.quotePremium,
                    quoteServiceFee: val.quoteServiceFee,
                  },
                },
              });
            }
          }}
          onCancel={() => {
            closeModal('falvey-insurance');
          }}
          declaredValue={props.ltlQuote?.ltlInsurance?.shippingSumInsured}
          customerCost={props.ltlQuote?.rateQuote.customerRateQuote?.totalCost}
          carrierCost={props.ltlQuote?.rateQuote.totalCost}
          updateDeclaredValue={(val) => {
            if (props.ltlQuote?.ltlInsurance) {
              updateLoadPropertyAtIndex({
                ltlQuote: {
                  ...props.ltlQuote,
                  ltlInsurance: {
                    ...props.ltlQuote.ltlInsurance,
                    shippingSumInsured: val,
                  },
                },
              });
            }
          }}
          falveyInsuranceQuote={
            props.ltlQuote?.ltlInsurance as FalveyInsuranceQuoteInLoad
          }
          ltlQuoteId={props.ltlQuote?.id}
          quoteParametersId={props.ltlQuote?.quoteParametersId}
        />
      </BasicModal>
      <TerminalInfoModal
        modalId={'terminal-info-modal'}
        onClose={closeModal}
        terminalInfo={props.ltlQuote?.terminalInfo}
      />
    </>
  );
};

export default DeprecatedFalveyLTLQuote;
