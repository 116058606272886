import { TabsComponent, Typography } from '@components';
import { SavedQuotesGridLegacy } from '@components/Grid/SavedQuotesGridLegacy';
import { SavedQuotesGrid } from '@components/Grid/SavedQuotesGrid';
import { savedQuoteStore } from '@store/ltl/store';
import { Paper, Stack } from '@suid/material';
import { Match, Switch, createSignal, onMount } from 'solid-js';
import { HistoryQuotesGrid } from '@components/Grid/HistoryQuotesGrid';
import { SavedQuoteInfo } from '@store/ltl';
import { fetchSavedQuotes } from '@store/ltl/actions';
import { featureFlags } from '@store/featureFlags/featureFlags';
import {
  isFeatureFlagEnabled,
  featureFlagStore,
} from '@store/featureFlags/store';
import {
  customerSavedQuotesStore,
  fetchSavedQuotesByCustomerId,
} from '@store/customers/customerDetails';
import { useParams } from '@solidjs/router';

import { saveQuoteClasses as cls } from './classes';

type Props = {
  isCustomerSaveQuote?: boolean;
};

export const SavedQuotes = (props: Props) => {
  const params = useParams();
  const isCustomerSaveQuote = props.isCustomerSaveQuote ?? false;
  const TabIndices = {
    SAVED_QUOTES: 0,
    HISTORY: 1,
  } as const;
  const [activeTab, setActiveTab] = createSignal<number>(
    TabIndices.SAVED_QUOTES,
  );
  const [quoteItems, setQuoteItems] = createSignal<SavedQuoteInfo[]>([]);
  const [quoteHistoryItems, setQuoteHistoryItems] = createSignal<
    SavedQuoteInfo[]
  >([]);

  onMount(async () => {
    if (featureFlagStore.isLoading) {
      const maxWaitTime = 10000; // 10,000ms = 10 seconds
      let elapsedTime = 0;
      await new Promise<void>((resolve, reject) => {
        const interval = 50;
        const checkLoading = setInterval(() => {
          if (!featureFlagStore.isLoading) {
            clearInterval(checkLoading);
            resolve();
          } else if ((elapsedTime += interval) >= maxWaitTime) {
            clearInterval(checkLoading);
            reject(new Error('Timed out waiting for feature flags to load.'));
          }
        }, interval);
      });
    }

    if (!isFeatureFlagEnabled(featureFlags.readFromNewLtlTablesSavedQuotes)()) {
      if (isCustomerSaveQuote) {
        await fetchSavedQuotesByCustomerId(params.id);
      } else {
        await fetchSavedQuotes();
      }

      const activeQuotes: SavedQuoteInfo[] = [];
      const nonActiveQuotes: SavedQuoteInfo[] = [];

      const savedQuote = isCustomerSaveQuote
        ? customerSavedQuotesStore
        : savedQuoteStore;

      savedQuote.items.forEach((item) => {
        if (item.quoteStatus === 'Active') {
          activeQuotes.push(item);
        } else {
          nonActiveQuotes.push(item);
        }
      });

      setQuoteItems(activeQuotes);
      setQuoteHistoryItems(nonActiveQuotes);
    }
  });

  return (
    <Stack
      spacing={1.4}
      p={2}
      bgcolor="#A5C6DA"
      height={isCustomerSaveQuote ? '100%' : '100vh'}
      class={`${isCustomerSaveQuote ? '!overflow-y-auto' : ''}`}
    >
      {(props.isCustomerSaveQuote === undefined ||
        !props.isCustomerSaveQuote) && (
        <Typography variant="h1" class={cls.heading}>
          LTL Saved Quotes
        </Typography>
      )}
      <TabsComponent
        activeTab={activeTab()}
        onTabClick={setActiveTab}
        tabs={['Saved Quotes', 'History']}
      />
      <Paper elevation={3}>
        <Stack
          p={1.4}
          bgcolor="white"
          class={cls.tabContainer}
          height={'calc(100vh - 130px)'}
        >
          <Switch>
            <Match when={activeTab() === TabIndices.SAVED_QUOTES}>
              {isFeatureFlagEnabled(
                featureFlags.readFromNewLtlTablesSavedQuotes,
              )() ? (
                <SavedQuotesGrid
                  isHistory={false}
                  isCustomerSaveQuote={isCustomerSaveQuote}
                />
              ) : (
                <SavedQuotesGridLegacy
                  items={quoteItems}
                  setQuoteItems={setQuoteItems}
                  isCustomerSaveQuote={isCustomerSaveQuote}
                />
              )}
            </Match>
            <Match when={activeTab() === TabIndices.HISTORY}>
              {isFeatureFlagEnabled(
                featureFlags.readFromNewLtlTablesSavedQuotes,
              )() ? (
                <SavedQuotesGrid
                  isHistory={true}
                  isCustomerSaveQuote={isCustomerSaveQuote}
                />
              ) : (
                <HistoryQuotesGrid
                  items={quoteHistoryItems}
                  isCustomerSaveQuote={isCustomerSaveQuote}
                />
              )}
            </Match>
          </Switch>
        </Stack>
      </Paper>
    </Stack>
  );
};
