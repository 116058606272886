import { apiClient } from '@api/apiClient';
import { ToastType } from '@components';
import {
  AddOrEditCommentPayload,
  V4ApiResponse,
} from '@store/customers/customerDetails';
import { createError } from '@utils/errorUtils';
import { handleToast } from '@utils/utils';
import { Comments } from '@store/orders';

import { setCarrierDetailsStore } from './store';
import {
  CarrieDetailsClaimHistory,
  CarrierAdjustmentAccounts,
  CarrierDetailLoadHistoryResponse,
  CarrierDetails,
  CarrierDetailsAdjustment,
  CarrierDocument,
  CarrierFuelSurchargeDetails,
  FactoringCompany,
  LoadHistoryPayload,
} from './types';

export const fetchCarrierDetails = async (carrierId: string) => {
  try {
    const response: V4ApiResponse<CarrierDetails> = await apiClient.get(
      `/carrier/carrierdetails/${carrierId}`,
    );
    if (response.isSuccess) {
      return response.value;
    }
  } catch (e: unknown) {
    const error = createError(e, 'Fetching carrier details failed');
    handleToast(ToastType.Error, error.message);
  }
};

export const fetchCarrierAdjustments = async (carrierId: string) => {
  try {
    const response: V4ApiResponse<CarrierDetailsAdjustment[]> =
      await apiClient.get(`/Carrier/Adjustments/${carrierId}`, {
        params: { carrierId },
      });
    return response;
  } catch (e: unknown) {
    const error = createError(e, 'Fetching carrier adjustments failed');
    handleToast(ToastType.Error, error.message);
  }
};

export const fetchCarrierAdjustmentAccounts = async () => {
  try {
    const response: V4ApiResponse<CarrierAdjustmentAccounts[]> =
      await apiClient.get('/carrier/adjustmentaccounts');
    if (response.isSuccess) {
      return response.value;
    }
  } catch (e: unknown) {
    const error = createError(
      e,
      'Fetching carrier adjustments accounts failed',
    );
    handleToast(ToastType.Error, error.message);
  }
};
export const fetchCarrierDocuments = async (carrierId: string) => {
  try {
    const response: V4ApiResponse<CarrierDocument[]> = await apiClient.get(
      `/Carrier/getcarrierdocument/${carrierId}`,
    );
    return response.value;
  } catch (e: unknown) {
    const error = createError(e, 'Fetching carrier documents failed');
    handleToast(ToastType.Error, error.message);
  }
};

export const fetchCarrierFuelSurcharge = async (carrierId: string) => {
  try {
    const resp: V4ApiResponse<CarrierFuelSurchargeDetails> =
      await apiClient.get(
        `/FuelSurcharge/getfuelsurcharge?ownerId=${carrierId}&ownerType=${1}&includeDeleted=${false}`,
      );
    if (resp.isSuccess) {
      return resp.value;
    }
  } catch (e: unknown) {
    const error = createError(e, 'Fetching carrier fuel surcharge failed');
    handleToast(ToastType.Error, error.message);
  }
};

export const addCarrierToPlaylistFavorite = async (payload: {
  selected: boolean;
  carrierId: number;
}) => {
  try {
    const resp: V4ApiResponse<number> = await apiClient.put(
      '/Playlist/Favorite',
      payload,
    );
    return resp;
  } catch (e: unknown) {
    const error = createError(e, 'Adding carrier playlist favorite failed');
    handleToast(ToastType.Error, error.message);
  }
};

export const fetchCarrierFactoringCompany = async (carrierId: number) => {
  try {
    const response: V4ApiResponse<FactoringCompany> = await apiClient.get(
      `/FactoringCompany/getfactoringdata/${carrierId}`,
    );
    return response.value;
  } catch (e: unknown) {
    const error = createError(e, 'Fetching carrier factoring company failed');
    handleToast(ToastType.Error, error.message);
  }
};

export const postCarrierComment = async (payload: AddOrEditCommentPayload) => {
  try {
    const res: V4ApiResponse<Comments> = await apiClient.post(
      '/Carrier/addcarriercomment',
      payload,
    );
    return res;
  } catch (e: unknown) {
    const error = createError(e, 'Adding carrier comments failed');
    handleToast(ToastType.Error, error.message);
  }
};

export async function deleteCarrierComment(commentId: number) {
  try {
    const res: V4ApiResponse<null> = await apiClient.delete(
      `/Carrier/deletecomment/${commentId}`,
    );
    return res.isSuccess;
  } catch (e: unknown) {
    const error = createError(e, 'Deleting carrier comments failed');
    handleToast(ToastType.Error, error.message);
  }
}

export const fetchCarrierLoadHistory = async (payload: LoadHistoryPayload) => {
  try {
    const response: V4ApiResponse<CarrierDetailLoadHistoryResponse> =
      await apiClient.post('Carrier/LoadHistory', payload);
    if ('value' in response) {
      return response.value;
    }
  } catch (e: unknown) {
    const error = createError(e, 'Fetching carrier load history failed');
    handleToast(ToastType.Error, error.message);
  }
};

export const saveNewCarrierAdjustments = async (
  carrierId: number,
  payload: Record<string, unknown>[],
) => {
  try {
    const res: V4ApiResponse<null> = await apiClient.post(
      '/Carrier/Adjustment',
      {
        id: carrierId,
        adjustments: payload,
      },
    );
    return res.isSuccess;
  } catch (e: unknown) {
    const error = createError(e, 'Saving new carrier adjustments failed');
    handleToast(ToastType.Error, error.message);
  }
};

export const fetchPastCarrierAdjustments = async (carrierId: number) => {
  try {
    const response: V4ApiResponse<CarrierDetailsAdjustment[]> =
      await apiClient.get(
        `/Carrier/AdjustmentHistory/${carrierId}?toCurrency=NaN`,
      );
    if (response.isSuccess) {
      return response.value;
    }
  } catch (e: unknown) {
    const error = createError(e, 'Fetching past carrier adjustments failed');
    handleToast(ToastType.Error, error.message);
  }
};

export const updateCarrierComment = async (
  payload: AddOrEditCommentPayload,
) => {
  try {
    const res: { isSuccess: boolean } = await apiClient.put(
      '/Carrier/updatecomment',
      payload,
    );
    return res.isSuccess;
  } catch (e: unknown) {
    const error = createError(e, 'Updating carrier comments failed');
    handleToast(ToastType.Error, error.message);
  }
};

export const updateCarrierDetailsRatings = async (
  payload: Record<string, string | number | null>,
) => {
  try {
    const res: V4ApiResponse<null> = await apiClient.put(
      '/Carrier/updaterating',
      payload,
    );
    return res.isSuccess;
  } catch (e: unknown) {
    const error = createError(e, 'Updating carrier ratings failed');
    handleToast(ToastType.Error, error.message);
  }
};

export const sendCarrierTransfloEmails = async (payload: {
  carrierId: number;
  contactIds: number[];
}) => {
  try {
    const resp: V4ApiResponse<null> = await apiClient.post(
      '/carrier/enabletransflocarrier',
      payload,
    );
    if (resp.isSuccess) {
      handleToast(
        ToastType.Success,
        'Sent Transflo email registration to selected contacts',
      );
    }
  } catch (e: unknown) {
    const error = createError(e, 'Sending carrier transflo emails failed');
    handleToast(ToastType.Error, error.message);
  }
};

export async function updateCarrierDetails(payload: CarrierDetails) {
  try {
    const res: V4ApiResponse<CarrierDetails> = await apiClient.put(
      '/Carrier',
      payload,
    );
    if (Boolean(res.isSuccess)) {
      handleToast(ToastType.Success, 'Carrier details saved successfully');
      setCarrierDetailsStore('carrierDetails', res.value);
      return res.isSuccess as boolean;
    }
  } catch (e: unknown) {
    const error = createError(e, 'Updating carrier details failed');
    handleToast(ToastType.Error, error.message);
  }
}

export const uploadCarrierDetailsDocument = async (
  carrierId: number,
  file: File,
) => {
  try {
    const formData = new FormData();
    formData.append('Files', file);
    formData.append('DocumentEntityType', '3');
    formData.append('EntityId', carrierId.toString());
    const res: V4ApiResponse<{
      results: { documentId: number; name: string; url: string }[];
    }> = await apiClient.post('/carrier/uploadfile', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return res.value;
  } catch (e: unknown) {
    const error = createError(e, 'Uploading carrier document failed');
    handleToast(ToastType.Error, error.message);
  }
};

export async function deleteCarrierDetailsDocument(payload: {
  file: string;
  type: string;
}) {
  try {
    await apiClient.delete('/carrier/deletefile', { data: payload });
  } catch (e: unknown) {
    const error = createError(e, 'Deleting carrier document failed');
    handleToast(ToastType.Error, error.message);
  }
}

export const fetchCarrierDetailsClaimHistory = async (carrierId: string) => {
  try {
    const response: V4ApiResponse<CarrieDetailsClaimHistory[]> =
      await apiClient.get(`carrier/claimhistory/${carrierId}`);
    if (response.isSuccess) {
      return response.value;
    }
  } catch (e: unknown) {
    const error = createError(e, 'Fetching carrier claim history failed');
    handleToast(ToastType.Error, error.message);
  }
};

export const deleteCarrier = async (carrierId: number) => {
  try {
    const res: V4ApiResponse<boolean> = await apiClient.delete(
      `/Carrier/${carrierId}`,
    );
    return res.isSuccess;
  } catch (e: unknown) {
    const error = createError(e, 'Deleting carrier failed');
    handleToast(ToastType.Error, error.message);
  }
};

export const blacklistCarrier = async (params: {
  id: number;
  comment: string;
}) => {
  try {
    const res: V4ApiResponse<null> = await apiClient.post(
      `/Carrier/addtoblacklist?id=${params.id}&comment=${params.comment}`,
    );
    return res.isSuccess;
  } catch (e: unknown) {
    const error = createError(e, 'Blacklisting carrier failed');
    handleToast(ToastType.Error, error.message);
  }
};

export const unblacklistCarrier = async (carrierId: number) => {
  try {
    const res: V4ApiResponse<null> = await apiClient.put(
      `/Carrier/removefromblacklist?id=${carrierId}`,
    );
    return res.isSuccess;
  } catch (e: unknown) {
    const error = createError(e, 'Unblacklisting carrier failed');
    handleToast(ToastType.Error, error.message);
  }
};

export async function saveCarrierDetails(payload: CarrierDetails) {
  try {
    const res: V4ApiResponse<CarrierDetails> = await apiClient.post(
      '/Carrier',
      payload,
    );
    handleToast(ToastType.Success, 'Carrier details saved successfully');
    return res.value.id;
  } catch (e: unknown) {
    const error = createError(e, 'Saving carrier details failed');
    handleToast(ToastType.Error, error.message);
  }
}
