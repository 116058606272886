import { MenuItemType } from '@components';

export const falveyVendorIntialState = {
  operationType: 'Insert',
  id: 0,
  orderId: 0,
  description: 'Insured Value:',
  invoicedDate: null,
  paidDate: null,
  name: 'Falvey Shippers Insurance',
  mailingAddress1: '66 Whitecap Dr',
  mailingAddress2: '',
  mailingCity: 'North Kingstown',
  mailingState: 'RI',
  mailingZip: '02852',
  countryCode: 'USA',
  serviceType: 'LTL Insurance',
  serviceTypeText: null,
  currencyType: 'USD',
  contactName: 'Falvey',
  contactPhone: '4017920144',
  status: null,
  synched: false,
  lineItems: [],
  payments: [],
  vendorPayDisplay: 0,
  otherServiceType: false,
};

export const trackingFrequency: MenuItemType[] = [
  {
    label: '15 minutes',
    value: '15',
  },
  {
    label: '30 minutes',
    value: '30',
  },
  {
    label: '1 hour',
    value: '60',
  },
  {
    label: '2 hours',
    value: '120',
  },
  {
    label: '4 hours',
    value: '240',
  },
];
