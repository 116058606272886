import { Paper, Box } from '@suid/material';
import { FalveyInsuranceQuote, LtlQuoteViewModel } from '@store/orders/types';

import { QuoteCarrierDetails } from './QuoteCarrierDetails';
import { QuoteCostDetails } from './QuoteCostDetails';

export function QuoteDetailsRowRender(
  props: Readonly<{
    quote: LtlQuoteViewModel;
    insuranceQuote?: FalveyInsuranceQuote;
    isHistory: boolean;
    customerId: number;
  }>,
) {
  const hasInsurance = !!props.insuranceQuote;

  return (
    <Paper
      sx={{
        display: 'flex',
        p: 2,
        gap: 2,
        mb: 2,
      }}
      elevation={2}
    >
      {/* LEFT: Carrier Details */}
      <Box
        sx={{
          // Same ratio logic you already have
          flex: hasInsurance ? 1.2 : 1.5,
          minWidth: 0,
        }}
      >
        <QuoteCarrierDetails quote={props.quote} />
      </Box>

      {/* RIGHT: Cost Details Container */}
      <Box
        sx={{
          display: 'flex',
          // If there's insurance, the cost container flexes to fill leftover
          flex: hasInsurance ? 1 : '0 0 auto',
          gap: 2,
        }}
      >
        {/* The "main" cost panel */}
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            // If single panel, cap width so it doesn't stretch too wide
            maxWidth: hasInsurance ? 'none' : 360,

            // 1) Let this panel share space evenly with the second panel
            // 2) flexBasis: 0 => means "start at 0 width" so the parent's space
            //    gets evenly distributed by flex:1
            // 3) minWidth:0 => prevents the content from forcing its width bigger
            flex: hasInsurance ? '1 1 0' : '0 1 auto',
            minWidth: 0,
            overflow: 'hidden',
          }}
        >
          <QuoteCostDetails
            quote={props.quote}
            insuranceQuote={props.insuranceQuote}
            customerId={props.customerId}
            isInsuranceRequest={false}
            isHistory={props.isHistory}
          />
        </Paper>

        {/* Only show the second cost panel if insurance is present */}
        {hasInsurance && (
          <Paper
            sx={{
              // Mirror the logic so both cost panels share space equally
              flex: '1 1 0',
              minWidth: 0,
              overflow: 'hidden',

              p: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <QuoteCostDetails
              quote={props.quote}
              insuranceQuote={props.insuranceQuote}
              customerId={props.customerId}
              isInsuranceRequest
              isHistory={props.isHistory}
            />
          </Paper>
        )}
      </Box>
    </Paper>
  );
}
