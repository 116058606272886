import { Button, ToastType, Typography } from '@components';
import { DialogBox } from '@components/DialogBox';
import { TextInput } from '@components/forms';
import { openDialogBox } from '@store/dialogBox';
import {
  AddFlagCommentPayload,
  addFlagComment,
  deleteFlagComment,
  getFlagComment,
} from '@store/orders';
import { Delete } from '@suid/icons-material';
import { Box, Grid, Skeleton } from '@suid/material';
import { convertUtcToLocalAndFormat } from '@utils/dateFormat';
import { handleToast } from '@utils/utils';
import { isEmpty } from 'lodash';
import { Component, Show, createSignal, onMount } from 'solid-js';

import { editBillingModalClasses as cls } from './classes';

type BillNoteFlagCommentsProps = {
  flagId: number;
};

const BillNoteFlagComments: Component<BillNoteFlagCommentsProps> = (
  props: BillNoteFlagCommentsProps,
) => {
  const flagId = props.flagId;
  const [comment, setComment] = createSignal('');
  const [comments, setComments] = createSignal<
    Record<string, string | number>[]
  >([]);
  const [loading, setLoading] = createSignal({
    fetch: false,
    add: false,
  });
  const [deleteCommentId, setDeleteCommentId] = createSignal<number | null>(
    null,
  );

  const addCommentOnBillingNote = async (
    e: Event & {
      submitter: HTMLElement;
    } & {
      currentTarget: HTMLFormElement;
      target: Element;
    },
  ) => {
    e.preventDefault();
    try {
      setLoading({
        ...loading(),
        add: true,
      });
      const payload: AddFlagCommentPayload = {
        id: flagId,
        comment: comment(),
      };
      const response = await addFlagComment(payload);
      setComments([
        ...comments(),
        response as unknown as Record<string, string | number>,
      ]);
      setComment('');
      handleToast(ToastType.Success, 'Comment Added Successfully');
    } catch (error) {
      handleToast(ToastType.Error, 'Failed to Add Comment');
    } finally {
      setLoading({
        ...loading(),
        add: false,
      });
    }
  };

  const getCommentOnBillingNote = async () => {
    try {
      setLoading({
        ...loading(),
        fetch: true,
      });
      const response = await getFlagComment(flagId);
      setComments(response);
    } catch (error) {
      handleToast(ToastType.Error, 'Failed to Fetch Comment');
    } finally {
      setLoading({
        ...loading(),
        fetch: false,
      });
    }
  };

  const deleteCommentOnBillingNote = async () => {
    try {
      if (deleteCommentId() === null) return;
      const response = await deleteFlagComment(deleteCommentId()!);
      if (!isEmpty(response) && Boolean(response.isSuccess)) {
        const newComments = comments().filter(
          (x) => x.id !== deleteCommentId(),
        );
        setComments(newComments);
        handleToast(ToastType.Success, 'Comment Deleted Successfully');
      }
    } catch (error) {
      handleToast(ToastType.Error, 'Failed to Delete Comment');
    } finally {
      setDeleteCommentId(null);
    }
  };

  onMount(() => getCommentOnBillingNote());

  return (
    <>
      <Grid item xs={12}>
        <Typography
          variant="body1"
          component="p"
          class={cls.messageHistoryTitle}
        >
          Message History
        </Typography>
      </Grid>
      <Grid item xs={12} displayRaw="flex" flexDirection="column" gap={2}>
        <form onSubmit={addCommentOnBillingNote}>
          <TextInput
            label="Add a Comment"
            placeholder="Enter description here"
            value={comment()}
            onChange={(value) => setComment(value as string)}
          />
          <Button type="submit" label="" class="!hidden" />
        </form>
      </Grid>
      <Show when={loading().fetch}>
        <Grid item xs={12}>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </Grid>
      </Show>
      {comments()
        .toReversed()
        .map((comment: Record<string, string | number>) => (
          <Grid item xs={12}>
            <Box class={cls.messageHeader}>
              <Typography
                variant="h4"
                component="h4"
                class={cls.messageUserName}
              >
                {comment.name}
              </Typography>
              <Box class={cls.messageHeaderRight}>
                <Typography
                  variant="body2"
                  component="span"
                  class={cls.messageDateTime}
                >
                  {convertUtcToLocalAndFormat(
                    comment.timestamp as string,
                    'MMMM dd, yyyy h:mm a',
                  )}
                </Typography>
                <Delete
                  class={cls.deleteIcon}
                  onClick={() => {
                    setDeleteCommentId(Number(comment.id));
                    openDialogBox('deleteNoteComment');
                  }}
                />
              </Box>
            </Box>
            <Box class="p-2">{comment.comment}</Box>
          </Grid>
        ))}
      <DialogBox
        title={'Are you sure you want to delete this comment?'}
        id="deleteNoteComment"
        onSubmit={deleteCommentOnBillingNote}
        onCancel={() => setDeleteCommentId(null)}
      />
    </>
  );
};

export default BillNoteFlagComments;
