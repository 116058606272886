import { BasicTable, Typography } from '@components';
import { Column } from '@components/BasicTable/BasicTable';
import { orderStore } from '@store/orders';
import { formatAmount } from '@utils/utils';
import { OrderLineItem } from '@typeDefinitions/lineItemTypes';

import classes from './classes';

export const Settlement = () => {
  const columns: Column<OrderLineItem>[] = [
    {
      name: 'Type',
      key: 'type',
      renderCell: (rowData) => rowData.type,
    },
    {
      name: 'Description',
      key: 'description',
      renderCell: (rowData) => rowData.description,
    },
    {
      name: 'Settled Date',
      key: 'date',
      renderCell: (rowData) => rowData.settledDate,
    },
    {
      name: 'Amount',
      key: 'amount',
      renderCell: (rowData) => (
        <Typography variant="body2" class={classes.totalAmount}>
          {formatAmount(Number(rowData.quantity) * Number(rowData.rate))}
        </Typography>
      ),
    },
  ];
  const footerRow = [
    {
      key: '#',
    },
    {
      key: 'link',
    },
    {
      renderCell: () => (
        <Typography variant="body2" class={classes.totalLabel}>
          Total
        </Typography>
      ),
      key: 'uploadedDoc',
    },
    {
      renderCell: () => (
        <Typography variant="body2" class={classes.totalAmount}>
          {formatAmount(
            orderStore.order.lineItems.length > 0
              ? orderStore.order.lineItems.reduce(
                  (acc, item) =>
                    acc + Number(item.rate) * Number(item.quantity),
                  0,
                )
              : 0,
          )}
        </Typography>
      ),
      key: 'uploadDoc',
    },
  ];

  return (
    <BasicTable<OrderLineItem>
      footerRow={footerRow}
      columns={columns}
      rows={orderStore.order.lineItems.filter(
        (item) => item.operationType !== 'Delete',
      )}
      tableBodyClasses={classes.tableBg}
      tableFooterClasses={classes.tableFooter}
      tableBodyRowClasses={classes.tableRow}
      tableFooterRowClasses={classes.tableFooterRow}
      cellClasses="!p-1 text-sm"
    />
  );
};
