import { DialogBox } from '@components/DialogBox';
import { openDialogBox } from '@store/dialogBox';
import { orderStore, removeLoadFromOrder, voidLoad } from '@store/orders';
import { Cancel, Warning } from '@suid/icons-material';
import { Box } from '@suid/material';
import { Show } from 'solid-js';
import { ILoadEditViewModel } from '@typeDefinitions/loadTypes';

import classes from './classes';

// eslint-disable-next-line complexity
export const CarrierTab = (
  index: number,
  item?: ILoadEditViewModel,
  setTabIndex?: (index: number) => void,
  disabled?: boolean,
) => {
  return (
    <Box
      class={`flex items-center gap-2 rounded-t-lg px-[5px] py-[5px] 
        ${item?.isCopied ?? false ? 'bg-[#ffe8b7]' : ''}
        ${item?.status === 'Void' ? 'bg-[#F4BDBD]' : ''}`}
    >
      <Show
        when={
          orderStore.orderFormError &&
          Object.keys(orderStore.orderFormError).some((key) =>
            key.includes(`loads[${index}]`),
          )
        }
      >
        <Box class={classes.errorChildBox}>
          <Warning sx={{ color: '#B00020' }} />
        </Box>
      </Show>
      <Box class={classes.tabChildBox}>
        <p
          class={`${
            orderStore.orderFormError &&
            orderStore.orderFormError[`loads[${index}].stopsCustomError`]
              ? '!text-[#B00020]'
              : 'text-[#0E2C3B]'
          } text-sm font-medium`}
        >
          {orderStore.order.loads[index]?.carrierName ?? 'CARRIER'}{' '}
          {item?.status === 'Void' ? (
            <span class={classes.colorRed}>(Voided)</span>
          ) : (
            ''
          )}
        </p>
        <p
          class={`${
            orderStore.orderFormError &&
            orderStore.orderFormError[`loads[${index}].stopsCustomError`]
              ? '!text-[#B00020]'
              : 'text-[#248670]'
          } text-sm font-medium`}
        >
          {`$${
            item?.lineItems
              ? item.lineItems
                  .reduce(
                    (acc, curr) =>
                      (acc += Number(curr.rate) * Number(curr.quantity)),
                    0,
                  )
                  .toFixed(2)
              : '0.00'
          }`}
        </p>
      </Box>
      <Show
        when={
          ((item?.status !== 'Billed' &&
            item?.status !== 'Paid' &&
            item?.status !== 'Void') ||
            item.id === 0) &&
          Boolean(index === orderStore.activeTab.index) &&
          !Boolean(disabled)
        }
        fallback={<></>}
      >
        <Cancel
          sx={{ color: '#123b50' }}
          onClick={(e: MouseEvent) => {
            //This is to prevent the tab click event from firing
            e.stopPropagation();
            if (item?.id === 0) {
              removeLoadFromOrder(index);
              setTabIndex?.(0);
            } else if (
              item?.status !== 'Billed' &&
              item?.status !== 'Paid' &&
              item?.status !== 'Void'
            ) {
              openDialogBox(`voidCarrier${index}`);
            }
          }}
        />
      </Show>
      <DialogBox
        id={`voidCarrier${index}`}
        title={`Are you sure you want to void load? ${item?.loadNum}`}
        onSubmit={() => voidLoad(item.id)}
      />
    </Box>
  );
};
