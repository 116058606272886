import { closeDialogBox, dialogBoxStore } from '@store/dialogBox';

import { DialogBox } from './DialogBox';
import { DialogBoxProps } from './types';

type DialogProps = Omit<DialogBoxProps, 'isDialogOpen' | 'closeDialogBox'> & {
  isDialogOpen?: DialogBoxProps['isDialogOpen'];
  closeDialogBox?: DialogBoxProps['closeDialogBox'];
};

export const WrappedDialogBox = (props: DialogProps) => {
  return (
    <DialogBox
      closeDialogBox={() => closeDialogBox(props.id!)}
      isDialogOpen={dialogBoxStore.dialogIds.includes(props.id!)}
      {...props}
    />
  );
};
