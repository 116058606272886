import { currencyTypes } from '@common/commonLists';
import { BasicModal } from '@components';
import { SelectField, TextInput } from '@components/forms';
import { closeModal, openModal } from '@store/modals';
import {
  carrierStore,
  orderStore,
  updateLoadPropertyAtIndex,
  validateFieldForOrder,
} from '@store/orders';
import { SelectChangeEvent } from '@suid/material/Select';
import { isAdmin, isSuperAdmin } from '@utils/utils';
import { createSignal, Show } from 'solid-js';
import { ILoadEditViewModel } from '@typeDefinitions/loadTypes';

import { HoldPayment } from './HoldPayment';

type Props = {
  load: ILoadEditViewModel;
  tabIndex: number;
};

const CarrierPayAdditionalInfo = (props: Props) => {
  const billingHoldingModalId = `billingHoldReason-${props.tabIndex}`;
  const [paymentStatus, setPaymentStatus] = createSignal<string>('');

  const onPaymentStatusChange = (e: SelectChangeEvent) => {
    setPaymentStatus(e.target.value);
    switch (e.target.value) {
      case 'Hold Carrier Pay':
        openModal(billingHoldingModalId);
        break;
      case 'Release Carrier Pay':
        updateLoadPropertyAtIndex({
          billingHold: false,
          billingHoldReason: '',
          billingHoldDescription: '',
        });
        setPaymentStatus('');
        break;
    }
  };
  return (
    <>
      <div class="flex gap-x-1">
        <Show when={isSuperAdmin() || isAdmin()}>
          <div class="ml-auto max-w-[170px] w-[100%] flex ">
            <SelectField
              size="small"
              menuItems={[
                { label: 'Hold Carrier Pay', value: 'Hold Carrier Pay' },
                {
                  label: 'Release Carrier Pay',
                  value: 'Release Carrier Pay',
                },
              ]}
              labelStyle={{
                inputLabelRoot: {
                  color: 'white',
                },
              }}
              renderValue={() => {
                if (orderStore.order.loads[props.tabIndex].billingHold) {
                  return <div class="mt-1">Release Carrier Pay</div>;
                }
                return <div class="mt-1">Hold Carrier Pay</div>;
              }}
              value={paymentStatus()}
              defaultSx={false}
              onChange={onPaymentStatusChange}
              sxProps={{
                color: 'white',
                '& .MuiSelect-outlined': {
                  height: 27,
                  padding: '7px 16px',
                  minHeight: 0,
                  fontSize: 14,
                  fontWeight: 500,
                },
                '& .MuiMenuItem-root': {
                  padding: '2px 0px 0px 0px',
                },
                '& .MuiSelect-icon': {
                  color: 'white',
                },
              }}
              backgroundColor={
                orderStore.order.loads[props.tabIndex].billingHold
                  ? '#5da05b'
                  : '#B00020'
              }
              disabled={orderStore.isReadOnly}
            />
          </div>
        </Show>
        <TextInput
          // required={props.load.mode !== 'LTL'}
          disabled={props.load.mode === 'LTL' || orderStore.isReadOnly}
          size="small"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          type="number"
          label="Starting Rate"
          placeholder="Add Rate"
          maxLength={10}
          value={props.load.minPrice}
          onChange={(value) => {
            const value1 = value === '' ? null : value;
            updateLoadPropertyAtIndex({
              minPrice: value1 as number,
            });
          }}
          onBlur={() =>
            validateFieldForOrder(
              `loads.${props.tabIndex}.minPrice`,
              `loads[${props.tabIndex}].minPrice`,
            )
          }
          error={
            orderStore.orderFormError &&
            orderStore.orderFormError[`loads[${props.tabIndex}].minPrice`]
          }
        />
        <TextInput
          // required={props.load.mode !== 'LTL'}
          disabled={props.load.mode === 'LTL' || orderStore.isReadOnly}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          size="small"
          type="number"
          label="Max Rate"
          maxLength={10}
          placeholder="Add Rate"
          value={props.load.maxPrice}
          onChange={(value) => {
            const value1 = value === '' ? null : value;
            updateLoadPropertyAtIndex({
              maxPrice: value1 as number,
            });
          }}
          onBlur={() =>
            validateFieldForOrder(
              `loads.${props.tabIndex}.maxPrice`,
              `loads[${props.tabIndex}].maxPrice`,
            )
          }
          error={
            orderStore.orderFormError &&
            orderStore.orderFormError[`loads[${props.tabIndex}].maxPrice`]
          }
        />
        <Show
          when={
            (carrierStore.carrier.length > 0 &&
              carrierStore.carrier[orderStore.activeTab.index]
                ?.currencyOptionEnabled) ||
            (carrierStore.carrier.length > 0 &&
              carrierStore.featureFlags[orderStore.activeTab.index]
                ?.triumphPayCurrency)
          }
        >
          <SelectField
            name="currencyType"
            size="small"
            label="Currency Type"
            menuItems={currencyTypes}
            value={props.load.currencyType}
            onChange={(event) => {
              updateLoadPropertyAtIndex({
                currencyType: event.target.value,
              });
            }}
            disabled={orderStore.isReadOnly}
          />
        </Show>
      </div>
      <BasicModal
        id={billingHoldingModalId}
        title="Billing Hold Reason"
        footer={false}
        showClose={false}
        onClose={() => {
          setPaymentStatus('');
          closeModal(billingHoldingModalId);
        }}
        width="70vw"
        modalStyles={{
          background: 'white',
          borderRadius: '5px',
        }}
      >
        <HoldPayment
          modalId={billingHoldingModalId}
          setPaymentStatus={setPaymentStatus}
          tabIndex={props.tabIndex}
          intialValues={{
            billingHoldReason: props.load.billingHoldReason ?? '',
            billingHoldDescription: props.load.billingHoldDescription ?? '',
          }}
        />
      </BasicModal>
    </>
  );
};

export default CarrierPayAdditionalInfo;
