import { Button } from '@components/Button';
import { Notification } from '@components/Notification';
import { useParams } from '@solidjs/router';
import {
  dateComparator,
  dateFormatter,
  hourAndMinuteMatcherFromText,
} from '@store/loadboard/utils';
import { quoteParametersStore, loadQuoteParameters } from '@store/ltl/store';
import { Box, Grid as MuiGrid, Skeleton, Stack } from '@suid/material';
import { DownloadCSVFileV4 } from '@utils/DownloadCSVFileV3';
import { formatAmount } from '@utils/utils';
import { atgLogo } from '@views/ltl';
import { LTLQuotesActionCellButton } from '@views/ltl/components/LTLQuotesActionCellButton';
import { ColDef, IRowNode } from '@ag-grid-community/core';
import AgGridSolid from '@ag-grid-community/solid';
import { DateTime } from 'luxon';
import { createSignal, onMount, Show } from 'solid-js';
import {
  isFeatureFlagEnabled,
  featureFlagStore,
} from '@store/featureFlags/store';
import { featureFlags } from '@store/featureFlags/featureFlags';
import { LtlQuoteParametersViewModel } from '@store/orders';
import { QuoteDetailGrid } from '@components/Grid/components/QuoteDetailGrid';

import { agGridCellStyle } from './AgGridCellStyle';
import { columnsToolPanel, filtersToolPanel } from './components/constants';

import '../Grid/AgGridStyles.css';

export const agGridCellStyleExpiringSoon = {
  'white-space': 'normal',
  'line-height': '1.6 ',
  'align-items': 'center !important',
  width: '91px',
  height: '1.75rem',
  'background-color': '#F59D25',
  margin: '.5rem',
  'border-radius': '.25rem',
  color: '#FFFFFF',
  'font-size': '0.75rem',
  'justify-content': 'center',
};

const quoteGroupFieldName = 'groupId';

const defaultAggFunc = (params: {
  rowNode: IRowNode | null;
  values: number[] | string[] | null;
}): number | string | null => {
  if (
    params.rowNode &&
    Boolean(params.rowNode.field) &&
    params.rowNode.field === quoteGroupFieldName &&
    params.values &&
    params.values.length >= 0
  ) {
    return params.values[0] as number | string | null;
  }
  return null;
};

const getFirstRowValueFromGroup = (
  node: IRowNode,
): LtlQuoteParametersViewModel | null => {
  if (
    (node.group ?? false) &&
    node.field === quoteGroupFieldName &&
    node.childrenAfterFilter
  ) {
    return node.childrenAfterFilter.length > 0
      ? (node.childrenAfterFilter[0].data as LtlQuoteParametersViewModel)
      : null;
  }
  return null;
};

export const SavedQuotesGrid = (props: {
  isHistory: boolean;
  isCustomerSaveQuote: boolean;
}) => {
  const [csvExportLoading, setCsvExportLoading] = createSignal(false);

  const params = useParams();

  onMount(async () => {
    await loadQuoteParameters(props.isHistory);
  });

  const getActionButtons = (params: {
    data: LtlQuoteParametersViewModel | null;
    node: IRowNode | null;
  }) => {
    if (params.data) {
      return <LTLQuotesActionCellButton quoteParameters={params.data} />;
    }

    if (!params.node) {
      return;
    }

    const quoteVal = getFirstRowValueFromGroup(params.node);
    if (quoteVal) {
      return (
        <LTLQuotesActionCellButton
          quoteParameters={quoteVal}
          isHistory={props.isHistory}
        />
      );
    }
  };
  const columnDefs: ColDef[] = [
    {
      headerName: 'Customer',
      field: 'customerName',
      rowGroup: true,
      valueGetter: (params) => {
        const data = params.data as { customerName?: string } | undefined;
        const customerName = data?.customerName ?? 'Unassigned';
        const quoteParametercount = quoteParametersStore.quoteParameters.filter(
          (quote) => quote.customerName === data?.customerName,
        ).length;
        return `${customerName} (${quoteParametercount})`;
      },
      valueFormatter: (params) => {
        const value = params.value as string;
        return value ? `${value.toUpperCase()}` : '';
      },
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
      },
      autoHeight: true,
      hide: true,
      minWidth: 180,
      flex: 1,
      cellClass: 'ag-custom-cell-class',
      cellRenderer: 'agGroupCellRenderer',
      suppressColumnsToolPanel: true,
    },
    {
      headerName: 'Quote Group #',
      field: quoteGroupFieldName,
      rowGroup: true,
      minWidth: 180,
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
      },
      hide: true,
      floatingFilter: true,
      autoHeight: true,
      cellClass: 'ag-custom-cell-class',
      suppressColumnsToolPanel: true,
      valueGetter: (params) => {
        const data = params.data as LtlQuoteParametersViewModel;
        const locator: string = data.quoteRequestLocator;
        const count: number = data.quoteCount ?? 0;
        return `${locator} (${count})`;
      },
    },
    {
      field: 'quoteDate',
      headerName: 'Date Saved',
      valueFormatter: dateFormatter,
      filter: 'agDateColumnFilter',
      cellStyle: agGridCellStyle,
      floatingFilter: false,
      autoHeight: true,
      filterParams: {
        comparator: dateComparator,
        buttons: ['reset'],
        suppressAndOrCondition: true,
      },
      aggFunc: defaultAggFunc,
    },
    {
      field: 'quoteDate',
      cellStyle: agGridCellStyle,
      autoHeight: true,
      headerName: 'Time Stamp',
      valueFormatter: (params) => {
        const value = params.value as string;
        return value ? DateTime.fromISO(value).toFormat('hh:mm a') : '';
      },
      floatingFilter: false,
      filter: 'agTextColumnFilter',
      filterParams: {
        maxNumConditions: 1,
        filterPlaceholder: 'Enter HH:mm time',
        trimInput: true,
        buttons: ['reset'],
        textMatcher: hourAndMinuteMatcherFromText,
        suppressAndOrCondition: true,
      },
      aggFunc: defaultAggFunc,
    },
    {
      field: 'expirationDateTimeLocal',
      cellStyle: (params: { node: IRowNode | null }) => {
        let isExpiring = false;
        if (params.node?.group ?? false) {
          const groupData = params.node?.aggData as LtlQuoteParametersViewModel;
          isExpiring = groupData.willExpireSoon;
        } else {
          const rowData = params.node?.data as LtlQuoteParametersViewModel;
          isExpiring = rowData.willExpireSoon;
        }
        return isExpiring ? agGridCellStyleExpiringSoon : agGridCellStyle;
      },
      autoHeight: true,
      headerName: 'Expiration Date',
      floatingFilter: false,
      valueFormatter: dateFormatter,
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: dateComparator,
        buttons: ['reset'],
        suppressAndOrCondition: true,
      },
      aggFunc: defaultAggFunc,
    },
    {
      field: 'createdByName',
      headerName: 'Created By',
      filter: 'agTextColumnFilter',
      floatingFilter: false,
      filterParams: {
        suppressAndOrCondition: true,
      },
      valueFormatter: (params) => {
        return params.value as string;
      },
      aggFunc: defaultAggFunc,
    },
    {
      field: 'declaredValue',
      cellRenderer: (params: {
        data: LtlQuoteParametersViewModel | null;
        node: IRowNode | null;
      }) => {
        if (params.data) {
          const declaredValue = params.data.ltlInsuranceQuote?.declaredValue;
          return declaredValue !== undefined && declaredValue > 0
            ? formatAmount(declaredValue)
            : '-';
        }
        const quoteParameters = params.node
          ? getFirstRowValueFromGroup(params.node)
          : null;

        if (quoteParameters) {
          const declaredValue =
            quoteParameters.ltlInsuranceQuote?.declaredValue;
          return declaredValue !== undefined && declaredValue > 0
            ? formatAmount(declaredValue)
            : '-';
        }

        return '';
      },
      pinned: 'right',
      initialWidth: 120,
      floatingFilter: false,
      aggFunc: defaultAggFunc,
    },
    {
      field: 'actions',
      cellRenderer: getActionButtons,
      pinned: 'right',
      initialWidth: 150,
      floatingFilter: false,
    },
    {
      field: 'originZip',
      headerName: 'Origin Zip',
      floatingFilter: false,
      aggFunc: defaultAggFunc,
    },
    {
      field: 'destinationZip',
      headerName: 'Destination Zip',
      floatingFilter: false,
      aggFunc: defaultAggFunc,
    },
    {
      field: 'originCity',
      headerName: 'Origin City',
      hide: true,
      floatingFilter: false,
      aggFunc: defaultAggFunc,
    },
    {
      field: 'originState',
      headerName: 'Origin State',
      hide: true,
      floatingFilter: false,
      aggFunc: defaultAggFunc,
    },
    {
      field: 'destinationCity',
      headerName: 'Destination City',
      hide: true,
      aggFunc: defaultAggFunc,
    },
    {
      field: 'destinationState',
      headerName: 'Destination State',
      hide: true,
      floatingFilter: false,
      aggFunc: defaultAggFunc,
    },
    {
      field: 'willExpireSoon',
      hide: true,
      floatingFilter: false,
      suppressColumnsToolPanel: true,
      aggFunc: defaultAggFunc,
    },
  ];

  const expiringSoonCount = () => {
    return quoteParametersStore.quoteParameters.filter(
      (quoteParameter) => quoteParameter.willExpireSoon,
    ).length;
  };
  const expiringSoonDate = () => {
    return DateTime.local().plus({ days: 1 }).toFormat('M/dd/yy');
  };

  const renderQuoteDetail = function (params: {
    data: LtlQuoteParametersViewModel;
  }) {
    return QuoteDetailGrid({
      quoteParametersId: params.data.id,
      insuranceQuote: params.data.ltlInsuranceQuote ?? undefined,
      isHistory: props.isHistory,
      customerId: params.data.customerId ?? 0,
    });
  };

  const exportCsv = async () => {
    try {
      setCsvExportLoading(true);
      await DownloadCSVFileV4(
        `${
          props.isCustomerSaveQuote
            ? `customer/exportCustomerSavedQuotes/${params.id}/active`
            : 'ltl/exportSavedQuotes/active'
        }`,
        'ActiveQuotesList.csv',
      );
    } finally {
      setCsvExportLoading(false);
    }
  };

  return (
    <Stack class="h-[100%]" spacing={1.2} pt={2}>
      <Box displayRaw="flex" alignItems="center" gap={2} mb={1}>
        <Box flex={1}>
          <Show when={quoteParametersStore.isLoading}>
            <Skeleton variant="text" width={'100%'} height={50} />
          </Show>
          <Show
            when={
              !quoteParametersStore.isLoading &&
              expiringSoonCount() !== 0 &&
              !props.isHistory
            }
          >
            <Notification
              tableRowNotification
              type="warning"
              isNotFlagTitle={true}
              text={
                <>
                  <strong class="font-bold!">{expiringSoonCount()}</strong>{' '}
                  quote(s) will be expiring on
                  <strong class="font-bold!"> {expiringSoonDate()}</strong>.
                  Please review the quotes that have their{' '}
                  <strong>Expiration Date </strong> highlighted in yellow.
                </>
              }
            />
          </Show>
        </Box>
        <Button
          isLoading={csvExportLoading()}
          label="Export Csv"
          variant="outlined"
          onClick={exportCsv}
        />
      </Box>
      <Show when={!featureFlagStore.isLoading}>
        <MuiGrid class="ag-theme-alpine !flex-1">
          <AgGridSolid
            overlayNoRowsTemplate="No data available"
            overlayLoadingTemplate={atgLogo}
            columnDefs={columnDefs}
            rowData={quoteParametersStore.quoteParameters}
            class="no-cell-grid"
            groupHideOpenParents={false}
            isFullWidthRow={(params: { rowNode: IRowNode | undefined }) => {
              if (params.rowNode?.group ?? false) {
                return false;
              }
              return true;
            }}
            fullWidthCellRenderer={renderQuoteDetail}
            getRowHeight={(params: { node: IRowNode | undefined }) => {
              if (params.node?.group ?? false) {
                return 42;
              }
              const rowData = params.node?.data as
                | { quoteCount?: number }
                | undefined;
              const quoteCount = rowData?.quoteCount ?? 1;
              const rowHeight = isFeatureFlagEnabled(featureFlags.falvey)()
                ? 276
                : 266;

              return quoteCount * rowHeight;
            }}
            suppressAggFuncInHeader={true}
            autoGroupColumnDef={{
              filter: 'agTextColumnFilter',
              cellRendererParams: {
                suppressCount: (params: { node: IRowNode }) => {
                  return params.node.field === 'groupId';
                },
              },
              filterValueGetter: (params) => {
                const rowData = params.data as LtlQuoteParametersViewModel;
                const { column } = params;
                const colId = column.getColId();

                if (colId.includes('customerName')) {
                  return rowData.customerName;
                } else if (colId.includes('groupId')) {
                  return rowData.quoteRequestLocator;
                }
              },
            }}
            gridOptions={{
              defaultColDef: {
                flex: 1,
                filter: true,
                floatingFilter: true,
              },
              suppressScrollOnNewData: true,
              groupDisplayType: 'multipleColumns',
              groupDefaultExpanded: 1,
              getRowId: (params: { data: LtlQuoteParametersViewModel }) => {
                const id = params.data.id;
                if (id == 0) {
                  return `missing-${Math.random()}`;
                }
                return String(id);
              },
              pagination: false,
              suppressPaginationPanel: true,
              sideBar: {
                toolPanels: [columnsToolPanel, filtersToolPanel],
                defaultToolPanel: '',
              },
              onGridReady: (params) => {
                quoteParametersStore.isLoading
                  ? params.api.showLoadingOverlay()
                  : params.api.hideOverlay();
              },
            }}
          />
        </MuiGrid>
      </Show>
    </Stack>
  );
};
