import { Row } from '@common/types';
import { BasicTable, Typography } from '@components';
import { orderStore } from '@store/orders';
import { formatAmount } from '@utils/utils';
import { get, size } from 'lodash';

import classes from './classes';

type Props = {
  index: number;
};

export const Settlement = (props: Props) => {
  const getFilteredLineItems = () =>
    get(orderStore.order, `vendors.${props.index}.lineItems`, []).filter(
      (item: Row) => item.operationType !== 'Delete',
    );

  const calculateTotalLineItems = () => {
    return size(getFilteredLineItems()) > 0
      ? getFilteredLineItems().reduce(
          (acc, item: Row) => acc + Number(item.rate) * Number(item.quantity),
          0,
        )
      : 0;
  };

  const columns = [
    {
      name: 'Type',
      key: 'type',
      renderCell: (rowData: Row) => rowData.type,
    },
    {
      name: 'Description',
      key: 'description',
      renderCell: (rowData: Row) => rowData.description,
    },
    {
      name: 'Settled Date',
      key: 'date',
      renderCell: (rowData: Row) => rowData.settledDate,
    },
    {
      name: 'Amount',
      key: 'amount',
      renderCell: (rowData: Row) => (
        <Typography
          variant="body1"
          class={`${classes.totalAmount} !font-normal`}
        >
          {formatAmount(Number(rowData.quantity) * Number(rowData.rate))}
        </Typography>
      ),
    },
  ];
  const footerRow = [
    {
      key: '#',
    },
    {
      key: 'link',
    },
    {
      renderCell: () => (
        <Typography variant="body1" class={classes.totalLabel}>
          Total
        </Typography>
      ),
      key: 'uploadedDoc',
    },
    {
      renderCell: () => (
        <Typography variant="body1" class={classes.totalAmount}>
          {formatAmount(calculateTotalLineItems())}
        </Typography>
      ),
      key: 'uploadDoc',
    },
  ];

  return (
    <BasicTable
      footerRow={footerRow}
      columns={columns}
      tableBodyClasses={classes.tableBg}
      tableFooterClasses={classes.tableFooter}
      tableBodyRowClasses={classes.tableFooterRow}
      rows={getFilteredLineItems()}
      tableFooterRowClasses={classes.tableFooterRow}
    />
  );
};
