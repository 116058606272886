import { Button, Typography } from '@components';
import { Add } from '@suid/icons-material';
import { Box, Grid } from '@suid/material';
import { Component } from 'solid-js';
import { useNavigate } from '@solidjs/router';
import { routeNewFactoringCompanyDetails } from '@utils/routeFactoringCompanyDetails';

import { factoringCompanyHeaderClasses as cls } from './classes';

export const FactoringCompanyHeader: Component = () => {
  const navigate = useNavigate();
  return (
    <Grid
      container
      alignItems="center"
      backgroundColor="white"
      class={cls.headerContainer}
      spacing={2}
    >
      <Grid item xs={12} sm={6}>
        <Box class={cls.factoringNameText}>
          <Typography variant="h4">Factoring Companies</Typography>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        displayRaw="flex"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Button
          startIcon={<Add />}
          variant="contained"
          label="New Factoring Company"
          onClick={() => {
            const isNative = routeNewFactoringCompanyDetails();
            isNative && navigate('/FactoringCompany/details');
          }}
        />
      </Grid>
    </Grid>
  );
};
