import { IOrderViewModel } from '@typeDefinitions/orderTypes';

import {
  HazmatDetails,
  ILoadEditViewModel,
  VendorContractViewModel,
} from './types';

export const vendorIntialState: VendorContractViewModel = {
  operationType: 'Insert',
  id: 0,
  orderId: 0,
  description: '',
  invoicedDate: null,
  paidDate: null,
  name: '',
  mailingAddress1: '',
  mailingAddress2: '',
  mailingCity: '',
  mailingState: '',
  mailingZip: '',
  countryCode: 'USA',
  serviceType: '',
  serviceTypeText: null,
  currencyType: 'USD',
  contactName: '',
  contactPhone: '',
  status: null,
  synched: false,
  lineItems: [],
  payments: [],
  vendorPayDisplay: 0,
  otherServiceType: false,
};

export const defaultLoadEditViewModel: ILoadEditViewModel = {
  operationType: 'Insert',
  id: 0,
  editable: false,
  orderId: 0,
  sequence: 0,
  carrierId: null,
  carrierActionPlan: false,
  teamRequired: false,
  hazmatRequired: false,
  tarpRequired: false,
  billingHold: false,
  trackingEnabled: false,
  enableTracking: false,
  od: false,
  freightValue: undefined,
  weight: 0,
  canRate: false,
  synched: false,
  edi: false,
  postingId: null,
  signedRateCon: false,
  customerId: 0,
  ramps: false,
  canVoidLoad: false,
  assignedId: 0,
  claim: null,
  loadInformation: undefined,

  // Below are all optional properties.
  // If these properties have meaningful default values in your real-world application,
  // you can replace 'undefined' with those values.
  warnings: undefined,
  loadNum: undefined,
  status: 'Unassigned',
  type: undefined,
  customerReferenceNumber: undefined,
  coveredById: undefined,
  coveredBy: undefined,
  carrierName: undefined,
  carrierStatus: undefined,
  carrierContactId: undefined,
  equipment: undefined,
  mode: undefined,
  carrierInstructions: undefined,
  terminal: undefined,
  practicalMileage: undefined,
  shortestMileage: undefined,
  driver: undefined,
  driverPhoneNumber: undefined,
  truckNumber: undefined,
  trailerNumber: undefined,
  trackingNumber: undefined,
  product: undefined,
  tempInstructions: undefined,
  billingHoldReason: undefined,
  billingHoldDescription: undefined,
  billedDate: undefined,
  paidDate: undefined,
  assignedToUserId: undefined,
  assignedToGroupId: undefined,
  assignedToUser: undefined,
  assignedToGroup: undefined,
  assignedToOffice: undefined,
  legalText: undefined,
  profitSplit: undefined,
  sharedWithOfficeId: undefined,
  nmfc: undefined,
  marketRate: undefined,
  class: undefined,
  minPrice: undefined,
  maxPrice: undefined,
  lastInternalNote: undefined,
  signedConfirmationDate: undefined,
  macropointInterval: undefined,
  quoteId: undefined,
  ltlSubCarrier: undefined,
  tenderedToTransfloDate: undefined,
  currencyType: undefined,
  cargoValueIsHigh: undefined,
  owner: undefined,
  carrierContact: undefined,
  ltlQuote: undefined,
  lineItems: undefined,
  payments: undefined,
  stops: undefined,
  comments: undefined,
  flags: undefined,
  items: undefined,
  quoteID: undefined,
  ltlquoteId: undefined,
  newLtlQuoteId: null,
  hideCustomerName: false,
  hasBeenSharedWithCapacity: false,
  enableLoadCarrierRestrictionOverride: false,
  enableLoadCarrierRestrictionOverrideOffice: null,
};

export const DEFAULT_ORDER_STATE: IOrderViewModel = {
  availableServices: [],
  customerId: null,
  currencyType: '',
  referenceNumber: '',
  assignedId: 0,
  status: 'Open',
  bidType: 'Spot Market',
  spotId: undefined,
  spotQuote: undefined,
  owner: '',
  holdRetraction: undefined,
  exceptionReason: '',
  customerContactId: undefined,
  revisitRetractionDate: '',
  lineItems: [],
  payments: [],
  adjustments: [],
  loads: [],
  vendors: [],
  // customerContact: {
  //   id: 0,
  //   objectId: 0,
  //   objectType: '',
  //   name: '',
  //   phone1: '',
  //   extension1: '',
  //   phone2: '',
  //   extension2: '',
  //   fax: '',
  //   email: '',
  //   type: '',
  //   status: '',
  //   createdDate: new Date(Date.now()),
  //   modifiedDate: new Date(Date.now()),
  //   mailerEmails: false,
  //   password: '',
  //   userName: '',
  //   phone: '',
  // },
  operationType: 'Insert',
  id: 0,
  chargeCreditCardFee: false,
  customerCredit: 0,
  billingHold: false,
  agingOut: false,
  editable: false,
  synched: false,
  private: false,
  edi: false,
  trackLoads: false,
  show214: false,
  daysPastDue: 0,
  canVoidOrder: false,
  createdDate: new Date(Date.now()),
  createdBy: 0,
  isDeleted: false,
  loadInformation: undefined,
  metadata: [],
  hideCustomerName: false,
};

export const defaultCustomerDetails = {
  id: 0,
  name: '',
  capacityGroup: {
    id: null,
    leader: null,
  },
  creditLimit: 0,
  available: 0,
  status: '',
  paymentTerms: '',
  thirdParty: false,
  edi: false,
  accountManager: {
    name: '',
    id: 0,
  },
  salesManager: {
    name: '',
    id: 0,
  },
  freightContacts: [],
  billingContacts: [],
  specialInstructions: '',
  trackLoads: false,
  requiredTrackingService: 0,
  trackingVisibilityProvider: null,
  chargeCreditCardFee: false,
  pcMilerVersion: '',
  metadata: [],
  invoiceMethod: '',
};

export const intialHazmatDetails: HazmatDetails = {
  operationType: 'Insert',
  unNumber: '',
  packingGroup: '',
  hazardClass: '',
  piece: '',
  contactNumber: '',
  loadItemId: 0,
  contactName: '',
};
