import { AddressCard, BasicModal, BasicTable, Button, Card } from '@components';
import { Contact } from '@store/carriers';
import { closeModal, openModal } from '@store/modals';
import { Delete, PersonAdd } from '@suid/icons-material';
import { IconButton, TableCell, TableRow } from '@suid/material';
import { AddNewContact } from '@views/order/components';
import {
  factoringCompanyDetailsStore,
  updateFactoringCompanyDetailsState,
} from '@store/factoringCompany/factoringCompanyDetails';
import { Component, createSignal, Index } from 'solid-js';
import { cloneDeep } from 'lodash';
import { OperationTypeV4 } from '@store/orders';

import { factoringCompanyDetailsContactCardClasses as cls } from './classes';

export const FactoringCompanyDetailsContactCard: Component = () => {
  const [selectedContact, setSelectedContact] = createSignal<Contact | null>(
    null,
  );

  const contactModalId = 'contactModalId';

  const handleFactoringCompanyContactData = (values: Contact) => {
    const updatedValues = {
      ...values,
      phone: values.phone1,
      operationType: !Boolean(values.id)
        ? ('Insert' as OperationTypeV4)
        : values.operationType,
    };
    const contacts = (
      factoringCompanyDetailsStore.factoringCompanyDetails.contacts ?? []
    ).map((contact) =>
      contact.id === values.id
        ? {
            ...contact,
            ...updatedValues,
            operationType:
              values.operationType !== 'Insert'
                ? ('Update' as OperationTypeV4)
                : ('Insert' as OperationTypeV4),
          }
        : contact,
    );

    if (!contacts.some((contact) => contact.id === values.id)) {
      contacts.push({ ...updatedValues, id: contacts.length + 1 });
    }
    updateFactoringCompanyDetailsState({ contacts });
    handleClose();
  };

  const handleDeleteContact = (id: number) => {
    const contacts = cloneDeep(
      factoringCompanyDetailsStore.factoringCompanyDetails.contacts ?? [],
    );
    const idx = contacts.findIndex((x) => x.id === id);
    if (contacts[idx].operationType === 'Insert') {
      contacts.splice(idx, 1);
    } else {
      contacts[idx].status = 'Inactive';
      contacts[idx].operationType = 'Update';
    }
    updateFactoringCompanyDetailsState({ contacts });
  };

  const handleClose = () => {
    closeModal(contactModalId);
    setSelectedContact(null);
  };

  const onEditContact = (contact: Contact) => {
    setSelectedContact(contact);
    openModal(contactModalId);
  };

  const columns = [{ name: 'Name' }, { name: 'Type' }, { name: 'Delete' }];

  const filteredContacts = () =>
    (
      factoringCompanyDetailsStore.factoringCompanyDetails.contacts ?? []
    ).filter((contact) => contact.status !== 'Inactive');

  return (
    <Card
      startTitle="Contact"
      accordion={true}
      loading={factoringCompanyDetailsStore.isLoading}
      endIcon={
        <Button
          variant="contained"
          color="primary"
          label="New Contact"
          onClick={() => openModal(contactModalId)}
          startIcon={<PersonAdd />}
        />
      }
    >
      <BasicTable
        columns={columns}
        tableContainerClasses="max-h-[400px] scrollbar-hide hover:scrollbar-default"
        tableHeaderClasses="!sticky top-0 !bg-[#f2f6f8] !shadow-sm z-[1]"
      >
        <Index each={filteredContacts()}>
          {(item) => (
            <TableRow
              class={cls.addressCardRow}
              onClick={() => onEditContact(item())}
            >
              <TableCell>
                <AddressCard
                  isCustomerContact={true}
                  mode="regular"
                  contact={{
                    userName: item().userName,
                    name: item().name,
                    email: item().email,
                    phone1: item().phone1,
                    extension1: item().extension1,
                    fax: item().fax,
                  }}
                  contactType={item().type}
                />
              </TableCell>
              <TableCell>{item().type}</TableCell>
              <TableCell>
                <IconButton
                  onClick={(e) => {
                    e.stopImmediatePropagation();
                    handleDeleteContact(item().id);
                  }}
                >
                  <Delete class={cls.deleteIcon} />
                </IconButton>
              </TableCell>
            </TableRow>
          )}
        </Index>
      </BasicTable>
      <BasicModal
        id={contactModalId}
        title={`${selectedContact() ? 'Edit' : 'Add'} Contact`}
        width="fit-content"
        footer={false}
        showClose={false}
        onClose={() => handleClose()}
      >
        <AddNewContact
          modalId={contactModalId}
          editObject={selectedContact}
          handleClose={handleClose}
          onSubmit={(values) => {
            handleFactoringCompanyContactData(values as Contact);
          }}
          initiatePath="FactoringCompanyDetails"
        />
      </BasicModal>
    </Card>
  );
};
