import { carrierUrls } from '@constants/urls';
import { OpenInNew } from '@suid/icons-material';
import { Box, Button, Stack } from '@suid/material';
import { Show } from 'solid-js';
import { ILoadEditViewModel } from '@typeDefinitions/loadTypes';

type PropsT = {
  load: ILoadEditViewModel;
  tabIndex: number;
  isCarrierSelected: boolean;
};

export const CarrierDetailActionButtons = (props: PropsT) => {
  return (
    <Show when={Boolean(props.load.carrierId) && Boolean(props.load.id)}>
      <Stack direction="row-reverse" gap={1} alignItems="center" mb="-5px">
        <Box>
          <Button
            variant="contained"
            size="small"
            class="!text-armstrong-sm !bg-white !border-[#123B50] !border font !rounded !text-[#123B50]"
            startIcon={<OpenInNew />}
            sx={{
              border: '1px solid',
            }}
            onClick={() =>
              window.open(carrierUrls.billOfLading(props.load.id), '_blank')
            }
          >
            Bill of Lading
          </Button>
        </Box>
        <Box>
          <Button
            variant="contained"
            size="small"
            class="!text-armstrong-sm !bg-white !border-[#123B50] !border font !rounded !text-[#123B50]"
            startIcon={<OpenInNew />}
            sx={{
              border: '1px solid',
            }}
            onClick={() =>
              window.open(carrierUrls.rateConfirmation(props.load.id), '_blank')
            }
          >
            Rate Confirmation
          </Button>
        </Box>
      </Stack>
    </Show>
  );
};
