import { Button } from '@components';
import { openModal } from '@store/modals';
import { ILoadEditViewModel } from '@typeDefinitions/loadTypes';

type Props = {
  load: ILoadEditViewModel;
};

export const GetRates = (props: Props) => {
  return (
    <div>
      {(props.load.ltlquoteId === null || props.load.id <= 0) && (
        <Button
          label={'Get LTL Rates'}
          onClick={() => {
            openModal('ltl-quoting-modal');
          }}
        />
      )}
    </div>
  );
};
