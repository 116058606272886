import { ILoadComments, LoadTrackingT } from '@store/orders';
import { formatDateString } from '@utils/dateFormat';
import { OperationType } from '@typeDefinitions/operationTypes';

export const trackingToComment = (t: LoadTrackingT): ILoadComments => {
  return {
    city: null,
    comment: `Shipment last tracked in ${t.city}, ${
      t.state
    } at ${formatDateString(t.locationTime, 'DDD t')}`,
    id: 0,
    latitude: t.latitude ?? null,
    longitude: t.longitude ?? null,
    milesRemaining: 0,
    name: '',
    objectId: 0,
    operationType: OperationType.None,
    state: null,
    temperature: 0,
    thumbnail: null,
    timestamp: '',
    type: null,
    userId: 0,
    visibleToCustomer: true,
  };
};
