import { ExcelSheetIcon } from '@assets';
import { Button } from '@components';
import { fetchFactoringCompanyCarrierGridData } from '@store/factoringCompany/factoringCompanyDetails';
import { Box, Grid, Stack } from '@suid/material';
import { atgLogo } from '@views/ltl';
import {
  ColDef,
  GridReadyEvent,
  IServerSideDatasource,
} from '@ag-grid-community/core';
import AgGridSolid, { AgGridSolidRef } from '@ag-grid-community/solid';
import { Component, createSignal } from 'solid-js';
import { FactoringCompany } from '@store/carriers';
import { routeCarrierDetails } from '@utils/routeCarrierDetails';
import { useNavigate, useParams } from '@solidjs/router';
import { DateTime } from 'luxon';
import { Carrier } from '@store/carriers/types';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';

import cls from './classes';

const agGridCellStyle = {
  padding: '5px 10px',
  'word-break': 'break-word',
  'white-space': 'normal',
  'line-height': '1.6 ',
  'align-items': 'center !important',
};

export const FactoringCompanyDetailsCarriersTab: Component = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [gridRef, setGridRef] = createSignal<AgGridSolidRef | null>(null);
  const commonDefs = {
    cellStyle: agGridCellStyle,
    filter: 'agTextColumnFilter',
    autoHeight: true,
    filterParams: {
      filterOptions: ['startsWith', 'equals', 'notEqual', 'contains'],
      suppressAndOrCondition: true,
      buttons: ['apply', 'reset'],
    },
  };

  const filterTypeMapping = {
    equals: 'EQUALS',
    notEqual: 'NOT_EQUALS',
    greaterThan: 'GREATER_THAN',
    greaterThanOrEqual: 'GREATER_THAN_OR_EQUALS',
    lessThan: 'LESS_THAN',
    lessThanOrEqual: 'LESS_THAN_OR_EQUALS',
    contains: 'CONTAINS',
    notContains: 'NOT_CONTAINS',
    startsWith: 'STARTS_WITH',
    endsWith: 'ENDS_WITH',
    blank: 'BLANK',
    notBlank: 'NOT_BLANK',
    inRange: 'IN_RANGE',
  };

  const columnDefs: ColDef[] = [
    {
      headerName: 'Name',
      field: 'name',
      sortable: true,
      sort: 'asc',
      ...commonDefs,
    },
    {
      headerName: 'MC',
      field: 'mc',
      ...commonDefs,
    },
    {
      field: 'dot',
      headerName: 'DOT',
      ...commonDefs,
    },
    {
      field: 'mailingCity',
      headerName: 'City',
      ...commonDefs,
    },
    {
      field: 'mailingState',
      headerName: 'State',
      ...commonDefs,
    },
  ];

  const transformFilters = (inputFilters: { [key: string]: unknown }) => {
    const outputFilters = Object.keys(inputFilters).map((key) => {
      const filter = inputFilters[key] as {
        filterType: string;
        type: keyof typeof filterTypeMapping;
        filter: string | number;
        dateFrom?: string | null;
        dateTo?: string | null;
      };
      if ((filter as { filterType: string }).filterType === 'date') {
        const dateFilters = [];

        if (Boolean(filter.dateFrom)) {
          dateFilters.push(
            DateTime.fromFormat(
              filter.dateFrom as string,
              'yyyy-MM-dd HH:mm:ss',
            ).toFormat('MM/dd/yyyy'),
          );
        }

        if (Boolean(filter.dateTo)) {
          dateFilters.push(
            DateTime.fromFormat(
              filter.dateTo as string,
              'yyyy-MM-dd HH:mm:ss',
            ).toFormat('MM/dd/yyyy'),
          );
        }
        return {
          field: key,
          filterType:
            filterTypeMapping[
              (filter as { type: keyof typeof filterTypeMapping }).type
            ],
          values: dateFilters,
        };
      }
      return {
        field: key,
        filterType:
          filterTypeMapping[
            (filter as { type: keyof typeof filterTypeMapping }).type
          ],
        values: [(filter as { filter: string }).filter],
      };
    });
    // Always include the factoringCompanyId filter
    outputFilters.push({
      field: 'factorId',
      filterType: 'EQUALS',
      values: [params.id],
    });

    return outputFilters;
  };

  const onGridReady = (params: GridReadyEvent) => {
    setGridRef(params);
    const dataSource = {
      getRows: async (params: {
        api: {
          paginationGetCurrentPage: () => number;
        };
        request: {
          filterModel: {
            [key: string]: {
              filter?: string;
              dateFrom?: string | null;
              dateTo?: null;
              filterType: 'date' | 'text' | 'set';
              type: string;
              values: string[];
            };
          };
          sortModel: {
            colId: string;
            sort: string;
          }[];
        };
        success: (data: { rowData: Carrier[]; rowCount: number }) => void;
        fail: () => void;
      }) => {
        let orderBy = '';
        let sortBy = '';
        if (params.request.sortModel.length > 0) {
          orderBy = params.request.sortModel[0].colId;
          sortBy = params.request.sortModel[0].sort;
        }
        const filteredObject = {
          filters: transformFilters(params.request.filterModel),
          sort: {
            field: orderBy || 'name',
            direction: sortBy || 'asc',
          },
          pageNumber: params.api.paginationGetCurrentPage() + 1,
          pageSize: 50,
        };

        try {
          gridRef()?.api.showLoadingOverlay();
          const data: {
            data: Carrier[];
            totalCount: number;
          } = await fetchFactoringCompanyCarrierGridData(filteredObject);
          params.success({
            rowData: data.data,
            rowCount: data.totalCount,
          });
        } catch (error) {
          params.fail();
        } finally {
          gridRef()?.api.hideOverlay();
        }
      },
    };
    params.api.setGridOption(
      'serverSideDatasource',
      dataSource as unknown as IServerSideDatasource,
    );
  };

  return (
    <Stack class={cls.carrierTabGridContainer} spacing={1}>
      <Box displayRaw="flex" alignItems="center" justifyContent="end">
        <Button
          startIcon={<img src={ExcelSheetIcon} alt="Excel Sheet" />}
          variant="contained"
          label="Export to Excel"
          class="cursor-pointer"
          onClick={() =>
            gridRef()?.api.exportDataAsExcel({
              fileName: 'FactoringCompanyCarriers.xlsx',
            })
          }
        />
      </Box>
      <Grid class={cls.carrierTabGrid}>
        <AgGridSolid
          modules={[ServerSideRowModelModule]}
          ref={gridRef}
          overlayNoRowsTemplate="No data available"
          overlayLoadingTemplate={atgLogo}
          columnDefs={columnDefs}
          gridOptions={{
            defaultColDef: { flex: 1, filter: true },
            onGridReady: onGridReady,
            rowHeight: 50,
            rowModelType: 'serverSide',
            pagination: true,
            paginationPageSize: 50,
            cacheBlockSize: 50,
            paginationPageSizeSelector: false,
            onCellClicked: (params: { data: FactoringCompany }) => {
              const { id } = params.data;
              if (!id) return;
              if (routeCarrierDetails(id)) {
                navigate(`/carrier/details/${id}`);
              }
            },
          }}
          class="no-cell-grid"
        />
      </Grid>
    </Stack>
  );
};
