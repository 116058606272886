import { BasicTable, Typography } from '@components';
import { orderStore, PickUpDropOffItem } from '@store/orders';
import { Stack, TableCell, TableRow } from '@suid/material';
import { createSignal, Index } from 'solid-js';
import {
  ArrowCircleDown,
  ArrowCircleUp,
  ArrowDropDown,
  ArrowDropUp,
} from '@suid/icons-material';
import { formatPhoneNumber } from '@utils/phoneNumberFormat';
import { DateTime } from 'luxon';
import { Column } from '@components/BasicTable/BasicTable';
import { getEligibleEntries } from '@utils/utils';
import { LastUsedTopStopModel } from '@typeDefinitions/stopTypes';
import { IOrderViewModel } from '@typeDefinitions/orderTypes';

import EDIStopEditSection from './EDIStopEditSection';

const iconStyles = {
  color: '#0070A2',
};

type Props = {
  noUpdate: boolean;
  order: IOrderViewModel;
};

const stopsColumns: Column<LastUsedTopStopModel>[] = [
  {
    name: 'Date',
    key: 'date',
    minWidth: 180,
  },
  {
    name: 'Location',
    key: 'location',
    minWidth: 160,
  },
  {
    name: 'Items',
    key: 'items',
    minWidth: 120,
  },
  {
    name: 'Weight',
    key: 'weight',
  },
  { name: '' },
];

const EDICompareChangeStops = (props: Props) => {
  const [currentStopIds, setCurrentStopIds] = createSignal<number[]>([]);
  const [loadTenderIds, setLoadTenderIds] = createSignal<number[]>([]);
  const showStopEditSection = (id: number) =>
    props.noUpdate
      ? currentStopIds().includes(id)
      : loadTenderIds().includes(id);

  const getTotalWeight = (rowData: LastUsedTopStopModel) => {
    const items = getEligibleEntries<PickUpDropOffItem>(
      props.order.loads[0].items,
    );

    return items
      .reduce((acc, itm) => {
        if (
          itm.operationType === 'Delete' ||
          (rowData.dropOff && rowData.id != itm.dropOffId) ||
          (!rowData.dropOff && rowData.id !== itm.pickUpId)
        ) {
          acc += 0;
        } else {
          acc += itm.weight;
        }
        return acc;
      }, 0)
      .toLocaleString();
  };

  const handleToggleClick = (id: number) => {
    const signal = props.noUpdate ? currentStopIds : loadTenderIds;
    const setSignal = props.noUpdate ? setCurrentStopIds : setLoadTenderIds;

    if (signal().includes(id)) {
      setSignal(signal().filter((i) => i !== id));
    } else {
      setSignal([...signal(), id]);
    }
  };

  const renderToggleIcons = (id: number) => {
    const isSelected = props.noUpdate
      ? currentStopIds().includes(id)
      : loadTenderIds().includes(id);
    return isSelected ? <ArrowDropUp /> : <ArrowDropDown />;
  };

  return (
    <BasicTable<LastUsedTopStopModel> columns={stopsColumns}>
      <Index each={props.order.loads[0].stops}>
        {(rowData, index) => {
          return rowData().operationType === 'Delete' ? null : (
            <>
              <TableRow
                sx={{
                  verticalAlign: 'top',
                  display: 'table-row',
                  height: '200px',
                }}
              >
                <TableCell>
                  <div class="flex items-center">
                    <span class="mr-4">
                      {rowData().pickUp && <ArrowCircleUp sx={iconStyles} />}
                      {rowData().dropOff && <ArrowCircleDown sx={iconStyles} />}
                    </span>
                    {DateTime.fromISO(rowData().stopDateTime!, {
                      zone: 'utc',
                    }).toFormat('MM/dd/yyyy')}
                  </div>
                </TableCell>
                <TableCell>
                  <Stack spacing={1}>
                    <Typography
                      component="p"
                      sxProps={{ fontWeight: 'bold' }}
                      variant="inherit"
                    >
                      {rowData().locationName}
                    </Typography>{' '}
                    <Typography component="p" variant="inherit">
                      {rowData().address1}, {rowData().address2},{' '}
                      {rowData().city}, {rowData().state}
                    </Typography>
                    <Typography component="p" variant="inherit">
                      {rowData().contact}
                      <br />
                      {formatPhoneNumber(rowData().phone ?? '')}
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell>
                  {orderStore.order.loads[0].items?.map((itm) => {
                    if (
                      itm.operationType !== 'Delete' &&
                      (itm.pickUpId == rowData().id ||
                        itm.dropOffId == rowData().id)
                    ) {
                      return (
                        <p>{`${itm.quantity} ${itm.item} ${itm.description}`}</p>
                      );
                    }
                  })}
                </TableCell>
                <TableCell>{getTotalWeight(rowData())} lbs</TableCell>
                <TableCell>
                  <span
                    class="cursor-pointer"
                    onClick={() => handleToggleClick(index)}
                  >
                    {renderToggleIcons(index)}
                  </span>
                </TableCell>
              </TableRow>
              {showStopEditSection(index) && (
                <EDIStopEditSection
                  colspan={stopsColumns.length}
                  noUpdate={props.noUpdate}
                  stop={rowData()}
                  items={props.order.loads[0].items ?? []}
                  index={index}
                />
              )}
            </>
          );
        }}
      </Index>
    </BasicTable>
  );
};

export default EDICompareChangeStops;
