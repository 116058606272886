import { Tab, TabsComponent } from '@components';
import { Box } from '@suid/material';
import { Component, createSignal, Match, Switch } from 'solid-js';
import { FactoringCompanyEditHistory } from '@views/factoringCompany/factoringCompanyEditHistory';
import { factoringCompanyDetailsStore } from '@store/factoringCompany/factoringCompanyDetails';

import { FactoringCompanyDetailsTab } from './FactoringCompanyDetailsTab';
import { FactoringCompanyDetailsCarriersTab } from './FactoringCompanyDetailsCarriersTab';

export const FactoringCompanyDetailsTabPanel: Component = () => {
  const [activeTab, setActiveTab] = createSignal(0);

  const tabs = ['DETAILS', 'CARRIERS', 'EDIT HISTORY'];
  const createTabs = ['DETAILS'];

  const tabsToRender = () => {
    return factoringCompanyDetailsStore.creationMode ? createTabs : tabs;
  };

  return (
    <>
      <TabsComponent
        tabs={tabsToRender().map((item) => (
          <Tab>{item}</Tab>
        ))}
        activeTab={activeTab()}
        onTabClick={setActiveTab}
        border={true}
        tabStyleClasses="rounded-t-lg"
        tabContainerStyles="ml-0 items-center"
        activeStyling="bg-[#ffe8b7]"
      />
      <div class="border-t border-gray-300 mb-2 ml-0" />
      <Box p={0}>
        <Switch>
          <Match when={activeTab() === 0}>
            <FactoringCompanyDetailsTab />
          </Match>
          <Match when={activeTab() === 1}>
            <FactoringCompanyDetailsCarriersTab />
          </Match>
          <Match when={activeTab() === 2}>
            <FactoringCompanyEditHistory />
          </Match>
        </Switch>
      </Box>
    </>
  );
};
