import { apiClient } from '@api/apiClient';
import { V4ApiResponse } from '@store/customers/customerDetails';
import { createError } from '@utils/errorUtils';

import { OldLoad, OldLoadPayload } from './types';

export const fetchOldLoads = async (payload: OldLoadPayload) => {
  try {
    const response: V4ApiResponse<{
      data: OldLoad[];
      totalCount: number;
    }> = await apiClient.post('/queue/oldloads', payload);
    if ('value' in response) {
      return response.value;
    }
  } catch (error: unknown) {
    throw createError(error, 'Failed to update status');
  }
};
