import { BasicModal } from '@components';
import {
  fetchLtlQuoteTerminals,
  LtlTerminalInfoViewModel,
  LtlTerminalViewModel,
} from '@store/orders';
import { Grid } from '@suid/material';
import { createEffect, createSignal } from 'solid-js';
import { isFeatureFlagEnabled } from '@store/featureFlags/store';
import { featureFlags } from '@store/featureFlags/featureFlags';

export const TerminalInfoModal = (props: {
  onClose: (modalId: string) => void;
  modalId: string;
  terminalInfo?: LtlTerminalInfoViewModel;
  quoteId: string;
}) => {
  const [terminalInfo, setTerminalInfo] = createSignal<
    LtlTerminalInfoViewModel | undefined
  >(props.terminalInfo);

  const sectionTerminal = (info: LtlTerminalViewModel | undefined) => {
    if (!Boolean(info)) return 'No Data to display';
    return (
      <div class="border-2 px-2 bg-[#F5F5F5] ">
        <div class="flex my-1">
          Name: <span class="ml-auto font-semibold">{info?.terminalName}</span>
        </div>
        <div class="flex my-1">
          Address1: <span class="ml-auto font-semibold">{info?.address1}</span>
        </div>
        <div class="flex my-1">
          Address2: <span class="ml-auto font-semibold">{info?.address2}</span>
        </div>
        <div class="flex my-1">
          City: <span class="ml-auto font-semibold">{info?.city}</span>
        </div>
        <div class="flex my-1">
          State:{' '}
          <span class="ml-auto font-semibold">{info?.stateOrProvince}</span>
        </div>
        <div class="flex my-1">
          Zip: <span class="ml-auto font-semibold">{info?.postalCode}</span>
        </div>
        <div class="flex my-1">
          Contact Name:{' '}
          <span class="ml-auto font-semibold">{info?.contactName}</span>
        </div>
        <div class="flex my-1">
          Phone: <span class="ml-auto font-semibold">{info?.contactPhone}</span>
        </div>
        <div class="flex my-1">
          Toll Free:{' '}
          <span class="ml-auto font-semibold">{info?.contactTollFree}</span>
        </div>
        <div class="flex my-1">
          Fax: <span class="ml-auto font-semibold">{info?.contactFax}</span>
        </div>
        <div class="flex my-1">
          Email: <span class="ml-auto font-semibold">{info?.contactEmail}</span>
        </div>
      </div>
    );
  };

  createEffect(async () => {
    if (
      isFeatureFlagEnabled(featureFlags.readFromNewLtlTables)() &&
      props.quoteId &&
      props.quoteId.trim() !== ''
    ) {
      const terminals = await fetchLtlQuoteTerminals(props.quoteId);
      setTerminalInfo(terminals);
    }
  });

  return (
    <BasicModal
      id={props.modalId}
      title="Terminal Info"
      width={'50vw'}
      onClose={() => props.onClose(props.modalId)}
      footer={false}
      showClose={true}
    >
      <Grid container spacing={4}>
        <Grid item xs={6} class="min-w-96">
          <div class="text-xl mb-2 min-w-96">Origin</div>
          {sectionTerminal(terminalInfo()?.pickupTerminal)}
        </Grid>
        <Grid item xs={6} class="min-w-96">
          <div class="text-xl mb-2 min-w-96">Destination</div>
          {sectionTerminal(terminalInfo()?.deliveryTerminal)}
        </Grid>
      </Grid>
    </BasicModal>
  );
};
