import { createStore } from 'solid-js/store';

import { ProspectDetailsStore } from './types';

const [prospectDetailsStore, setProspectDetailsStore] =
  createStore<ProspectDetailsStore>({
    loading: false,
    prospectDetails: {
      id: 0,
      name: '',
      address1: null,
      address2: null,
      city: null,
      state: null,
      zip: null,
      status: '',
      createdById: null,
      createdBy: '',
      createdDate: '',
      lastContactDate: '',
      followUpDate: null,
      color: '',
      public: false,
      customerMatchFound: false,
      callLog: [],
      notes: [],
      contacts: [],
      quotes: [],
      equipmentOptions: [],
      operationType: 'None',
    },
    customerMatches: [],
  });

export { prospectDetailsStore, setProspectDetailsStore };
