import { Card, Tab, TabsComponent } from '@components';
import { orderStore } from '@store/orders';
import { Grid } from '@suid/material';
import { capitalize } from 'lodash';
import { Match, Show, Switch, createSignal } from 'solid-js';
import { ILoadEditViewModel } from '@typeDefinitions/loadTypes';

import { CarrierPay } from './CarrierPay';
import { CarrierSettlement } from './CarrierSettlement';
import { calculateSettlementCount } from './stops/stopsEditor/utilFunctions';
import CarrierPayAdditionalInfo from './CarrierPayAdditionalInfo';
import { CarrierPayments } from './CarrierPayments';

type Props = {
  load: ILoadEditViewModel;
  tabIndex: number;
};

export const CarrierInstructions = (props: Props) => {
  const [activeTab, setActiveTab] = createSignal(0);

  return (
    <Card startTitle="Carrier Billing Info" loading={orderStore.loading}>
      <Grid container spacing={2} id="instructions-and-expected-costs">
        <Show when={orderStore.order.loads[props.tabIndex].billingHold}>
          <Grid item xs={12}>
            <div class="border-4 border-[#E09A9A] mt-2 mb-2 p-1 bg-[#FFE5E5]">
              <span>
                {`This carrier payment is on hold because of the ${capitalize(
                  orderStore.order.loads[props.tabIndex].billingHoldReason,
                )} ; ${
                  orderStore.order.loads[props.tabIndex].billingHoldDescription
                }`}
              </span>
            </div>
          </Grid>
        </Show>
      </Grid>
      <TabsComponent
        tabs={[
          'CARRIER CHARGES',
          `CARRIER PAYMENTS (${props.load.payments?.length ?? 0})`,
          `SETTLEMENT (${calculateSettlementCount(
            `loads.${props.tabIndex}.lineItems`,
          )})`,
        ].map((item) => (
          <Tab>{item}</Tab>
        ))}
        activeTab={activeTab()}
        onTabClick={setActiveTab}
      />
      <Switch>
        <Match when={activeTab() === 0}>
          <div class="my-4">
            <CarrierPayAdditionalInfo {...props} />
            <CarrierPay load={props.load} tabIndex={props.tabIndex} />
          </div>
        </Match>
        <Match when={activeTab() === 1}>
          <CarrierPayments tabIndex={props.tabIndex} load={props.load} />
        </Match>
        <Match when={activeTab() === 2}>
          <CarrierSettlement tabIndex={props.tabIndex} load={props.load} />
        </Match>
      </Switch>
    </Card>
  );
};
