/* eslint-disable complexity */
import {
  AutoComplete,
  Button,
  DatePicker,
  IconLabel,
  Notification,
  Popover,
  Radio,
  TimePicker,
  ToastType,
} from '@components';
import AddressAutoComplete, {
  AddressDetails,
} from '@components/Autocomplete/AddressAutoComplete';
import {
  CheckboxInput,
  PhoneInput,
  SelectField,
  TextAreaField,
  TextInput,
} from '@components/forms';
import { closeModal } from '@store/modals';
import {
  featureFlagGetCustomerFeatures,
  getLocationTimezone,
  orderStore,
  PickUpDropOffItem,
  setToAddressBook,
} from '@store/orders';
import { getTimeZoneAbbreviation } from '@store/orders/helper';
import { Add } from '@suid/icons-material';
import CheckIcon from '@suid/icons-material/Check';
import DoNotDisturbIcon from '@suid/icons-material/DoNotDisturb';
import { Box, Divider, Grid } from '@suid/material';
import { SelectChangeEvent } from '@suid/material/Select';
import { LastUsedTopStopModel } from '@typeDefinitions/stopTypes';
import { logError } from '@utils/errorLogging';
import { convertDateTimeV2, handleToast, printError } from '@utils/utils';
import { get } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import { DateTime } from 'luxon';
import { createSignal, onMount, Show } from 'solid-js';
import { IntegerTimeInput } from '@components/IntegerTimeInput';

import cls from './classes';
import { defaultLoadItems } from './defaultValues';
import { SetAppReminder } from './SetAppReminder';
import { StopItems } from './StopItems';
import { StopListContainer } from './StopListContainer';
import { StopsMainProps } from './types';

export const TIME_ZONES = [
  { label: 'Eastern', value: 'EST' },
  { label: 'Central', value: 'CST' },
  { label: 'Mountain', value: 'MST' },
  { label: 'Pacific', value: 'PST' },
];

export const StopsMain = (props: StopsMainProps) => {
  const { setFields, setActiveView, setSmallestLoadItemId, setIsDirty } = props;
  const [addingtoAddressBook, setAddingToAddressBook] = createSignal(false);

  onMount(() => {
    if (
      orderStore.order.loads[orderStore.activeTab.index].stops !== undefined
    ) {
      if (
        orderStore.order.loads[orderStore.activeTab.index]?.stops!.length >=
          1 &&
        props.stop === null
      ) {
        setStopsFields('pickUp', false);
        setStopsFields('dropOff', true);
      } else if (!!props.stop && props.stop.timezone === null) {
        getLocationTimezone(props.stop.countryCode ?? 'US', props.stop.zip)
          .then((timeZoneAbbr) => {
            if (timeZoneAbbr !== undefined) {
              const abbr = getTimeZoneAbbreviation(timeZoneAbbr);
              setFields('timezone', abbr);
            }
          })
          .catch(printError);
      }
    }
    props.setFields(
      'loadItems',
      cloneDeep([
        ...(orderStore.order.loads[orderStore.activeTab.index].items ?? []),
      ]),
    );
  });

  const setStopsFields = (field: string, value: unknown) => {
    setIsDirty(true);
    setFields(field, value);
  };

  const handleGooglePlacesSelect = (place: AddressDetails) => {
    const { address1 = '', city = '', state = '', zipCode = '' } = place;
    setStopsFields('address1', address1);
    setStopsFields('city', city);
    setStopsFields('state', state);
    setStopsFields('zip', zipCode);

    getLocationTimezone(place.country ?? 'US', zipCode)
      .then((timeZoneAbbr) => {
        if (timeZoneAbbr !== undefined) {
          const abbr = getTimeZoneAbbreviation(timeZoneAbbr);
          setFields('timezone', abbr);
        }
      })
      .catch((e) => {
        printError(e);
      });
  };

  const handleSelectFromAddressBook = () => {
    if (orderStore.order.customerId === null) {
      return;
    }
    try {
      setActiveView('select-from-addresses');
    } catch (error) {
      handleToast(ToastType.Error, (error as Error).message);
    }
  };

  const getTotalWeight = () => {
    return props.data().loadItems.reduce((acc, itm) => {
      if (
        itm.operationType === 'Delete' ||
        (props.data().dropOff && props.data().id != itm.dropOffId) ||
        (!props.data().dropOff && props.data().id !== itm.pickUpId)
      ) {
        acc += 0;
      } else {
        acc += Number(itm.weight);
      }
      return acc;
    }, 0);
  };

  const addNewListItem = () => {
    const loadItems = [
      ...props.data().loadItems,
      {
        ...defaultLoadItems,
        item: props.mode === 'LTL' ? 'Pallets' : 'Items',
        id: props.smallestLoadItemId(),
        dropOffId: props.data().pickUp ? null : props.data().id,
        pickUpId: props.data().pickUp ? props.data().id : null,
      },
    ] as PickUpDropOffItem[];

    setStopsFields('loadItems', loadItems);
    setSmallestLoadItemId(props.smallestLoadItemId() - 1);
  };

  const handleAddToAddressBook = async () => {
    const {
      locationName,
      address1,
      address2,
      city,
      state,
      zip,
      countryCode,
      instructions,
      directions,
      description,
      phone,
      contact,
    } = props.data();
    if (!Boolean(orderStore.order.customerId)) {
      handleToast(
        ToastType.Caution,
        'Please select a customer for the order to add to address book.',
      );
      return;
    }
    setAddingToAddressBook(true);

    try {
      const success = await setToAddressBook({
        customerId: orderStore.order.customerId!,
        name: locationName,
        specialInstructions: instructions,
        addressLine1: address1,
        addressLine2: address2,
        city: city,
        state: state,
        zip: zip,
        openTimeFrom: null,
        openTimeTo: null,
        contactName: contact,
        contactPhone: phone,
        countryCode: countryCode,
        internalNote: description,
        drivingDirections: directions,
      });

      if (success) {
        handleToast(
          ToastType.Success,
          'Address added to address book successfully',
        );
      } else {
        handleToast(ToastType.Error, 'Failed to add address to address book');
      }

      setAddingToAddressBook(false);
    } catch (error) {
      logError('handleAddToAddressBook', error as Error);
      handleToast(ToastType.Error, (error as Error).message);
    }
  };

  const setAddressFromStopList = (stop: LastUsedTopStopModel) => {
    setStopsFields('address1', stop.address1 ?? '');
    setStopsFields('address2', stop.address2 ?? '');
    setStopsFields('city', stop.city ?? '');
    setStopsFields('state', stop.state ?? '');
    setStopsFields('zip', stop.zip);
    setStopsFields('contact', stop.contact ?? '');
    setStopsFields('locationName', stop.locationName ?? '');
    setStopsFields('phone', stop.phone ?? '');
    setStopsFields('email', stop.email ?? '');
    setStopsFields('instructions', stop.instructions ?? '');
    setStopsFields('directions', stop.directions ?? '');
    setStopsFields('countryCode', stop.countryCode ?? 'USA');

    getLocationTimezone(stop.countryCode ?? 'US', stop.zip)
      .then((timeZoneAbbr) => {
        if (timeZoneAbbr !== undefined) {
          const abbr = getTimeZoneAbbreviation(timeZoneAbbr);
          setFields('timezone', abbr);
        }
      })
      .catch((e) => {
        printError(e);
      });
  };

  const handleRadioClick = (type: unknown) => {
    let loadItems = cloneDeep(props.data().loadItems);
    if (type === 'Pick Up') {
      setStopsFields('pickUp', true);
      setStopsFields('dropOff', false);
      setStopsFields('destination', false);
    } else {
      setStopsFields('pickUp', false);
      setStopsFields('dropOff', true);
      setStopsFields('origin', false);
    }
    loadItems = loadItems.map((itm) => {
      if (itm.operationType === 'Delete') return itm;
      if (type === 'Pick Up' && itm.dropOffId === props.data().id) {
        itm.dropOffId = null;
        itm.pickUpId = props.data().id;
        itm.operationType =
          itm.operationType !== 'Insert' ? 'Update' : itm.operationType;
      } else if (type === 'Drop Off' && itm.pickUpId === props.data().id) {
        itm.pickUpId = null;
        itm.dropOffId = props.data().id;
        itm.operationType =
          itm.operationType !== 'Insert' ? 'Update' : itm.operationType;
      }
      return itm;
    });
    setStopsFields('loadItems', loadItems);

    //check Edi Vals as we use same fields for both pickup and dropoff
    setDriverOutAuto214ManualOverride();
    setDriverInAuto214ManualOverride();
    setApptointmentAuto214ManualOverride();
  };

  const meetsEdiUpdatesTrackingRequirement = (): boolean => {
    return Boolean(props.stop && props.stop.id > 0 && props.mode !== 'LTL');
  };
  const checkCustomerFeatureFlags = (feature: string) => {
    return get(
      featureFlagGetCustomerFeatures,
      `featureFlagCustomer.${feature}`,
      false,
    );
  };

  const areDatesEqual = (firstDateStr: string, secondDateStr: string) => {
    const firstDate = DateTime.fromISO(firstDateStr, { zone: 'utc' });

    const secondDate = DateTime.fromISO(secondDateStr, { zone: 'utc' });

    return firstDate.toMillis() === secondDate.toMillis();
  };

  const setDriverOutAuto214ManualOverride = () => {
    if (meetsEdiUpdatesTrackingRequirement()) {
      const hasAgentSetDriverOutTime = !areDatesEqual(
        props.initialStopVals.driverDateOut,
        convertDateTimeV2(
          props.data().driverDateOut,
          props.data().driverTimeOut,
        ),
      );
      if (
        checkCustomerFeatureFlags('EDIPickupDriverOutAuto214') &&
        props.data().pickUp
      ) {
        setFields(
          'shouldSendEdiBooleanVals.shouldSendPickupDriverOutAuto214',
          hasAgentSetDriverOutTime,
        );
      } else if (
        checkCustomerFeatureFlags('EDIDropoffDriverOutAuto214') &&
        props.data().dropOff
      ) {
        setFields(
          'shouldSendEdiBooleanVals.shouldSendDropoffDriverOutAuto214',
          hasAgentSetDriverOutTime,
        );
      }
    }
  };

  const setDriverInAuto214ManualOverride = () => {
    if (meetsEdiUpdatesTrackingRequirement()) {
      const hasAgentSetDriverOutTime = !areDatesEqual(
        props.initialStopVals.driverDateIn,
        convertDateTimeV2(props.data().driverDateIn, props.data().driverTimeIn),
      );
      if (
        checkCustomerFeatureFlags('EDIPickupDriverInAuto214') &&
        props.data().pickUp
      ) {
        setFields(
          'shouldSendEdiBooleanVals.shouldSendPickupDriverInAuto214',
          hasAgentSetDriverOutTime,
        );
      } else if (
        checkCustomerFeatureFlags('EDIDropoffDriverInAuto214') &&
        props.data().dropOff
      ) {
        setFields(
          'shouldSendEdiBooleanVals.shouldSendDropoffDriverInAuto214',
          hasAgentSetDriverOutTime,
        );
      }
    }
  };

  const setApptointmentAuto214ManualOverride = () => {
    if (meetsEdiUpdatesTrackingRequirement()) {
      const hasAgentSetAppointmentTime =
        !areDatesEqual(
          props.initialStopVals.appointmentDate,
          convertDateTimeV2(
            props.data().dateAppointment,
            props.data().timeAppointment,
          ),
        ) ||
        props.data().appointmentNum !== props.initialStopVals.appointmentNum;
      if (
        checkCustomerFeatureFlags('EDIPickupAppointmentAuto214') &&
        props.data().pickUp
      ) {
        setFields(
          'shouldSendEdiBooleanVals.shouldSendPickupAppointmentAuto214',
          hasAgentSetAppointmentTime,
        );
      } else if (
        checkCustomerFeatureFlags('EDIDropoffAppointmentAuto214') &&
        props.data().dropOff
      ) {
        setFields(
          'shouldSendEdiBooleanVals.shouldSendDropoffAppointmentAuto214',
          hasAgentSetAppointmentTime,
        );
      }
    }
  };

  return (
    <form ref={props.form}>
      <Grid container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <StopListContainer
              setAddressFromStopList={setAddressFromStopList}
            />
          </Grid>
          <Grid item xs={12} md={4.5}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <div class="phone:text-xs 1080p:text-lg font-semibold bg-[#f1f1f1]	rounded-md p-1">
                  Location
                </div>
              </Grid>
              <Grid item xs={12}>
                <div class="flex justify-end">
                  <div
                    style={
                      orderStore.order.customerId === null
                        ? {
                            color: 'rgba(0, 0, 0, 0.26)',
                          }
                        : undefined
                    }
                    class={`text-xs font-bold ${
                      orderStore.order.customerId === null
                        ? ' Mui-disabled'
                        : 'cursor-pointer  hover:underline'
                    } mb-2`}
                    onClick={handleSelectFromAddressBook}
                  >
                    Select from Address Book
                  </div>
                </div>
                <AutoComplete
                  id="autocomplete-stop-modal-name"
                  endpoint="stop/autocomplete?expression"
                  defaultValue=""
                  label="Stop Name"
                  value={props.data().locationName}
                  onChange={(itm: string) =>
                    setStopsFields('locationName', itm)
                  }
                  onItemSelect={(item) => {
                    setAddressFromStopList(item as LastUsedTopStopModel);
                  }}
                  renderItem={(item) => (
                    <div>
                      {(item as LastUsedTopStopModel).locationName}
                      <div>
                        <div>{(item as LastUsedTopStopModel).address1}</div>
                        <div>
                          {(item as LastUsedTopStopModel).city},{' '}
                          {(item as LastUsedTopStopModel).state}{' '}
                          {(item as LastUsedTopStopModel).zip}
                        </div>
                      </div>
                    </div>
                  )}
                  error={props.errors().locationName}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={12}>
                <IconLabel
                  label="Address"
                  content={
                    <Radio
                      id="1"
                      options={[
                        { label: 'Pick Up', value: 'Pick Up' },
                        { label: 'Drop off', value: 'Drop Off' },
                      ]}
                      flowDirection="row"
                      value={props.data().pickUp ? 'Pick Up' : 'Drop Off'}
                      onClick={handleRadioClick}
                    />
                  }
                  textColorCondition={true}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={12}>
                <AddressAutoComplete
                  label={
                    <span>
                      Address line 1<sup>*</sup>
                    </span>
                  }
                  placeholder="Enter Address"
                  value={props.data().address1}
                  onChange={(itm) => setStopsFields('address1', itm)}
                  onItemSelect={(item) => {
                    handleGooglePlacesSelect(item);
                  }}
                  zIndex="9999"
                  error={props.errors().address1}
                  maxLength={100}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={12}>
                <TextInput
                  label="Address line 2"
                  placeholder="Address line 2"
                  value={props.data().address2}
                  onChange={(itm: string) => setStopsFields('address2', itm)}
                  maxLength={100}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextInput
                  label="City"
                  required
                  placeholder="City"
                  error={props.errors().city}
                  value={props.data().city}
                  onChange={(itm: string) => setStopsFields('city', itm)}
                  maxLength={50}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextInput
                  label="State"
                  required
                  placeholder="State"
                  error={props.errors().state}
                  value={props.data().state}
                  onChange={(itm: string) =>
                    setStopsFields('state', itm.slice(0, 2))
                  }
                  maxLength={2}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextInput
                  label="Zip"
                  required
                  placeholder="Zip"
                  value={props.data().zip}
                  onChange={(itm: string) => setStopsFields('zip', itm)}
                  error={props.errors().zip}
                  maxLength={7}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <SelectField
                  label="USA/CAN/MEX"
                  menuItems={[
                    { label: 'USA', value: 'USA' },
                    { label: 'CAN', value: 'CAN' },
                    { label: 'MEX', value: 'MEX' },
                  ]}
                  value={props.data().countryCode}
                  onChange={(e: SelectChangeEvent) => {
                    setStopsFields('countryCode', e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  label="+ Add to address book"
                  size="small"
                  onClick={() => handleAddToAddressBook()}
                  isLoading={addingtoAddressBook()}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <div class="phone:text-xs 1080p:text-lg font-semibold bg-[#f1f1f1]	rounded-md p-1">
                  Contact Info
                </div>
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  label={'Name'}
                  placeholder="Name"
                  value={props.data().contact}
                  onChange={(itm: string) => setStopsFields('contact', itm)}
                  error={props.errors().contact}
                  maxLength={50}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  label="Email"
                  placeholder="Email"
                  error={props.errors().email}
                  value={props.data().email}
                  onChange={(itm: string) => setStopsFields('email', itm)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <PhoneInput
                  label="Phone"
                  placeholder="Phone Number"
                  error={props.errors().phone}
                  value={props.data().phone}
                  onChange={(value) => setStopsFields('phone', value)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={4.5} xs={12}>
            <div class="phone:text-xs 1080p:text-lg font-semibold bg-[#f1f1f1]	rounded-md p-1 mb-4">
              Stop Information
            </div>
            <Grid container spacing={1}>
              <Grid item lg={12} xl={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <DatePicker
                      label="Date"
                      value={props.data().stopDateTime}
                      error={props.errors().stopDateTime}
                      handleChange={(value: string) => {
                        setStopsFields('stopDateTime', value);
                        if (!props.stop || props.stop.id < 0) {
                          setStopsFields('driverDateIn', value);
                          setStopsFields('driverDateOut', value);
                        }
                      }}
                      asRawDate
                      dataTestId="stopDate"
                    />
                  </Grid>
                  {/* Time is being deprecated, but will remain visibile for older orders that already have a time value */}
                  <Show when={props.data().time && !props.data().timezone}>
                    <Grid item xs={12} mt={1}>
                      <TextInput
                        value={props.data().time}
                        placeholder="Time"
                        label="Time"
                        error={props.errors().appointmentNum}
                        onChange={(itm: string) => setStopsFields('time', itm)}
                        dataTestId="stopTimeField"
                      />
                    </Grid>
                  </Show>
                  <Grid item xs={12} mt={2}>
                    <SelectField
                      label="Time Zone"
                      menuItems={TIME_ZONES}
                      placeholder="Select Time Zone"
                      value={props.data().timezone}
                      onChange={(e) => {
                        setFields('timezone', e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <IntegerTimeInput
                      label="Begin Time"
                      placeholder="Select Begin Time"
                      onChange={(value) => {
                        setFields('beginTimeRange', value);
                      }}
                      value={props.data().beginTimeRange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <IntegerTimeInput
                      label="End Time"
                      placeholder="Select End Time"
                      onChange={(value) => {
                        setFields('endTimeRange', value);
                      }}
                      value={props.data().endTimeRange}
                    />
                  </Grid>
                  <Grid item xs={12} mt={2}>
                    <TextInput
                      label={props.data().pickUp ? 'Pickup #' : 'Dropoff #'}
                      placeholder="Pickup"
                      error={props.errors().referenceNum}
                      value={props.data().referenceNum}
                      onChange={(itm: string) =>
                        setStopsFields('referenceNum', itm)
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {orderStore.order.edi && (
                      <Grid item xs={12} mt={2}>
                        <TextInput
                          label="Stop Code (EDI)"
                          placeholder=""
                          error={props.errors().stopCode}
                          value={props.data().stopCode}
                          onChange={(itm: string) =>
                            setStopsFields('stopCode', itm)
                          }
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={12} xl={6}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <div class="phone:text-xs 1080p:text-md font-semibold  bg-[#f1f1f1]	rounded-md mt-2 p-1">
                      Appointment
                    </div>
                    <Show
                      when={
                        props.stop &&
                        props.stop.id > 0 &&
                        (props.data().pickUp
                          ? checkCustomerFeatureFlags(
                              'EDIPickupAppointmentAuto214',
                            )
                          : checkCustomerFeatureFlags(
                              'EDIDropoffAppointmentAuto214',
                            ))
                      }
                    >
                      <CheckboxInput
                        onChange={() => {
                          const field = props.data().pickUp
                            ? 'shouldSendPickupAppointmentAuto214'
                            : 'shouldSendDropoffAppointmentAuto214';
                          setFields(
                            `shouldSendEdiBooleanVals.${field}`,
                            !props.data().shouldSendEdiBooleanVals[field],
                          );
                        }}
                        checked={
                          props.data().shouldSendEdiBooleanVals[
                            props.data().pickUp
                              ? 'shouldSendPickupAppointmentAuto214'
                              : 'shouldSendDropoffAppointmentAuto214'
                          ]
                        }
                        label="Send EDI Update"
                        class="!ml-auto"
                      />
                    </Show>
                  </Grid>
                  <Grid item xs={12}>
                    <div class="border-2 p-2 border-slate-500 rounded-md">
                      <div class="flex gap-x-3">
                        <CheckboxInput
                          label=""
                          sxProps={{ marginLeft: '5px' }}
                          checked={props.data().needsAppointment}
                          onChange={(val) => {
                            setStopsFields('needsAppointment', val);
                            setApptointmentAuto214ManualOverride();
                          }}
                        />
                        <div class="flex flex-col gap-y-2">
                          <DatePicker
                            size="small"
                            label="Date"
                            value={props.data().dateAppointment}
                            handleChange={(value: string) => {
                              setStopsFields('dateAppointment', value);
                              setApptointmentAuto214ManualOverride();
                            }}
                            dataTestId="appointmentDate"
                          />
                          <TimePicker
                            label="Time"
                            value={props.data().timeAppointment}
                            onChange={(newTime) => {
                              setStopsFields(
                                'timeAppointment',
                                newTime.includes('T:00') ? '' : newTime,
                              );
                              setApptointmentAuto214ManualOverride();
                            }}
                            error={props.errors().timeAppointment}
                            dataTestId="appointmentTime"
                          />
                          <Show
                            when={
                              props.mode !== 'LTL' &&
                              ((props.stop &&
                                props.stop.id > 0 &&
                                props.data().pickUp &&
                                checkCustomerFeatureFlags(
                                  'EDIPickupAppointmentAuto214',
                                )) ||
                                (props.data().dropOff &&
                                  checkCustomerFeatureFlags(
                                    'EDIDropoffAppointmentAuto214',
                                  )))
                            }
                          >
                            <SelectField
                              label="Time Zone"
                              menuItems={TIME_ZONES}
                              placeholder="Select Time Zone"
                              value={props.data().appointmentTimeZone}
                              onChange={(e) => {
                                setFields(
                                  'appointmentTimeZone',
                                  e.target.value,
                                );
                              }}
                            />
                          </Show>
                        </div>
                      </div>
                    </div>
                    <div class="mt-2">
                      <TextInput
                        label="Appointment #"
                        placeholder="Appointment #"
                        error={props.errors().appointmentNum}
                        value={props.data().appointmentNum}
                        onChange={(itm: string) => {
                          setApptointmentAuto214ManualOverride();
                          setStopsFields('appointmentNum', itm);
                        }}
                      />
                    </div>
                    <div class="text-[#1B4960] font-roboto text-base font-normal flex border-0 hover:bg-white">
                      <Popover
                        eleName="Set Reminder"
                        classes={cls.reminderTxt}
                        sxProps={{
                          backgroundColor: 'white',
                          borderRadius: '0',
                          textDecoration: 'underline !important',
                        }}
                      >
                        {(closePopover) => (
                          <SetAppReminder type="Load" onSubmit={closePopover} />
                        )}
                      </Popover>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <div class="phone:text-xs 1080p:text-md font-semibold  bg-[#f1f1f1]	rounded-md p-1 mb-2 flex items-center">
                      {orderStore.order.loads[orderStore.activeTab.index]
                        .mode === 'LTL'
                        ? 'Shipping Hours (Open)*'
                        : 'Driver In Time'}

                      <Show when={props.mode !== 'LTL'}>
                        <Show
                          when={
                            props.stop &&
                            props.stop.id > 0 &&
                            (props.data().pickUp
                              ? checkCustomerFeatureFlags(
                                  'EDIPickupDriverInAuto214',
                                )
                              : checkCustomerFeatureFlags(
                                  'EDIDropoffDriverInAuto214',
                                ))
                          }
                        >
                          <CheckboxInput
                            onChange={() => {
                              const field = props.data().pickUp
                                ? 'shouldSendPickupDriverInAuto214'
                                : 'shouldSendDropoffDriverInAuto214';
                              setFields(
                                `shouldSendEdiBooleanVals.${field}`,
                                !props.data().shouldSendEdiBooleanVals[field],
                              );
                            }}
                            checked={
                              props.data().shouldSendEdiBooleanVals[
                                props.data().pickUp
                                  ? 'shouldSendPickupDriverInAuto214'
                                  : 'shouldSendDropoffDriverInAuto214'
                              ]
                            }
                            label="Send EDI Update"
                            class="!ml-auto"
                          />
                        </Show>
                      </Show>
                    </div>
                    <DatePicker
                      value={props.data().driverDateIn}
                      handleChange={(value: string) => {
                        setStopsFields('driverDateIn', value);
                        setDriverInAuto214ManualOverride();
                      }}
                      error={props.errors().driverDateIn}
                      dataTestId={'driverDateIn'}
                    />
                    <div class="mt-2">
                      <TimePicker
                        value={props.data().driverTimeIn}
                        onChange={(newTime) => {
                          setStopsFields('driverTimeIn', newTime);
                          setDriverInAuto214ManualOverride();
                        }}
                        error={props.errors().driverTimeIn}
                        dataTestId={'driverTimeIn'}
                      />
                    </div>
                    <Show
                      when={
                        props.mode !== 'LTL' &&
                        ((props.stop &&
                          props.stop.id > 0 &&
                          props.data().pickUp &&
                          checkCustomerFeatureFlags(
                            'EDIPickupDriverInAuto214',
                          )) ||
                          (props.data().dropOff &&
                            checkCustomerFeatureFlags(
                              'EDIDropoffDriverInAuto214',
                            )))
                      }
                    >
                      <Box mt={1}>
                        <SelectField
                          label="Time Zone"
                          menuItems={TIME_ZONES}
                          placeholder="Select Time Zone"
                          value={props.data().driverInTimeZone}
                          onChange={(e) => {
                            setFields('driverInTimeZone', e.target.value);
                          }}
                        />
                      </Box>
                    </Show>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div class="phone:text-xs font-semibold 1080p:text-md bg-[#f1f1f1]	rounded-md p-1 mb-2 flex items-center px-2">
                      {orderStore.order.loads[orderStore.activeTab.index]
                        .mode === 'LTL'
                        ? 'Shipping Hours (Closed)*'
                        : 'Driver Out Time'}
                      <Show when={props.mode !== 'LTL'}>
                        <Show
                          when={
                            props.stop &&
                            props.stop.id > 0 &&
                            (props.data().pickUp
                              ? checkCustomerFeatureFlags(
                                  'EDIPickupDriverOutAuto214',
                                )
                              : checkCustomerFeatureFlags(
                                  'EDIDropoffDriverOutAuto214',
                                ))
                          }
                        >
                          <CheckboxInput
                            onChange={() => {
                              const field = props.data().pickUp
                                ? 'shouldSendPickupDriverOutAuto214'
                                : 'shouldSendDropoffDriverOutAuto214';
                              props.setFields(
                                `shouldSendEdiBooleanVals.${field}`,
                                !props.data().shouldSendEdiBooleanVals[field],
                              );
                            }}
                            checked={
                              props.data().shouldSendEdiBooleanVals[
                                props.data().pickUp
                                  ? 'shouldSendPickupDriverOutAuto214'
                                  : 'shouldSendDropoffDriverOutAuto214'
                              ]
                            }
                            label="Send EDI Update"
                            class="!ml-auto"
                          />
                        </Show>
                      </Show>
                    </div>
                    <DatePicker
                      value={props.data().driverDateOut}
                      handleChange={(value: string) => {
                        setStopsFields('driverDateOut', value);
                        setDriverOutAuto214ManualOverride();
                      }}
                      error={props.errors().driverDateOut}
                      dataTestId={'driverDateOut'}
                    />
                    <div class="mt-2">
                      <TimePicker
                        value={props.data().driverTimeOut}
                        onChange={(newTime) => {
                          setStopsFields('driverTimeOut', newTime);
                          setDriverOutAuto214ManualOverride();
                        }}
                        error={props.errors().driverTimeOut}
                        dataTestId={'driverTimeOut'}
                      />
                    </div>
                    <Show
                      when={
                        props.mode !== 'LTL' &&
                        ((props.stop &&
                          props.stop.id > 0 &&
                          props.data().pickUp &&
                          checkCustomerFeatureFlags(
                            'EDIPickupDriverOutAuto214',
                          )) ||
                          (props.data().dropOff &&
                            checkCustomerFeatureFlags(
                              'EDIDropoffDriverOutAuto214',
                            )))
                      }
                    >
                      <Box mt={1}>
                        <SelectField
                          label="Time Zone"
                          menuItems={TIME_ZONES}
                          placeholder="Select Time Zone"
                          value={props.data().driverOutTimeZone}
                          onChange={(e) => {
                            setFields('driverOutTimeZone', e.target.value);
                          }}
                        />
                      </Box>
                    </Show>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <div class="flex font-semibold text-lg bg-[#f1f1f1] mt-2	rounded-md p-1 items-center">
              <div>
                Items {props.data().pickUp ? 'Picking Up' : 'Dropping Off'}
              </div>
              {props.mode === 'LTL' &&
              (props.hasDropOffStop || props.data().dropOff) ? (
                <Button
                  disabled={!Boolean(orderStore.order.customerId)}
                  label="Open Product Catalog"
                  onClick={() => setActiveView('ltl-product-catalog')}
                  startIcon={<Add />}
                  sx={{ marginLeft: '16px' }}
                  size="small"
                />
              ) : null}
            </div>
          </Grid>
          <Grid item xs={12} class="mb-3">
            {!props.hasDropOffStop && !props.data().dropOff ? (
              <Notification
                tableRowNotification={false}
                type="info"
                text="You will manage your inventory after creating a Drop Off stop."
              />
            ) : null}
            <div
              class={
                !(!props.hasDropOffStop && !props.data().dropOff)
                  ? 'block'
                  : 'hidden'
              }
            >
              <StopItems
                mode={props.mode}
                loadItems={props.data().loadItems}
                arrayName="loadItems"
                setFields={setStopsFields}
                addNewListItem={addNewListItem}
                stopType={props.data().pickUp ? 'Pick Up' : 'Drop Off'}
                options={
                  props.data().pickUp
                    ? props.dropOffOptions
                    : props.pickUpOptions
                }
                stopIds={props.stopIds}
                stopId={props.data().id}
                //@ts-expect-error is ok
                errors={props.errors().loadItems}
              />
              {
                <span class="mt-2 inline-block">
                  <b>Weight: {getTotalWeight()} pounds </b>
                </span>
              }
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={4}>
            <div class="flex justify-end items-center gap-x-1">
              <div class="text-xs	font-medium">Rate Con:</div>
              <DoNotDisturbIcon sx={{ color: '#B00020', fontSize: '14px' }} />
            </div>
            <TextAreaField
              rows={5}
              label="Internal Notes"
              placeholder="This field does not appear on the rate confirmation"
              value={props.data().description}
              onChange={(itm: string) => setStopsFields('description', itm)}
              error={props.errors().description}
              shrink={true}
              backgroundColor="#f7eaea"
              invertPadding
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div class="flex justify-end items-center gap-x-1">
              <div class="text-xs	font-medium">Rate Con:</div>
              <CheckIcon sx={{ color: '#248670', fontSize: '14px' }} />
            </div>
            <TextAreaField
              rows={5}
              label={'Directions'}
              placeholder="This field will appear on the rate confirmation"
              value={props.data().directions}
              onChange={(itm: string) => setStopsFields('directions', itm)}
              error={props.errors().directions}
              shrink={true}
              backgroundColor="#e6efe6"
              invertPadding
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <div class="flex justify-end items-center gap-x-1">
              <div class="text-xs	font-medium">Rate Con:</div>
              <CheckIcon sx={{ color: '#248670', fontSize: '14px' }} />
            </div>
            <TextAreaField
              rows={5}
              label={'Instructions'}
              placeholder="This field will appear on the rate confirmation"
              value={props.data().instructions}
              onChange={(itm: string) => setStopsFields('instructions', itm)}
              error={props.errors().instructions}
              shrink={true}
              backgroundColor="#e6efe6"
              invertPadding
            />
          </Grid>
        </Grid>
        <Grid item xs={12} justifyContent="flex-end" displayRaw="flex" mt={2}>
          <Button class="max-w-[150px]" label="Done" type="submit" />
          <Button
            class="max-w-[150px]"
            variant=""
            label="Cancel"
            type="Cancel"
            onClick={() => {
              closeModal(props.modalId);
            }}
          />
        </Grid>
      </Grid>
    </form>
  );
};
