import { onMount, Show, For } from 'solid-js';
import { loadQuote, quoteStore } from '@store/ltl/store';
import logo from '@assets/atg-loading-new-logo-23.gif';
import { QuoteDetailsRowRender } from '@views/ltl/components/QuoteDetailsRowRender';
import { FalveyInsuranceQuote } from '@store/orders';
import { Box } from '@suid/material';

import '@components/Grid/AgGridStyles.css';

export const QuoteDetailGrid = (props: {
  quoteParametersId: number;
  insuranceQuote?: FalveyInsuranceQuote;
  customerId: number;
  isHistory: boolean;
}) => {
  onMount(async () => {
    await loadQuote(props.quoteParametersId);
  });

  return (
    <Show
      when={!quoteStore.isLoading}
      fallback={
        <div class="loading-container">
          <img src={logo} alt="Loading..." />
        </div>
      }
    >
      <div>
        <For each={quoteStore.quotes}>
          {(quote) => {
            return (
              <Box sx={{ ml: 25 }}>
                <QuoteDetailsRowRender
                  quote={quote}
                  isHistory={props.isHistory}
                  insuranceQuote={props.insuranceQuote}
                  customerId={props.customerId}
                />
              </Box>
            );
          }}
        </For>
      </div>
    </Show>
  );
};
