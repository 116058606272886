import { countryTypes, currencyTypes } from '@common/commonLists';
import { Card, Notification } from '@components';
import AddressAutoComplete, {
  AddressDetails,
} from '@components/Autocomplete/AddressAutoComplete';
import {
  PhoneInput,
  SelectField,
  TextAreaField,
  TextInput,
} from '@components/forms';
import {
  VendorContractViewModel,
  orderStore,
  updateVendorPropertyAtIndex,
  validateFieldForOrder,
} from '@store/orders';
import { Grid } from '@suid/material';
import { isLTLAdmin } from '@utils/utils';
import { vendorServiceTypes, vendorStatusTypes } from '@views/order/constants';

const getVendorNotificationText = () => {
  return `Use this to pay third-party services on your Orders. Be sure that when they invoice, they do so with reference number V-${orderStore.order.id}`;
};

type Props = {
  vendor: VendorContractViewModel;
  index: number;
};

const updateAndValidateVendor = async (
  e: string,
  index: number,
  field: string,
) => {
  updateVendorPropertyAtIndex({
    [field]: e,
  });
  await validateFieldForOrder(
    `vendors.${index}.${field}`,
    `vendors[${index}].${field}`,
  );
};

const VendorBasicInfo = (props: Props & { disableFalveyFields: boolean }) => {
  return (
    <Grid item sm={6} md={4} gap={4}>
      <Grid item container spacing={2}>
        <Grid item xs={12}>
          <TextInput
            label="Vendor (Required)"
            value={props.vendor.name}
            maxLength={50}
            onChange={async (e) => {
              await updateAndValidateVendor(e as string, props.index, 'name');
            }}
            error={
              orderStore.orderFormError &&
              orderStore.orderFormError[`vendors[${props.index}].name`]
            }
            disabled={props.vendor.name === 'Falvey Shippers Insurance'}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectField
            label="Status"
            value={props.vendor.status ?? 'Open'}
            menuItems={vendorStatusTypes.map((item) => ({
              value: item,
              label: item,
            }))}
            onChange={async (e) => {
              await updateAndValidateVendor(
                e.target.value,
                props.index,
                'status',
              );
            }}
            error={
              orderStore.orderFormError &&
              orderStore.orderFormError[`vendors[${props.index}].status`]
            }
            disabled={props.disableFalveyFields}
          ></SelectField>
        </Grid>
        <Grid item xs={props.vendor.serviceType === 'Other' ? 8 : 12}>
          <SelectField
            label="Service Type"
            value={props.vendor.serviceType}
            onChange={async (e) => {
              await updateAndValidateVendor(
                e.target.value,
                props.index,
                'serviceType',
              );
            }}
            error={
              orderStore.orderFormError &&
              orderStore.orderFormError[`vendors[${props.index}].serviceType`]
            }
            menuItems={vendorServiceTypes.map((item) => ({
              value: item,
              label: item,
            }))}
            disabled={props.disableFalveyFields}
          ></SelectField>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            display: props.vendor.serviceType !== 'Other' ? 'none' : 'block',
          }}
        >
          <TextInput
            label="Add text"
            value={props.vendor.serviceTypeText}
            onChange={async (e) => {
              await updateAndValidateVendor(
                e as string,
                props.index,
                'serviceTypeText',
              );
            }}
            error={
              orderStore.orderFormError &&
              orderStore.orderFormError[
                `vendors[${props.index}].serviceTypeText`
              ]
            }
            disabled={props.disableFalveyFields}
          />
        </Grid>
        <Grid item xs={12}>
          <TextAreaField
            label="Comment/Description (Required)"
            value={props.vendor.description}
            maxLength={2000}
            onChange={async (e) => {
              await updateAndValidateVendor(e, props.index, 'description');
            }}
            error={
              orderStore.orderFormError &&
              orderStore.orderFormError[`vendors[${props.index}].description`]
            }
            disabled={props.disableFalveyFields}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const VendorAdditionalInfo = (
  props: Props & { disableFalveyFields: boolean },
) => {
  const itemOnSelect = async (item: AddressDetails) => {
    updateVendorPropertyAtIndex({
      mailingAddress1: item.address1,
      mailingCity: item.city,
      mailingState: item.state,
      mailingZip: item.zipCode,
    });
    await validateFieldForOrder(
      `vendors.${props.index}.mailingAddress1`,
      `vendors[${props.index}].mailingAddress1`,
    );
    await validateFieldForOrder(
      `vendors.${props.index}.mailingCity`,
      `vendors[${props.index}].mailingCity`,
    );
    await validateFieldForOrder(
      `vendors.${props.index}.mailingState`,
      `vendors[${props.index}].mailingState`,
    );
    await validateFieldForOrder(
      `vendors.${props.index}.mailingZip`,
      `vendors[${props.index}].mailingZip`,
    );
  };

  return (
    <Grid item sm={6} md={4} spacing={4}>
      <Grid item container spacing={2}>
        <Grid item xs={12}>
          <TextInput
            label="Contact Name (Required)"
            value={props.vendor.contactName}
            maxLength={50}
            onChange={async (e) => {
              await updateAndValidateVendor(
                e as string,
                props.index,
                'contactName',
              );
            }}
            error={
              orderStore.orderFormError &&
              orderStore.orderFormError[`vendors[${props.index}].contactName`]
            }
            disabled={props.disableFalveyFields}
          />
        </Grid>
        <Grid item xs={12}>
          <PhoneInput
            label="Phone Number"
            value={props.vendor.contactPhone}
            onChange={async (e) => {
              await updateAndValidateVendor(e, props.index, 'contactPhone');
            }}
            error={
              orderStore.orderFormError &&
              orderStore.orderFormError[`vendors[${props.index}].contactPhone`]
            }
            disabled={props.disableFalveyFields}
          />
        </Grid>
        <Grid item xs={12}>
          <AddressAutoComplete
            label="Address Line 1"
            placeholder="Enter Address"
            value={props.vendor.mailingAddress1}
            onChange={(e) => {
              updateVendorPropertyAtIndex({
                mailingAddress1: e,
              });
            }}
            onItemSelect={(item) => itemOnSelect(item)}
            error={
              orderStore.orderFormError &&
              orderStore.orderFormError[
                `vendors[${props.index}].mailingAddress1`
              ]
            }
            disabled={props.disableFalveyFields}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            label="Address Line 2"
            value={props.vendor.mailingAddress2}
            onChange={async (e) => {
              await updateAndValidateVendor(
                e as string,
                props.index,
                'mailingAddress2',
              );
            }}
            error={
              orderStore.orderFormError &&
              orderStore.orderFormError[
                `vendors[${props.index}].mailingAddress2`
              ]
            }
            disabled={props.disableFalveyFields}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            label="City"
            value={props.vendor.mailingCity}
            onChange={async (e) => {
              await updateAndValidateVendor(
                e as string,
                props.index,
                'mailingCity',
              );
            }}
            error={
              orderStore.orderFormError &&
              orderStore.orderFormError[`vendors[${props.index}].mailingCity`]
            }
            disabled={props.disableFalveyFields}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            label="State"
            value={props.vendor.mailingState}
            onChange={async (e) => {
              await updateAndValidateVendor(
                e as string,
                props.index,
                'mailingState',
              );
            }}
            error={
              orderStore.orderFormError &&
              orderStore.orderFormError[`vendors[${props.index}].mailingState`]
            }
            disabled={props.disableFalveyFields}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            label="Zip"
            value={props.vendor.mailingZip}
            onChange={async (e) => {
              await updateAndValidateVendor(
                e as string,
                props.index,
                'mailingZip',
              );
            }}
            error={
              orderStore.orderFormError &&
              orderStore.orderFormError[`vendors[${props.index}].mailingZip`]
            }
            disabled={props.disableFalveyFields}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectField
            label="Country"
            value={props.vendor.countryCode}
            onChange={async (e) => {
              await updateAndValidateVendor(
                e.target.value,
                props.index,
                'countryCode',
              );
            }}
            error={
              orderStore.orderFormError &&
              orderStore.orderFormError[`vendors[${props.index}].countryCode`]
            }
            menuItems={countryTypes}
            disabled={props.disableFalveyFields}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectField
            label="Currency"
            value={props.vendor.currencyType}
            onChange={async (e) => {
              await updateAndValidateVendor(
                e.target.value,
                props.index,
                'currencyType',
              );
            }}
            error={
              orderStore.orderFormError &&
              orderStore.orderFormError[`vendors[${props.index}].currencyType`]
            }
            menuItems={currencyTypes}
            disabled={props.disableFalveyFields}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export const Vendor = (props: Props) => {
  return (
    <Card startTitle="Vendor Details" loading={orderStore.loading}>
      <Notification type={'info'} text={getVendorNotificationText()} />
      <Grid xs={12} container spacing={2} mt={2}>
        <VendorBasicInfo
          disableFalveyFields={
            props.vendor.name === 'Falvey Shippers Insurance' && !isLTLAdmin()
          }
          {...props}
        />
        <VendorAdditionalInfo
          disableFalveyFields={
            props.vendor.name === 'Falvey Shippers Insurance' && !isLTLAdmin()
          }
          {...props}
        />
      </Grid>
    </Card>
  );
};
