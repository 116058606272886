import { reasonsToHoldPayment } from '@common/commonLists';
import { Button } from '@components';
import { SelectField, TextInput } from '@components/forms';
import { createForm } from '@felte/solid';
import { validator } from '@felte/validator-yup';
import { closeModal } from '@store/modals';
import { updateLoadPropertyAtIndex } from '@store/orders';
import { Box, Grid } from '@suid/material';
import { SelectChangeEvent } from '@suid/material/Select';
import { printLog } from '@utils/utils';
import { get } from 'lodash';
import { Setter } from 'solid-js';

import { formSchema } from './validation';

type Fields = {
  billingHoldDescription: string;
  billingHoldReason: string;
};

type Props = {
  modalId: string;
  setPaymentStatus: Setter<string>;
  tabIndex: number;
  intialValues: Fields;
};

function HoldPayment(props: Props) {
  const { form, setFields, data, errors } = createForm<Fields>({
    initialValues: props.intialValues,
    extend: validator({ schema: formSchema }),
    onSubmit: () => {
      setPaymentHoldingReason();
    },
    onError: (errors) => {
      printLog(errors, 'errors');
    },
  });

  const setPaymentHoldingReason = () => {
    updateLoadPropertyAtIndex({
      billingHold: true,
      billingHoldReason: data().billingHoldReason,
      billingHoldDescription: data().billingHoldDescription,
    });
    props.setPaymentStatus('');
    closeModal(props.modalId);
  };

  return (
    <Grid container direction="row" alignItems="center">
      <form ref={form} class="w-full">
        <Grid container spacing={2} direction="row" class="mb-4">
          <Grid item sm={12}>
            <SelectField
              size="small"
              label="Reason *"
              menuItems={reasonsToHoldPayment}
              value={data().billingHoldReason}
              onChange={(e: SelectChangeEvent) => {
                setFields('billingHoldReason', e.target.value);
              }}
              error={get(errors(), 'billingHoldReason')}
            />
          </Grid>

          <Grid item sm={12}>
            <TextInput
              multiline={true}
              rows={5}
              value={data().billingHoldDescription}
              label={'Description *'}
              placeholder={'Enter Description here'}
              classes="w-full"
              onChange={(value) => {
                setFields('billingHoldDescription', value as string);
              }}
              error={get(errors(), 'billingHoldDescription[0]')}
            />
          </Grid>
        </Grid>
        <Box class="flex justify-end mt-14" gap={3}>
          <Button label={'Save'} variant="contained" type="submit" />
          <Button
            label={'Cancel'}
            variant={'text'}
            onClick={() => {
              props.setPaymentStatus('');
              closeModal(props.modalId);
            }}
          />
        </Box>
      </form>
    </Grid>
  );
}

export default HoldPayment;
