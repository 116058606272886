import { v3Client } from '@api/apiClient';
import { closeModal } from '@store/modals';
import { orderStore, setOrderStore } from '@store/orders';
import {
  ICustomerPayment,
  PaymentViewModel,
  TabType,
} from '@store/orders/types';
import { createSignal, onMount, Show } from 'solid-js';
import { formatAmount, handleToast } from '@utils/utils';
import { ToastType } from '@components/Toast/Toast';
import { OperationTypeV4 } from '@typeDefinitions/operationTypes';

import { checkDetailsModalId } from './CarrierPayments';

type checkDetailsType = {
  code: string | null;
  txnId: string | null;
  activeDate: string | null;
  amount: number;
  amountUsed: number;
  amountRemaining: number;
  createdDate: string | null;
  issuedTo: string | null;
  paidTo: string | null;
  notes: string | null;
  uses: number;
  status: string | null;
  voidDate: string | null;
  voided: boolean;
};

export const CheckDetails = (props: {
  activeRow: ICustomerPayment | PaymentViewModel;
}) => {
  const [checkDetails, setCheckDetails] = createSignal<checkDetailsType | null>(
    null,
  );

  const load = () => {
    if (orderStore.activeTab.type === TabType.load) {
      return orderStore.order.loads[orderStore.activeTab.index];
    }
    return null;
  };

  onMount(async () => {
    const response: checkDetailsType = await v3Client.get(
      `/Load/CheckDetails/${props.activeRow.referenceNum}?id=${props.activeRow.referenceNum}`,
    );
    setCheckDetails(response);
  });

  const voidCheck = async () => {
    let response: { success: boolean };
    if (load()) {
      response = await v3Client.post(
        `/Load/LineItem/${props.activeRow.id}/VoidCheck`,
        {
          PaymentId: props.activeRow.id,
        },
      );
    } else {
      response = await v3Client.post(
        `/VendorContract/LineItem/${props.activeRow.id}/VoidCheck`,
        {
          PaymentId: props.activeRow.id,
        },
      );
    }
    if (response.success) {
      if (load()?.status === 'Billed' || load()?.status === 'Paid') {
        handleToast(
          ToastType.Caution,
          'Successfully voided check. You must save the order to update invoicing',
        );
      } else {
        handleToast(ToastType.Success, 'Successfully voided check');
      }
      if (load()) {
        setOrderStore(
          'order',
          'loads',
          orderStore.activeTab.index,
          'payments',
          (payments) =>
            payments?.map((payment) => {
              if (payment.referenceNum === props.activeRow.referenceNum) {
                return {
                  ...payment,
                  amount: 0,
                  operationType: 'Update' as OperationTypeV4,
                };
              }
              return payment;
            }),
        );
        setOrderStore(
          'order',
          'loads',
          orderStore.activeTab.index,
          'lineItems',
          (lineItems) =>
            lineItems?.map((lineItem) => {
              if (lineItem.referenceNumber === props.activeRow.referenceNum) {
                return {
                  ...lineItem,
                  status: 'Unsettled',
                  readOnly: false,
                  referenceNumber: undefined,
                  operationType: 'Update' as OperationTypeV4,
                };
              }
              return lineItem;
            }),
        );
      }
      if (orderStore.activeTab.type === TabType.vendor) {
        setOrderStore(
          'order',
          'vendors',
          orderStore.activeTab.index,
          'payments',
          (payments) =>
            payments?.map((payment) => {
              if (payment.referenceNum === props.activeRow.referenceNum) {
                return {
                  ...payment,
                  operationType: 'Delete' as OperationTypeV4,
                };
              }
              return payment;
            }),
        );
        setOrderStore(
          'order',
          'vendors',
          orderStore.activeTab.index,
          'lineItems',
          (lineItems) =>
            lineItems?.map((lineItem) => {
              if (lineItem.referenceNumber === props.activeRow.referenceNum) {
                return {
                  ...lineItem,
                  status: 'Unsettled',
                  readOnly: false,
                  operationType: 'Update' as OperationTypeV4,
                };
              }
              return lineItem;
            }),
        );
      }
    } else {
      handleToast(ToastType.Error, 'Failed to void check');
    }
    closeModal(checkDetailsModalId);
  };

  return (
    <>
      <div class="font-bold text-md py-2">EFS Check Details</div>
      <div>
        <div class="flex flex-col gap-y-2">
          <div>Code: {checkDetails()?.code}</div>
          <div>Issued To: {checkDetails()?.issuedTo}</div>
          <div>Paid To: {checkDetails()?.paidTo}</div>
        </div>

        <div class="flex justify-between items-center mt-4">
          <div>
            <div class="font-semibold">Amount </div>
            <div class="text-green-600 font-bold">
              {formatAmount(checkDetails()?.amount)}
            </div>
          </div>
          <div>
            <div class="font-semibold">Used </div>
            <div class="text-green-600 font-bold">
              {formatAmount(checkDetails()?.amountUsed)}
            </div>
          </div>
          <div>
            <div class="font-semibold">Available </div>
            <div class="text-green-600 font-bold">
              {formatAmount(checkDetails()?.amountRemaining)}
            </div>
          </div>
          <Show when={checkDetails()?.amountUsed === 0}>
            <button
              class="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
              onClick={() => {
                void voidCheck();
              }}
            >
              Void
            </button>
          </Show>
        </div>
      </div>
    </>
  );
};
