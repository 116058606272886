import { Notification } from '@components/Notification';
import { VendorContractViewModel } from '@store/orders';
import { Show } from 'solid-js';

import { VendorCosts } from './VendorCosts';
import { VendorPayments } from './VendorPayments';
import { Vendor } from './Vendors';

type Props = {
  vendor: VendorContractViewModel;
  index: number;
  vendorError?: string;
};

export const VendorContainer = (props: Props) => {
  return (
    <div class="rounded shadow p-2">
      <Show
        when={
          Boolean(props.vendorError) &&
          props.vendor.name === 'Falvey Shippers Insurance'
        }
      >
        <Notification type="error" text={props.vendorError} sxProps="!mb-3" />
      </Show>
      <Vendor {...props} />
      <VendorCosts {...props} />
      <VendorPayments {...props} />
    </div>
  );
};
