import { ReassignTypeEnum } from '@common/types';
import { Button, Popover, ReassignContactCard, Typography } from '@components';
import { openModal } from '@store/modals';
import { Box } from '@suid/material';
import { ILoadEditViewModel } from '@typeDefinitions/loadTypes';
import { ASSIGN_LOAD_ID } from '@views/order/constants';
import { Show } from 'solid-js';

import { coveredByStyle } from '../styles';
import { loadInfoStore } from './store';
import { handleReassignedUser } from './utils';

type PropsT = {
  load: ILoadEditViewModel;
  tabIndex: number;
};

export const AssignedTo = (props: PropsT) => {
  return (
    <div>
      <div class="flex bg-[#dfecf2] p-2 rounded max-h-12 min-w-52">
        <Box
          onClick={() => {
            void handleReassignedUser(
              props.load.assignedToUserId as number,
              ReassignTypeEnum.AssignedTo,
            );
          }}
        >
          <Popover
            eleName={
              <div class="text-left">
                <div class="text-[#123b50] font-semibold text-xs">
                  Assigned To
                </div>
                <Show
                  when={props.load.assignedToUser != null}
                  fallback={
                    <Typography
                      variant="body1"
                      component="div"
                      sxProps={{ textAlign: 'left' }}
                    >
                      -
                    </Typography>
                  }
                >
                  <div class="font-medium">{props.load.assignedToUser}</div>
                </Show>
              </div>
            }
            sxProps={coveredByStyle}
          >
            <Box sx={{ padding: '8px' }}>
              <ReassignContactCard
                contact={loadInfoStore.assignedTo}
                showIcon
                isLoading={loadInfoStore.isLoading}
              />
            </Box>
          </Popover>
        </Box>
        <div class="ml-auto mt-3.5">
          <Button
            variant="text"
            size="small"
            class=" !text-[#026EA1] !font-medium !text-sm !leading-6 !tracking-tighter !cursor-pointer !h-[20px]"
            label="REASSIGN"
            onClick={() => openModal(`${ASSIGN_LOAD_ID}-${props.tabIndex}`)}
          />
        </div>
      </div>
      <Show when={props.load.assignedToGroup != null}>
        <div class="opacity-60 text-xs font-medium ml-2 ">
          {props.load.assignedToGroup}
        </div>
      </Show>
    </div>
  );
};
