import {
  orderStore,
  updateLoadPropertyAtIndex,
  updateOrderFormErrorState,
  updateOrderState,
  updateVendorPropertyAtIndex,
} from '@store/orders';
import { userStore } from '@store/user';
import { LineItemViewModel } from '@typeDefinitions/lineItemTypes';
import { orderFormSchema } from '@views/order/validations/orderSchema';
import { get, size } from 'lodash';
import { ValidationError } from 'yup';

export const calculateSettlementCount = (key: string) => {
  return size(get(orderStore.order, key) as unknown as LineItemViewModel[]) > 0
    ? (get(orderStore.order, key) as unknown as LineItemViewModel[]).filter(
        (x) => x.status === 'Settled',
      ).length
    : 0;
};

export const userHasRole = (role: string) => {
  return userStore.user.roles != null && userStore.user.roles.includes(role);
};

export const userHasPermission = (role: string) => {
  if (userStore.user.permissions) {
    return userStore.user.permissions.includes(role);
  }
  return false;
};

export const isManager = () => userHasRole('Manager');

export const isAdmin = () => userHasRole('Admin');

export const removeTableRow = (
  index: number,
  arr: unknown,
  type: 'vendor' | 'load' | 'order',
  key: string,
) => {
  const items = [...get(arr, key, [])] as Record<string, string | number>[];

  const updateFunction = {
    vendor: updateVendorPropertyAtIndex,
    load: updateLoadPropertyAtIndex,
    order: updateOrderState,
  }[type];

  if (items[index].operationType === 'Insert') {
    items.splice(index, 1);
  } else {
    items[index] = {
      ...items[index],
      operationType: 'Delete',
    };
  }

  updateFunction({ [key]: items });
  validate();
};

const validate = () => {
  try {
    orderFormSchema.validateSync(orderStore.order.lineItems, {
      abortEarly: false,
    });
    updateOrderFormErrorState({});
  } catch (validationError: unknown) {
    const errors: Record<string, string[]> = {};
    if (validationError instanceof ValidationError) {
      validationError.inner.forEach((error: ValidationError) => {
        if (error.path !== undefined) errors[error.path] = error.errors;
      });
      updateOrderFormErrorState(errors);
    }
  }
};
