import { Button } from '@components/Button';
import { DialogBox } from '@components/DialogBox';
import { Notification } from '@components/Notification';
import { ToastType } from '@components/Toast';
import { useParams } from '@solidjs/router';
import { customerSavedQuotesStore } from '@store/customers/customerDetails';
import {
  dateComparator,
  dateFormatter,
  hourAndMinuteMatcherFromText,
} from '@store/loadboard/utils';
import { SavedQuoteInfo } from '@store/ltl';
import { markSaveQuoteRemoved } from '@store/ltl/services';
import { savedQuoteStore } from '@store/ltl/store';
import { Box, Grid as MuiGrid, Skeleton, Stack } from '@suid/material';
import { DownloadCSVFileV4 } from '@utils/DownloadCSVFileV3';
import { formatAmount, handleToast, printError } from '@utils/utils';
import { atgLogo } from '@views/ltl';
import { LTLQuotesActionCellButtonLegacy as LTLQuotesActionCellButton } from '@views/ltl/components/LTLQuotesActionCellButtonLegacy';
import { NewQuoteDetailsRowRender } from '@views/ltl/components/NewQuoteDetailsRowRender';
import { QuoteDetailsRowRenderLegacy } from '@views/ltl/components/QuoteDetailsRowRenderLegacy';
import { ColDef, IRowNode, ValueGetterParams } from '@ag-grid-community/core';
import AgGridSolid, { AgGridSolidRef } from '@ag-grid-community/solid';
import { DateTime } from 'luxon';
import { Accessor, createSignal, Show } from 'solid-js';
import {
  isFeatureFlagEnabled,
  featureFlagStore,
} from '@store/featureFlags/store';
import { featureFlags } from '@store/featureFlags/featureFlags';

import { agGridCellStyle } from './AgGridCellStyle';
import { columnsToolPanel, filtersToolPanel } from './components/constants';

import '../Grid/AgGridStyles.css';

export const agGridCellStyleExpiringSoon = {
  'white-space': 'normal',
  'line-height': '1.6 ',
  'align-items': 'center !important',
  width: '91px',
  height: '1.75rem',
  'background-color': '#F59D25',
  margin: '.5rem',
  'border-radius': '.25rem',
  color: '#FFFFFF',
  'font-size': '0.75rem',
  'justify-content': 'center',
};

const quoteGroupFieldName = 'groupId';

const defaultAggFunc = (params: {
  rowNode: IRowNode | null;
  values: number[] | string[] | null;
}): number | string | null => {
  if (
    params.rowNode &&
    Boolean(params.rowNode.field) &&
    params.rowNode.field === quoteGroupFieldName &&
    params.values &&
    params.values.length >= 0
  ) {
    return params.values[0] as number | string | null;
  }
  return null;
};

const getFirstRowValueFromGroup = (node: IRowNode): SavedQuoteInfo | null => {
  if (
    (node.group ?? false) &&
    node.field === quoteGroupFieldName &&
    node.childrenAfterFilter
  ) {
    return node.childrenAfterFilter.length > 0
      ? (node.childrenAfterFilter[0].data as SavedQuoteInfo)
      : null;
  }
  return null;
};

export const SavedQuotesGridLegacy = (props: {
  items: Accessor<SavedQuoteInfo[]>;
  setQuoteItems: (items: SavedQuoteInfo[]) => void;
  isCustomerSaveQuote: boolean;
}) => {
  const [csvExportLoading, setCsvExportLoading] = createSignal(false);
  const [rowId, setRowId] = createSignal<string | null>(null);

  const params = useParams();

  const columnDefs: ColDef[] = [
    {
      headerName: 'Customer',
      field: 'customerName',
      rowGroup: true,
      valueFormatter: (params) => {
        const value = params.value as string;
        return Boolean(value) ? `${value.toUpperCase()}` : '';
      },
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
      },
      autoHeight: true,
      hide: true,
      minWidth: 180,
      cellClass: 'ag-custom-cell-class',
      cellRenderer: 'agGroupCellRenderer',
      suppressColumnsToolPanel: true,
    },
    {
      headerName: 'Quote Group #',
      field: quoteGroupFieldName,
      rowGroup: true,
      minWidth: 180,
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
      },
      hide: true,
      floatingFilter: true,
      autoHeight: true,
      cellClass: 'ag-custom-cell-class',
      cellRenderer: 'agGroupCellRenderer',
      valueGetter: (params) => {
        const data = params.data as
          | { quoteRequestLocator?: string; quoteRequestId?: string }
          | undefined;
        return isFeatureFlagEnabled(featureFlags.useShortQuoteGroupLocator)() &&
          data &&
          Boolean(data.quoteRequestLocator)
          ? data.quoteRequestLocator
          : data
            ? data.quoteRequestId
            : params.node?.key;
      },
      suppressColumnsToolPanel: true,
    },
    {
      field: 'createdDateTimeLocal',
      headerName: 'Date Saved',
      valueFormatter: dateFormatter,
      filter: 'agDateColumnFilter',
      cellStyle: agGridCellStyle,
      floatingFilter: false,
      autoHeight: true,
      filterParams: {
        comparator: dateComparator,
        buttons: ['reset'],
        suppressAndOrCondition: true,
      },
      aggFunc: defaultAggFunc,
    },
    {
      field: 'createdDateTimeLocal',
      cellStyle: agGridCellStyle,
      autoHeight: true,
      headerName: 'Time Stamp',
      valueFormatter: (params) => {
        const value = params.value as string;
        return value ? DateTime.fromISO(value).toFormat('hh:mm a') : '';
      },
      floatingFilter: false,
      filter: 'agTextColumnFilter',
      filterParams: {
        maxNumConditions: 1,
        filterPlaceholder: 'Enter HH:mm time',
        trimInput: true,
        buttons: ['reset'],
        textMatcher: hourAndMinuteMatcherFromText,
        suppressAndOrCondition: true,
      },
      aggFunc: defaultAggFunc,
    },
    {
      field: 'expirationDateTimeLocal',
      cellStyle: (params: { node: IRowNode | null }) => {
        let isExpiring = false;
        if (params.node?.group ?? false) {
          const groupData = params.node?.aggData as SavedQuoteInfo | undefined;
          isExpiring = groupData?.willExpireSoon ?? false;
        } else {
          const rowData = params.node?.data as SavedQuoteInfo | undefined;
          isExpiring = rowData?.willExpireSoon ?? false;
        }
        return isExpiring ? agGridCellStyleExpiringSoon : agGridCellStyle;
      },
      autoHeight: true,
      headerName: 'Expiration Date',
      floatingFilter: false,
      valueFormatter: dateFormatter,
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: dateComparator,
        buttons: ['reset'],
        suppressAndOrCondition: true,
      },
      aggFunc: defaultAggFunc,
    },
    {
      field: 'createdByName',
      headerName: 'Created By',
      filter: 'agTextColumnFilter',
      floatingFilter: false,
      filterParams: {
        suppressAndOrCondition: true,
      },
      valueFormatter: (params: {
        data: {
          cPCustomerContactId?: number;
        };
        value: string;
      }) => {
        const value = params.value;
        return Boolean(
          Boolean(params.data) ? params.data.cPCustomerContactId : '',
        )
          ? `(${value})`
          : value;
      },
      aggFunc: defaultAggFunc,
    },
    {
      field: 'declaredValue',
      cellRenderer: (params: {
        data: SavedQuoteInfo | null;
        node: IRowNode | null;
      }) => {
        if (params.data) {
          const declaredValue = params.data.declaredValue;
          return Boolean(declaredValue)
            ? formatAmount(declaredValue as number)
            : '-';
        }
        const quoteVal = params.node
          ? getFirstRowValueFromGroup(params.node)
          : null;

        if (quoteVal) {
          const declaredValue = quoteVal.declaredValue;
          return Boolean(declaredValue)
            ? formatAmount(declaredValue as number)
            : '-';
        }

        return '';
      },
      pinned: 'right',
      initialWidth: 120,
      floatingFilter: false,
      aggFunc: defaultAggFunc,
    },
    {
      field: 'actions',
      cellRenderer: (params: {
        data: SavedQuoteInfo | null;
        node: IRowNode | null;
      }) => {
        if (params.data) {
          return <LTLQuotesActionCellButton savedQuoteInfo={params.data} />;
        }

        if (!params.node) {
          return;
        }

        const quoteVal = getFirstRowValueFromGroup(params.node);
        if (quoteVal) {
          return <LTLQuotesActionCellButton savedQuoteInfo={quoteVal} />;
        }
      },
      pinned: 'right',
      initialWidth: 150,
      floatingFilter: false,
    },
    {
      field: 'originZip',
      headerName: 'Origin Zip',
      floatingFilter: false,
      aggFunc: defaultAggFunc,
    },
    {
      field: 'destinationZip',
      headerName: 'Destination Zip',
      floatingFilter: false,
      aggFunc: defaultAggFunc,
    },
    {
      field: 'originCity',
      headerName: 'Origin City',
      hide: true,
      floatingFilter: false,
      aggFunc: defaultAggFunc,
    },
    {
      field: 'originState',
      headerName: 'Origin State',
      hide: true,
      floatingFilter: false,
      aggFunc: defaultAggFunc,
    },
    {
      field: 'destinationCity',
      headerName: 'Destination City',
      hide: true,
      aggFunc: defaultAggFunc,
    },
    {
      field: 'destinationState',
      headerName: 'Destination State',
      hide: true,
      floatingFilter: false,
      aggFunc: defaultAggFunc,
    },
    {
      field: 'willExpireSoon',
      hide: true,
      floatingFilter: false,
      suppressColumnsToolPanel: true,
      aggFunc: defaultAggFunc,
    },
  ];

  const savedQuote = props.isCustomerSaveQuote
    ? customerSavedQuotesStore
    : savedQuoteStore;

  let gridRef: AgGridSolidRef;

  const expiringSoonCount = () =>
    props.items().filter((f) => f.willExpireSoon).length;

  const expiringSoonDate = () => {
    return DateTime.local().plus({ days: 1 }).toFormat('M/dd/yy');
  };

  const markQuoteRemoved = async () => {
    try {
      const id = rowId();
      if (id !== null) {
        await markSaveQuoteRemoved(id).then((res) => {
          if (res) {
            props.setQuoteItems(props.items().filter((f) => f.id !== id));
            gridRef.api.refreshCells({
              force: true,
              suppressFlash: true,
            });
          }
          handleToast(ToastType.Success, 'Quote Deleted');
        });
      }
    } catch (error) {
      printError(error);
      handleToast(ToastType.Error, 'Failed to delete quote');
    }
  };

  const renderQuoteDetail = function (params: { data: SavedQuoteInfo }) {
    const { declaredValue, quotePremium, quoteServiceFee } = params.data;
    let additionalParams = {};
    if (Boolean(declaredValue) && Boolean(quotePremium)) {
      additionalParams = {
        declaredValue: declaredValue,
        falveyInsuranceVals: {
          quotePremium: quotePremium,
          quoteServiceFee: quoteServiceFee,
          shippingSumInsured: declaredValue,
        },
      };
    }
    //Prepare data for the detail row as the keys are different from the ltl quote display hence making the same format for both to make this work
    const preparedData = {
      ...params.data.responseInfo,
      totalCost: params.data.carrierTotal,
      customerRateQuote: {
        totalCost: params.data.customerTotal,
        lineItems: params.data.responseInfo.lineItems,
      },
    };
    return isFeatureFlagEnabled(featureFlags.falvey)()
      ? NewQuoteDetailsRowRender({
          data: preparedData,
          id: params.data.id,
          gridRef: gridRef,
          setRowId: setRowId,
          customerId: params.data.customerId ?? 0,
          request: params.data.requestInfo,
          isGetQuote: false,
          ...additionalParams,
        })
      : QuoteDetailsRowRenderLegacy({
          data: params.data.responseInfo,
          id: params.data.id,
          gridRef: gridRef,
          setRowId: setRowId,
          customerId: params.data.customerId ?? 0,
        });
  };

  const exportCsv = async () => {
    try {
      setCsvExportLoading(true);
      await DownloadCSVFileV4(
        `${
          Boolean(props.isCustomerSaveQuote)
            ? `customer/exportCustomerSavedQuotes/${params.id}/active`
            : 'ltl/exportSavedQuotes/active'
        }`,
        'ActiveQuotesList.csv',
      );
    } finally {
      setCsvExportLoading(false);
    }
  };

  return (
    <Stack class="h-[100%]" spacing={1.2} pt={2}>
      <Box displayRaw="flex" alignItems="center" gap={2} mb={1}>
        <Box flex={1}>
          <Show when={savedQuote.isLoading}>
            <Skeleton variant="text" width={'100%'} height={50} />
          </Show>
          <Show when={!savedQuote.isLoading && expiringSoonCount() !== 0}>
            <Notification
              tableRowNotification
              type="warning"
              isNotFlagTitle={true}
              text={
                <>
                  <strong class="font-bold!">{expiringSoonCount()} </strong>
                  quote(s) will be expiring on
                  <strong class="font-bold!"> {expiringSoonDate()}</strong>.
                  Please review the quotes that have their{' '}
                  <strong>Expiration Date </strong> highlighted in yellow.
                </>
              }
            />
          </Show>
        </Box>
        <Button
          isLoading={csvExportLoading()}
          label="Export Csv"
          variant="outlined"
          onClick={exportCsv}
        />
      </Box>
      <Show when={!featureFlagStore.isLoading}>
        <MuiGrid class="ag-theme-alpine !flex-1">
          <AgGridSolid
            ref={(ref) => (gridRef = ref)}
            overlayNoRowsTemplate="No data available"
            overlayLoadingTemplate={atgLogo}
            columnDefs={columnDefs}
            rowData={props.items()}
            class="no-cell-grid"
            groupHideOpenParents={false}
            isFullWidthRow={(params: { rowNode: IRowNode | undefined }) => {
              if (params.rowNode?.group ?? false) {
                return false;
              }
              return true;
            }}
            fullWidthCellRenderer={renderQuoteDetail}
            getRowHeight={(params: { node: IRowNode | undefined }) => {
              if (params.node?.group ?? false) {
                return 42;
              }
              if (isFeatureFlagEnabled(featureFlags.falvey)()) {
                return 266;
              }
              return 276;
            }}
            suppressAggFuncInHeader={true}
            autoGroupColumnDef={{
              filter: 'agTextColumnFilter',
              filterValueGetter: (params: ValueGetterParams) => {
                const colId = params.column.getColId();
                if (colId.includes('customerName')) {
                  return (params.data as SavedQuoteInfo).customerName;
                } else if (colId.includes('groupId')) {
                  return Boolean(
                    (params.data as SavedQuoteInfo).quoteRequestLocator,
                  ) &&
                    Boolean(
                      isFeatureFlagEnabled(
                        featureFlags.useShortQuoteGroupLocator,
                      )(),
                    )
                    ? (params.data as SavedQuoteInfo).quoteRequestLocator
                    : (params.data as SavedQuoteInfo).groupId;
                }
              },
            }}
            gridOptions={{
              defaultColDef: {
                flex: 1,
                filter: true,
                floatingFilter: true,
              },
              suppressScrollOnNewData: true,
              groupDisplayType: 'multipleColumns',
              groupDefaultExpanded: 5,
              getRowId: (params: { data: SavedQuoteInfo }) => params.data.id,
              pagination: false,
              suppressPaginationPanel: true,
              sideBar: {
                toolPanels: [columnsToolPanel, filtersToolPanel],
                defaultToolPanel: '',
              },
              onGridReady: (params) => {
                savedQuote.isLoading
                  ? params.api.showLoadingOverlay()
                  : params.api.hideOverlay();
              },
            }}
          />
        </MuiGrid>
      </Show>
      <DialogBox
        title="Are you sure? This will delete this quote."
        id="deleteSavedQuote"
        onSubmit={markQuoteRemoved}
        onClose={() => setRowId(null)}
      />
    </Stack>
  );
};
