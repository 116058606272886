import { createStore } from 'solid-js/store';

import { CarrierDetailsState, CarrierLoadHistoryState } from './types';

const [carrierDetailsStore, setCarrierDetailsStore] =
  createStore<CarrierDetailsState>({
    carrierDetails: null,
    isLoading: false,
    isError: false,
    carrierDocuments: [],
    carrierFuelSurcharge: null,
    carrierAdjustments: [],
    selectedCountry: 'US',
    carrierDetailsErrors: null,
    creationMode: false,
    carrierAdjustmentAccounts: [],
  });

const [carrierLoadHistoryStore, setCarrierLoadHistoryStore] =
  createStore<CarrierLoadHistoryState>({
    isLoading: false,
    isError: false,
    items: [],
    totalCount: 0,
  });
// @ts-expect-error debug console
window.carrierDetailsStore = { carrierDetailsStore };

export {
  carrierDetailsStore,
  setCarrierDetailsStore,
  carrierLoadHistoryStore,
  setCarrierLoadHistoryStore,
};
