import { Warning } from '@suid/icons-material';
import { Box } from '@suid/material';
import { Show } from 'solid-js';
import Cancel from '@suid/icons-material/Cancel';
import {
  VendorContractViewModel,
  orderStore,
  removeVendorFromOrder,
  saveOrderDetails,
  setOrderStore,
  voidVendor,
} from '@store/orders';
import { formatAmount } from '@utils/utils';
import { DialogBox } from '@components/DialogBox';
import { openDialogBox } from '@store/dialogBox';
import { setLtlQuoteState } from '@store/ltl/store';
import { getLTLFalveyPricingQuotes } from '@store/ltl/actions';
import { isFalveyInsuranceCommitted } from '@store/orders/helper';
import { OrderLineItem } from '@typeDefinitions/lineItemTypes';

import classes from './classes';

const checkVendorTabError = (index: number) => {
  //Note: adding this to show the error on the vendor tab for falvey insurance
  const {
    order: { vendors },
    vendorFalveyInsuranceError,
  } = orderStore;
  if (
    vendorFalveyInsuranceError &&
    vendors &&
    vendors[index].name === 'Falvey Shippers Insurance'
  )
    return true;
  for (const key in orderStore.orderFormError) {
    const newKey = key.split('.')[0];
    if (newKey === `vendors[${index}]`) return true;
  }
};

const getVendorPrice = (index: number) => {
  return orderStore.order.vendors
    ? orderStore.order.vendors[index].lineItems?.reduce(
        (acc, item) => acc + Number(item.rate) * Number(item.quantity),
        0,
      )
    : 0;
};

export const VendorTab = (
  index: number,
  item: VendorContractViewModel | undefined,
  setTabIndex?: (index: number) => void,
) => {
  const removeCustomerPayLineItem = (
    lineItems: OrderLineItem[],
  ): OrderLineItem[] => {
    const falveyCPIndex = lineItems.findIndex(
      (lineItem) =>
        lineItem.type === 'LTL Insurance' &&
        lineItem.operationType !== 'Delete',
    );

    if (falveyCPIndex !== -1) {
      if (lineItems[falveyCPIndex].operationType === 'Insert') {
        lineItems.splice(falveyCPIndex, 1);
      } else {
        lineItems[falveyCPIndex] = {
          ...lineItems[falveyCPIndex],
          operationType: 'Delete',
        };
      }
    }
    return lineItems;
  };

  const handleVendorRemoval = async () => {
    if (item?.id !== 0) {
      const resp = await voidVendor(item!.id as number, index);
      Boolean(resp) && setTabIndex?.(0);
      await saveOrderDetails();
    } else {
      removeVendorFromOrder(index);
      setTabIndex?.(0);
    }
    setLtlQuoteState('declaredValue', undefined);
  };

  const updateLoadDeclaredValue = async () => {
    //This will return index of the first Load with declared value
    const falveyLoadIndex = orderStore.order.loads.findIndex((load) =>
      Boolean(load.ltlQuote?.ltlInsurance),
    );

    if (falveyLoadIndex !== -1) {
      await getLTLFalveyPricingQuotes(
        {
          declaredValue: 0,
          ltlQuoteId: orderStore.order.loads[falveyLoadIndex].ltlQuote
            ? orderStore.order.loads[falveyLoadIndex].ltlQuote.id
            : undefined,
        },
        true,
      );
      setOrderStore(
        'order',
        'loads',
        falveyLoadIndex,
        'ltlQuote',
        'ltlInsurance',
        'shippingSumInsured',
        0,
      );
    }
  };

  const removeFalveyInsurance = async () => {
    // Remove CP from order
    const lineItems = removeCustomerPayLineItem([
      ...orderStore.order.lineItems,
    ]);
    setOrderStore('order', 'lineItems', lineItems);

    // Remove vendor from order
    await handleVendorRemoval();
    // Update load declared value
    await updateLoadDeclaredValue();
  };

  const removeVendorTabHidden = () => {
    if (item?.name === 'Falvey Shippers Insurance') {
      return (
        (item.status === 'Open' || item.id === 0) &&
        !isFalveyInsuranceCommitted()
      );
    }
    return item?.status === 'Open' || item?.id === 0;
  };

  return (
    <Box class={'flex items-center gap-2 rounded-t-lg px-[5px] py-[5px] '}>
      <Show when={checkVendorTabError(index)}>
        <Box class={classes.errorChildBox}>
          <Warning sx={{ color: '#B00020' }} />
        </Box>
      </Show>
      <Box class={classes.tabChildBox}>
        <p
          class={`${
            checkVendorTabError(index) ? '!text-[#B00020]' : 'text-[#0E2C3B]'
          } text-sm font-medium]`}
        >
          {item?.name !== '' ? item?.name : 'VENDOR'}
        </p>
        <p
          class={`${
            checkVendorTabError(index) ? '!text-[#B00020]' : 'text-[#0E2C3B]'
          } text-sm font-medium`}
        >
          {formatAmount(getVendorPrice(index) ?? 0)}
        </p>
      </Box>
      <Show when={removeVendorTabHidden()} fallback={<></>}>
        <Cancel
          onClick={(e: MouseEvent) => {
            //This is to prevent the tab click event from firing
            e.stopPropagation();

            if (item?.id === 0 && item.name !== 'Falvey Shippers Insurance') {
              removeVendorFromOrder(index);
              setTabIndex?.(0);
            } else if (
              (item?.name === 'Falvey Shippers Insurance' && item.id === 0) ||
              item?.status === 'Open'
            ) {
              openDialogBox(`voidVendor${index}`);
            }
          }}
        />
      </Show>
      <DialogBox
        id={`voidVendor${index}`}
        title={
          item?.name === 'Falvey Shippers Insurance'
            ? `Are sure they want to remove the insurance quote from the Order ${
                orderStore.order.id
              }-${orderStore.activeTab.index + 1}?`
            : `Are you sure you want to void vendor ${orderStore.order.id}-${
                orderStore.activeTab.index + 1
              }?`
        }
        onSubmit={async () => {
          if (item?.name === 'Falvey Shippers Insurance') {
            await removeFalveyInsurance();
          } else {
            void voidVendor(item!.id as number, index);
            setTabIndex?.(0);
          }
        }}
      />
    </Box>
  );
};
