import { Button, ToastType } from '@components';
import {
  customerStore,
  orderStore,
  shareAndUnShareLoadCapacity,
  updateLoadPropertyAtIndex,
} from '@store/orders';
import ReduceCapacityIcon from '@suid/icons-material/ReduceCapacity';
import { ILoadEditViewModel } from '@typeDefinitions/loadTypes';
import { handleToast } from '@utils/utils';
import { isEmpty } from 'lodash';
import { createSignal } from 'solid-js';

type PropsT = {
  load: ILoadEditViewModel;
  tabIndex: number;
};

const ShareAndUnShareCapacity = (props: PropsT) => {
  const [capacityLoading, setCapacityLoading] = createSignal(false);
  const shareOrUnShareCapacity = async () => {
    try {
      setCapacityLoading(true);
      const payload = {
        id: props.load.id,
        customerId: customerStore.customer.id,
        loadId: orderStore.order.loads[props.tabIndex].id,
        orderId: orderStore.order.id,
        removeCapacity: props.load.hasBeenSharedWithCapacity,
      };
      const res = await shareAndUnShareLoadCapacity(payload);

      if (!isEmpty(res) && Boolean(res.isSuccess)) {
        updateLoadPropertyAtIndex({
          hasBeenSharedWithCapacity: !props.load.hasBeenSharedWithCapacity,
          assignedToUserId: res.value.assignedId,
          assignedToUser: res.value.assignedName,
        });
      }
      handleToast(
        ToastType.Success,
        props.load.hasBeenSharedWithCapacity
          ? 'Capacity Shared'
          : 'Capacity Un-Shared',
      );
    } catch (error) {
      handleToast(ToastType.Error, (error as Error).message);
    } finally {
      setCapacityLoading(false);
    }
  };

  const shareCapacityDisabled = () => {
    return (
      !(customerStore.customer.capacityGroupId !== null) ||
      props.load.id < 1 ||
      capacityLoading() ||
      ['Billed', 'Void', 'Paid'].includes(props.load.status ?? '') ||
      props.load.hideCustomerName
    );
  };

  const disabledCapacityButtonTooltip = () => {
    if (!shareCapacityDisabled()) {
      return 'Press to Un-Share';
    } else if (['Billed', 'Void', 'Paid'].includes(props.load.status ?? '')) {
      return 'Cannot Un-Share after billing';
    }
    return '';
  };

  const shareUnShareButtonLabel = () =>
    props.load.hasBeenSharedWithCapacity
      ? 'Un-Share Capacity'
      : 'Share Capacity';

  const shareUnShareButtonColor = () =>
    props.load.hasBeenSharedWithCapacity || shareCapacityDisabled()
      ? '#123B50'
      : '#248670';

  const shareUnShareButtonTitle = () =>
    props.load.hasBeenSharedWithCapacity
      ? 'Please send an email to shared.capacity@armstrongtransport.com to inquire about Shared Capacity'
      : disabledCapacityButtonTooltip();

  return (
    <div class="min-w-[180px]">
      <Button
        size="small"
        startIcon={<ReduceCapacityIcon />}
        disabled={shareCapacityDisabled()}
        isLoading={capacityLoading()}
        class={`!text-armstrong-sm !bg-[${shareUnShareButtonColor()}]`}
        variant="contained"
        label={shareUnShareButtonLabel()}
        title={shareUnShareButtonTitle()}
        onClick={shareOrUnShareCapacity}
      />
    </div>
  );
};

export default ShareAndUnShareCapacity;
