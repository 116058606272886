import { Close, Search } from '@suid/icons-material';
import CloseIcon from '@suid/icons-material/Close';
import {
  Chip,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Popper,
  TextField,
} from '@suid/material';
import { debounce, get } from 'lodash';
import {
  For,
  JSX,
  createEffect,
  createSignal,
  mergeProps,
  onCleanup,
  onMount,
} from 'solid-js';
import { formatPhoneNumber } from '@utils/phoneNumberFormat';

import ClickAwayListener from './ClickAwayListener';
import { ATGAutoCompleteItemT, AutocompleteProps } from './types';

const defaultValue = {
  debounceDelay: 300,
  debounce: true,
  defaultValue: '',
  error: null,
  forceAutoSelect: false,
  mode: 'single',
  isStatic: false,
  minCharacters: 2,
};

export const Autocomplete = <T,>(props: AutocompleteProps<T>) => {
  const mp = mergeProps(defaultValue, props);

  const [anchorEl, setAnchorEl] = createSignal<HTMLInputElement | null>(null);
  const [loading, setLoading] = createSignal<boolean>(false);
  const [isDropdownOpen, setDropdownOpen] = createSignal<boolean>(false);
  const [searchQuery, setSearchQuery] = createSignal<string>(
    get(props, 'defaultValue') ?? ('' as string),
  );
  const [searchResults, setSearchResults] = createSignal<
    ATGAutoCompleteItemT<T>[] | T[]
  >([]);
  const [activeIndex, setActiveIndex] = createSignal(-1);
  let listItemRef: HTMLLIElement[] = [];

  createEffect(() => {
    if (Boolean(mp.value)) {
      setSearchQuery(mp.value as string);
    } else {
      setSearchQuery(mp.defaultValue);
    }
  });

  function calculateWidth() {
    const textFieldElement = document.getElementById(props.id);
    if (textFieldElement) {
      return textFieldElement.clientWidth;
    }
    return 450;
  }

  const handleInputFocus = (event: MouseEvent) => {
    if (searchQuery() && searchResults().length > 0) setDropdownOpen(true);
    setAnchorEl(event.target as HTMLInputElement);
    event.stopPropagation();
  };

  const debounceDelay = 1000; // milliseconds

  const performSearch = debounce(async (query: string) => {
    const charactersLimit = mp.minCharacters;
    if (
      (query.trim().length > charactersLimit && query !== '') ||
      Boolean(props.isStatic)
    ) {
      setLoading(true);
      props.fetchData && (await props.fetchData(query, setSearchResults));
      if (Boolean(props.forceAutoSelect) && searchResults().length === 1) {
        handleSelect(searchResults()[0]);
        setDropdownOpen(false);
      } else {
        setDropdownOpen(true);
      }
      setLoading(false);
    } else {
      setSearchResults([]);
    }
  }, debounceDelay);

  const handleInputChange = (e: Event) => {
    setDropdownOpen(false);
    //NOTE: set activeIndex to 0 and reset listItemref when search query changes
    listItemRef = [];
    setSearchResults([]);
    setActiveIndex(0);
    const target = e.target as HTMLInputElement;
    if (props.phoneInput ?? false) {
      const cursorPosition = target.selectionStart;
      const oldValue = target.value;
      const oldLength = oldValue.length;
      const newUnformattedValue = oldValue.replace(/[^0-9]/g, '');
      if (newUnformattedValue.length <= 10) {
        const newValue = formatPhoneNumber(newUnformattedValue);
        const newLength = newValue.length;
        setSearchQuery(newValue);
        mp.onChange && mp.onChange(newUnformattedValue);
        if (cursorPosition !== null) {
          const diff = newLength - oldLength;
          target.setSelectionRange(
            cursorPosition + diff,
            cursorPosition + diff,
          );
        }
      }
      void performSearch(newUnformattedValue);
    } else {
      setSearchQuery(target.value);
      mp.onChange && mp.onChange(target.value);
      void performSearch(target.value);
    }
  };

  const handleSelect = (
    item: ATGAutoCompleteItemT<T>,
    isProgrammatic = false,
  ) => {
    (props.shouldPerformSearch ?? false) && setSearchQuery('');
    setDropdownOpen(false);
    if (props.onItemSelect) {
      props.onItemSelect(item, isProgrammatic);
    }
    setActiveIndex(-1);
    Boolean(props.clearSearch) && handleClearSearch();
  };

  const handleEnterSelect = () => {
    if (
      isNaN(activeIndex()) ||
      activeIndex() < 0 ||
      activeIndex() >= searchResults().length
    ) {
      return;
    }

    handleSelect(searchResults()[activeIndex()], true);
  };

  const handleClearSearch = () => {
    setSearchQuery('');
    setDropdownOpen(false);
    if (props.onClearValue) {
      props.onClearValue();
      setSearchResults([]);
    }
    setActiveIndex(-1);
  };

  createEffect(() => {
    if (props.clearSearch ?? false) {
      handleClearSearch();
      setSearchResults([]);
    }
  });

  function renderEndAdornment() {
    if (loading()) {
      return <CircularProgress />;
    } else if (searchQuery() && !Boolean(props.disabled)) {
      return (
        <Close
          sx={{ cursor: 'pointer', zIndex: '49' }}
          onClick={handleClearSearch}
        />
      );
    } else if (Boolean(props.hideIcon) || Boolean(props.disabled)) {
      return <></>;
    }

    return <Search sx={{ cursor: 'pointer' }} />;
  }

  let observer: IntersectionObserver;

  onMount(() => {
    const textFieldElement = document.getElementById(
      props.id,
    ) as HTMLInputElement;
    if (Boolean(textFieldElement)) {
      observer = new IntersectionObserver(
        (entries: IntersectionObserverEntry[]) => {
          entries.forEach((entry) => {
            if (Boolean(!entry.isIntersecting)) {
              setDropdownOpen(false);
            }
          });
        },
        {
          root: null,
          threshold: 0.1,
        },
      );

      observer.observe(textFieldElement);
    }
    if (Boolean(props.isStatic)) {
      setDropdownOpen(true);
      props.staticItemList && setSearchResults(props.staticItemList);
    }
  });

  onCleanup(() => {
    if (Boolean(observer)) {
      observer.disconnect();
    }
  });

  const handleKeyDown = (e: KeyboardEvent) => {
    //NOTE: Removed create effect causing issues with multiple triggers and used event callback from TextField
    if (e.key === 'Enter') {
      e.preventDefault();
      handleEnterSelect();
    }
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      if (searchResults().length > 0 && isDropdownOpen()) {
        setActiveIndex((prevIndex) => {
          if (e.key === 'ArrowUp') {
            if (prevIndex === -1 || prevIndex === 0) {
              return searchResults().length - 1;
            }
            return prevIndex - 1;
          }
          return (prevIndex + 1) % searchResults().length;
        });
        if (activeIndex() >= 0 && activeIndex() < listItemRef.length) {
          const activeElement = listItemRef[activeIndex()];
          activeElement.scrollIntoView({
            block: 'nearest',
          });
        }
      }
    }
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        setDropdownOpen(false);
        setActiveIndex(-1);
      }}
      innerRefClass={mp.innerRefClass}
    >
      <TextField
        name={props.name}
        onBlur={props.onBlur}
        id={props.id}
        onFocus={(e: unknown) => handleInputFocus(e as MouseEvent)}
        fullWidth
        required={props.required}
        autoComplete="off"
        onChange={handleInputChange}
        value={searchQuery()}
        placeholder={props.placeholder}
        variant={props.variant ?? 'outlined'}
        class={props.class}
        label={props.label}
        onInput={props.onInput}
        size={props.size || 'small'}
        InputProps={{
          startAdornment: props.startAdornment,
          endAdornment:
            props.disableClear !== true
              ? (props.customButton as JSX.Element) ?? renderEndAdornment()
              : '',
          onKeyDown: handleKeyDown,
        }}
        inputProps={props.inputProps}
        data-testid={props.dataTestId}
        error={Boolean(props.disabled) ? undefined : Boolean(props.error)}
        disabled={props.disabled}
        helperText={
          Array.isArray(props.error) && props.error.length > 0
            ? props.error[0]
            : ''
        }
        sx={{
          backgroundColor: 'white',
          '& .MuiInputLabel-root.Mui-focused': {
            fontWeight: 600, //Label Font weight when focused
          },
          '& .MuiInputLabel-root.MuiFormLabel-filled:not(.Mui-disabled):not(.Mui-error)':
            {
              fontWeight: 600, //Label Font weight when there is a value
              color: '#123b50', //Label color when there is a value
            },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderWidth: '1px',
              borderColor: '#80b6cf',
              fontSize: '14px',
              color: '#123b50',
              fontWeight: 600,
            },
          },
          height: props.size === 'small' ? '36px' : undefined,
          fontSize: props.size === 'small' ? '14px' : undefined,
        }}
      />
      {props.multipleValueRender &&
        props.mode === 'multiple' &&
        props.multipleValueRender()}
      {props.multipleSelectValues &&
        props.mode === 'multiple' &&
        props.multipleSelectValues.map((value) => (
          <Chip
            label={<>{get(value, `${props.renderMultipleKey}`)}</>}
            deleteIcon={
              <CloseIcon
                sx={{
                  fontSize: '15px !important',
                  color: 'white !important',
                }}
                onMouseDown={(event) => event.stopPropagation()}
              />
            }
            sx={{
              color: 'white',
              margin: '5px 5px 5px 0px',
              backgroundColor: '#026EA1',
              borderRadius: 1,
            }}
            onDelete={() => {
              props.onMultipleDelete && props.onMultipleDelete(value);
              handleClearSearch();
            }}
          />
        ))}
      <Popper
        component={'div'}
        open={isDropdownOpen()}
        anchorEl={anchorEl()}
        placement="bottom-start"
        class="!bg-white shadow-lg !border-1"
        style={{ width: `${calculateWidth() + 40}px`, 'z-index': 1300 }}
      >
        <List
          class={`!overflow-auto !max-h-60 ${
            searchResults().length > 0 ? '' : '!p-0'
          }`}
        >
          {searchResults().length === 0 &&
            searchQuery().length > 3 &&
            !loading() && (
              <ListItem classes={{ root: 'hover:bg-gray-100' }}>
                <ListItemText
                  primary="No results found"
                  secondary="Please try with different name"
                />
              </ListItem>
            )}
          <For each={searchResults()}>
            {(item: ATGAutoCompleteItemT<T>, i) => (
              <ListItem
                onClick={() => handleSelect(item)}
                classes={{
                  root: `hover:bg-gray-100 cursor-pointer ${
                    activeIndex() === i() ? 'bg-gray-100' : ''
                  }`,
                }}
                sx={props.renderItemStyle ? props.renderItemStyle : {}}
                ref={(el) => {
                  listItemRef[i()] = el;
                }}
              >
                {props.renderItem ? (
                  props.renderItem(item)
                ) : (
                  <ListItemText
                    class="ml-5"
                    primary={get(item, props.responseFieldName ?? 'name')}
                  />
                )}
              </ListItem>
            )}
          </For>
        </List>
      </Popper>
    </ClickAwayListener>
  );
};
