import { ILoadEditViewModel } from '@typeDefinitions/loadTypes';

import { Layout } from './Layouts/Layout';

export type Props = {
  load: ILoadEditViewModel;
  index: number;
  edi: boolean;
  show214: boolean;
};

export const LoadContainer = (props: Props) => {
  return (
    <div>
      <Layout
        load={props.load}
        index={props.index}
        edi={props.edi}
        show214={props.show214}
      />
    </div>
  );
};
