import {
  equipmentTypes,
  intermodalEquipmentTypes,
  loadModesTypes,
} from '@common/commonLists';
import { CheckboxInput, SelectField, TextInput } from '@components/forms';
import { orderStore, updateLoadPropertyAtIndex } from '@store/orders';
import { ILoadEditViewModel } from '@typeDefinitions/loadTypes';

type PropsT = {
  load: ILoadEditViewModel;
  tabIndex: number;
};

export const LoadDetailsRow = (props: PropsT) => {
  return (
    <div class="mb-2">
      <div class="text-base text-armstrong-primary font-semibold">
        Load Details
      </div>
      <div class="flex gap-x-2 my-2">
        <div class="w-1/3">
          <SelectField
            menuItemGrow
            label="Mode"
            menuItems={loadModesTypes.map((mode) => {
              return {
                label: mode,
                value: mode,
              };
            })}
            onChange={(e) =>
              updateLoadPropertyAtIndex({
                // @ts-expect-error This is the same error in the old code.
                mode: e.target.value,
                teamRequired:
                  e.target.value === 'LTL' ? false : props.load.teamRequired,
                secondDriver:
                  e.target.value === 'LTL'
                    ? undefined
                    : props.load.secondDriver,
                secondDriverPhoneNumber:
                  e.target.value === 'LTL'
                    ? undefined
                    : props.load.secondDriverPhoneNumber,
              })
            }
            value={props.load.mode ?? ''}
            size="small"
          />
        </div>
        <div class="w-full">
          <TextInput
            label="Product"
            value={props.load.product ?? ''}
            onChange={(val: string) =>
              updateLoadPropertyAtIndex({
                product: val,
              })
            }
            size="small"
            maxLength={30}
          />
        </div>
      </div>
      <div class="flex gap-x-2 my-2">
        <div class="w-1/3">
          <SelectField
            menuItemGrow
            label="Equipment"
            menuItems={
              props.load.mode === 'Intermodal'
                ? intermodalEquipmentTypes
                : equipmentTypes
            }
            onChange={(e) =>
              updateLoadPropertyAtIndex({
                equipment: e.target.value,
              })
            }
            size="small"
            value={props.load.equipment ?? ''}
          />
        </div>
        <div class="w-1/3">
          <TextInput
            label="Temperature Instructions"
            value={props.load.tempInstructions ?? ''}
            onChange={(val: string) =>
              updateLoadPropertyAtIndex({
                tempInstructions: val,
              })
            }
            size="small"
            classes="h-full"
            error={
              orderStore.orderFormError &&
              orderStore.orderFormError[
                `loads[${props.tabIndex}].tempInstructions`
              ]
            }
          />
        </div>
        <div class="w-1/3">
          <TextInput
            label="Tracking Number"
            value={props.load.trackingNumber ?? ''}
            onChange={(val: string) =>
              updateLoadPropertyAtIndex({
                trackingNumber: val,
              })
            }
            maxLength={60}
            size="small"
          />
        </div>
      </div>
      <div class="flex gap-x-2">
        {props.load.mode !== 'Intermodal' ? (
          <>
            <CheckboxInput
              label="Team"
              checked={props.load.teamRequired}
              onChange={(checked) => {
                updateLoadPropertyAtIndex({
                  teamRequired: checked,
                  secondDriver: checked ? props.load.secondDriver : undefined,
                  secondDriverPhoneNumber: checked
                    ? props.load.secondDriverPhoneNumber
                    : undefined,
                });
              }}
            />
            <CheckboxInput
              label="Over Dimension"
              checked={props.load.od}
              onChange={(checked) => {
                updateLoadPropertyAtIndex({
                  od: checked,
                });
              }}
            />
            <CheckboxInput
              label="Tarps Required"
              checked={props.load.tarpRequired}
              onChange={(checked) => {
                updateLoadPropertyAtIndex({
                  tarpRequired: checked,
                });
              }}
            />
          </>
        ) : null}
        <CheckboxInput
          label="Hazmat"
          checked={props.load.hazmatRequired}
          onChange={(checked) => {
            updateLoadPropertyAtIndex({
              hazmatRequired: checked,
            });
          }}
        />
        <CheckboxInput
          label="Ramps Required"
          checked={props.load.ramps}
          onChange={(checked) => {
            updateLoadPropertyAtIndex({
              ramps: checked,
            });
          }}
        />
      </div>
    </div>
  );
};
