import { BasicModal, BasicTable, Card, Typography } from '@components';
import { Column } from '@components/BasicTable/BasicTable';
import { closeModal, openModal } from '@store/modals';
import { PaymentViewModel, VendorContractViewModel } from '@store/orders/types';
import InfoIcon from '@suid/icons-material/Info';
import { IconButton } from '@suid/material';
import { formatAmount } from '@utils/utils';
import { get, size } from 'lodash';
import { createSignal, Show } from 'solid-js';

import { checkDetailsModalId } from '../CarrierPayments';
import { CheckDetails } from '../CheckDetails';
import classes from './classes';

type Props = {
  index: number;
  vendor: VendorContractViewModel;
};

const iconStyles = {
  color: '#0070A2',
  cursor: 'pointer',
};

export const VendorPayments = (props: Props) => {
  const getFilteredLineItems = () =>
    get(props.vendor, 'lineItems', []).filter(
      (item) => item.operationType !== 'Delete',
    );
  const [activeRow, setActiveRow] = createSignal<PaymentViewModel | null>(null);

  const calculateTotalLineItems = () => {
    return size(getFilteredLineItems()) > 0
      ? getFilteredLineItems().reduce(
          (acc, item) => acc + Number(item.rate) * Number(item.quantity),
          0,
        )
      : 0;
  };

  const calculateTotalPayments = () => {
    return size(get(props.vendor, 'payments')) > 0
      ? get(props.vendor, 'payments', [])
          .filter((item) => item.operationType !== 'Delete')
          .reduce((acc, item) => acc + Number(item.amount), 0)
      : 0;
  };

  const columns: Column<PaymentViewModel>[] = [
    {
      name: 'Date',
      key: 'Date',
      renderCell: (rowData) => rowData.paidDate,
    },
    {
      name: 'Description',
      key: 'description',
      minWidth: 200,
      renderCell: (rowData) => rowData.description,
    },
    {
      name: 'Reference',
      key: 'reference',
      renderCell: (rowData) => rowData.referenceNum,
    },
    {
      name: 'Amount',
      key: 'amount',
      renderCell: (rowData) => <>{Number(rowData.amount).toFixed(2)}</>,
    },
    {
      name: 'Payment #',
      key: 'payment',
      renderCell: (rowData) => <>{rowData.checkNumber}</>,
    },
    {
      name: 'Info',
      key: 'info',
      renderCell: (rowData) => (
        <Show
          when={rowData.checkNumber}
          fallback={<div class="w-[40px]"></div>}
        >
          <IconButton>
            <InfoIcon
              sx={{ ...iconStyles, fontSize: '1.5rem' }}
              onClick={() => {
                setActiveRow(rowData);
                openModal(checkDetailsModalId);
              }}
            />
          </IconButton>
        </Show>
      ),
    },
  ];

  const footerRow = [
    {
      key: '#',
    },
    {
      key: '#',
    },
    {
      renderCell: () => (
        <Typography variant="body1" class={classes.totalLabel}>
          Outstanding
        </Typography>
      ),
      key: 'Outstanding',
    },
    {
      renderCell: () => (
        <Typography variant="body1" class={classes.totalAmount}>
          {formatAmount(calculateTotalLineItems() - calculateTotalPayments())}
        </Typography>
      ),
      key: '#',
    },
    {
      renderCell: () => (
        <Typography variant="body1" class={classes.totalLabel}>
          Total Paid
        </Typography>
      ),
      key: '#',
    },
    {
      key: '#',
      renderCell: () => (
        <Typography variant="body1" class={classes.totalAmount}>
          {formatAmount(calculateTotalPayments())}
        </Typography>
      ),
    },
  ];

  return (
    <Card startTitle="Vendor Payments">
      <BasicTable<PaymentViewModel>
        footerRow={footerRow}
        footerBorder={false}
        tableBodyClasses={classes.tableBg}
        tableFooterClasses={classes.tableFooter}
        tableBodyRowClasses={classes.tableFooterRow}
        columns={columns}
        rows={props.vendor.payments || []}
        tableFooterRowClasses={classes.tableFooterRow}
      />
      <BasicModal
        title="Check Details"
        id={checkDetailsModalId}
        onClose={() => closeModal(checkDetailsModalId)}
        showClose={false}
        header={false}
        width={'40vw'}
        footer={false}
      >
        <CheckDetails activeRow={activeRow()!} />
      </BasicModal>
    </Card>
  );
};
