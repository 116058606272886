// TODO take this file out of contexts and put it in a more appropriate place
// this file was created to use with FormContext which we are not using anymore
import { ILoadEditViewModel } from '@typeDefinitions/loadTypes';
import { IOrderViewModel } from '@typeDefinitions/orderTypes';
import { createConditionalSchema } from '@utils/formUtils';
import { compact, flatten } from 'lodash';
import * as yup from 'yup';

import { adjustmentsSchema } from './adjustmentSchema';
import {
  checkLTLModeError,
  checkTLModeError,
  // createLineItemTypeSchema,
  isStopValid,
  ltlLoadHasItemDescription,
} from './helperFunctions';
import { vendorSchema } from './vendorsSchema';

/* Use this Phone Regex for validating phone number in project.  */
const phoneRegExp = /^\(\d{3}\) \d{3}-\d{4}$/;

const lineItemSchemaFactory = (section: string) => {
  return yup.object().shape({
    type: yup.string().nullable(),
    description: yup
      .string()
      .nullable()
      .max(150, 'Description must be no more than 150 characters')
      .meta({ section }),
    quantity: yup
      .mixed()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .test('is-number-or-empty', 'quantity must be a number', (value: any) => {
        return value === '' || !isNaN(Number(value));
      })
      .test(
        'must-be-at-least-999',
        'Quantity must be less than 99999',
        (value) => {
          return Number(value) <= 99999;
        },
      )
      .nullable()
      .meta({ section }),
    rate: yup
      .mixed()
      .test('is-number-or-empty', 'Rate must be a number', (value: unknown) => {
        return value === null || value === '' || !isNaN(Number(value));
      })
      .test(
        'must-be-at-least-999',
        'Rate must be less than 10000000',
        (value) => {
          return Number(value) <= 10000000;
        },
      )
      .nullable()
      .meta({ section }),

    estimate: yup.boolean().nullable(),
  });
};

const flagSchema = yup.object().shape({
  description: yup.string().nullable(),
  flagType: yup.string().nullable(),
  emailId: yup.string().nullable(),
});

const freightValueSchema = (cargoValueIsHigh: boolean) => {
  if (cargoValueIsHigh) {
    return yup
      .number()
      .min(100001, 'Cargo Value must be $100,001 - $999,999,999')
      .max(999999999, 'Cargo Value must be $100,001 - $999,999,999')
      .required('Cargo Value is required')
      .meta({
        section: 'carrier',
      });
  }

  return yup
    .number()
    .nullable()
    .min(0, 'Cargo Value must be $0 - $100,000')
    .max(100000, 'Cargo Value must be $0 - $100,000')
    .meta({
      section: 'carrier',
    });
};

const loadsSchema = yup.object().shape({
  cargoValueIsHigh: yup.string().nullable(),
  freightValue: yup
    .number()
    .nullable()
    .when('cargoValueIsHigh', ([cargoValueIsHigh], schema) => {
      return schema.test({
        name: 'freightValue',
        test: function (value) {
          const load = this.parent as ILoadEditViewModel;
          const mode = load.mode;

          if (mode === 'Partial') {
            return true; // Skip validation if mode is 'Partial'
          }

          try {
            freightValueSchema(cargoValueIsHigh === 'Yes').validateSync(value);
            return true;
          } catch (error) {
            // @ts-expect-error this will be known
            return this.createError({ message: error.message });
          }
        },
      });
    }),
  minPrice: yup.number().nullable(),
  maxPrice: yup.number().nullable(),
  stopsCustomError: yup.string().when(() => {
    return yup
      .string()
      .nullable()
      .test({
        name: 'stopsCustomError',
        test: function () {
          const { createError, options, parent } = this;
          const { index } = options as { index: number };
          const load = parent as ILoadEditViewModel;

          const stopErr = isStopValid(load.stops ?? []);
          const TLModeError =
            load.mode === 'TL' ? checkTLModeError(load, index) : [];
          const LTLModeError =
            load.mode === 'LTL' ? checkLTLModeError(load) : [];
          const missingItemDescriptionError =
            load.mode === 'LTL' && !ltlLoadHasItemDescription(load)
              ? ['Stop Items are missing an Item Description.']
              : [];

          const errors = compact(
            flatten([
              stopErr,
              TLModeError,
              LTLModeError,
              missingItemDescriptionError,
            ]),
          );

          if (errors.length > 0) {
            return createError({
              message: errors,
            });
          }
          return true;
        },
      })
      .meta({ section: 'carrier' });
  }),
  driver: yup
    .string()
    .max(50, 'Driver name must be less than 50 characters')
    .nullable(),
  driverPhoneNumber: yup
    .string()
    .trim()
    .transform((value: string) => (value === '' ? null : value))
    .nullable()
    .length(10, 'Phone number must be exactly 10 digits'),
  trailerNumber: yup
    .string()
    .max(50, 'Trailer number must be less than 50 characters')
    .nullable(),
  truckNumber: yup
    .string()
    .max(50, 'Truck number must be less than 50 characters')
    .nullable(),
  lineItems: yup
    .array()
    .of(createConditionalSchema(lineItemSchemaFactory('instructionsAndCosts'))),
  flags: yup.array().of(flagSchema),
  nextCheckCallDateUtc: yup.string().nullable(),
  tempInstructions: yup
    .string()
    .nullable()
    .max(1000, 'Instructions must be less than 1000 characters'),
});

export const orderFormSchema = yup.object({
  customerId: yup.number().when((value) => {
    return value[0] === null
      ? yup
          .number()
          .required('Customer is required')
          .meta({ section: 'customerInformation' })
      : yup.number().nullable();
  }),
  status: yup.string(),
  referenceNumber: yup
    .string()
    .nullable()
    .max(120, 'Reference Number is too long'),
  bidType: yup
    .string()
    .required('A bid type is required')
    .meta({ section: 'customerInformation' }),
  spotId: yup
    .string()
    .nullable()
    .max(50, 'Spot Id must be less than 50 characters'),
  spotQuote: yup
    .mixed()
    .nullable()
    .test('is-number', 'Spot Quote must be a number', (value: unknown) => {
      if (value === null || value === '' || value === undefined) {
        return true;
      }
      return !isNaN(Number(value));
    })
    .test(
      'max-value',
      'Spot Quote can not be more than 99999.99',
      (value: unknown) => {
        if (value === null || value === '' || value === undefined) {
          return true;
        }
        return Number(value) <= 99999.99;
      },
    ),
  ownner: yup.string(),
  driverPhonext: yup
    .string()
    .nullable()
    .matches(phoneRegExp, {
      message: 'Phone number is not valid',
      excludeEmptyString: true,
    })
    .meta({ section: 'carrierDriverDetails' }),
  loadTenderId: yup.number().nullable(),
  currencyType: yup.string(),
  carrierInstructions: yup.string(),
  legalText: yup.string(),
  lineItems: yup
    .array()
    .of(createConditionalSchema(lineItemSchemaFactory('customerInformation'))),
  loads: yup.array().of(createConditionalSchema(loadsSchema)),
  vendors: yup.array().of(createConditionalSchema(vendorSchema)),
  // cargoValue: yup.string(),
  holdRetraction: yup.boolean().nullable(),
  exceptionReason: yup
    .string()
    .when('holdRetraction', ([holdRetraction]) =>
      holdRetraction === true
        ? yup
            .string()
            .max(500)
            .required('Exception Reason is required')
            .meta({ section: 'customerInformation' })
        : yup.string().max(500).nullable(),
    ),
  revisitRetractionDate: yup
    .string()
    .when('holdRetraction', ([holdRetraction]) =>
      holdRetraction === true
        ? yup
            .string()
            .required('Revisit Retraction Hold Date is required')
            .meta({ section: 'customerInformation' })
        : yup.string().nullable(),
    ),
  adjustments: yup.array().of(adjustmentsSchema),
  badAdjustments: yup.string().when(() => {
    return yup
      .string()
      .nullable()
      .test({
        name: 'badAdjustments',
        test: function () {
          const order: IOrderViewModel = this.parent;
          const adjError = order.adjustments.some((adj) => {
            const retract =
              adj.amount > 0 && (adj.retraction === 1 || adj.retraction === 2);
            const refund = adj.amount < 0 && adj.retraction === 3;
            return retract || refund;
          });
          if (adjError) {
            return this.createError({
              message:
                'There is an adjustment with a bad type and amount combination. Please check your adjustments and try again.',
            });
          }
          return true;
        },
      })
      .meta({ section: 'customerInformation' });
  }),
});
