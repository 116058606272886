import { Button, DatePicker } from '@components';
import { SelectField, TextInput } from '@components/forms';
import { NumericInput } from '@components/forms/NumericInput';
import { createForm } from '@felte/solid';
import { validator } from '@felte/validator-yup';
import { closeModal } from '@store/modals';
import { ICustomerPayment, updateLoadPropertyAtIndex } from '@store/orders';
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@suid/material';
import { SelectChangeEvent } from '@suid/material/Select';
import { getDataValue } from '@utils/formUtils';
import { printLog } from '@utils/utils';
import { lineItemsTypesForCarrierPay } from '@views/order/constants';
import { DateTime } from 'luxon';
import * as yup from 'yup';
import { ILoadEditViewModel } from '@typeDefinitions/loadTypes';
import { OperationTypeV4 } from '@typeDefinitions/operationTypes';

import cls from './classes';
import { addEFSFormSchema } from './validation';

type Props = {
  load: ILoadEditViewModel;
  modalId: string;
  tabIndex: number;
};

export const AddMissingEfsCheckModal = (props: Props) => {
  const { form, setFields, data, errors } = createForm<
    yup.InferType<typeof addEFSFormSchema> & ICustomerPayment
  >({
    initialValues: {
      referenceNum: '',
      description: '',
      checkNumber: '',
      amount: null,
      paidDate: DateTime.now().startOf('day').toISO({
        includeOffset: false,
        suppressMilliseconds: true,
      }),
      type: '',
      status: '',
      id: null,
    },
    extend: validator({ schema: addEFSFormSchema }),
    onSubmit: (values) => {
      const payments = props.load.payments ? [...props.load.payments] : [];

      const updateValues = {
        operationType: 'Insert' as OperationTypeV4,
        referenceNum: values.referenceNum,
        paidDate: values.paidDate,
        checkNumber: '',
        type: 'Payment',
        status: '',
        id: 0,
      };

      payments.push({
        ...updateValues,
        description: `EFS Check For (${values.description || 'undefined'})`,
        checkNumber: values.checkNumber || '',
        amount: values.amount,
      });

      payments.push({
        ...updateValues,
        description: `EFS Fee (${values.description || 'undefined'})`,
        amount: typeof values.amount === 'number' ? values.amount * 0.05 : null,
      });

      updateLoadPropertyAtIndex({ payments: payments });

      closeModal(props.modalId);
    },
    onError: (errors) => {
      printLog(errors, 'Form Errors');
    },
  });

  const commonStyle = { margin: '0 8px 0 0', flex: '1' };

  return (
    <form ref={form}>
      <Grid container gap={1}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Ref #</TableCell>
                <TableCell>Money Code</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Line Item</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow class={cls.tableRow}>
                <TableCell>
                  <TextInput
                    id="ref-field"
                    name="referenceNum"
                    label=""
                    error={errors().referenceNum}
                    sxProps={commonStyle}
                  />
                </TableCell>
                <TableCell>
                  {
                    <TextInput
                      id="money-code-field"
                      name="checkNumber"
                      label=""
                      error={errors().checkNumber}
                      sxProps={commonStyle}
                    />
                  }
                </TableCell>
                <TableCell>
                  {
                    <NumericInput
                      name="amount"
                      value={data().amount}
                      label=""
                      error={errors().amount ?? ''}
                      required
                    />
                  }
                </TableCell>
                <TableCell>
                  {
                    <DatePicker
                      value={getDataValue(data, 'paidDate') as string}
                      handleChange={(newDate) => {
                        setFields('paidDate', newDate);
                      }}
                      error={errors().paidDate ?? ''}
                    />
                  }
                </TableCell>
                <TableCell>
                  <SelectField
                    name="type"
                    label=""
                    menuItems={lineItemsTypesForCarrierPay}
                    value={data().description}
                    onChange={(e: SelectChangeEvent) =>
                      setFields('description', e.target.value)
                    }
                    sxProps={{ ...commonStyle, minWidth: '200px' }}
                    error={errors().description}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Box class="flex justify-end mt-14 gap-3">
        <Button label="Save" type="submit" variant="contained" />
        <Button
          label="Cancel"
          variant="text"
          onClick={() => closeModal(props.modalId)}
        />
      </Box>
    </form>
  );
};
