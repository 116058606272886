import { Button } from '@components/Button';
import { Menu } from '@components/Menu';
import { ltlUrls } from '@constants/urls';
import { useNavigate } from '@solidjs/router';
import { SavedQuoteInfo } from '@store/ltl';
import { setLTLQuote } from '@store/ltl/store';
import { openModal } from '@store/modals';
import MoreVertIcon from '@suid/icons-material/MoreVert';
import OpenInNewIcon from '@suid/icons-material/OpenInNew';
import RequestQuoteIcon from '@suid/icons-material/RequestQuote';
import { Box } from '@suid/material';
import { copyQuoteEvent } from '@utils/copyQuoteEvent';
import { MapSavedQuoteToLTLQuote } from '@utils/ltl/MapSavedQuoteToLTLQuote';
import { routeOrderDetails } from '@utils/routeOrderDetails';
import { LtlQuotingModal } from '@views/order/components/ltlQuotingModal';

import { savedLTLQuotesActionsClasses as cls } from './classes';

interface SavedQuoteActionButtonProps {
  savedQuoteInfo: SavedQuoteInfo;
  isHistory?: boolean;
}

export const LTLQuotesActionCellButtonLegacy = (
  data: SavedQuoteActionButtonProps,
) => {
  const navigate = useNavigate();

  if (Boolean(data.savedQuoteInfo) && Boolean(data.savedQuoteInfo.quoteID)) {
    return (
      <Box gap={0.5} displayRaw="flex">
        {Boolean(data.isHistory) && data.savedQuoteInfo.orderId != null && (
          <Button
            size="small"
            startIcon={<OpenInNewIcon />}
            class={cls.orderBtn}
            onClick={() => {
              if (data.savedQuoteInfo.orderId !== null) {
                const isNative = routeOrderDetails(data.savedQuoteInfo.orderId);
                if (isNative) {
                  navigate(`/order/details/${data.savedQuoteInfo.orderId}`);
                }
              }
            }}
            label={String(data.savedQuoteInfo.orderId)}
          />
        )}
        <LtlQuotingModal id={data.savedQuoteInfo.id} />
        <Button
          size="small"
          startIcon={<RequestQuoteIcon />}
          class={cls.buttonBgColor}
          label="Requote"
          onClick={() => {
            setLTLQuote(MapSavedQuoteToLTLQuote(data.savedQuoteInfo));
            openModal(`ltl-quoting-modal-${data.savedQuoteInfo.id}`);
          }}
        />
        {!Boolean(data.isHistory) &&
          Boolean(data.savedQuoteInfo.customerName) && (
            <Menu
              menuItems={['Copy Quote Info', 'Download Quote Info PDF']}
              menuButtonLabel={<MoreVertIcon class={cls.moreVertiIcon} />}
              onMenuItemClick={(item: unknown) => {
                if (item === 'Copy Quote Info') {
                  copyQuoteEvent(data.savedQuoteInfo.groupId);
                }
                if (item === 'Download Quote Info PDF') {
                  window.open(
                    ltlUrls.quoteInfoPDF(data.savedQuoteInfo.groupId),
                    '_blank',
                  );
                }
              }}
              downArrowShowHide={false}
              width={200}
            />
          )}
      </Box>
    );
  }
};
