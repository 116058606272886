import { Card, FileUpload } from '@components';
import { DocumentEntityType } from '@components/FileUpload';
import { useParams } from '@solidjs/router';
import {
  fetchDocuments,
  orderDocumentStore,
  orderStore,
  setDocuments,
} from '@store/orders';
import { onMount } from 'solid-js';

export type CustomerUploadFileProps = {
  documentEntityType: DocumentEntityType;
  entityId: number;
};

export const CustomerFileUpload = (props: CustomerUploadFileProps) => {
  const params = useParams();

  onMount(async () => {
    await fetchDocuments(params.id);
  });

  return (
    <Card
      startTitle="Upload Documents"
      raised={true}
      loading={orderStore.loading}
    >
      <FileUpload
        height={5}
        alreadyUploadedDocuments={orderDocumentStore.documents}
        {...props}
        onChange={(files) => {
          setDocuments(files.map((f) => f.document));
        }}
      />
    </Card>
  );
};
