/* eslint-disable prettier/prettier */

import { LoadModeTypes } from '@typeDefinitions/loadTypes';

export const loadModesTypes: LoadModeTypes[] = [
  'TL',
  'LTL',
  'Partial',
  'Intermodal',
  'Air',
  'Ocean',
];

export const equipmentValues = [
  'B-TR - B-Train/Supertrain',
  'Beam Trailer',
  'CN',
  'CONT',
  'DD',
  'Double Drop Extended',
  'Drop Deck Landoll',
  'Drop Side',
  'DT',
  'Extendable Flatbed',
  'F',
  'F/SD',
  'F48',
  'FR',
  'FVR',
  'FWS',
  'HS',
  'HSF',
  'HSV',
  'LB',
  'Low Profile Step Deck',
  'MAXI',
  'OT',
  'Pneumatic Dry Bulk',
  'PO',
  'R',
  'R2',
  'R53',
  'RA',
  'Reefer or Vented Van',
  'Removable Goose. Ext.',
  'RGN',
  'SD',
  'SD/RGN',
  'Sprinter Van',
  'Stepdeck with Ramps',
  'Stepdeck with Sides',
  'SV',
  'T',
  'TA',
  'TS',
  'V',
  'V10',
  'V15',
  'V20',
  'V26',
  'V2',
  'V3',
  'V35',
  'V53',
  'VC',
  'VF',
  'VI',
  'VL',
  'VR',
  'VV',
  'VV',
  'VW',
  '20\' Container',
  '40\' Container',
  '40\' High Cube Container',
  '45\' Container',
  '45\' EILU Long Container',
  '48\' Container',
  '53\' High Cube Container',
  '53\' Trailer',
];

export const equipmentTypes = [
  { label: 'B-TR - B-Train/Supertrain', value: 'B-TR - B-Train/Supertrain' },
  { label: 'Beam Trailer', value: 'Beam Trailer' },
  { label: 'CN, Conestoga', value: 'CN, Conestoga' },
  { label: 'CONT, Container Trailer', value: 'CONT, Container Trailer' },
  { label: 'DD, Double Drop', value: 'DD, Double Drop' },
  { label: 'Double Drop Extended', value: 'Double Drop Extended' },
  { label: 'Drop Deck Landoll', value: 'Drop Deck Landoll' },
  { label: 'Drop Side', value: 'Drop Side' },
  { label: 'DT/Dump Trailer', value: 'DT/Dump Trailer' },
  { label: 'Extendable Flatbed', value: 'Extendable Flatbed' },
  { label: 'F, Flatbed', value: 'F, Flatbed' },
  { label: 'F/SD, Flatbed/StepDeck', value: 'F/SD, Flatbed/StepDeck' },
  // eslint-disable-next-line prettier/prettier
  { label: 'F48, Flatbed 48\'', value: 'F48, Flatbed 48\'' },
  // eslint-disable-next-line prettier/prettier
  { label: 'FR/53\' Flat or Reefer', value: 'FR/53\' Flat or Reefer' },
  { label: 'FVR, Flat, Van or Refer', value: 'FVR, Flat, Van or Refer' },
  { label: 'FWS, Flat w/Sides', value: 'FWS, Flat w/Sides' },
  { label: 'HS, Hot Shot', value: 'HS, Hot Shot' },
  { label: 'HSF, Hot Shot Flat', value: 'HSF, Hot Shot Flat' },
  { label: 'HSV, Hot Shot Van', value: 'HSV, Hot Shot Van' },
  { label: 'LB, Lowboy', value: 'LB, Lowboy' },
  { label: 'Low Profile Step Deck', value: 'Low Profile Step Deck' },
  {
    label: 'MAXI, Maxi or Double Flat Trailers',
    value: 'MAXI, Maxi or Double Flat Trailers',
  },
  { label: 'OT', value: 'OT' },
  { label: 'Pneumatic Dry Bulk', value: 'Pneumatic Dry Bulk' },
  { label: 'PO, Power Only', value: 'PO, Power Only' },
  { label: 'R, Reefer', value: 'R, Reefer' },
  { label: 'R2, Reefer Double', value: 'R2, Reefer Double' },
  { label: 'R53, 53\' Reefer', value: 'R53, 53\' Reefer' },
  { label: 'RA, Reefer with Air ride', value: 'RA, Reefer with Air ride' },
  { label: 'Reefer or Vented Van', value: 'Reefer or Vented Van' },
  { label: 'Removable Goose. Ext.', value: 'Removable Goose. Ext.' },
  { label: 'RGN, Removable Goose Neck', value: 'RGN, Removable Goose Neck' },
  { label: 'SD, Step Deck', value: 'SD, Step Deck' },
  { label: 'SD/RGN, Step Deck or RGN', value: 'SD/RGN, Step Deck or RGN' },
  { label: 'Sprinter Van', value: 'Sprinter Van' },
  { label: 'Stepdeck with Ramps', value: 'Stepdeck with Ramps' },
  { label: 'Stepdeck with Sides', value: 'Stepdeck with Sides' },
  { label: 'SV, Straight Van', value: 'SV, Straight Van' },
  { label: 'T, Tankers', value: 'T, Tankers' },
  { label: 'TA, Tanker Aluminum', value: 'TA, Tanker Aluminum' },
  { label: 'TS, Tanker Steel', value: 'TS, Tanker Steel' },
  { label: 'V, Van', value: 'V, Van' },
  { label: 'V10, 10\' Van', value: 'V10, 10\' Van' },
  { label: 'V15, 15\' Van', value: 'V15, 15\' Van' },
  { label: 'V20, 20\' Van', value: 'V20, 20\' Van' },
  { label: 'V26, 26\' Van', value: 'V26, 26\' Van' },
  { label: 'V2, Van Double', value: 'V2, Van Double' },
  { label: 'V3, Van Triple', value: 'V3, Van Triple' },
  { label: 'V35, 35\' Van', value: 'V35, 35\' Van' },
  { label: 'V53, 53\' Van', value: 'V53, 53\' Van' },
  { label: 'VC, Van with Curtains', value: 'VC, Van with Curtains' },
  { label: 'VF, Van or Flat', value: 'VF, Van or Flat' },
  { label: 'VI, Van Insulated', value: 'VI, Van Insulated' },
  { label: 'VL, Van Logistics', value: 'VL, Van Logistics' },
  { label: 'VR, Van Or Reefer', value: 'VR, Van Or Reefer' },
  { label: 'VV, Vented Van', value: 'VV, Vented Van' },
  { label: 'VV, Van with Roller bed', value: 'VV, Van with Roller bed' },
  { label: 'VW, Van blanket wrap', value: 'VW, Van blanket wrap' },
];

export const equipmentsArray = [
  { label: 'Beam Trailer', value: '79' },
  { label: 'B-TR - B-Train/Supertrain', value: '65' },
  { label: 'CN, Conestoga', value: '62' },
  { label: 'CONT, Container Trailer', value: '63' },
  { label: 'DD, Double Drop', value: '3' },
  { label: 'Double Drop Extended', value: '83' },
  { label: 'Drop Deck Landoll', value: '92' },
  { label: 'Drop Side', value: '80' },
  { label: 'DT/Dump Trailer', value: '75' },
  { label: 'Extendable Flatbed', value: '78' },
  { label: 'F, Flatbed', value: '1' },
  { label: 'F/SD, Flatbed/StepDeck', value: '70' },
  { label: 'F48, Flatbed 48\'', value: '94' },
  { label: 'FR/53\' Flat or Reefer', value: '74' },
  { label: 'FVR, Flat, Van or Refer', value: '66' },
  { label: 'FWS, Flat w/Sides', value: '4' },
  { label: 'HS, Hot Shot', value: '5' },
  { label: 'HSF, Hot Shot Flat', value: '69' },
  { label: 'HSV, Hot Shot Van', value: '68' },
  { label: 'LB, Lowboy', value: '6' },
  { label: 'Low Profile Step Deck', value: '84' },
  { label: 'MAXI, Maxi or Double Flat Trailers', value: '64' },
  { label: 'OT, Van Open Top', value: '103' },
  { label: 'Pneumatic Dry Bulk', value: '76' },
  { label: 'PO, Power Only', value: '7' },
  { label: 'R, Reefer', value: '8' },
  { label: 'R2, Reefer Double', value: '96' },
  { label: 'R53, 53\' Reefer', value: '9' },
  { label: 'RA, Reefer with Air ride', value: '95' },
  { label: 'Reefer or Vented Van', value: '90' },
  { label: 'Removable Goose. Ext.', value: '91' },
  { label: 'RGN, Removable Goose Neck', value: '10' },
  { label: 'SD, Step Deck', value: '11' },
  { label: 'SD/RGN, Step Deck or RGN', value: '73' },
  { label: 'Sprinter Van', value: '81' },
  { label: 'Stepdeck with Ramps', value: '82' },
  { label: 'Stepdeck with Sides', value: '93' },
  { label: 'SV, Straight Van', value: '14' },
  { label: 'TA, Tanker Aluminum', value: '97' },
  { label: 'Tankers', value: '77' },
  { label: 'TL, Trailer', value: '106' },
  { label: 'TS, Tanker Steel', value: '98' },
  { label: 'V, Van', value: '13' },
  { label: 'V10, 10\' Van', value: '85' },
  { label: 'V15, 15\' Van', value: '86' },
  { label: 'V2, Van Double', value: '100' },
  { label: 'V20, 20\' Van', value: '87' },
  { label: 'V26, 26\' Van', value: '88' },
  { label: 'V3, Van Triple', value: '105' },
  { label: 'V35, 35\' Van', value: '89' },
  { label: 'V53, 53\' Van', value: '18' },
  { label: 'VB, Van with Roller bed', value: '104' },
  { label: 'VC, Van with Curtains', value: '61' },
  { label: 'VF, Van or Flat', value: '72' },
  { label: 'VI, Van Insulated', value: '101' },
  { label: 'VL, Van Logistics', value: '102' },
  { label: 'VR, Van Or Reefer', value: '67' },
  { label: 'VV, Vented Van', value: '17' },
  { label: 'VW, Van blanket wrap', value: '99' },
];
export const intermodalEquipmentTypes = [
  '20\' Container',
  '40\' Container',
  '40\' High Cube Container',
  '45\' Container',
  '45\' EILU Long Container',
  '48\' Container',
  '53\' High Cube Container',
  '53\' Trailer',
];

export const LoadItemTypes = [
  'Boxes',
  'Bags',
  'Bundles',
  'Cases',
  'Crates',
  'Drums',
  'Items',
  'Pallets',
  'Parts',
  'Pieces',
  'Skids',
  'Totes',
  'Units',
];

export const UOMList = ['lbs', 'kg'];

export const hazardousMaterials = [
  {
    label: '1.1 Massive Explosive Hazard',
    value: '1.1 Massive Explosive Hazard',
  },
  { label: '1.2 Projection Hazard', value: '1.2 Projection Hazard' },
  {
    label: '1.3 Fire and/or Minor Blast/Minor Projection Hazard',
    value: '1.3 Fire and/or Minor Blast/Minor Projection Hazard',
  },
  { label: '1.4 Minor Explosion Hazard', value: '1.4 Minor Explosion Hazard' },
  {
    label: '1.5 Very Insensitive with Mass Explosion Hazard',
    value: '1.5 Very Insensitive with Mass Explosion Hazard',
  },
  {
    label: '1.6 Extremely Insensitive; No Mass Explosion Hazard',
    value: '1.6 Extremely Insensitive; No Mass Explosion Hazard',
  },
  { label: '2.1 Flammable Gases', value: '2.1 Flammable Gases' },
  { label: '2.2 Nonflammable Gases', value: '2.2 Nonflammable Gases' },
  { label: '2.3 Toxic Gases', value: '2.3 Toxic Gases' },
  {
    label: '3 Flammable and Combustible Liquids',
    value: '3 Flammable and Combustible Liquids',
  },
  { label: '4.1 Flammable Solids', value: '4.1 Flammable Solids' },
  {
    label: '4.2 Spontaneously Combustible',
    value: '4.2 Spontaneously Combustible',
  },
  { label: '4.3 Dangerous When Wet', value: '4.3 Dangerous When Wet' },
  { label: '5.1 Oxiders', value: '5.1 Oxiders' },
  { label: '5.2 Organic Peroxides', value: '5.2 Organic Peroxides' },
  { label: '6.1 Toxic Substances', value: '6.1 Toxic Substances' },
  { label: '6.2 Infectious Substances', value: '6.2 Infectious Substances' },
  { label: '7 Radioactive Material', value: '7 Radioactive Material' },
  { label: '8 Corrosives', value: '8 Corrosives' },
  { label: '9 Miscellaneous', value: '9 Miscellaneous' },
];

export const packingGroups = [
  { label: 'No Packing Group Assigned', value: 'No Packing Group Assigned' },
  { label: 'I', value: 'I' },
  { label: 'II', value: 'II' },
  { label: 'III', value: 'III' },
];

export const shipmentPackagingTypes = [
  { label: 'A-Frame', value: 'A-Frame' },
  { label: 'Bags', value: 'Bags' },
  { label: 'Bales', value: 'Bales' },
  { label: 'Barrel', value: 'Barrel' },
  { label: 'Basket', value: 'Basket' },
  { label: 'Batteries', value: 'Batteries' },
  { label: 'Bins', value: 'Bins' },
  { label: 'Box', value: 'Box' },
  { label: 'Break Bulk', value: 'Break Bulk' },
  { label: 'Bundle', value: 'Bundle' },
  { label: 'Cans', value: 'Cans' },
  { label: 'Cart', value: 'Cart' },
  { label: 'Carton', value: 'Carton' },
  { label: 'Case', value: 'Case' },
  { label: 'Chest', value: 'Chest' },
  { label: 'Container', value: 'Container' },
  { label: 'Crate', value: 'Crate' },
  { label: 'Cylinder', value: 'Cylinder' },
  { label: 'Drum', value: 'Drum' },
  { label: 'Gallons', value: 'Gallons' },
  { label: 'Gaylords', value: 'Gaylords' },
  { label: 'HalfPallets', value: 'HalfPallets' },
  { label: 'Hopper', value: 'Hopper' },
  { label: 'Jerricans', value: 'Jerricans' },
  { label: 'Lift Van', value: 'Lift Van' },
  { label: 'Loose', value: 'Loose' },
  { label: 'Package', value: 'Package' },
  { label: 'Pails', value: 'Pails' },
  { label: 'Pallet', value: 'Pallet' },
  { label: 'Piece', value: 'Piece' },
  { label: 'Racks', value: 'Racks' },
  { label: 'Reel', value: 'Reel' },
  { label: 'Roll', value: 'Roll' },
  { label: 'Skid', value: 'Skid' },
  { label: 'Stacks', value: 'Stacks' },
  { label: 'Totes', value: 'Totes' },
  { label: 'Truckload', value: 'Truckload' },
  { label: 'Tube', value: 'Tube' },
  { label: 'U-Box', value: 'U-Box' },
  { label: 'Unit', value: 'Unit' },
  { label: 'Vehicle', value: 'Vehicle' },
  { label: 'Wheels', value: 'Wheels' },
];

export const itemsClass = [
  '50',
  '55',
  '60',
  '65',
  '70',
  '77.5',
  '85',
  '92.5',
  '100',
  '110',
  '125',
  '150',
  '175',
  '200',
  '250',
  '300',
  '400',
  '500',
];

export const pricingOptionList = [
  { label: 'Line Haul', value: 'Line Haul' },
  {
    label: 'Rate per Mile',
    value: 'Rate per Mile',
  },
  {
    label: 'Rate per Pound',
    value: 'Rate per Pound',
  },
  {
    label: 'Rate per Unit',
    value: 'Rate per Unit',
  },
];

export const PricingOptionsArray = [
  'Line Haul',
  'Rate per Mile',
  'Rate per Pound',
  'Rate per Unit',
];

export const AccessorialsOptionsArray = [
  'Accessorial',
  'Damage',
  'Dead Head',
  'Detention',
  'Detention Loading',
  'Detention Unloading',
  'Driver Assist',
  'Fuel',
  'Fumigation',
  'Layover',
  'Lumper',
  'Lumper Loading',
  'Lumper Unloading',
  'Out of Route Miles',
  'Positioning',
  'Protective Services',
  'Re Delivery',
  'Storage',
  'Team Service',
  'TONU',
];

export const FeesOptionsArray = [
  'Bonus',
  'Check Call',
  'Drop Trailer',
  'Fee',
  'Fine',
  'GPS Tracking',
  'Interstate/Highway Toll',
  'Late Fee',
  'New York Delivery Charge',
  'On-time Delivery',
  'On-time Pickup',
  'Over Weight',
  'Pallet Charge',
  'Premium Charge',
  'Special Handling Service',
  'Tarp Free',
  'Unloading',
];

export const InterModalOptionsArray = [
  'Block And Brace',
  'Chassis',
  'Diversion',
  'Driver Assist',
  'Driver Count',
  'Driver Sweep',
  'Driver Wash Out',
  'Driver Dunnage Disposal',
  'Driver Layover',
  'Dry Run (TONU) Equipment Not Used',
  'Hazmat',
  'High Value Seal Charge',
  'Lumper Loading',
  'Lumper Unloading',
  'Miscellaneous',
  'Night Delivery',
  'Pier Pass',
  'Power Detention Line Unload',
  'Power Detention Drop and Hook',
  'Pre-Pull',
  'Reconsignment',
  'Scale Light and Heavy',
  'Scale Single Charge',
  'Stop Off',
  'Tri-Axle',
  'Yard Axle',
  'Yard Storage',
];

export const LTLOptionsArray = [
  'Notification Delivery',
  'Inside Pickup',
  'Inside Delivery',
  'Liftgate Pickup',
  'Liftgate Delivery',
  'Limited Access Pickup',
  'Limited Access Delivery',
  'Over Length 8 < 12',
  'Over Length 12 < 16',
  'Over Length 16 < 20',
  'Over Length 20 < 28',
  'Over Length > 28',
  'Residential Pickup',
  'Residential Delivery',
  'Airport Pickup',
  'Airport Delivery',
  'Appointment Pickup',
  'Appointment Delivery',
  'CFS Pickup',
  'CFS Delivery',
  'Freeze Point Pickup',
  'Freeze Point Delivery',
  'Trade Show Pickup',
  'Trade Show Delivery',
  'White Glove Service',
  'Ground Delivery Service',
  'Guarenteed Delivery Day',
  'Redelivery',
  'Sort & Segregate',
  'Additional Freight',
  'Agent Authorized SED',
  'Bill of Lading',
  'Blind Shipment',
  'CarrierCharge',
  'Clean Air Surcharge',
  'COI Required',
  'Correction of Terms',
  'Cubic Capacity',
  'Dimensional Weight',
  'Discount',
  'Gate Fee',
  'Handling Fee',
  'High Cost Area Fee',
  'Inspection Free',
  'Labelling',
  'Labor',
  'LOA',
  'Re-Class',
  'Reweigh Fee',
  'Saturday Pickup',
  'Seal',
  'Tarp',
  'Warehouse Fee',
  'Sort & Segregate Delivery',
  'Sort & Segregate Pickup',
];

export const MiscOptionsArray = [
  'Adjustment',
  'GST/HST',
  'Misc',
  'Short Pay',
  'Write Off',
  'Paid Wrong Carrier',
  'New York Delivery Charge',
  'Premium Charge',
  'Special Handling Service',
  'Interstate/Highway Toll',
];

export const categoryOptions = [
  { category: 'Pricing', options: PricingOptionsArray },
  { category: 'Accessorials', options: AccessorialsOptionsArray },
  { category: 'Fees', options: FeesOptionsArray },
  { category: 'Intermodal', options: InterModalOptionsArray },
  { category: 'LTL', options: LTLOptionsArray },
  { category: 'Misc', options: MiscOptionsArray },
];

export const currencyTypes = ['USD', 'CAD'];

export const countryTypes = [
  {
    value: 'USA',
    label: 'USA',
  },
  {
    value: 'CAN',
    label: 'CAN',
  },
  {
    value: 'MEX',
    label: 'MEX',
  },
];

export const statusOptions = [
  { label: 'Open', value: 'Open' },
  { label: 'Invoiced', value: 'Invoiced' },
  { label: 'Paid', value: 'Paid' },
  { label: 'Disputed', value: 'Disputed' },
  { label: '120+ Reviewed', value: '120+ Reviewed' },
  { label: 'Void', value: 'Void' },
  { label: 'In Collections', value: 'In Collections' },
];

export const reasonsToHoldPayment = [
  { label: 'Agent', value: 'agent' },
  { label: 'Claims', value: 'claims' },
  { label: 'Damages', value: 'damages' },
  { label: 'Missing LOR/NOA', value: 'missingLOR/NOA' },
  { label: 'Missing PPWK', value: 'missingPPWK' },
  { label: 'Rate Discrepancy', value: 'discrepancy' },
  { label: 'Other', value: 'other' },
];

export const loadSearchEvent = [
  { label: 'Dropped Off', value: 'Dropped Off' },
  { label: 'Picked Up', value: 'Picked Up' },
  { label: 'Created On', value: 'Created On' },
];

export const timeSpanEvent = [
  { label: 'Today', value: 'Today' },
  { label: 'This Month', value: 'This Month' },
  { label: 'Last Month', value: 'Last Month' },
  { label: 'Year to date', value: 'Year to date' },
  { label: 'Last year', value: 'Last year' },
];

export const announcementsMessages = [
  { label: 'Fix', value: 'Fix' },
  { label: 'New Feature', value: 'New Feature' },
  { label: 'Warning', value: 'Warning' },
  { label: 'Announcement', value: 'Announcement' },
  { label: 'Reminder', value: 'Reminder' },
  { label: 'Important', value: 'Important' },
];

export const revenueSearchEvent = [
  { label: 'Dropped', value: 'Dropoff' },
  { label: 'Picked Up', value: 'Pickup' },
  { label: 'Created', value: 'CreatedDate' },
  { label: 'Invoiced', value: 'InvoicedDate' },
  { label: 'Paid', value: 'PaidDate' },
];

export const statesMenuItems = [
  { label: 'Alaska', value: 'AK' },
  { label: 'Alabama', value: 'AL' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Maine', value: 'ME' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Montana', value: 'MT' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New York', value: 'NY' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Washington', value: 'WA' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wyoming', value: 'WY' },
];
