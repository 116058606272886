export default {
  headerContainer:
    '!shadow-lg h-[100%] !h-fit sm:!h-fit  px-4 py-3 z-10 md:!h-fit lg:h-24',
  orderSubText: 'divide-x-[1px] divide-black',
  headerBtn: '!bg-[#E0E0E0] !text-black',
  customerNameText: 'flex items-center gap-2',
  customerActionBtns: 'flex items-center gap-3 justify-end',
  blacklistCommentTitle: '!text-[#1B4960] !text-base !font-medium',
  carrierTabGridContainer: 'min-h-[400px] h-[calc(100vh-160px)]',
  carrierTabGrid: 'ag-theme-alpine !flex-1',
  headerDeleteBtn: '!bg-[#B00020] !text-white',
};

export const factoringCompanyDetailsContactCardClasses = {
  rowFormGrid: 'w-full',
  newContactStack: 'flex-1 pr-2',
  existingContactStack: 'flex-1 pl-4',
  addressCardStack: 'h-96 overflow-y-scroll',
  buttonFooterGrid: 'mt-5 p-1',
  addressCard: 'border-b border-solid border-lightgray',
  dividerGrid: 'mt-2 mb-2',
  titleTypography:
    'text-black !text-xl !font-medium !leading-8 !tracking-[0.15px]',
  addressCardRow: 'hover:bg-[#D2E2ED] cursor-pointer',
  deleteIcon: '!text-[#B00020]',
};
