import { BasicTable, Button } from '@components';
import { CheckboxInput, TextInput } from '@components/forms';
import { getFalveyPricingQuoteData } from '@store/ltl/services';
import { ltlQuoteState } from '@store/ltl/store';
import { FalveyInsuranceQuoteInLoad, LTLLoadItem } from '@store/ltl/types';
import { LtlQuoteLoadItemViewModel } from '@store/orders';
import { Cached, Check } from '@suid/icons-material';
import { Box, Stack } from '@suid/material';
import { ConfigManager } from '@utils/ConfigManager';
import { formatAmount } from '@utils/utils';
import { isEmpty } from 'lodash';
import { Accessor, createSignal, onMount } from 'solid-js';
import * as yup from 'yup';

interface InsuranceUpdateProps {
  items: LTLLoadItem[] | LtlQuoteLoadItemViewModel[];
  onConfirmation: (
    val?: FalveyInsuranceQuoteInLoad,
    type?: string,
    declaredValue?: number,
  ) => void;
  onCancel: () => void;
  declaredValue?: number;
  updateDeclaredValue: (val: number) => void;
  customerCost?: number;
  carrierCost?: number;
  falveyInsuranceQuote?: FalveyInsuranceQuoteInLoad | undefined;
  ltlQuoteId?: string;
  quoteParametersId?: number;
  isStoreQuoteLoading?: Accessor<boolean>;
}

const columns = [
  { name: 'Item Description', key: 'description' },
  { name: '# of Units', key: 'quantity' },
  { name: 'Weight', key: 'weight' },
  { name: 'Class', key: 'class' },
];

export const InsuranceUpdateAndConfirmationForm = (
  props: InsuranceUpdateProps,
) => {
  const [isConfirmed, setIsConfirmed] = createSignal(false);
  const [declaredVal, setDeclaredVal] = createSignal(props.declaredValue);
  const [currentDeclaredVal, setCurrentDeclaredVal] = createSignal(
    props.declaredValue,
  );
  const [falveyInsurance, setFalveyInsurance] =
    createSignal<FalveyInsuranceQuoteInLoad>();
  const [isLoadingBtn, setIsLoadingBtn] = createSignal<string>('');
  const [updateBtnClicked, setUpdateBtnClicked] = createSignal(false);
  const [declaredValError, setDeclaredValError] = createSignal<
    Record<string, string[]>
  >({});
  const updateBtnDisabled = () => {
    return (
      !isEmpty(declaredValError()) ||
      isLoadingBtn() !== '' ||
      !(currentDeclaredVal() !== declaredVal())
    );
  };
  const confirmedBtnDisabled = () => {
    return (
      (!isEmpty(declaredValError()) && !isConfirmed()) ||
      (isEmpty(falveyInsurance()) && !isConfirmed()) ||
      (props.isStoreQuoteLoading &&
        props.isStoreQuoteLoading() &&
        !isConfirmed()) ||
      (updateBtnDisabled() && !isConfirmed()) ||
      !updateBtnClicked()
    );
  };
  const fetchFalveyPricingQuote = async () => {
    setIsLoadingBtn('update');
    try {
      const resp = (await getFalveyPricingQuoteData({
        shipmentTypeID: '139015',
        declaredValue: declaredVal() as number,
        currency: 'USD',
        shippingMerchandiseDescription: '',
        ltlQuoteId: !isEmpty(props.ltlQuoteId) ? props.ltlQuoteId : undefined,
        quoteParametersId: props.quoteParametersId,
      })) as { insurancePremium: number; serviceFee: number };
      setFalveyInsurance({
        quotePremium: resp.insurancePremium,
        quoteServiceFee: resp.serviceFee,
        shippingSumInsured: declaredVal() as number,
      });
    } finally {
      setIsLoadingBtn('');
    }
  };

  const handleUpdateButtonClick = async () => {
    await fetchFalveyPricingQuote();
    setUpdateBtnClicked(true);
    setCurrentDeclaredVal(declaredVal());
  };

  const getFalveyInsuraceRates = (type: 'insurance' | 'both') => {
    if (Boolean(falveyInsurance())) {
      const { quotePremium, quoteServiceFee } =
        falveyInsurance() as FalveyInsuranceQuoteInLoad;
      switch (type) {
        case 'insurance':
          return quotePremium;
        case 'both':
          return quotePremium + quoteServiceFee;
        default:
          return 0;
      }
    }
    return 0;
  };

  onMount(() => {
    if (props.falveyInsuranceQuote) {
      setFalveyInsurance(props.falveyInsuranceQuote);
    }
    if (Boolean(props.declaredValue)) {
      setUpdateBtnClicked(true);
    }
  });

  const Schema = yup.object().shape({
    declaredValue: yup
      .number()
      .max(999999, 'Must be less than 999999')
      .min(1, 'Must be greater than 0')
      .required('Declared value is required'),
  });

  const validateDeclaredValue = (vl: number) => {
    try {
      Schema.validateSync({ declaredValue: vl }, { abortEarly: false });
      setDeclaredValError({});
      return true;
    } catch (validationError: unknown) {
      const errors: Record<string, string[]> = {};
      if (validationError instanceof yup.ValidationError) {
        validationError.inner.forEach((error: yup.ValidationError) => {
          if (error.path !== undefined) {
            errors[error.path] = error.errors;
          }
        });
        setDeclaredValError(errors);

        return false;
      }
    }
  };

  return (
    <Box>
      <Box class="flex mb-4 items-center">
        <span class="text-xl font-semibold">Confirm Insurance Coverage</span>
        <span class="text-sm text-[#666666] ml-auto">
          Add a declared value to see your updated rates.
        </span>
      </Box>
      <Box class="flex gap-4 mb-4">
        <TextInput
          label="Declared Value"
          placeholder="Input Cargo Value"
          value={Boolean(declaredVal()) ? String(declaredVal()) : ''}
          type="number"
          onChange={(val) => {
            setUpdateBtnClicked(false);
            setDeclaredVal(Number(val));
            validateDeclaredValue(declaredVal() as number);
          }}
          classes="!w-[50%]"
          size="small"
          error={
            !isEmpty(declaredValError())
              ? declaredValError()['declaredValue']
              : ''
          }
        />
        <Button
          onClick={handleUpdateButtonClick}
          label="Update"
          startIcon={isLoadingBtn() === 'update' ? undefined : <Cached />}
          sx={{
            background: 'white',
            color: '#026EA1',
            border: '1px solid #026EA1',
            '&:hover': { background: '#026EA1', color: 'white' },
          }}
          isLoading={isLoadingBtn() === 'update'}
          disabled={updateBtnDisabled()}
        />
      </Box>
      <Box class="font-semibold">Rates with Insurance</Box>
      <Box class="flex my-4">
        <Box class="flex">
          <Stack>
            <span class="font-semibold">Agent</span>
            <span class="text-[#666666] text-sm ml-4">Carrier Rate</span>
            <span class="text-[#666666] text-sm ml-4">Insurance Rate</span>
          </Stack>
          <Stack class="!ml-auto">
            <span class="text-[#26856f]">
              {formatAmount(
                (props.carrierCost ?? 0) + getFalveyInsuraceRates('insurance'),
              )}
            </span>
            <span class="text-sm ml-auto text-[#666666]">
              {formatAmount(props.carrierCost ?? 0)}
            </span>
            <span class="text-sm ml-auto text-[#666666]">
              {getFalveyInsuraceRates('insurance') !== 0
                ? formatAmount(getFalveyInsuraceRates('insurance'))
                : '--'}
            </span>
          </Stack>
        </Box>
        <Box class="flex ml-auto">
          <Stack>
            <span class="font-semibold">Customer</span>
            <span class="text-[#666666] text-sm ml-4">Insurance & Fees</span>
          </Stack>
          <Stack class="!ml-auto">
            <span class="text-[#26856f]">
              {formatAmount(
                (props.customerCost ?? 0) + getFalveyInsuraceRates('both'),
              )}
            </span>
            <span class="text-sm ml-auto text-[#666666]">
              {getFalveyInsuraceRates('both') !== 0
                ? formatAmount(getFalveyInsuraceRates('both'))
                : '--'}
            </span>
          </Stack>
        </Box>
      </Box>
      <Box class="flex items-center">
        <CheckboxInput
          checked={isConfirmed()}
          label={''}
          onChange={(val) => setIsConfirmed(val)}
          class={'termsAndConditionsCheckbox'}
        />
        <Box class="flex ml-[8px]">
          I agree to the
          <Box class="ml-1">
            <a
              target={'_blank'}
              class="text-[#026EA1] underline"
              href={`${ConfigManager.domainUrl}api/document/serveDocument/?url=Global%20Docs/0/072224_FalveyPolicy.pdf`}
            >
              Terms and Conditions.
            </a>
            <span class="text-[#af0020]">*</span>
          </Box>
        </Box>
      </Box>
      <BasicTable<LTLLoadItem> columns={columns} rows={props.items} />
      <Stack spacing={1} class="mt-6 font-semibold">
        <Box class="text-lg">Deductibles (Based on Declared Value)</Box>
        <Box>$0 USD to $10,000 USD....................................$0</Box>
        <Box>$10,000.01 USD to $25,000 USD......................$500</Box>
        <Box>$25,000.01 USD to $100,000 USD....................$1,000</Box>
        <Box>
          Greater than $100,000.01.................................2% of
          Declared Value
        </Box>
      </Stack>
      <Box class="flex justify-end mt-4 gap-2">
        <Button
          label="Confirm"
          onClick={async () => {
            setIsLoadingBtn('confirm');
            await props.onConfirmation(
              falveyInsurance(),
              Boolean(props.declaredValue) ? 'update' : 'insert',
              declaredVal() as number,
            );
            props.updateDeclaredValue(declaredVal() as number);
            setIsLoadingBtn('');
          }}
          disabled={confirmedBtnDisabled()}
          isLoading={
            (props.isStoreQuoteLoading && props.isStoreQuoteLoading()) ||
            ltlQuoteState.isLoading
          }
          startIcon={<Check />}
          sx={{
            background: '#278670',
          }}
        />
        <Button
          variant="outlined"
          label="Cancel"
          onClick={props.onCancel}
          disabled={ltlQuoteState.isLoading || isLoadingBtn() !== ''}
        />
      </Box>
    </Box>
  );
};
