/* eslint-disable complexity */
import { flagImgs, flagOptionList } from '@components/FlagPopup/constants';
import { Close, Info, Warning, WarningAmber } from '@suid/icons-material';
import { Chip, Grid, Stack, Typography } from '@suid/material';
import { FlagType } from '@typeDefinitions/flagTypes';
import { isEmpty } from 'lodash';
import { JSX, Show, createSignal, mergeProps } from 'solid-js';

import cls, { colors, notificationStyles } from './classes';

type typeOptions =
  | 'warning'
  | 'info'
  | 'error'
  | 'Red Flag'
  | 'Green Flag'
  | 'Blue Flag'
  | 'Yellow Flag'
  | 'Orange Flag'
  | 'Needs Approval'
  | 'Get Paperwork'
  | 'Refund Request'
  | 'Efs Issued'
  | 'Missing Paperwork'
  | 'Hot Load'
  | 'Billing Hold'
  | 'Billing Note'
  | 'tableError'
  | 'Tender Updated'
  | '';

interface NotificationProps {
  startIcon?: string;
  type: typeOptions | FlagType;
  text?: JSX.Element | string;
  subText?: JSX.Element | string;
  endIcon?: JSX.Element;
  playIcon?: JSX.Element;
  accordion?: boolean | undefined;
  expanded?: boolean;
  cleared?: boolean;
  disputed?: boolean;
  onToggle?: () => void;
  onClick?: () => void;
  tableRowNotification?: boolean;
  sxProps?: string;
  imgIcon?: string;
  getCustomStyle?: () => string | undefined;
  needsApprovalFlag?: boolean;
  commentLength?: JSX.Element | string;
  onClose?: () => void;
  isNotFlagTitle?: boolean;
  typeIconStyleContainerClasses?: string;
}

const defaultProps = {
  accordion: false,
  expanded: false,
  tableRowNotification: false,
};

const iconMap = (type: string): JSX.Element => {
  switch (type) {
    case 'warning':
      return <Warning style={{ 'font-size': '24px', color: colors.warning }} />;
    case 'error':
    case 'tableError':
      return (
        <WarningAmber style={{ 'font-size': '24px', color: colors.error }} />
      );
    case 'info':
      return <Info style={{ 'font-size': '24px', color: colors.info }} />;
    case flagOptionList[type]:
      return <img src={flagImgs[type]} class="p-[12px]" alt="alt flag" />;
    default:
      return <Info style={{ 'font-size': '24px', color: colors.info }} />;
  }
};

export const conditionalCursor = (condition: boolean) => ({
  cursor: condition ? 'pointer' : 'default',
});

const Notification = (props: NotificationProps): JSX.Element => {
  const mp = mergeProps(defaultProps, props);
  const [isExpanded, setIsExpanded] = createSignal(
    mp.accordion ? mp.expanded : false,
  );
  const handleToggle = () => {
    setIsExpanded(!isExpanded());
    mp.onToggle && mp.onToggle();
  };

  return (
    <Grid
      container
      flexDirection="row"
      gap={3}
      alignItems="center"
      class={`${cls.container} ${mp.sxProps} ${
        mp.tableRowNotification && '!my-0'
      } ${
        mp.getCustomStyle
          ? mp.getCustomStyle()
          : !isEmpty(flagOptionList[mp.type])
            ? cls[flagOptionList[mp.type]]
            : cls[mp.type]
      }`}
      sx={{
        boxShadow: mp.tableRowNotification
          ? ''
          : '0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.20);',
        '&:First-child': {
          marginBottom: '0px',
        },
        opacity: Boolean(props.cleared) ? 0.6 : 1,
      }}
      onClick={mp.onClick}
    >
      <Grid class={`${mp.typeIconStyleContainerClasses}`}>
        {Boolean(mp.imgIcon) ? (
          <img src={mp.imgIcon} class="size-[44px]" alt="alt flag" />
        ) : (
          iconMap(mp.type)
        )}
      </Grid>
      <Grid class="flex-1 relative">
        <Grid container direction="column" item xs={12}>
          <Grid item style={cls.content} width={'100%'}>
            <Grid
              item
              xs={12}
              container
              direction="row"
              flexWrap="nowrap"
              alignContent="flex-end"
            >
              <Grid
                item
                xs={
                  props.onClose && Boolean(mp.commentLength)
                    ? 10
                    : props.onClose
                      ? 11
                      : 12
                }
                alignContent="center"
              >
                <Grid item xs={12}>
                  <Stack direction="row">
                    <div
                      class={
                        props.isNotFlagTitle ?? false ? '' : cls.flagTitleText
                      }
                    >
                      {mp.text}
                    </div>
                    {props.disputed ?? false ? (
                      <Chip
                        label="Disputed"
                        size="small"
                        color="warning"
                        sx={{ marginLeft: 1 }}
                      />
                    ) : undefined}
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <div class="break-all">
                    {mp.accordion
                      ? typeof mp.subText === 'string' &&
                        mp.subText.length > 360
                        ? isExpanded()
                          ? mp.subText
                          : mp.subText.substring(0, 360) + '...'
                        : mp.subText
                      : mp.subText}
                  </div>
                </Grid>
              </Grid>
              {mp.commentLength !== undefined && (
                <Grid item xs={1} class={cls.gridFlagComments}>
                  <div>{mp.commentLength}</div>
                </Grid>
              )}
              {props.onClose && (
                <Grid
                  item
                  xs={1}
                  displayRaw="flex"
                  alignItems="center"
                  justifyContent="end"
                >
                  <Close
                    onClick={props.onClose}
                    class="text-gray-400 cursor-pointer hover:text-gray-500"
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Show
            when={
              mp.accordion &&
              typeof mp.subText === 'string' &&
              mp.subText.length > 360
            }
          >
            <Grid item class="!pt-2">
              <Typography
                variant="body2"
                component="span"
                sx={{
                  ...notificationStyles.alignRight,
                  ...conditionalCursor(mp.subText !== ''),
                }}
                onClick={mp.accordion ? handleToggle : undefined}
              >
                {isExpanded() ? mp.endIcon : mp.playIcon}
              </Typography>
            </Grid>
          </Show>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Notification;
