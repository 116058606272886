import { Popover } from '@components';
import { Box, Grid, Typography } from '@suid/material';
import { domainUrl } from '@constants/urls';
import {
  LtlQuoteViewModel,
  LtlTerminalInfoViewModel,
} from '@store/orders/types';
import { formatAmount } from '@utils/utils';
import { InfoOutlined } from '@suid/icons-material';

import ViewTerminalPopover from '../ViewTerminalPopover';
import { quoteDetailsRowRenderClasses as cls } from './classes';

export const QuoteCarrierDetails = (props: { quote: LtlQuoteViewModel }) => {
  return (
    <Box
      class={cls.fullHeightColumn}
      sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      p={2}
    >
      <Grid container spacing={0.5} alignItems="stretch">
        <Grid item xs={12} md={4}>
          <Typography
            variant="body1"
            class="!font-medium"
            data-testid="quoteCarrierDetails-carrierName-quote-{props.quote.id}"
          >
            {props.quote.carrierName}
          </Typography>

          <Typography variant="body1" class={cls.labelText} mt={1}>
            Carrier Quote ID
          </Typography>
          <Typography variant="body1" class={cls.infoText}>
            {props.quote.carrierQuoteId}
          </Typography>

          <Box class={cls.logoBox} mt={3}>
            {' '}
            {/* Add spacing above the logo */}
            <img
              src={`${domainUrl}${props.quote.logoImagePath}`}
              alt="carrier logo"
              class={cls.carrierLogo}
            />
          </Box>
        </Grid>

        {/* Second Column (Transit Days, Delivery Date, Service Type) */}
        <Grid item xs={12} md={4} mt={3}>
          {' '}
          {/* Moves content down */}
          <Typography variant="body1" class={cls.labelText}>
            Est. Transit Days
          </Typography>
          <Typography variant="body1" class={cls.infoText}>
            {props.quote.transitDays}
          </Typography>
          <Typography variant="body1" class={cls.labelText} mt={2}>
            Est. Delivery Date
          </Typography>
          <Typography variant="body1" class={cls.infoText}>
            {props.quote.estimatedDeliveryDate != null
              ? new Date(props.quote.estimatedDeliveryDate).toLocaleDateString()
              : '--'}
          </Typography>
          <Typography variant="body1" class={cls.labelText} mt={2}>
            Service Type
          </Typography>
          <Typography variant="body1" class={cls.infoText}>
            {props.quote.serviceTypeDisplayName}
          </Typography>
        </Grid>

        <Grid item xs={12} md={4} mt={3}>
          {' '}
          <Box mb={2}>
            <Popover
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              eleName={
                <button
                  class="flex items-center text-[#307eac]"
                  data-testid="quoteCarrierDetails-terminalInfo-quote-{props.quote.id}"
                >
                  <InfoOutlined class="mr-1" />
                  Terminal Info
                </button>
              }
            >
              <ViewTerminalPopover
                terminalInfo={
                  props.quote.terminalInfo as LtlTerminalInfoViewModel
                }
                quoteId={String(props.quote.id)}
              />
            </Popover>
          </Box>
          <Typography variant="body1" class={cls.labelText} mt={2}>
            Movement Type
          </Typography>
          <Typography variant="body1" class={cls.infoText}>
            {props.quote.movementType ?? '--'}
          </Typography>
          <Typography variant="body1" class={cls.labelText} mt={2}>
            Max Carrier Liability
          </Typography>
          <Typography variant="body1" class={cls.liabilityText}>
            <Typography
              variant="body1"
              component="span"
              class={cls.infoTextBold}
            >
              New:
            </Typography>
            <Typography variant="body1" component="span" class={cls.infoText}>
              {formatAmount(props.quote.maxNewLiability)}
            </Typography>
            <Typography
              variant="body1"
              component="span"
              class={cls.infoTextBold}
            >
              Used:
            </Typography>
            <Typography variant="body1" component="span" class={cls.infoText}>
              {formatAmount(props.quote.maxUsedLiability)}
            </Typography>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
