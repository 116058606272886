import { Card, ToastType } from '@components';
import AddressAutoComplete, {
  AddressDetails,
} from '@components/Autocomplete/AddressAutoComplete';
import CommonFileUpload, {
  CommonFileUploadFiles,
} from '@components/FileUpload/CommonFileUpload';
import { CheckboxInput, SelectField, TextInput } from '@components/forms';
import { useParams } from '@solidjs/router';
import {
  officeDetailStore,
  setOfficeDetailStore,
} from '@store/office/officeDetails';
import { updateOfficeDetailsState } from '@store/office/officeDetails/actions';
import {
  deleteOfficeDetailsDocument,
  uploadOfficeDetailDocument,
} from '@store/office/officeDetails/services';
import { Stack } from '@suid/material';
import { handleToast, isFullAdmin } from '@utils/utils';
import { keys, last } from 'lodash';
import { produce } from 'solid-js/store';

import { AccountingGroups, OfficeType } from '../constants';

export const OfficeDetails = () => {
  const params = useParams();

  const handleFileUpload = async (file: File) => {
    try {
      setOfficeDetailStore('officeDocuments', [
        {
          dateAdded: new Date().toISOString(),
          id: officeDetailStore.officeDocuments.length + 1,
          name: file.name,
          url: '',
        },
        ...officeDetailStore.officeDocuments,
      ]);
      const res = await uploadOfficeDetailDocument(Number(params.id), file);
      if (res.url) {
        const name =
          typeof res.url == 'string'
            ? last(res.url.split('/'))
            : keys(res.url)[0];
        setOfficeDetailStore(
          produce((state) => {
            const newFiles = [...state.officeDocuments];
            const objectIndex = newFiles.findIndex((f) => f.name === file.name);
            if (objectIndex !== -1) {
              newFiles[objectIndex].url = res.url;
              newFiles[objectIndex].name = name!;
            }
            state.officeDocuments = newFiles;
          }),
        );
      }
    } catch (error) {
      handleToast(ToastType.Error, 'Uploading office documents failed');
      setOfficeDetailStore(
        produce((state) => {
          state.officeDocuments = state.officeDocuments.filter(
            (f) => f.name !== file.name,
          );
        }),
      );
    }
  };

  const handleFileRemove = async (
    index: number,
    item: CommonFileUploadFiles,
  ) => {
    try {
      const payload = new FormData();
      payload.append('file', item.url!);
      payload.append('type', 'Office');
      await deleteOfficeDetailsDocument(payload);
      setOfficeDetailStore(
        produce((state) => {
          const newFiles = [...state.officeDocuments];
          newFiles.splice(index, 1);
          state.officeDocuments = newFiles;
        }),
      );
      handleToast(ToastType.Success, 'Office document deleted');
    } catch (error: unknown) {}
  };

  const customValidation = (file: File) => {
    if (
      officeDetailStore.officeDocuments.some(
        (f) => f.name.split('_')[1] === file.name,
      )
    ) {
      handleToast(ToastType.Error, 'File already exists');
      return false;
    }
    return true;
  };

  const getRecruiterOptions = () =>
    officeDetailStore.Recruiters.map((item) => ({
      label: item.name,
      value: String(item.id),
    }));

  const getOfficeOwnerOptions = () => {
    return (
      officeDetailStore.officeDetail?.users.map((item) => {
        return { label: item.name, value: item.id };
      }) || []
    );
  };

  const itemOnSelect = (place: AddressDetails) => {
    const { address1 = '', city = '', state = '', zipCode = '' } = place;
    setOfficeDetailStore(
      produce((detail) => {
        if (detail.officeDetail) {
          detail.officeDetail.address1 = address1;
          detail.officeDetail.city = city;
          detail.officeDetail.state = state;
          detail.officeDetail.zip = zipCode;
        }
      }),
    );
  };

  return (
    <Card startTitle="Office Details" loading={officeDetailStore.isLoading}>
      <Stack spacing={2}>
        <TextInput
          label="Name"
          value={officeDetailStore.officeDetail?.name ?? ''}
          onChange={(e: string) =>
            updateOfficeDetailsState({
              name: e,
            })
          }
          error={officeDetailStore.officeDetailErrors?.name}
        />

        <CheckboxInput
          label="Is Active"
          checked={officeDetailStore.officeDetail?.isActive ?? false}
          onChange={(e) =>
            updateOfficeDetailsState({
              isActive: e as boolean,
            })
          }
        />

        <TextInput
          label="Description"
          value={officeDetailStore.officeDetail?.description ?? ''}
          onChange={(e: string) =>
            updateOfficeDetailsState({
              description: e,
            })
          }
          error={officeDetailStore.officeDetailErrors?.description}
        />

        <SelectField
          label="Accounting Group:"
          menuItems={AccountingGroups}
          value={officeDetailStore.officeDetail?.accountingGroupId}
          onChange={(e) =>
            updateOfficeDetailsState({
              accountingGroupId: e.target.value,
            })
          }
          error={officeDetailStore.officeDetailErrors?.accountingGroupId}
        />

        <SelectField
          label="Office Owner:"
          menuItems={getOfficeOwnerOptions()}
          value={officeDetailStore.officeDetail?.officeManagerId}
          onChange={(e) =>
            updateOfficeDetailsState({
              officeManagerId: e.target.value,
            })
          }
        />

        <SelectField
          label="Recruited By:"
          menuItems={getRecruiterOptions()}
          value={officeDetailStore.officeDetail?.recruitedByUserId}
          onChange={(e) =>
            updateOfficeDetailsState({
              recruitedByUserId: e.target.value,
            })
          }
        />

        <SelectField
          label="Office Type"
          menuItems={OfficeType}
          value={officeDetailStore.officeDetail?.officeType}
          onChange={(e) =>
            updateOfficeDetailsState({
              officeType: e.target.value,
            })
          }
        />

        <TextInput
          label="Commission (must be decimal):"
          value={officeDetailStore.officeDetail?.commissionPercentage ?? ''}
          onChange={(e: unknown) =>
            updateOfficeDetailsState({
              commissionPercentage: e as number,
            })
          }
          error={officeDetailStore.officeDetailErrors?.commissionPercentage}
        />

        <AddressAutoComplete
          label="Address Line 1"
          placeholder="Enter Address"
          value={officeDetailStore.officeDetail?.address1}
          onChange={(e: string) =>
            updateOfficeDetailsState({
              address1: e,
            })
          }
          onItemSelect={(item) => itemOnSelect(item)}
          error={officeDetailStore.officeDetailErrors?.address1}
        />

        <TextInput
          label="Address Line 2"
          value={officeDetailStore.officeDetail?.address2 ?? ''}
          onChange={(e: string) =>
            updateOfficeDetailsState({
              address2: e,
            })
          }
        />

        <Stack direction="row" spacing={2}>
          <TextInput
            label="City"
            value={officeDetailStore.officeDetail?.city ?? ''}
            onChange={(e: string) =>
              updateOfficeDetailsState({
                city: e,
              })
            }
            error={officeDetailStore.officeDetailErrors?.city}
          />
          <TextInput
            label="State"
            value={officeDetailStore.officeDetail?.state ?? ''}
            onChange={(e: string) =>
              updateOfficeDetailsState({
                state: e,
              })
            }
            error={officeDetailStore.officeDetailErrors?.state}
          />
          <TextInput
            label="Zip"
            value={officeDetailStore.officeDetail?.zip ?? ''}
            onChange={(e: string) =>
              updateOfficeDetailsState({
                zip: e,
              })
            }
            error={officeDetailStore.officeDetailErrors?.zip}
          />
        </Stack>

        <TextInput
          label="Intacct Id"
          value={officeDetailStore.officeDetail?.accountingSysId ?? ''}
          onChange={(e: string) =>
            updateOfficeDetailsState({
              accountingSysId: e,
            })
          }
        />

        <CommonFileUpload
          id="carrier-documents"
          maxFileSize={12582912}
          height={5}
          uploadedFiles={officeDetailStore.officeDocuments}
          shouldUploadOnSelect
          isRemoveFile
          removeFile={handleFileRemove}
          apiCallBackFn={handleFileUpload}
          customValidation={customValidation}
          isAdmin={isFullAdmin()}
        />
      </Stack>
    </Card>
  );
};
