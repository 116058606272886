import { onMount, onCleanup } from 'solid-js';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.css';

function formatIntegerToTime(timeInt: number | null | undefined): string {
  if (timeInt === null || timeInt === undefined) {
    return '';
  }
  const str = timeInt.toString().padStart(4, '0');
  const hours = str.slice(0, 2);
  const minutes = str.slice(2, 4);
  return `${hours}:${minutes}`;
}

export const IntegerTimeInput = (props: {
  placeholder?: string;
  onChange?: (value: number | null) => void;
  value?: number | null;
  label?: string;
}) => {
  let timeInput: HTMLInputElement | null;
  let fpInstance: flatpickr.Instance | null;

  onMount(() => {
    if (timeInput) {
      fpInstance = flatpickr(timeInput, {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'H:i',
        time_24hr: true,
        defaultDate: formatIntegerToTime(props.value ?? null),
        onChange: (date) => {
          const timeMatch = date[0].toString().match(/(\d{2}:\d{2}):\d{2}/);
          if (timeMatch && timeMatch[1]) {
            const timeWithoutColon = timeMatch[1].replace(':', '');
            if (Number(timeWithoutColon) === 0) {
              props.onChange?.(null);
            } else {
              props.onChange?.(Number(timeWithoutColon));
            }
          }
        },
      });
    }
  });

  onCleanup(() => {
    if (fpInstance) {
      fpInstance.destroy();
    }
  });

  return (
    <div class="">
      <div class="text-xs text-[#123b50] font-bold w-fit bg-white relative top-2 left-2 px-2">
        {props.label}
      </div>
      <div class="border-[1px] border-[#80b6cf] rounded-md p-1 hover:border-black">
        <input
          class="focus:outline-none w-full px-2 h-6"
          ref={(el) => (timeInput = el)}
          type="time"
          placeholder={props.placeholder}
          value={formatIntegerToTime(props.value)}
        />
      </div>
    </div>
  );
};
