import { currencyTypes } from '@common/commonLists';
import { BasicModal, AutoComplete } from '@components';
import { openModal } from '@store/modals';
import {
  Carrier,
  CarrierFeatures,
  CarrierState,
  carrierStore,
  getCarrierDetail,
  getCarrierFeatures,
  orderStore,
  setCarrierStore,
  updateLoadPropertyAtIndex,
} from '@store/orders';
import { userStore } from '@store/user';
import { Grid } from '@suid/material';
import IsLTLLoad from '@views/order/components/models/IsLTLLoad';
import { produce } from 'solid-js/store';
import { IS_LTL_LOAD_MODAL_ID } from '@views/order/constants';
import { isSafetyRequired } from '@views/order/helpers';
import { CarrierAutoCompleteModel } from '@typeDefinitions/carrierTypes';
import { ILoadEditViewModel } from '@typeDefinitions/loadTypes';

import { StatusBadge } from '../StatusBadge';

type PropsT = {
  load: ILoadEditViewModel;
  tabIndex: number;
  disabled?: boolean;
  size?: 'medium' | 'small' | undefined;
};

const setDefaultCurrencyType = (tabIndex: number) => {
  if (
    carrierStore.carrier.length &&
    !carrierStore.carrier[tabIndex]?.currencyOptionEnabled &&
    carrierStore.featureFlags.length > 0 &&
    !carrierStore.featureFlags[tabIndex].triumphPayCurrency
  ) {
    updateLoadPropertyAtIndex({
      currencyType: currencyTypes[0],
    });
  }
};

export const handleCarrierChange = async (
  carrierId: number,
  carrierName: string,
  tabIndex: number,
  load: ILoadEditViewModel,
) => {
  if (userStore.user.officeId !== 63) {
    updateLoadPropertyAtIndex({
      coveredById: userStore.user.id,
      coveredBy: userStore.user.name,
    });
  }

  await getCarrierFeatures(carrierId, tabIndex).then(() => {
    setDefaultCurrencyType(tabIndex);
  });

  await getCarrierDetail(carrierId, tabIndex, true).then(() => {
    setDefaultCurrencyType(tabIndex);
    if (isSafetyRequired()) {
      openModal('safetyWarning');
    }
  });

  updateLoadPropertyAtIndex({
    carrierName: carrierName,
    carrierId: carrierId,
    carrierContactId: carrierStore.carrier[tabIndex]?.contacts[0]?.id,
    status: load.status === 'Unassigned' ? 'Assigned' : load.status,
    carrierStatus: carrierStore.carrier[tabIndex]?.status,
    carrierActionPlan: carrierStore.carrier[tabIndex]?.carrierActionPlan,
    quoteId: null,
    ltlquoteId: null,
    enableLoadCarrierRestrictionOverride: false,
  });

  if (carrierStore.carrier[tabIndex]?.ltlEnabled && load.mode !== 'LTL') {
    openModal(IS_LTL_LOAD_MODAL_ID);
  }
};

export const CarrierAutoComplete = (props: PropsT) => {
  return (
    <>
      <AutoComplete
        id={`auto-complete-carrierName-${props.tabIndex}`}
        endpoint="carrier/autocomplete?term"
        label={'Carrier'}
        disabled={props.disabled}
        value={carrierStore.carrier[props.tabIndex]?.name}
        onItemSelect={async (item) => {
          const item2 = item as CarrierAutoCompleteModel;
          await handleCarrierChange(
            item2.id,
            item2.name,
            props.tabIndex,
            props.load,
          );
        }}
        size={props.size ?? 'small'}
        disableClear={['Billed', 'Paid'].includes(props.load.status as string)}
        renderItem={(item) => {
          const autoItem = item as CarrierAutoCompleteModel;
          return (
            <Grid container wrap="nowrap" justifyContent="space-between">
              <Grid item>
                <Grid container direction="column">
                  <Grid item class="font-bold">
                    {autoItem.name.length >= 60
                      ? autoItem.name.slice(0, 60) + '...'
                      : autoItem.name}
                  </Grid>
                  <Grid item class="text-black text-opacity-50">
                    MC: {autoItem.mc} | DOT: {autoItem.dot}
                  </Grid>
                  <Grid item class="text-black text-opacity-50">
                    Location: {autoItem.city}, {autoItem.state}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item paddingLeft={2}>
                <Grid container direction="column">
                  <Grid item alignSelf="center">
                    <Grid container direction={'row'} wrap="nowrap">
                      <Grid item alignSelf="center" paddingRight={2}>
                        Status
                      </Grid>
                      <Grid item alignSelf="center">
                        <StatusBadge
                          text={autoItem.status}
                          color={(text: string) => {
                            if (text === 'Active') return '#28957C';
                            if (text === 'Inactive') return '#F59D25';
                            return '#B00020';
                          }}
                          size="medium"
                        />
                      </Grid>
                    </Grid>
                    <Grid item paddingTop={2}>
                      <Grid
                        container
                        direction={'row'}
                        wrap="nowrap"
                        flexGrow={1}
                        flexShrink={0}
                        justifyContent="flex-end"
                      >
                        <Grid item alignSelf="center">
                          {autoItem.ltlEnabled && (
                            <StatusBadge
                              text="LTL"
                              color="#31637F"
                              size="small"
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        }}
        onClearValue={() => {
          setCarrierStore(
            produce((s: CarrierState) => {
              s.carrier[props.tabIndex] = {} as Carrier;
              s.featureFlags[props.tabIndex] = {} as CarrierFeatures;
            }),
          );
          updateLoadPropertyAtIndex({
            driver: undefined,
            driverPhoneNumber: undefined,
            truckNumber: undefined,
            trailerNumber: undefined,
            trackingEnabled: Boolean(
              orderStore.order.loads[props.tabIndex].trackingServiceId,
            )
              ? orderStore.order.loads[props.tabIndex].trackingEnabled
              : false,
            carrierId: undefined,
            carrierName: undefined,
            carrierContactId: undefined,
            signedConfirmationDate: undefined,
            signedRateCon: false,
            status: 'Unassigned',
          });
        }}
      />
      <BasicModal
        id={IS_LTL_LOAD_MODAL_ID}
        header={false}
        footer={false}
        width="280px"
      >
        <IsLTLLoad modalId={IS_LTL_LOAD_MODAL_ID} />
      </BasicModal>
    </>
  );
};
