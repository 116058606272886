import { ApiResponse, apiClient, v3Client } from '@api/apiClient';
import { AxiosResponse } from 'axios';
import { get } from 'lodash';
import {
  ATGAutoCompleteItem,
  AutocompleteProps,
} from '@components/Autocomplete/types';

import { Autocomplete } from './AutoComplete';

export const NewAutoComplete = (
  props: AutocompleteProps<ATGAutoCompleteItem>,
) => {
  const fetchData = async (
    searchQuery: string,
    setSearchResults: (items: ATGAutoCompleteItem[]) => void,
  ) => {
    let response:
      | AxiosResponse<ApiResponse<ATGAutoCompleteItem[]>>
      | { data: ATGAutoCompleteItem[] }
      | ATGAutoCompleteItem[];
    let items;
    if (props.version === 'v3') {
      response = await v3Client.get<
        string,
        { data: ATGAutoCompleteItem[] } | ATGAutoCompleteItem[]
      >(`${props.endpoint}${searchQuery.trimStart()}`);
      if ('data' in response) {
        items = response.data;
      } else {
        items = response;
      }

      setSearchResults(items);
    } else {
      try {
        response = await apiClient.get<
          string,
          AxiosResponse<ApiResponse<ATGAutoCompleteItem[]>>
        >(`${props.endpoint}=${encodeURIComponent(searchQuery.trimStart())}`);

        // TODO: The type of `response` here need to be handled properly.
        // We shouldn't have to guess what response is.
        if ('value' in response) {
          items = response.value as ATGAutoCompleteItem[];
        } else {
          response = await apiClient.get<
            string,
            AxiosResponse<ApiResponse<ATGAutoCompleteItem[]>>
          >(`${props.endpoint}=${searchQuery.trimStart()}`);

          // TODO: The type of `response` here need to be handled properly.
          // We shouldn't have to guess what response is.
          if ('value' in response) {
            items = response.value as ATGAutoCompleteItem[];
          } else {
            items = response as unknown as ATGAutoCompleteItem[];
          }
        }

        // As per requirements, we only need to show the first 50 results
        // in our autocomplete list. The limit can be tweaked, but keep in
        // mind that the list absolutely needs to be limited, otherwise it
        // can crash the app or cause performance issues.
        setSearchResults(items.slice(0, 50));
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    }
  };
  const getStaticData = (
    searchQuery: string,
    setSearchResults: (items: ATGAutoCompleteItem[]) => void,
  ) => {
    if (!Boolean(searchQuery)) {
      props.staticItemList && setSearchResults(props.staticItemList);
    } else {
      const items = (props.staticItemList || []).filter((item) =>
        get(item, 'value', '')
          .toLowerCase()
          .includes(searchQuery.toLowerCase()),
      );
      setSearchResults(items);
    }
  };
  return (
    <Autocomplete
      fetchData={Boolean(props.isStatic) ? getStaticData : fetchData}
      {...props}
    />
  );
};
