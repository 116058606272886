import { ConfigManager } from './ConfigManager';
import { printLog } from './utils';

export const routeOrderDetails = (orderId: number) => {
  if (window.self !== window.top) {
    if (window.top) {
      ConfigManager.v4OrderDetailsHost.split(',').forEach((host) => {
        try {
          parent.postMessage(
            {
              message: 'navigateToOrderDetails',
              orderId: orderId,
            },
            {
              targetOrigin: host,
            },
          );
        } catch (e) {
          printLog(
            'There was an error posting message to parent -- this is expected',
            e,
          );
        }
      });
    }
    return false;
  }
  return true;
};

export const routeOrderDetailsQuote = (
  quoteCorrelationId: string,
  quoteId: string,
) => {
  if (window.self !== window.top) {
    if (window.top) {
      ConfigManager.v4OrderDetailsHost.split(',').forEach((host) => {
        try {
          parent.postMessage(
            {
              message: 'navigateToOrderDetails',
              quoteCorrelationId: quoteCorrelationId,
              quoteId: quoteId,
            },
            {
              targetOrigin: host,
            },
          );
        } catch (e) {
          printLog(
            'There was an error posting message to parent -- this is expected',
            e,
          );
        }
      });
    }
    return false;
  }
  return true;
};

export const routeOrderDetailsCopyOrder = (copyOrderId: string) => {
  // eslint-disable-next-line no-alert
  if (window.self !== window.top) {
    if (window.top) {
      ConfigManager.v4OrderDetailsHost.split(',').forEach((host) => {
        // eslint-disable-next-line no-alert
        try {
          parent.postMessage(
            {
              message: 'navigateToOrderDetails',
              copyOrderId: copyOrderId,
            },
            {
              targetOrigin: host,
            },
          );
        } catch (e) {
          printLog(
            'There was an error posting message to parent -- this is expected',
            e,
          );
        }
      });
    }
    return false;
  }
  return true;
};

export const routeOrderDetailsCopyOrderWithQuote = (
  copyOrderId: string,
  quoteCorrelationId: string,
) => {
  if (window.self !== window.top) {
    if (window.top) {
      ConfigManager.v4OrderDetailsHost.split(',').forEach((host) => {
        try {
          parent.postMessage(
            {
              message: 'navigateToOrderDetails',
              quoteCorrelationId: quoteCorrelationId,
              copyOrderId: copyOrderId,
            },
            {
              targetOrigin: host,
            },
          );
        } catch (e) {
          printLog(
            'There was an error posting message to parent -- this is expected',
            e,
          );
        }
      });
    }
    return false;
  }
  return true;
};

export const routeOrderDetailsQuoteWithId = (
  quoteCorrelationId: string,
  orderId: number,
) => {
  if (window.self !== window.top) {
    if (window.top) {
      ConfigManager.v4OrderDetailsHost.split(',').forEach((host) => {
        try {
          parent.postMessage(
            {
              message: 'navigateToOrderDetails',
              quoteCorrelationId: quoteCorrelationId,
              orderId: orderId,
            },
            {
              targetOrigin: host,
            },
          );
        } catch (e) {
          printLog(
            'There was an error posting message to parent -- this is expected',
            e,
          );
        }
      });
    }
    return false;
  }
  return true;
};

export const oldLoadTotalCountEvent = (count: number) => {
  if (window.self !== window.top) {
    if (window.top) {
      ConfigManager.v4OrderDetailsHost.split(',').forEach((host) => {
        try {
          parent.postMessage(
            {
              message: 'oldLoadTotalCount',
              count: count,
            },
            {
              targetOrigin: host,
            },
          );
        } catch (e) {
          printLog(
            'There was an error posting message to parent -- this is expected',
            e,
          );
        }
      });
    }
    return false;
  }
  return true;
};
