import { Card, Tab, TabsComponent } from '@components';
import { VendorContractViewModel } from '@store/orders/types';
import { createSignal } from 'solid-js';

import { calculateSettlementCount } from '../stops/stopsEditor/utilFunctions';
import { Settlement } from './Settlement';
import { VendorPay } from './VendorPay';

type Props = {
  vendor: VendorContractViewModel;
  index: number;
};

export const VendorCosts = (props: Props) => {
  const [activeTab, setActiveTab] = createSignal<number>(0);

  const tabs = [
    'Vendor Pay',
    `SETTLEMENTS(${calculateSettlementCount(
      `vendors.${props.index}.lineItems`,
    )}) `,
  ];

  const onClick = (index: number) => {
    setActiveTab(index);
  };

  return (
    <Card startTitle="Vendor Costs">
      <TabsComponent
        tabs={tabs.map((item) => (
          <Tab onClick={onClick}>{item}</Tab>
        ))}
        activeTab={activeTab()}
        onTabClick={setActiveTab}
      />
      <div class="tab1">
        <div class={activeTab() === 0 ? 'block' : 'hidden'}>
          <VendorPay vendor={props.vendor} index={props.index} />
        </div>
        <div class={activeTab() === 1 ? 'block' : 'hidden'}>
          <Settlement index={props.index} />
        </div>
      </div>
    </Card>
  );
};
