import {
  carrierStore,
  featureFlagGetCustomerFeatures,
  LtlQuoteLoadItemViewModel,
  orderStore,
  PickUpDropOffItem,
} from '@store/orders';
import { ILoadEditViewModel } from '@typeDefinitions/loadTypes';
import { LastUsedTopStopModel } from '@typeDefinitions/stopTypes';
import { getEligibleEntries, isAdmin, sdbm } from '@utils/utils';
import { each, get, isEmpty, isEqual } from 'lodash';

export const refundAdjustmentError = (
  retractionCount: number,
  refundCount: number,
) => {
  return refundCount - retractionCount > 0;
};

export const retractionAdjustmentError = (
  retractionCount: number,
  refundCount: number,
) => {
  return retractionCount - refundCount > 1;
};

export const setCustomerCommentVisiblityForComponent = () => {
  if (
    orderStore.order.loads[orderStore.activeTab.index].mode == 'LTL' ||
    get(
      featureFlagGetCustomerFeatures,
      'featureFlagCustomer.CustomerCommentVisibility',
    )
  ) {
    return false;
  }
  return true;
};

export const isStopValid = (stops: LastUsedTopStopModel[]) => {
  let hasPickup = false;
  let hasDropoff = false;

  for (const stop of stops) {
    if (stop.operationType === 'Delete') continue;

    if (stop.pickUp) hasPickup = true;
    if (stop.dropOff) hasDropoff = true;

    if (hasPickup && hasDropoff) break;
  }

  if (!hasPickup && !hasDropoff) {
    return 'You need at least one pick up and one drop off';
  } else if (!hasPickup) {
    return 'You need at least one pick up';
  } else if (!hasDropoff) {
    return 'You need at least one drop off';
  }

  return '';
};

export const checkTLModeError = (
  load: ILoadEditViewModel,
  carrierIndex: number,
) => {
  const disallowedStatuses = ['Void', 'Billed', 'Paid', 'Closed'];

  const err = [];

  if (
    !load.cargoValueIsHigh! &&
    !isAdmin() &&
    !disallowedStatuses.includes(load.status!)
  ) {
    err.push('Indicate if the cargo value is more than $100,000');
  }

  if (load.cargoValueIsHigh! === 'No' && load.freightValue! > 100000) {
    err.push('Enter cargo value: 0- $100,000.');
  }
  if (
    load.cargoValueIsHigh! === 'Yes' &&
    !load.enableLoadCarrierRestrictionOverride &&
    load.freightValue! > 100000 &&
    carrierStore.carrier.length > 0 &&
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    carrierStore.carrier[carrierIndex] &&
    carrierStore.carrier[carrierIndex].totalLoads <= 3
  ) {
    err.push(
      'Load cannot be booked because the carrier has a load history of three loads or less and the value of the load is $100,000 or greater and they should contact Carrier Relations to override the booking if they want to proceed with the selected carrier',
    );
  }
  return err;
};

function CheckZipForChanges(
  load: ILoadEditViewModel,
  eligibleStops: LastUsedTopStopModel[],
) {
  if (!Boolean(load.ltlQuote?.quoteId) || !load.ltlQuote) return false;
  const normalizeZip = (zip: string | undefined): string | undefined =>
    zip?.trim().replace(/\s/g, '').toUpperCase().split('-')[0];

  const originZip = normalizeZip(load.ltlQuote.rateRequest.originZip);
  const destinationZip = normalizeZip(load.ltlQuote.rateRequest.destinationZip);

  const eligibleOriginStop = eligibleStops.find((x) => x.pickUp);
  const stopOriginZip = normalizeZip(eligibleOriginStop?.zip);

  if (stopOriginZip !== originZip) {
    return true;
  }

  const eligibleDestinationStop = eligibleStops.find((x) => !x.pickUp);
  const stopDestinationZip = normalizeZip(eligibleDestinationStop?.zip);

  if (stopDestinationZip !== destinationZip) {
    return true;
  }

  return false;
}

const reduceItemsToHash = (
  hashes: Record<number, number>,
  item: PickUpDropOffItem | LtlQuoteLoadItemViewModel,
) => {
  const str = [item.quantity, item.weight, item.class].join(',');
  const hash = sdbm(str);

  if (hash in hashes) {
    hashes[hash]++;
  } else {
    hashes[hash] = 1;
  }

  return hashes;
};

const hasStopChanges = (load: ILoadEditViewModel) => {
  let quoteChanges = false;
  const stopChanges = false;
  let zipChanges = false;
  const eligibleItems = getEligibleEntries<PickUpDropOffItem>(load.items);
  const quoteItems = load.ltlQuote?.ltlQuoteLoadItems ?? [];

  if (quoteItems.length > 0 && quoteItems.length == eligibleItems.length) {
    if (eligibleItems.length !== quoteItems.length) {
      quoteChanges = true;
    } else {
      const eligibleItemsHashes = eligibleItems.reduce(
        reduceItemsToHash,
        {} as Record<number, number>,
      );

      const quotedItemsHashes = quoteItems.reduce(
        reduceItemsToHash,
        {} as Record<number, number>,
      );

      quoteChanges = !isEqual(eligibleItemsHashes, quotedItemsHashes);
    }
  } else {
    quoteChanges = true;
  }

  const eligibleStops = getEligibleEntries<LastUsedTopStopModel>(load.stops);
  if (!quoteChanges) {
    const zipForChanges = CheckZipForChanges(load, eligibleStops);
    zipChanges = zipForChanges;
  }

  return {
    quoteChanges: quoteChanges,
    stopChanges: stopChanges,
    zipChanges: zipChanges,
  };
};

export const checkLTLModeError = (load: ILoadEditViewModel) => {
  const err: string[] = [];
  const eligibleStops = getEligibleEntries<LastUsedTopStopModel>(load.stops);

  const deliveryWindowsValid = () => {
    let valid = true;
    each(eligibleStops, (stop) => {
      if (!stop.driverInTime! || !stop.driverOutTime!) {
        valid = false;
      }
    });
    return valid;
  };

  const quoteItems = load.ltlQuote?.ltlQuoteLoadItems;

  if (
    !isEmpty(load.ltlquoteId) &&
    !isEmpty(quoteItems) &&
    !isEmpty(eligibleStops)
  ) {
    if (!deliveryWindowsValid()) {
      err.push(
        'Pickup and delivery windows are not set. These can be set by entering Driver In/Driver Out times on stops.',
      );
    }
    // Per Mike 11/8/2021, we are not going to check for item changes until we have a better understanding of how to handle them.
    // if (hasStopChanges(load).quoteChanges) {
    //   err.push(
    //     'Item changes have been made on this load, please confirm the item quantity, weight and class are the same as originally quoted and re-quote the load.',
    //   );
    // }

    if (hasStopChanges(load).stopChanges) {
      err.push(
        'Stops have incomplete address information. Please add necessary address details.',
      );
    }
  }
  const zipChanges = CheckZipForChanges(load, eligibleStops);
  if (zipChanges) {
    err.push(
      'Zip code changes have been made to the stops on this load. Please re-quote the load.',
    );
  }

  return err;
};

export const ltlLoadHasItemDescription = (load: ILoadEditViewModel) => {
  const eligibleItems = getEligibleEntries<PickUpDropOffItem>(load.items);
  return eligibleItems.every((item) => item.description.length > 0);
};
