import { Button, DatePicker, TimePicker, ToastType } from '@components';
import { TextInput } from '@components/forms';
import { createForm } from '@felte/solid';
import { validator } from '@felte/validator-yup';
import {
  AppointmentReminderModel,
  setAppointmentReminder,
} from '@store/orders';
import { handleToast } from '@utils/utils';
import { DateTime } from 'luxon';
import * as yup from 'yup';
import { Box, Stack } from '@suid/material';

import cls from './classes';

interface SetAppReminderProps {
  onSubmit: () => void;
  type: 'Load' | 'Prospect';
}
export const SetAppReminder = (props: SetAppReminderProps) => {
  const { form, errors, data, setFields } = createForm({
    initialValues: {
      title: '',
      description: '',
      time: DateTime.now()
        .setZone('local')
        .set({
          hour: 12,
          minute: 30,
          second: 0,
          millisecond: 0,
        })
        .toISO({
          includeOffset: false,
          suppressMilliseconds: true,
        }),
      date: DateTime.now().setZone('local').toISO(),
      startTime: DateTime.now().setZone('local').toISO(),
      endTime: DateTime.now().plus({ minutes: 30 }).setZone('local').toISO(),
      objectIdType: props.type,
      public: false,
    },
    extend: validator({
      schema: yup.object().shape({
        title: yup.string().required(),
        description: yup.string().required(),
        time: yup.string().required(),
        date: yup.string().required(),
      }),
    }),
    onSubmit: async (values) => {
      const date = values.date?.split('T')[0];
      const time = values.time?.split('T')[1];
      const startTime = DateTime.fromISO(`${date}T${time}`, {
        zone: 'utc',
      })
        .setZone('local')
        .toUTC()
        .toISO();
      const endTime = DateTime.fromISO(`${date}T${time}`, {
        zone: 'utc',
      })
        .plus({ minutes: 30 })
        .setZone('local')
        .toUTC()
        .toISO();

      const payload = {
        startTime: startTime,
        endTime: endTime,
        title: values.title,
        description: values.description,
        date: DateTime.fromISO(String(values.date)).toFormat('L/d/yyyy'),
        time: DateTime.fromISO(String(values.time)).toFormat('hh:mm a'),
        objectIdType: values.objectIdType,
        public: false,
      };

      try {
        await setAppointmentReminder(payload as AppointmentReminderModel);
      } catch (error) {
        handleToast(ToastType.Error, (error as Error).message);
      }

      props.onSubmit();
    },
  });

  const handleTimeChange = (newTime: string) => {
    setFields('time', newTime.includes('T:00') ? '' : newTime);
  };

  const title = {
    Load: 'Set App Reminder',
    Prospect: 'Prospect Followup',
  };
  return (
    <form ref={form} class={cls.popoverClass} id="Set">
      <span class={`${cls.modalHeadingLeftAlign}`}>{title[props.type]}</span>
      <Stack spacing={2} class="p-4">
        <TextInput
          label="Title"
          placeholder="Title"
          value={data().title}
          onChange={(itm) => setFields('title', itm as string)}
          error={errors().title}
        />
        <DatePicker
          value={data().date!}
          handleChange={(date) => setFields('date', date)}
          error={errors().date as string[]}
        />
        <TimePicker
          label=""
          value={data().time!}
          onChange={handleTimeChange}
          error={errors().time}
        />
        <TextInput
          name="description"
          label="Description"
          multiline
          placeholder="Body"
          rows={3}
          value={data().description}
          onChange={(itm) => setFields('description', itm)}
          error={errors().description}
        />
        {/* </div> */}
        <Box displayRaw="flex" alignItems="center" justifyContent="end" gap={2}>
          <Button type="submit" label="Set Reminder" />
          <Button
            variant="text"
            label="Cancel"
            onClick={() => {
              props.onSubmit();
            }}
          />
        </Box>
      </Stack>
    </form>
  );
};
