import { Button, ToastType } from '@components';
import { TextInput } from '@components/forms';
import { featureFlags } from '@store/featureFlags/featureFlags';
import { isFeatureFlagEnabled } from '@store/featureFlags/store';
import {
  calculateMileage,
  customerStore,
  orderStore,
  setOrderStore,
  updateLoadPropertyAtIndex,
} from '@store/orders';
import { Box } from '@suid/material';
import {
  createMaxNumber,
  getActiveStopIndices,
  handleToast,
  trimDecimalValue,
} from '@utils/utils';
import { size } from 'lodash';
import { Component, createEffect, createSignal } from 'solid-js';
import { produce } from 'solid-js/store';

import classes from './classes';

type StopsMileageProps = {
  tabIndex: number;
  disabled?: boolean;
};

const StopsMileage: Component<StopsMileageProps> = (props) => {
  const [loading, setLoading] = createSignal(false);

  const preventHyphen = (event: KeyboardEvent) => {
    if (event.key === '-') {
      event.preventDefault();
    }
  };

  let isFirstRender = true;

  createEffect(async () => {
    if (orderStore.activeTab.type === 'load') {
      const stops = orderStore.order.loads[
        orderStore.activeTab.index
      ]?.stops?.filter((stop) => {
        return stop.operationType !== 'Delete';
      });
      if (isFeatureFlagEnabled(featureFlags.stopMileage)() && stops) {
        if (isFirstRender) {
          isFirstRender = false;
          return;
        }
        if (stops.length <= 1) {
          setOrderStore(
            'order',
            'loads',
            orderStore.activeTab.index,
            produce((draft) => {
              draft.practicalMileage = 0;
              draft.shortestMileage = 0;
            }),
          );
          return;
        }
        await getMileage();
      }
    }
  });

  const getMileage = async () => {
    if (orderStore.isReadOnly) {
      return;
    }

    if (
      !orderStore.order.loads[orderStore.activeTab.index]?.stops?.filter(
        (stop) => {
          return stop.operationType !== 'Delete';
        },
      )
    )
      return;
    try {
      setLoading(true);
      const response = await calculateMileage(
        orderStore.order.loads[orderStore.activeTab.index]?.stops?.filter(
          (stop) => {
            return stop.operationType !== 'Delete';
          },
        ),
        customerStore.customer.pcMilerVersion
          ? customerStore.customer.pcMilerVersion
          : null,
      );
      const activeStopIndices = getActiveStopIndices();
      const newStopList =
        orderStore.order.loads[orderStore.activeTab.index]?.stops ?? [];
      let distanceResponse:
        | {
            shortMiles: string | number;
            practicalMiles: string | number;
          }
        | undefined;

      for (const stopIndex of activeStopIndices) {
        const arrayIndex: number = activeStopIndices.indexOf(stopIndex);
        if (arrayIndex === activeStopIndices.length - 1) {
          setOrderStore(
            'order',
            'loads',
            orderStore.activeTab.index,
            'stops',
            stopIndex,
            produce((draft) => {
              draft.practicalMileageToNextStop = null;
              draft.shortestMileageToNextStop = null;
            }),
          );
          continue;
        }
        distanceResponse = await calculateMileage(
          [
            newStopList[stopIndex],
            newStopList[activeStopIndices[arrayIndex + 1]],
          ],
          customerStore.customer.pcMilerVersion
            ? customerStore.customer.pcMilerVersion
            : null,
        );
        setOrderStore(
          'order',
          'loads',
          orderStore.activeTab.index,
          'stops',
          stopIndex,
          produce((draft) => {
            draft.practicalMileageToNextStop =
              distanceResponse?.practicalMiles as number;
            draft.shortestMileageToNextStop =
              distanceResponse?.shortMiles as number;
          }),
        );
      }
      setOrderStore(
        'order',
        'loads',
        orderStore.activeTab.index,
        produce((draft) => {
          const opType = draft.operationType === 'Insert' ? 'Insert' : 'Update';
          draft.practicalMileage = response?.practicalMiles as number;
          draft.shortestMileage = response?.shortMiles as number;
          draft.operationType = opType;
        }),
      );
    } catch (error) {
      handleToast(ToastType.Error, 'Error while getting mileage');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      displayRaw="flex"
      flexDirection="row"
      justifyContent="end"
      gap={2}
      class="mb-[16px]"
    >
      <TextInput
        placeholder="Practical"
        value={orderStore.order.loads[props.tabIndex]?.practicalMileage ?? ''}
        size="small"
        onChange={(value: number) => {
          updateLoadPropertyAtIndex({
            practicalMileage:
              value > createMaxNumber(7, 2)
                ? Number(trimDecimalValue(String(value), 7, 2, false))
                : value,
          });
        }}
        classes={classes.stopsMileageTextInput}
        label="Practical"
        type="number"
        disabled={props.disabled}
        onKeyDown={preventHyphen}
      />
      <TextInput
        placeholder="Shortest"
        value={orderStore.order.loads[props.tabIndex]?.shortestMileage ?? ''}
        size="small"
        onChange={(value: number) => {
          updateLoadPropertyAtIndex({
            shortestMileage:
              value > createMaxNumber(7, 2)
                ? Number(trimDecimalValue(String(value), 7, 2, false))
                : value,
          });
        }}
        classes={classes.stopsMileageTextInput}
        label="Shortest"
        type="number"
        disabled={props.disabled}
        onKeyDown={preventHyphen}
      />
      <Button
        label="Mileage"
        sx={{ backgroundColor: '#468DB5' }}
        onClick={getMileage}
        isLoading={loading()}
        disabled={
          loading() ||
          size(
            orderStore.order.loads[props.tabIndex]?.stops?.filter(
              (stop) => stop.operationType !== 'Delete',
            ),
          ) < 2 ||
          props.disabled
        }
      />
    </Box>
  );
};

export default StopsMileage;
