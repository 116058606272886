import { Button } from '@components/Button';
import { customerSavedQuotesStore } from '@store/customers/customerDetails';
import { dateComparator, dateFormatter } from '@store/loadboard/utils';
import { SavedQuoteInfo } from '@store/ltl';
import { savedQuoteStore } from '@store/ltl/store';
import { Box, Grid as MuiGrid, Stack } from '@suid/material';
import { DownloadCSVFileV4 } from '@utils/DownloadCSVFileV3';
import { atgLogo } from '@views/ltl';
import { LTLQuotesActionCellButtonLegacy as LTLQuotesActionCellButton } from '@views/ltl/components/LTLQuotesActionCellButtonLegacy';
import {
  QuoteDetailsRowRenderLegacy,
  QuoteStatus,
} from '@views/ltl/components/QuoteDetailsRowRenderLegacy';
import { ColDef, ValueGetterParams } from '@ag-grid-community/core';
import AgGridSolid from '@ag-grid-community/solid';
import { Accessor, createSignal, Show } from 'solid-js';
import { useParams } from '@solidjs/router';
import '../Grid/AgGridStyles.css';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { featureFlags } from '@store/featureFlags/featureFlags';
import {
  isFeatureFlagEnabled,
  featureFlagStore,
} from '@store/featureFlags/store';

import { agGridCellStyle } from './AgGridCellStyle';
import { columnsToolPanel, filtersToolPanel } from './components/constants';

export const HistoryQuotesGrid = (props: {
  items: Accessor<SavedQuoteInfo[]>;
  isCustomerSaveQuote: boolean;
}) => {
  const [csvExportLoading, setCsvExportLoading] = createSignal(false);

  const params = useParams();

  const columnDefs: ColDef[] = [
    {
      headerName: 'Customer',
      field: 'customerName',
      rowGroup: true,
      valueFormatter: (params) => {
        const value = params.value as string;
        return Boolean(value) ? `${value.toUpperCase()}` : '';
      },
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
      },
      autoHeight: true,
      minWidth: 180,
      hide: true,
      cellClass: 'ag-custom-cell-class',
      cellRenderer: 'agGroupCellRenderer',
    },
    {
      headerName: 'Quote Group #',
      field: 'groupId',
      rowGroup: true,
      minWidth: 300,
      hide: true,
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
      },
      autoHeight: true,
      cellClass: 'ag-custom-cell-class',
      cellRenderer: 'agGroupCellRenderer',
      valueGetter: (params) => {
        const data = params.data as
          | { quoteRequestLocator?: string; quoteRequestId?: string }
          | undefined;
        return isFeatureFlagEnabled(featureFlags.useShortQuoteGroupLocator)() &&
          data &&
          Boolean(data.quoteRequestLocator)
          ? data.quoteRequestLocator
          : data
            ? data.quoteRequestId
            : params.node?.key;
      },
    },
    {
      field: 'createdDateTimeLocal',
      headerName: 'Date Saved',
      valueFormatter: dateFormatter,
      cellStyle: agGridCellStyle,
      filter: 'agDateColumnFilter',
      autoHeight: true,
      filterParams: {
        comparator: dateComparator,
        buttons: ['reset'],
        suppressAndOrCondition: true,
      },
      floatingFilter: false,
    },
    {
      field: 'quoteStatus',
      headerName: 'Quote Status',
      cellStyle: agGridCellStyle,
      autoHeight: true,
      cellRenderer: (params: { data: SavedQuoteInfo }) => {
        if (Boolean(params.data)) {
          return QuoteStatus(params.data.quoteStatus);
        }
      },
      filter: 'agSetColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
      },
      minWidth: 120,
      floatingFilter: false,
    },
    {
      field: 'expirationDateTimeLocal',
      headerName: 'Expiration Date',
      valueFormatter: dateFormatter,
      cellStyle: agGridCellStyle,
      autoHeight: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: dateComparator,
        buttons: ['reset'],
        suppressAndOrCondition: true,
      },
      floatingFilter: false,
    },
    {
      field: 'createdByName',
      headerName: 'Created By',
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
      },
      valueFormatter: (params: {
        data: {
          cPCustomerContactId?: number;
        };
        value: string;
      }) => {
        const value = params.value;
        return Boolean(
          Boolean(params.data) ? params.data.cPCustomerContactId : '',
        )
          ? `(${value})`
          : value;
      },
      floatingFilter: false,
    },
    {
      field: 'actions',
      cellRenderer: (params: { data: SavedQuoteInfo }) => {
        return (
          <LTLQuotesActionCellButton
            savedQuoteInfo={params.data}
            isHistory={true}
          />
        );
      },
      pinned: 'right',
      initialWidth: 220,
      floatingFilter: false,
    },
    {
      field: 'originZip',
      headerName: 'Origin Zip',
      floatingFilter: false,
    },
    {
      field: 'destinationZip',
      headerName: 'Destination Zip',
      floatingFilter: false,
    },
    {
      field: 'originCity',
      headerName: 'Origin City',
      hide: true,
      floatingFilter: false,
    },
    {
      field: 'originState',
      headerName: 'Origin State',
      hide: true,
      floatingFilter: false,
    },
    {
      field: 'destinationCity',
      headerName: 'Destination City',
      hide: true,
      floatingFilter: false,
    },
    {
      field: 'destinationState',
      headerName: 'Destination State',
      hide: true,
      floatingFilter: false,
    },
  ];
  const savedQuote = props.isCustomerSaveQuote
    ? customerSavedQuotesStore
    : savedQuoteStore;

  const exportCsv = async () => {
    try {
      setCsvExportLoading(true);
      await DownloadCSVFileV4(
        `${
          Boolean(props.isCustomerSaveQuote)
            ? `customer/exportCustomerSavedQuotes/${params.id}/history`
            : 'ltl/exportSavedQuotes/history'
        }`,
        'QuoteHistory.csv',
      );
    } finally {
      setCsvExportLoading(false);
    }
  };

  return (
    <Stack class="h-[100%]">
      <Box displayRaw="flex" justifyContent="end">
        <Button
          isLoading={csvExportLoading()}
          label="Export Csv"
          variant="outlined"
          onClick={exportCsv}
        />
      </Box>
      <MuiGrid class="ag-theme-alpine !flex-1 pt-4">
        <Show when={!featureFlagStore.isLoading}>
          <AgGridSolid
            columnDefs={columnDefs}
            modules={[
              MasterDetailModule,
              ExcelExportModule,
              MenuModule,
              ClipboardModule,
            ]}
            rowData={props.items()}
            overlayNoRowsTemplate="No data available"
            overlayLoadingTemplate={atgLogo}
            class="no-cell-grid"
            groupHideOpenParents={true}
            autoGroupColumnDef={{
              filter: 'agTextColumnFilter',
              filterValueGetter: (params: ValueGetterParams) => {
                const colId = params.column.getColId();
                if (colId.includes('customerName')) {
                  return (params.data as SavedQuoteInfo).customerName;
                } else if (colId.includes('groupId')) {
                  return Boolean(
                    (params.data as SavedQuoteInfo).quoteRequestLocator,
                  ) &&
                    isFeatureFlagEnabled(
                      featureFlags.useShortQuoteGroupLocator,
                    )()
                    ? (params.data as SavedQuoteInfo).quoteRequestLocator
                    : (params.data as SavedQuoteInfo).groupId;
                }
              },
            }}
            gridOptions={{
              defaultColDef: {
                flex: 1,
                filter: true,
                floatingFilter: true,
              },
              masterDetail: true,
              pagination: false,
              suppressPaginationPanel: true,
              suppressScrollOnNewData: true,
              groupDisplayType: 'multipleColumns',
              groupDefaultExpanded: 5,
              sideBar: {
                toolPanels: [columnsToolPanel, filtersToolPanel],
                defaultToolPanel: '',
              },
              onGridReady: (params) => {
                savedQuote.isLoading
                  ? params.api.showLoadingOverlay()
                  : params.api.hideOverlay();
              },
              detailCellRenderer: function (params: { data: SavedQuoteInfo }) {
                return QuoteDetailsRowRenderLegacy({
                  data: params.data.responseInfo,
                  quoteStatus: params.data.quoteStatus,
                  id: params.data.id,
                  type: 'history',
                });
              },
              detailCellRendererParams: {
                additionalDetailsExpanded: true,
              },
            }}
          />
        </Show>
      </MuiGrid>
    </Stack>
  );
};
