import { apiClient } from '@api/apiClient';
import { CarrierDocument, FactoringCompany } from '@store/carriers';
import {
  AddOrEditCommentPayload,
  V4ApiResponse,
} from '@store/customers/customerDetails';
import { handleToast } from '@utils/utils';
import { ToastType } from '@components';
import { logError } from '@utils/errorLogging';
import { createError } from '@utils/errorUtils';
import { Comments } from '@store/orders';
import { Carrier } from '@store/carriers/types';
import { get } from 'lodash';

import {
  CarrierGridPayload,
  FactoringCompanyDetailsEditHistory,
  FactoringCompanyLoadHistoryApiResponse,
} from './types';

const handleFactoringCompanyError = (error: unknown, toastMessage: string) => {
  if (Array.isArray(error)) {
    const errorMessages = error.map((v) => {
      if (typeof v === 'string') {
        return v;
      } else if (typeof v === 'object' && v !== null && 'errorMessage' in v) {
        return (v as { errorMessage: string }).errorMessage;
      }
    });
    handleToast(ToastType.Error, errorMessages.join(', '));
  } else {
    handleToast(ToastType.Error, toastMessage);
  }
};

export const fetchCustomerFactoringCompanyDetails = async (id: string) => {
  try {
    const response: V4ApiResponse<FactoringCompany> = await apiClient.get(
      `FactoringCompany/${id}`,
    );
    return response;
  } catch (error: unknown) {
    handleFactoringCompanyError(
      error,
      'Failed to fetch factoring company data',
    );
  }
};

export const deleteFactoringCompanyDetails = async (id: number) => {
  try {
    const res: {
      isSuccess: boolean;
    } = await apiClient.delete(`FactoringCompany/${id}`);
    return res.isSuccess;
  } catch (error: unknown) {
    handleFactoringCompanyError(error, '`Failed to delete factoring company');
  }
};

export const saveNewFactoringCompantDetails = async (
  data: FactoringCompany,
) => {
  try {
    const response: V4ApiResponse<FactoringCompany> = await apiClient.post(
      'FactoringCompany',
      data,
    );
    if (response.isSuccess) return response.value;
  } catch (error: unknown) {
    handleFactoringCompanyError(error, 'Failed to save new factoring company');
  }
};

export async function fetchFactoringCompanyDetailsLoadHistory(
  payload: Record<string, unknown>,
) {
  try {
    const response: V4ApiResponse<FactoringCompanyLoadHistoryApiResponse> =
      await apiClient.post('/factoringcompany/loadHistory', payload);
    if ('value' in response) {
      return response.value;
    }
  } catch (error: unknown) {
    const e = createError(error, 'Fetching carrier load history failed');
    handleToast(ToastType.Error, e.message);
  }
}

export const updateFactoringCompanyDetails = async (
  id: number,
  data: FactoringCompany,
) => {
  try {
    const response: V4ApiResponse<FactoringCompany> = await apiClient.put(
      `FactoringCompany/updatefactoringcompany/${id}`,
      data,
    );
    if (response.isSuccess) return response.value;
  } catch (error: unknown) {
    handleFactoringCompanyError(error, 'Failed to update factoring company');
  }
};

export const fetchFactoringCompanyDetailsDocuments = async (id: string) => {
  try {
    const response: V4ApiResponse<CarrierDocument[]> = await apiClient.get(
      `Document/getfactoringcompanydocument/${id}`,
    );
    if (response.isSuccess) {
      return response.value as unknown as CarrierDocument[];
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      handleToast(
        ToastType.Error,
        `Failed to fetch factoring company documents: ${error.message}`,
      );
    }
  }
};

export const uploadFactoringCompanyDetailsDocument = async (
  id: number,
  file: File,
) => {
  try {
    const formData = new FormData();
    formData.append('Files', file);
    formData.append('DocumentEntityType', '4');
    formData.append('EntityId', id.toString());
    const res: V4ApiResponse<{
      results: { documentId: number; name: string; url: string }[];
    }> = await apiClient.post('/Document/CreateDocument', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return res.value;
  } catch (error: unknown) {
    throw new Error(`Failed to upload factoring company document: ${error}`);
  }
};

export const deleteFactoringCompanyDetailsDocument = async (payload: {
  file: string;
}) => {
  try {
    await apiClient.post('/Document/DeleteFile', payload);
  } catch (error: unknown) {
    throw new Error('Deleting factoring company document failed');
  }
};

export const deleteFactoringCompanyDetailsComment = async (
  commentId: number,
) => {
  try {
    const response: {
      isSuccess: boolean;
    } = await apiClient.delete(
      `FactoringCompany/deletefactoringcompanycomment/${commentId}`,
    );
    if (response.isSuccess) return response.isSuccess;
    throw new Error('Failed to Delete Comment');
  } catch (error: unknown) {
    const e = createError(error, 'Failed to Delete Factoring Company Comment');
    handleToast(ToastType.Error, e.message);
  }
};

export async function addFactoringCompanyDetailsComment(
  factoringCompanyId: number,
  payload: AddOrEditCommentPayload,
) {
  try {
    const response: V4ApiResponse<Comments> = await apiClient.post(
      `FactoringCompany/addfactoringcompanycomment/${factoringCompanyId}`,
      payload,
    );
    return response;
  } catch (error: unknown) {
    const e = createError(error, 'Failed to Add Factoring Company Comment');
    handleToast(ToastType.Error, e.message);
  }
}

export async function fetchFactoringCompanyDetailsEditHistory(id: string) {
  try {
    const response: V4ApiResponse<FactoringCompanyDetailsEditHistory[]> =
      await apiClient.get(`/FactoringCompany/edithistory/${id}`);
    if (response.isSuccess) {
      return response.value;
    }
    throw new Error('Failed to fetch factoring company edit history');
  } catch (e) {
    logError('fetchFactoringCompanyDetailsEditHistory', e as Error);
    const error = createError(
      e,
      'Failed to fetch factoring company edit history',
    );
    handleToast(ToastType.Error, error.message);
  }
}

export async function fetchFactoringCompanyCarrierGridData(
  payload: CarrierGridPayload,
): Promise<Carrier | undefined> {
  try {
    const response: V4ApiResponse<Carrier> = await apiClient.post(
      'FactoringCompany/getfactoringcarrierdata',
      payload,
    );
    if ('value' in response) {
      return get(response, 'value');
    }
  } catch (error: unknown) {
    const e = createError(
      error,
      'Failed to fetch factoring company carrier Data',
    );
    handleToast(ToastType.Error, e.message);
  }
}
