import {
  BasicModal,
  Button,
  Row as BasicRowType,
  DialogBox,
} from '@components';
import {
  AddressBookModel,
  deleteAddress,
  getFilteredAddressBookData,
  setAddressBookStore,
} from '@store/customers/customerDetails/addressBookTab';
import { Box, Grid, IconButton, Stack, Typography } from '@suid/material';
import {
  ColDef,
  CellClickedEvent,
  GridReadyEvent,
  IServerSideDatasource,
} from '@ag-grid-community/core';
import AgGridSolid, { AgGridSolidRef } from '@ag-grid-community/solid';
import { closeModal, openModal } from '@store/modals';
import { createEffect, createSignal } from 'solid-js';
import { Delete, PlaylistAddCheck } from '@suid/icons-material';
import { dateFormatter } from '@store/loadboard';
import { dateComparator } from '@store/loadboard/utils';
import { openDialogBox } from '@store/dialogBox';
import { LTLCatalogs } from '@store/orders';

import { addressBookGridStyle as classes } from './classes';
import AddOrEditAddressBook from './AddOrEditAddressBook';
import {
  AddressColumn,
  agGridCellStyle,
  DefaultOrigin,
} from './AddressBookTab';

export type AddressBookGridProps = {
  onRowClick?: (rowData: BasicRowType) => void;
  customerId?: number;
};

export const [defaultOriginItem, setDefaultOriginItem] = createSignal<
  AddressBookModel[]
>([]);

const AddressBookGrid = (props: AddressBookGridProps) => {
  const [gridRef, setGridRef] = createSignal<AgGridSolidRef | null>(null);
  const [selectedRow, setSelectedRow] = createSignal<AddressBookModel | null>(
    null,
  );
  const [edit, setEdit] = createSignal(false);
  const [triggerAddUpdateFn, setTriggerAddUpdateFn] = createSignal<
    'add' | 'update' | 'delete' | 'none'
  >('none');

  const addModalId = 'addOrEditAddressBook';

  const [isDeleteLoading, setIsDeleteLoading] = createSignal(false);

  const commonFilterParams = {
    suppressAndOrCondition: true,
    buttons: ['apply', 'reset'],
    filterOptions: ['startsWith'],
  };

  const columnDefs: ColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      width: 220,
      filter: 'agTextColumnFilter',
      filterParams: commonFilterParams,
      floatingFilter: true,
      cellRenderer: (params: { data: AddressBookModel }) => {
        return <DefaultOrigin {...params.data} />;
      },
      autoHeight: true,
      cellStyle: agGridCellStyle,
      sort: 'asc',
    },
    {
      field: 'addressLine1',
      headerName: 'Address',
      filter: 'agTextColumnFilter',
      filterParams: commonFilterParams,
      cellRenderer: (params: { data: AddressBookModel }) => {
        return (
          <AddressColumn
            val1={params.data.addressLine1}
            val2={params.data.addressLine2}
          />
        );
      },
      autoHeight: true,
      cellStyle: agGridCellStyle,
    },
    {
      field: 'city',
      headerName: 'City',
      filter: 'agTextColumnFilter',
      filterParams: commonFilterParams,
      autoHeight: true,
      cellStyle: agGridCellStyle,
    },
    {
      field: 'state',
      headerName: 'State',
      filter: 'agTextColumnFilter',
      filterParams: commonFilterParams,
      maxWidth: 100,
    },
    {
      field: 'contactName',
      headerName: 'Contact',
      filter: 'agTextColumnFilter',
      filterParams: commonFilterParams,
      cellRenderer: (params: { data: AddressBookModel }) => {
        return (
          <AddressColumn
            val1={params.data.contactName}
            val2={params.data.contactPhone}
          />
        );
      },
      autoHeight: true,
      cellStyle: agGridCellStyle,
    },
    {
      field: 'createdOn',
      headerName: 'Created',
      filter: 'agDateColumnFilter',
      valueFormatter: dateFormatter,
      filterParams: {
        comparator: dateComparator,
        buttons: ['apply', 'reset'],
        suppressAndOrCondition: true,
        filterOptions: ['startsWith'],
      },
      autoHeight: true,
      cellStyle: agGridCellStyle,
    },
    {
      field: 'specialInstructions',
      headerName: 'Location Instructions',
      autoHeight: true,
      cellStyle: agGridCellStyle,
      floatingFilter: false,
    },
    {
      field: 'delete',
      filter: false,
      sortable: false,
      cellRenderer: (params: { data: AddressBookModel }) => {
        return (
          <IconButton
            onClick={() => {
              setSelectedRow(params.data);
              openDialogBox('deleteAddressItem');
            }}
            disabled={isDeleteLoading()}
          >
            <Delete class="text-red-700" />
          </IconButton>
        );
      },
      pinned: 'right',
      maxWidth: 100,
    },
  ];

  const onGridReady = (params: GridReadyEvent) => {
    setGridRef(params);
    const dataSource = {
      getRows: async (params: {
        api: {
          paginationGetCurrentPage: () => number;
        };
        request: {
          filterModel: {
            [key: string]: {
              filter?: string;
              dateFrom?: string | null;
              dateTo?: null;
              filterType: 'date' | 'text' | 'set';
              type: string;
              values: string[];
            };
          };
          sortModel: {
            colId: string;
            sort: string;
          }[];
        };
        success: (data: {
          rowData: AddressBookModel[];
          rowCount: number;
        }) => void;
        fail: () => void;
      }) => {
        let orderBy = '';
        let sortBy = '';
        if (params.request.sortModel.length > 0) {
          orderBy = params.request.sortModel[0].colId;
          sortBy = params.request.sortModel[0].sort;
        }
        const filteredObject = {
          name: '',
          addressLine1: '',
          addressLine2: '',
          city: '',
          contactName: '',
          country: '',
          email: '',
          phone: '',
          state: '',
          zip: '',
          createdOn: '',
          specialInstructions: '',
          internalNote: '',
          orderBy,
          sortBy,
          ...Object.fromEntries(
            Object.entries(params.request.filterModel).map(([key, value]) => [
              key,
              key === 'createdOn' ? value.dateFrom : value.filter,
            ]),
          ),
          customerId: props.customerId,
          pageNumber: params.api.paginationGetCurrentPage() + 1,
          pageSize: 50,
        };

        try {
          gridRef()?.api.showLoadingOverlay();
          if (filteredObject.customerId === undefined) {
            return;
          }
          const data = await getFilteredAddressBookData(filteredObject);
          setDefaultOriginItem(data.addresses);
          params.success({
            rowData: data.addresses,
            rowCount: data.totalCount,
          });
        } catch (error) {
          params.fail();
        } finally {
          gridRef()?.api.hideOverlay();
        }
      },
    };
    params.api.setGridOption(
      'serverSideDatasource',
      dataSource as unknown as IServerSideDatasource,
    );
  };

  const handleDeleteRow = async () => {
    setIsDeleteLoading(true);
    const id = selectedRow()?.id;
    if (Boolean(id)) {
      const res = await deleteAddress(id!);
      if (Boolean(res)) {
        setTriggerAddUpdateFn('delete');
      }
    }
    setIsDeleteLoading(false);
  };

  const onModalClose = () => {
    setEdit(false);
    setSelectedRow(null);
    closeModal(addModalId);
  };

  const updateRowAtIndex = () => {
    const rowNode = gridRef()?.api.getRowNode(selectedRow()?.id as string);
    if (rowNode) {
      rowNode.setData(selectedRow() as AddressBookModel);
      gridRef()?.api.refreshCells({ rowNodes: [rowNode] });
      if (gridRef()) {
        onGridReady(gridRef() as unknown as GridReadyEvent);
      }
    }
  };

  function addRow() {
    gridRef()?.api.applyServerSideTransaction({ add: [selectedRow()] });
    if (gridRef()) {
      onGridReady(gridRef() as unknown as GridReadyEvent);
    }
  }

  function removeRow() {
    const rowNode = gridRef()?.api.getRowNode(selectedRow()?.id as string);
    if (rowNode) {
      setDefaultOriginItem((prev) =>
        prev.filter((item) => item.id !== selectedRow()?.id),
      );
      gridRef()?.api.applyServerSideTransaction({ remove: [rowNode.data] });
    }
  }

  createEffect(() => {
    const triggerAction = triggerAddUpdateFn();
    if (triggerAction === 'update' && edit()) {
      updateRowAtIndex();
    } else if (triggerAction === 'add' && !edit()) {
      addRow();
    } else if (triggerAction === 'delete') {
      removeRow();
    }
    setTriggerAddUpdateFn('none');
  });

  return (
    <Stack class={classes.stackStyle}>
      <Box class={classes.addBtnBox}>
        <Button
          label={<div class={classes.addBtn}>Add Address</div>}
          variant="contained"
          size="small"
          onClick={() => {
            setEdit(false);
            setAddressBookStore('addOrEditErrorMessage', '');
            setAddressBookStore('selectedAddress', null);
            openModal(addModalId);
          }}
        />
        <Box class="ml-4 flex gap-2 justify-between items-center">
          <Typography class="!text-xs !font-semibold">Legend</Typography>
          <PlaylistAddCheck class="text-[#15668f]" />
          <Typography class="!text-xs">Default Origin</Typography>
        </Box>
      </Box>
      <Grid class={classes.gridStyle}>
        <AgGridSolid
          ref={gridRef}
          columnDefs={columnDefs}
          overlayNoRowsTemplate="<span class='ag-overlay-loading-center'>Failed to fetch data from API</span>"
          gridOptions={{
            defaultColDef: {
              flex: 1,
              filter: false,
              floatingFilter: true,
              sortable: true,
            },
            onGridReady: onGridReady,
            paginationPageSizeSelector: false,
            rowHeight: 40,
            rowModelType: 'serverSide',
            getRowId: (params: { data: AddressBookModel }) =>
              params.data.id as string,
            pagination: true,
            paginationPageSize: 50,
            cacheBlockSize: 50,
            sideBar: {
              toolPanels: [
                {
                  id: 'columns',
                  labelDefault: 'Columns',
                  labelKey: 'columns',
                  iconKey: 'columns',
                  toolPanel: 'agColumnsToolPanel',
                },
                {
                  id: 'filters',
                  labelDefault: 'Filters',
                  labelKey: 'filters',
                  iconKey: 'filter',
                  toolPanel: 'agFiltersToolPanel',
                },
              ],
              defaultToolPanel: '',
            },
          }}
          onCellClicked={(e: CellClickedEvent) => {
            if (props.onRowClick) return;
            if (e.data != null && e.colDef.field !== 'delete') {
              setSelectedRow(e.data as AddressBookModel);
              setEdit(true);
              setAddressBookStore('addOrEditErrorMessage', '');
              openModal(addModalId);
            }
          }}
          onRowClicked={(params: { data: LTLCatalogs }) => {
            props.onRowClick && props.onRowClick(params.data);
          }}
          pagination
        />
      </Grid>
      <BasicModal
        id={addModalId}
        title="Add/Edit Address"
        width="800px"
        footer={false}
        onClose={onModalClose}
      >
        <AddOrEditAddressBook
          gridRef={gridRef}
          customerId={props.customerId}
          edit={edit}
          setEdit={setEdit}
          selectedRow={selectedRow}
          handleClose={onModalClose}
          setSelectedRow={setSelectedRow}
          triggerAddUpdateFn={triggerAddUpdateFn}
          setTriggerAddUpdateFn={setTriggerAddUpdateFn}
        />
      </BasicModal>
      <DialogBox
        id="deleteAddressItem"
        title={`Are you sure you want to delete this following address/stop: ${selectedRow()
          ?.name} ?`}
        onSubmit={async () => {
          await handleDeleteRow();
        }}
        onSubmitText="Delete"
      />
    </Stack>
  );
};

export default AddressBookGrid;
