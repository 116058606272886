import {
  BasicTable,
  DatePicker,
  TableRowErrors,
  ToastType,
  Typography,
} from '@components';
import { Column } from '@components/BasicTable/BasicTable';
import { DialogBox } from '@components/DialogBox';
import DisabledTextField from '@components/TextField/DisabledTextField';
import { CheckboxInput, SelectField, TextInput } from '@components/forms';
import { openDialogBox } from '@store/dialogBox';
import {
  IAdjustment,
  orderStore,
  updateOrderState,
  validateFieldForOrder,
} from '@store/orders';
import { AddCircleOutlineOutlined, Delete } from '@suid/icons-material';
import { Grid, IconButton, TableCell, TableRow } from '@suid/material';
import { SelectChangeEvent } from '@suid/material/Select';
import {
  formatAmount,
  handleToast,
  isAdmin,
  isSuperAdmin,
  orderTableErrors,
} from '@utils/utils';
import { adjustmentsOptions } from '@views/order/constants';
import {
  refundAdjustmentError,
  retractionAdjustmentError,
} from '@views/order/validations/helperFunctions';
import { Accessor, Index, Show, createSignal } from 'solid-js';

import { removeTableRow } from '../../carrier/stops/stopsEditor/utilFunctions';
import classes from './classes';
import { AdjustmentErrors } from './components';

export const Adjustment = () => {
  const [deleteIndex, setDeleteIndex] = createSignal<number | null>(null);

  const columns: Column<IAdjustment>[] = [
    {
      name: 'Description',
    },
    {
      name: 'Delinquent',
    },
    {
      name: 'Settled Date',
    },
    {
      name: 'Amount',
    },
    {
      name: '',
    },
  ];

  const addRow = () => {
    const adjustments = [...orderStore.order.adjustments];
    adjustments.push({
      operationType: 'Insert',
      id: 0,
      orderId: orderStore.order.id,
      amount: 0,
      description: '',
      settledDate: null,
      retraction: null,
      order: null,
    });
    updateOrderState({ adjustments: adjustments });
  };

  const onAdjustmentChange = (
    key: string,
    value: string | number,
    index: number,
  ) => {
    const adjustments = [...orderStore.order.adjustments];
    if (adjustments[index].operationType === 'None') {
      adjustments[index] = {
        ...adjustments[index],
        [key]: value,
        operationType: 'Update',
      };
    } else {
      adjustments[index] = { ...adjustments[index], [key]: value };
    }
    updateOrderState({ adjustments: adjustments });
  };

  const footerRow = [
    {
      renderCell: () => (
        <IconButton onClick={addRow} disabled={orderStore.isReadOnly}>
          <AddCircleOutlineOutlined class={classes.addIcon} fontSize="small" />
        </IconButton>
      ),
      key: 'ideaTitle',
    },
    {
      key: '#',
    },
    {
      renderCell: () => (
        <Typography variant="body2" class={classes.totalLabel}>
          Total
        </Typography>
      ),
      key: '#',
    },
    {
      renderCell: () => (
        <Typography variant="body2" class={classes.totalAmount}>
          {formatAmount(
            orderStore.order.adjustments.reduce(
              (acc, curr) => Number(acc) + Number(curr.amount),
              0,
            ),
          )}
        </Typography>
      ),
      key: 'uploadDoc',
    },
    {
      key: '#',
    },
  ];

  const cellStyles = {
    padding: '1px 1px',
    borderBottom: '0px !important',
  };

  const marginTopStyles = { marginTop: '16px' };

  const allowToSelectRetractionOrRefund = (value: number) => {
    const adjustments = orderStore.order.adjustments.filter(
      (x) => x.operationType != 'Delete',
    );
    const selectedElementCount = adjustments.filter(
      (x) => x.retraction == value,
    ).length;

    switch (value) {
      case 1: // 90 Days retraction
        // check to see if 90 days retraction is selected more than 1 time
        const count3 = adjustments.filter((x) => x.retraction === 3).length;
        if (retractionAdjustmentError(selectedElementCount, count3)) {
          handleToast(
            ToastType.Error,
            '90 Days Retraction can not be performed as action already exists and no corresponding refund is created for it.',
          );
        }
        return;
      case 2: // 120 Days retraction
        // check to see if 120 days retraction is selected more than 1 time
        // get the refund count for 120 day refund
        const count2 = adjustments.filter((x) => x.retraction === 5).length;
        if (retractionAdjustmentError(selectedElementCount, count2))
          if (selectedElementCount > 1) {
            handleToast(
              ToastType.Error,
              '120 Days Retraction can not be performed as action already exists and no corresponding refund is created for it.',
            );
          }
        return;
      case 3: // 90 Days refund
        // check to see if  1 90 day retraction is there
        const count = adjustments.filter((x) => x.retraction === 1).length;
        if (refundAdjustmentError(count, selectedElementCount)) {
          handleToast(
            ToastType.Error,
            '90 Days Refund can not be performed as corresponding retraction is not present.',
          );
        }
        return;
      case 5: // 120 Days refund
        // check to see if  1 120 day retraction is there
        const count1 = adjustments.filter((x) => x.retraction === 2).length;
        if (refundAdjustmentError(count1, selectedElementCount)) {
          handleToast(
            ToastType.Error,
            '120 Days Refund can not be performed as corresponding retraction is not present.',
          );
        }
    }
  };

  const canAdjust = () => {
    if (isAdmin() || isSuperAdmin()) return true;
    return false;
  };

  return (
    <Grid container>
      <AdjustmentErrors />
      <Grid item xs={12}>
        <BasicTable
          footerRow={isSuperAdmin() ? footerRow : undefined}
          cellClasses="!px-[1px] !py-[1px] !text-xs"
          columns={columns}
          children={
            <Index each={orderStore.order.adjustments}>
              {(item: Accessor<IAdjustment>, index) => (
                <Show when={item().operationType !== 'Delete'}>
                  <TableRow class={classes.tableRow}>
                    <TableCell sx={cellStyles}>
                      <TextInput
                        value={item().description}
                        onChange={(value: string) => {
                          onAdjustmentChange('description', value, index);
                        }}
                        classes={'bg-white rounded-md'}
                        label=""
                        placeholder="Description"
                        error={
                          orderStore.orderFormError &&
                          orderStore.orderFormError[
                            `adjustments[${index}].description`
                          ]
                        }
                        onBlur={() =>
                          validateFieldForOrder(
                            `adjustments.${index}.description`,
                            `adjustments[${index}].description`,
                          )
                        }
                        noErrorMessage
                        disabled={
                          !canAdjust() ||
                          item().settledDate !== null ||
                          orderStore.isReadOnly
                        }
                        size="small"
                      />
                    </TableCell>
                    <TableCell sx={{ ...cellStyles, minWidth: '80px' }}>
                      <SelectField
                        menuItemGrow
                        label=""
                        value={item().retraction}
                        menuItems={adjustmentsOptions}
                        onChange={(e: SelectChangeEvent) => {
                          onAdjustmentChange(
                            'retraction',
                            e.target.value,
                            index,
                          );
                          allowToSelectRetractionOrRefund(
                            Number(e.target.value),
                          );
                        }}
                        disabled={
                          !canAdjust() ||
                          item().settledDate !== null ||
                          orderStore.isReadOnly
                        }
                        size="small"
                      />
                    </TableCell>
                    <TableCell sx={cellStyles}>
                      <DisabledTextField
                        value={item().settledDate?.toString() ?? ''}
                      />
                    </TableCell>
                    <TableCell
                      sx={{ ...cellStyles, minWidth: '90px', maxWidth: '90px' }}
                    >
                      <TextInput
                        label=""
                        type="number"
                        placeholder="Amount"
                        value={item().amount || ''}
                        onChange={async (value) => {
                          onAdjustmentChange('amount', value as number, index);
                          await validateFieldForOrder(
                            `adjustments[${index}].amount`,
                            'adjustments[' + index + '].amount',
                          );
                        }}
                        classes={'bg-white rounded-md'}
                        variant="outlined"
                        error={
                          orderStore.orderFormError &&
                          orderStore.orderFormError[
                            `adjustments[${index}].amount`
                          ]
                        }
                        onBlur={() =>
                          validateFieldForOrder(
                            `adjustments.${index}.amount`,
                            `adjustments[${index}].amount`,
                          )
                        }
                        noErrorMessage
                        disabled={
                          !canAdjust() ||
                          item().settledDate !== null ||
                          orderStore.isReadOnly
                        }
                        size="small"
                      />
                    </TableCell>
                    <TableCell sx={{ padding: '0px', paddingRight: '5px' }}>
                      <IconButton
                        onClick={() => {
                          openDialogBox('deleteAdjustmentRow');
                          setDeleteIndex(index);
                        }}
                        disabled={orderStore.isReadOnly}
                      >
                        <Delete class={classes.deleteIcon} fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  <TableRowErrors
                    columnsLength={columns.length}
                    tableErrors={orderTableErrors(
                      ['rate', 'quantity', 'type'],
                      'adjustments[{index}].{field}',
                      index,
                    )}
                  />
                </Show>
              )}
            </Index>
          }
          tableBodyClasses={classes.tableBg}
          tableFooterClasses={classes.tableFooter}
          tableBodyRowClasses={classes.tableFooterRow}
          tableFooterRowClasses={classes.tableFooterRow}
        />
      </Grid>
      <Grid item xs={12} container sx={marginTopStyles}>
        <Grid xs={6}>
          <CheckboxInput
            label="Hold Retraction"
            checked={orderStore.order.holdRetraction}
            onChange={(value) => updateOrderState({ holdRetraction: value })}
            disabled={!isSuperAdmin()}
          />
        </Grid>
        <Grid xs={6}>
          <DatePicker
            label="Revisit Retraction Hold Date"
            value={orderStore.order.revisitRetractionDate ?? ''}
            handleChange={async (value) => {
              updateOrderState({ revisitRetractionDate: value });
              await validateFieldForOrder(
                'revisitRetractionDate',
                'revisitRetractionDate',
              );
            }}
            error={orderStore.orderFormError?.revisitRetractionDate}
            disabled={!isSuperAdmin()}
            size="small"
          />
        </Grid>
      </Grid>
      <Grid xs={12} sx={marginTopStyles}>
        <TextInput
          value={orderStore.order.exceptionReason}
          label={'Exception Reason'}
          maxLength={500}
          onChange={async (value) => {
            updateOrderState({ exceptionReason: value as string });
            await validateFieldForOrder('exceptionReason', 'exceptionReason');
          }}
          multiline
          placeholder="This is a note"
          variant="outlined"
          error={
            orderStore.orderFormError &&
            orderStore.orderFormError['exceptionReason']
          }
          disabled={!isSuperAdmin()}
          size="small"
        />
      </Grid>
      <DialogBox
        id="deleteAdjustmentRow"
        title={'Are you sure you want to delete this adjustment?'}
        onSubmitText="Delete"
        onSubmit={() => {
          removeTableRow(
            deleteIndex()!,
            orderStore.order,
            'order',
            'adjustments',
          );
          setDeleteIndex(null);
        }}
      />
    </Grid>
  );
};
