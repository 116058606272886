export const featureFlags = {
  readFromNewLtlTables: 'ltl_VNEXT-2856_TableRefactor_ReadFromNew',
  readFromNewLtlTablesSavedQuotes: 'ReadFromNewLtlTablesSavedQuotes',
  policySharedCapacity: 'PolicySharedCapacity',
  writeToOldLtlTables: 'ltl_VNEXT-2856_TableRefactor_WriteToOld',
  trackingFrequency: 'TrackingFrequency',
  stopMileage: 'StopMileage',
  carrierContactOwnershipSource: 'CarrierContactOwnershipSource',
  useShortQuoteGroupLocator: 'UseShortQuoteGroupLocator',
  falvey: 'Falvey',
} as const;
