import { EditHistoryGrid } from '@components/Grid/EditHistoryGrid';
import { Layout } from '@layouts/index';
import { useParams } from '@solidjs/router';
import { Box } from '@suid/material';
import { Notification } from '@components/Notification';

export const EditHistory = () => {
  const params = useParams();
  const orderId = params.id;
  return (
    <Layout>
      <Notification
        type="warning"
        text="Edits may take up to 15 minutes to appear. Please wait for the table to update."
      />
      <Box class="!rounded-md h-[100%] p-4" bgcolor="#E1ECF2">
        <EditHistoryGrid orderId={orderId} />
      </Box>
    </Layout>
  );
};
