import { TextMatcherParams, ValueFormatterFunc } from '@ag-grid-community/core';
import { Box } from '@suid/material';
import { formatPhoneNumber } from '@utils/phoneNumberFormat';
import { DateTime } from 'luxon';

export const dateComparator = (
  date1: Date | string,
  date2: Date | string,
): number => {
  const dateValue1 = date1 instanceof Date ? date1 : new Date(date1);
  const dateValue2 = date2 instanceof Date ? date2 : new Date(date2);

  if (isNaN(dateValue1.getTime()) || isNaN(dateValue2.getTime())) {
    return 0;
  }

  const normalizedDate1 = new Date(
    dateValue1.getFullYear(),
    dateValue1.getMonth(),
    dateValue1.getDate(),
  );

  const normalizedDate2 = new Date(
    dateValue2.getFullYear(),
    dateValue2.getMonth(),
    dateValue2.getDate(),
  );
  if (normalizedDate2 < normalizedDate1) {
    return -1;
  } else if (normalizedDate2 > normalizedDate1) {
    return 1;
  }
  return 0;
};

/**
 * This function converts the column value (which is a date) into a string of
 * `HH:mm` format, so that we can freely match against the time.
 * @param params This contains things like the filter text, value, & grid api.
 * @returns The matched value or `null`.
 */
export const hourAndMinuteMatcherFromText = (
  params: TextMatcherParams,
): string | null => {
  if (!Boolean(params.filterText)) {
    return params.value as string;
  }

  // The filter text could contain trailing whitespaces. So we remove them here.
  // If we don't someone could enter "  13:45" and it would not match.
  const filterText = (params.filterText as string).trim();

  // The date format could be anything, but it will always be supported by JS.
  // I didn't want to add any complex logic in here, so I parse as a JS date &
  // then I parse in Luxon.
  const date = new Date(params.value as string);
  const lDate = DateTime.fromJSDate(date);
  const time = lDate.toFormat('HH:mm');

  if (time.includes(filterText)) {
    return params.value as string;
  }

  return null;
};

export const dateFormatter: ValueFormatterFunc<unknown, Date> = (param) => {
  let date = param.value;

  if (date === undefined || date === null) {
    return '';
  }

  date = new Date(date);

  if (date.toString() === 'Invalid Date') {
    return '';
  }

  return `${DateTime.fromJSDate(date).toFormat('MM/dd/yyyy')}`;
};

export const fullDateFormatter: ValueFormatterFunc<unknown, Date> = (param) => {
  let date = param.value;

  if (date === undefined || date === null) {
    return '';
  }

  date = new Date(date);

  if (date.toString() === 'Invalid Date') {
    return '';
  }

  return `${DateTime.fromJSDate(date).toFormat('MM/dd/yyyy HH:mm')}`;
};

export const underlinedCellRenderer = (params: {
  value: string | Date | undefined;
}) => {
  const div = document.createElement('u');
  div.style.cursor = 'pointer';

  if (params.value instanceof Date) {
    // @ts-expect-error - The error here doesn't really matter.
    div.innerHTML = dateFormatter(params);
  } else {
    div.innerHTML = params.value ?? '';
  }

  return div;
};

export const shortDateRenderer = (params: { value: string }) => {
  const date = DateTime.fromISO(params.value, {
    zone: 'Etc/UTC',
  })
    .toLocal()
    .toFormat('MM/dd/yyyy');

  return <Box>{date}</Box>;
};

export const timeRenderer = (params: { value: string }) => {
  const date = DateTime.fromISO(params.value, { zone: 'Etc/UTC' })
    .toLocal()
    .toFormat('t');

  return <Box>{date}</Box>;
};

export const dateFormatterDayYear: ValueFormatterFunc<unknown, Date> = (
  param,
) => {
  let date = param.value;
  if (date === undefined || date === null) {
    return '';
  }
  date = new Date(date);
  if (date.toString() === 'Invalid Date') {
    return '';
  }
  return `${DateTime.fromJSDate(date).toLocal().toFormat('ccc, LLL dd, yyyy')}`;
};

// Last Check is already in UTC
export const lastCheckDateFormatter: ValueFormatterFunc<unknown, Date> = ({
  value,
}) => {
  if (!value) return '';
  const date = new Date(value);
  if (date.toString() === 'Invalid Date') return '';
  return `${DateTime.fromJSDate(date).toFormat('MM/dd/yyyy HH:mm')}`;
};

export const fullDateFormatterYear: ValueFormatterFunc<unknown, Date> = (
  param,
) => {
  let date = param.value;

  if (date === undefined || date === null) {
    return '';
  }

  date = new Date(date);

  if (date.toString() === 'Invalid Date') {
    return '';
  }

  return `${DateTime.fromJSDate(date).toLocal().toFormat('MM/dd/yyyy - T')}`;
};

export const fullDateFormatterYearNoTime: ValueFormatterFunc<unknown, Date> = (
  param,
) => {
  let date = param.value;

  if (date === undefined || date === null) {
    return '';
  }

  date = new Date(date);

  if (date.toString() === 'Invalid Date') {
    return '';
  }

  return `${DateTime.fromJSDate(date).toLocal().toFormat('MM/dd/yyyy')}`;
};

export const currencyFormatter = (amount: number, currency = 'USD') => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  });

  return formatter.format(amount);
};

export const currencyCellFormatter: ValueFormatterFunc<unknown, number> = (
  param,
) => {
  const amount = param.value;

  if (amount === undefined || amount === null) {
    return '';
  }

  return currencyFormatter(amount);
};

export const weightValueCellFormatter: ValueFormatterFunc<unknown, number> = (
  param,
) => {
  const weight = parseFloat(param.value);

  if (isNaN(weight)) {
    return '';
  }

  return weight.toFixed(3);
};

export const phoneNumberCellFormatter: ValueFormatterFunc<unknown, string> = (
  param,
) => {
  const number = param.value;

  if (number === undefined || number === null) {
    return '';
  }

  return formatPhoneNumber(number);
};

export const dateFormatterTime: ValueFormatterFunc<unknown, Date> = (param) => {
  let date = param.value;
  if (date === undefined || date === null) {
    return '';
  }
  date = new Date(date);
  if (date.toString() === 'Invalid Date') {
    return '';
  }
  return `${DateTime.fromJSDate(date).toLocal().toFormat('HH:mm')}`;
};

export const dateFormatterDayYearTime: ValueFormatterFunc<unknown, Date> = (
  param,
) => {
  let date = param.value;
  if (date === undefined || date === null) {
    return '';
  }
  date = new Date(date);
  if (date.toString() === 'Invalid Date') {
    return '';
  }
  return `${DateTime.fromJSDate(date)
    .toLocal()
    .toFormat('ccc, LLL dd, yyyy h:mm a')}`;
};

export const timeAgoFormatter = (params: { value: string }) => {
  const date = params.value;
  if (!date) {
    return '';
  }

  const parsedDate = DateTime.fromJSDate(new Date(date));

  if (!parsedDate.isValid) {
    // If the date is invalid, return an empty string
    return '';
  }

  return parsedDate.toRelative();
};

export const compareDates = (
  valueA: string | null,
  valueB: string | null,
): number => {
  if (!Boolean(valueA) && !Boolean(valueB)) return 0;
  if (!Boolean(valueA)) return -1;
  if (!Boolean(valueB)) return 1;

  const dateA = DateTime.fromFormat(valueA!, 'MM/dd/yyyy');
  const dateB = DateTime.fromFormat(valueB!, 'MM/dd/yyyy');

  if (!dateA.isValid || !dateB.isValid) return 0;

  if (dateA < dateB) return -1;
  if (dateA > dateB) return 1;
  return 0;
};
