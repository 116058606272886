import { BillingHold, BillingNote } from '@assets';
import { Button, Notification, ToastType, Typography } from '@components';
import { TextInput } from '@components/forms';
import { closeModal } from '@store/modals';
import {
  FlagType,
  deleteFlag,
  orderStore,
  updateFlag,
  updateOrderState,
} from '@store/orders';
import { Box, Grid } from '@suid/material';
import { handleToast } from '@utils/utils';
import { EDIT_BILLING_HOLD_AND_NOTE } from '@views/order/constants';
import { Component, Show, createSignal } from 'solid-js';
import { FlagViewModel } from '@typeDefinitions/flagTypes';

import BillNoteFlagComments from './BillNoteFlagComments';
import { editBillingModalClasses as cls } from './classes';

export type EditBillingHoldAndNotesModalProps = {
  modalId: string;
  item: FlagViewModel;
  index: number;
};

const EditBillingHoldAndNotesModal: Component<
  EditBillingHoldAndNotesModalProps
> = (props: EditBillingHoldAndNotesModalProps) => {
  const flagId = props.item.id;
  const isBillingHold = props.item.flagType === FlagType.BillingHold;

  const [description, setDescription] = createSignal(
    props.item.description ?? '',
  );

  const [deleteLoading, setDeleteLoading] = createSignal(false);
  const [updateLoading, setUpdateLoading] = createSignal(false);

  const onClose = () => closeModal(props.modalId);
  const finalize = () => {
    closeModal(props.modalId);
    setUpdateLoading(false);
    setDeleteLoading(false);
  };

  const deleteOrCloseBillingHoldAndNote = async () => {
    try {
      setDeleteLoading(true);
      await deleteFlag(flagId);
      handleToast(ToastType.Success, 'Flag Removed Succesfully');

      const newFlags = orderStore.order.flags?.filter(
        (flag) => flag.id !== flagId,
      );

      updateOrderState({
        ...orderStore.order,
        flags: newFlags,
      });

      closeModal(EDIT_BILLING_HOLD_AND_NOTE);
    } catch (error) {
      handleToast(ToastType.Error, 'Failed to delete flag');
    } finally {
      finalize();
    }
  };

  const updateBillingHoldAndNote = async () => {
    try {
      setUpdateLoading(true);
      const payload: FlagViewModel = {
        ...props.item,
        description: description(),
      };
      const response = await updateFlag(payload);

      const updatedFlags = orderStore.order.flags
        ? [...orderStore.order.flags]
        : [];

      updatedFlags[props.index] = response as unknown as FlagViewModel;

      updateOrderState({
        ...orderStore.order,
        flags: updatedFlags,
      });
      handleToast(ToastType.Success, 'Flag Updated Succesfully');
    } catch (error) {
      handleToast(ToastType.Error, 'Failed to update flag');
    } finally {
      finalize();
    }
  };

  const renderData = {
    modalTitle: isBillingHold ? 'Edit Billing Hold' : 'Edit Billing Note',
    cancelButtonText: isBillingHold ? 'Remove Flag' : 'Close Flag',
    labelText: isBillingHold ? 'Billing Hold' : 'Billing Note',
    imgSource: isBillingHold ? BillingHold : BillingNote,
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} px={1} pb={2} alignItems="center">
        <Grid item xs={11}>
          <Typography class={cls.modalTitle} variant="h6" component="h2">
            {renderData.modalTitle}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Show when={isBillingHold}>
          <Grid item xs={12}>
            <Notification
              type="info"
              text="Prevent Billing from processing this load until this hold is released."
            />
          </Grid>
        </Show>
        <Grid item xs={12} sm={10}>
          <Grid container spacing={2.5}>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                component="p"
                class={cls.subLabelText}
              >
                Queue
              </Typography>
              <Typography variant="body1" component="p">
                {renderData.labelText}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextInput
                multiline
                label="Description"
                placeholder="Enter description here"
                rows={2}
                value={description()}
                onChange={(value) => setDescription(value as string)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={2}
          displayRaw="flex"
          alignItems="center"
          justifyContent="center"
        >
          <img src={renderData.imgSource} class="h-20" alt="Billing Note" />
        </Grid>
        <Show when={!isBillingHold}>
          <BillNoteFlagComments flagId={flagId} />
        </Show>
      </Grid>
      <Grid container justifyContent="flex-end" gap={2} pt={4}>
        <Button
          variant="contained"
          label="Update Flag"
          onClick={updateBillingHoldAndNote}
          disabled={updateLoading() || deleteLoading()}
          isLoading={updateLoading()}
          class={cls.footerBtns}
        />
        <Button
          variant="contained"
          class={`${cls.footerBtns} !bg-[#d32f2f]`}
          label={renderData.cancelButtonText}
          disabled={deleteLoading() || updateLoading()}
          isLoading={deleteLoading()}
          onClick={deleteOrCloseBillingHoldAndNote}
        />
        <Button
          variant="text"
          label="Cancel"
          onClick={onClose}
          class={cls.footerBtns}
        />
      </Grid>
    </Box>
  );
};

export default EditBillingHoldAndNotesModal;
