import { Button, TimePicker, DatePicker } from '@components';
import { PhoneInput, SelectField, TextInput } from '@components/forms';
import {
  orderStore,
  PickUpDropOffItem,
  updateLoadPropertyAtIndex,
} from '@store/orders';
import { Card, Stack, TableCell, TableRow } from '@suid/material';
import { convertDateTimeV2 } from '@utils/utils';
import { Component } from 'solid-js';
import CheckBoxOutlinedIcon from '@suid/icons-material/CheckBoxOutlined';
import { SelectChangeEvent } from '@suid/material/Select';
import { LastUsedTopStopModel } from '@typeDefinitions/stopTypes';

import EDICompareChangeItems from './EDICompareChangeItems';

type StopEditSectionProps = {
  colspan: number;
  stop: LastUsedTopStopModel;
  noUpdate: boolean;
  items: PickUpDropOffItem[];
  index: number;
};

type UpdateButtonProps = {
  onClick: () => void;
  classes: string;
  noUpdate: boolean;
};

const StopEditSection: Component<StopEditSectionProps> = (props) => {
  const handleStopFieldChanges = (
    field: keyof LastUsedTopStopModel,
    value: string,
  ) => {
    const updateStops = orderStore.order.loads[0].stops?.map((stop) => {
      if (stop.id === props.stop.id) {
        return { ...stop, [field]: value };
      }
      return stop;
    });
    updateLoadPropertyAtIndex(
      {
        stops: updateStops,
      },
      0,
    );
  };

  const handleUpdateButtonClick = (
    values: Partial<LastUsedTopStopModel>,
    idx: number,
  ) => {
    const stops =
      orderStore.order.loads[0].stops?.filter(
        (itm) => itm.operationType !== 'Delete',
      ) ?? [];

    if (stops.length > 0) {
      const updatedStops = orderStore.order.loads[0].stops?.map((stop) => {
        if (stop.id === stops[idx].id) {
          return { ...stop, ...values };
        }
        return stop;
      });
      updateLoadPropertyAtIndex(
        {
          stops: updatedStops,
        },
        0,
      );
    }
  };

  const handleDateTimeChange = (date: string, time: string) => {
    const dateTime = convertDateTimeV2(date, time);
    handleStopFieldChanges('stopDateTime', dateTime);
  };

  const UpdateButton: Component<UpdateButtonProps> = (props) => {
    return !props.noUpdate ? (
      <div class={`${props.classes} text-right mt-auto mb-[2px]`}>
        <Button
          startIcon={<CheckBoxOutlinedIcon class="text-[#ffffff]" />}
          label="UPDATE"
          class="updateRateClass"
          variant="contained"
          sx={{ backgroundColor: '#388E3C' }}
          onClick={props.onClick}
        />
      </div>
    ) : null;
  };

  return (
    <TableRow>
      <TableCell colspan={props.colspan} class="!p-0 !mb-2">
        <Card class="p-4 !border-1 border border-transparent !rounded-tl-none !rounded-tr-none mx-[1px]">
          <div class="flex mb-[24px]">
            <div class="w-[50%]">
              <TextInput
                label="Stop Name"
                required
                placeholder="Stop Name"
                value={props.stop.locationName}
                onChange={(itm) =>
                  handleStopFieldChanges('locationName', itm as string)
                }
                disabled={!props.noUpdate}
              />
            </div>
            <UpdateButton
              onClick={() => {
                handleUpdateButtonClick(
                  {
                    locationName: props.stop.locationName,
                  },
                  props.index,
                );
              }}
              classes="w-[50%]"
              noUpdate={props.noUpdate}
            />
          </div>
          <div class="flex mb-[24px]">
            <Stack spacing={1.5}>
              <TextInput
                label={
                  <span>
                    Address line 1<sup>*</sup>
                  </span>
                }
                placeholder="Enter Address"
                value={props.stop.address1}
                onChange={(itm) =>
                  handleStopFieldChanges('address1', itm as string)
                }
                disabled={!props.noUpdate}
              />
              <TextInput
                label="Address line 2"
                placeholder="Address line 2"
                value={props.stop.address2}
                onChange={(itm) =>
                  handleStopFieldChanges('address2', itm as string)
                }
                disabled={!props.noUpdate}
              />
              <div class="flex">
                <div class="flex w-[70%]">
                  <TextInput
                    label="City"
                    required
                    placeholder="City"
                    value={props.stop.city}
                    onChange={(itm) =>
                      handleStopFieldChanges('city', itm as string)
                    }
                    classes="!mr-2"
                    disabled={!props.noUpdate}
                  />
                  <TextInput
                    label="State"
                    required
                    placeholder="State"
                    value={props.stop.state}
                    onChange={(itm) =>
                      handleStopFieldChanges('state', itm as string)
                    }
                    classes="!mr-2"
                    disabled={!props.noUpdate}
                  />
                  <TextInput
                    label="Zip"
                    required
                    placeholder="Zip"
                    value={props.stop.zip}
                    onChange={(itm) =>
                      handleStopFieldChanges('zip', itm as string)
                    }
                    classes="!mr-2"
                    disabled={!props.noUpdate}
                  />
                  <SelectField
                    label="USA/CAN/MEX"
                    menuItems={[
                      { label: 'USA', value: 'USA' },
                      { label: 'CAN', value: 'CAN' },
                      { label: 'MEX', value: 'MEX' },
                    ]}
                    value={props.stop.countryCode as string}
                    onChange={(e: SelectChangeEvent) =>
                      handleStopFieldChanges('countryCode', e.target.value)
                    }
                    disabled={!props.noUpdate}
                  />
                </div>
                <UpdateButton
                  onClick={() => {
                    handleUpdateButtonClick(
                      {
                        zip: props.stop.zip,
                        state: props.stop.state,
                        countryCode: props.stop.countryCode,
                        address1: props.stop.address1,
                        address2: props.stop.address2,
                        city: props.stop.city,
                      },
                      props.index,
                    );
                  }}
                  classes="w-[30%]"
                  noUpdate={props.noUpdate}
                />
              </div>
            </Stack>
          </div>
          <div class="flex mb-[24px]">
            <div class="flex w-[70%]">
              <div class="w-[50%] pr-4">
                <DatePicker
                  label=""
                  value={props.stop.stopDateTime as string}
                  handleChange={(date) =>
                    handleDateTimeChange(date, props.stop.stopDateTime ?? '')
                  }
                  disabled={!props.noUpdate}
                />
              </div>
              <div class="w-[50%] pr-4">
                <TimePicker
                  label="Time"
                  value={props.stop.stopDateTime as string}
                  onChange={(newTime) => {
                    handleDateTimeChange(
                      props.stop.stopDateTime ?? '',
                      newTime,
                    );
                  }}
                  disabled={!props.noUpdate}
                />
              </div>
            </div>
            <UpdateButton
              onClick={() => {
                handleUpdateButtonClick(
                  {
                    stopDateTime: props.stop.stopDateTime,
                  },
                  props.index,
                );
              }}
              classes="w-[30%]"
              noUpdate={props.noUpdate}
            />
          </div>
          <div class="mb-[24px] flex">
            <div class="w-[80%]">
              <TextInput
                label="Appointment #"
                required
                placeholder=""
                value={props.stop.appointmentNum ?? ''}
                onChange={(itm) =>
                  handleStopFieldChanges('appointmentNum', itm as string)
                }
                disabled={!props.noUpdate}
              />
            </div>
            <UpdateButton
              onClick={() => {
                handleUpdateButtonClick(
                  {
                    appointmentNum: props.stop.appointmentNum,
                  },
                  props.index,
                );
              }}
              classes="w-[20%]"
              noUpdate={props.noUpdate}
            />
          </div>
          <div class="mb-[24px] flex">
            <div class="w-[80%]">
              <TextInput
                label="PickUp #"
                required
                placeholder=""
                value={props.stop.referenceNum ?? ''}
                onChange={(itm) =>
                  handleStopFieldChanges('referenceNum', itm as string)
                }
                disabled={!props.noUpdate}
              />
            </div>
            <UpdateButton
              onClick={() => {
                handleUpdateButtonClick(
                  {
                    referenceNum: props.stop.referenceNum,
                  },
                  props.index,
                );
              }}
              classes="w-[20%]"
              noUpdate={props.noUpdate}
            />
          </div>
          <div class="mb-[24px] flex">
            <div class="w-[80%]">
              <Stack spacing={1.5}>
                <TextInput
                  label={'Name'}
                  placeholder="Name"
                  value={props.stop.contact}
                  onChange={(itm) =>
                    handleStopFieldChanges('contact', itm as string)
                  }
                  disabled={!props.noUpdate}
                />
                <PhoneInput
                  label="Phone"
                  placeholder="Phone Number"
                  value={props.stop.phone ?? ''}
                  onChange={(value) => handleStopFieldChanges('phone', value)}
                  disabled={!props.noUpdate}
                />
                <TextInput
                  label="Email"
                  placeholder="Email"
                  value={props.stop.email ?? ''}
                  onChange={(itm) =>
                    handleStopFieldChanges('email', itm as string)
                  }
                  disabled={!props.noUpdate}
                />
              </Stack>
            </div>
            <UpdateButton
              onClick={() => {
                handleUpdateButtonClick(
                  {
                    email: props.stop.email,
                    phone: props.stop.phone,
                    contact: props.stop.contact,
                  },
                  props.index,
                );
              }}
              classes="w-[20%]"
              noUpdate={props.noUpdate}
            />
          </div>
          <EDICompareChangeItems
            noUpdate={props.noUpdate}
            stop={props.stop}
            items={props.items}
            index={props.index}
          />
        </Card>
      </TableCell>
    </TableRow>
  );
};

export default StopEditSection;
