import { Popover } from '@components';
import { Box, Grid, Typography } from '@suid/material';
import { InfoOutlined } from '@suid/icons-material';
import { FalveyInsuranceQuote, LtlQuoteViewModel } from '@store/orders/types';
import { Show } from 'solid-js';
import { formatAmount } from '@utils/utils';
import { calculateMargin } from '@common/utils';

import ViewCostDetailsPopover from '../ViewCostDetailsPopover';
import { quoteDetailsRowRenderClasses as cls } from './classes';
import { QuoteActionDetails } from './QuoteActionDetails';

export const QuoteCostDetails = (props: {
  quote: LtlQuoteViewModel;
  insuranceQuote?: FalveyInsuranceQuote;
  customerId: number;
  isHistory: boolean;
  isInsuranceRequest: boolean;
}) => {
  const getAgentPriceWithInsurace = (carrierTotal: number) => {
    const insurancePremium = props.insuranceQuote ? carrierTotal : 0;

    return insurancePremium + props.quote.carrierTotal;
  };

  const margin = calculateMargin(
    props.quote.customerTotal ?? 0,
    props.quote.carrierTotal,
  );

  return (
    <Box class={cls.container} p={2}>
      <Grid container spacing={0.5} alignItems="stretch">
        {/* Cost Details Popover & Margin */}
        <Grid item xs={6}>
          <Box class={cls.popoverContainer}>
            <Popover
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              eleName={
                <button class={cls.popoverButton}>
                  <InfoOutlined class="mr-1" /> Cost Details
                </button>
              }
            >
              <ViewCostDetailsPopover data={props.quote} />
            </Popover>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Show when={margin > 0}>
            <Typography variant="body2" align="right">
              Margin: {margin}%
            </Typography>
          </Show>
        </Grid>
        {/* Row 2: Agent Cost (Bold) */}
        <Grid item xs={6}>
          <Typography variant="body2" class={cls.boldText}>
            Agent
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography
            variant="body1"
            align="right"
            data-testid="quoteCostDetails-carrierTotal-quote-{props.quote.id}"
          >
            {formatAmount(getAgentPriceWithInsurace(props.quote.carrierTotal))}
          </Typography>
        </Grid>
        {/* Row 3: Carrier Rate (Indented) */}
        <Grid item xs={6}>
          <Box class={cls.indentedLabel}>
            <Typography variant="body2" class={cls.tightSpacing}>
              Carrier Rate
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" align="right">
            {formatAmount(props.quote.carrierTotal)}
          </Typography>
        </Grid>
        {/* Row 4: Insurance Rate (Indented) */}
        <Show when={props.insuranceQuote}>
          <Grid item xs={6}>
            <Box class={cls.indentedLabel}>
              <Typography variant="body2" class={cls.tightSpacing}>
                Insurance Rate
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" align="right">
              {formatAmount(props.insuranceQuote?.premium ?? 0)}
            </Typography>
          </Grid>
        </Show>
        {/* Row 5: Customer Cost (Bold) */}
        <Grid item xs={6} sx={{ mt: 2 }}>
          <Typography
            variant="body2"
            class={`${cls.boldText} ${cls.tightSpacing}`}
          >
            Customer
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ mt: 2 }}>
          <Typography variant="body1" align="right">
            {formatAmount(props.quote.customerTotal)}
          </Typography>
        </Grid>
        {/* Row 6: Insurance & Fees (Indented) */}
        <Show when={props.insuranceQuote}>
          <Grid item xs={6}>
            <Box class={cls.indentedLabel}>
              <Typography variant="body2" class={cls.tightSpacing}>
                Insurance & Fees
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" align="right">
              {formatAmount(props.quote.carrierTotal)}
            </Typography>
          </Grid>
        </Show>
        <Show when={!props.isHistory}>
          <QuoteActionDetails
            isInsuranceRequest={props.isInsuranceRequest}
            quote={props.quote}
            insuranceQuote={props.insuranceQuote}
            customerId={props.customerId}
          ></QuoteActionDetails>
        </Show>
      </Grid>
    </Box>
  );
};
