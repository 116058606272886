import { GridOptions, GridReadyEvent } from '@ag-grid-community/core';
import AgGridSolid, { AgGridSolidRef } from '@ag-grid-community/solid';
import logo from '@assets/logo.svg';
import { useNavigate } from '@solidjs/router';
import { Stack, Grid as SuiGrid } from '@suid/material';
import { routeOfficeDetails } from '@utils/routeOfficeDetails';

import { officeColumnDefs } from './components/OfficeColumnDef';

type OfficeGridProps = {
  items: Record<string, unknown>[];
  loading?: boolean;
  gridOptions?: GridOptions;
  onGridReady: () => Promise<void>;
};

const imageTag = `<img src=${logo} alt="" />`;

export const OfficeGrid = (props: OfficeGridProps) => {
  let gridRef: AgGridSolidRef | undefined;
  const navigate = useNavigate();

  const onGridReady = async (gridApi: GridReadyEvent) => {
    gridApi.api.showLoadingOverlay();
    await props.onGridReady();
    gridApi.api.hideOverlay();
  };
  return (
    <Stack class="h-[100%] p-4">
      <SuiGrid class="!ag-theme-alpine !flex-1">
        <AgGridSolid
          ref={gridRef}
          class={'ag-theme-alpine'}
          columnDefs={officeColumnDefs}
          rowData={props.items}
          gridOptions={{
            defaultColDef: {
              flex: 1,
              filter: true,
            },
            onGridReady: (gridApi) => onGridReady(gridApi),
            rowHeight: 50,
          }}
          onRowClicked={(e) => {
            const officeId = (e.data as { id: number }).id;
            if (officeId) {
              const isNative = routeOfficeDetails(officeId);
              isNative && navigate(`/office/details/${officeId}`);
            }
          }}
          overlayLoadingTemplate={imageTag}
          noRowsOverlayComponent={imageTag}
          suppressPaginationPanel={false}
          pagination={true}
          paginationPageSize={100}
        />
      </SuiGrid>
    </Stack>
  );
};
