import { lazy } from 'solid-js';

import { RemitToQueue } from './RemitToQueue';
import { CallingQueue } from './CallingQueue';

const ApCallingQueue = lazy(() => import('./ApCallingQueue'));

const ApCallingQueueRoute = {
  path: '/accounting/apcallingqueue',
  component: ApCallingQueue,
};

export { ApCallingQueueRoute, RemitToQueue, CallingQueue };
