import { LoadTrackingT } from '@store/orders';
import { Box, Card, CardContent } from '@suid/material';
import { formatDateString } from '@utils/dateFormat';

type PropsT = {
  tracking: LoadTrackingT;
};

export const TrackingCard = (props: PropsT) => {
  return (
    <Card
      sx={{
        flexShrink: 0,
        flexGrow: 0,
        color: 'white',
        background: '#000000DE',
      }}
    >
      <CardContent sx={{ height: '60px', padding: '5px' }}>
        <Box>
          In {props.tracking.city ?? '-'}, {props.tracking.state ?? '-'}
        </Box>
        <Box class="text-xs">
          {formatDateString(props.tracking.locationTime, 'DD t')}
        </Box>
      </CardContent>
    </Card>
  );
};
