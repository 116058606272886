import { BasicModal, Notification } from '@components';
import { closeModal, openModal } from '@store/modals';
import { orderStore } from '@store/orders';
import { userStore } from '@store/user';
import { Stack } from '@suid/material';
import BillingHoldModal from '@views/order/components/models/BillingHoldModal';
import EditBillingNotesModal from '@views/order/components/models/EditBillingHoldAndNotesModal';
import {
  ADD_BILLING_HOLD_NOTE,
  EDIT_BILLING_HOLD_AND_NOTE,
} from '@views/order/constants';
import { get, isEmpty } from 'lodash';
import { Accessor, Component, Index, Show, createSignal } from 'solid-js';
import { convertUtcToLocalAndFormat } from '@utils/dateFormat';
import { flagImgs } from '@components/FlagPopup/constants';
import { FlagViewModel } from '@typeDefinitions/flagTypes';

import classes from './classes';

const modalStyles = { maxWidth: '90vw', background: 'white' };

type SelectedFlagType = {
  item: FlagViewModel | null;
  index: number | null;
};

const BillingHoldFlag: Component = () => {
  const [selectedFlag, setSelectedFlag] = createSignal<SelectedFlagType>({
    item: null,
    index: null,
  });

  const flagNotificationTextContent = (item: Accessor<FlagViewModel>) => {
    return (
      <div class={classes.noteContainer}>
        <strong>{item().flagType} </strong>
        <span>- </span>
        <span>{item().description}</span>
      </div>
    );
  };

  const flagNotificationSubTextContent = (item: Accessor<FlagViewModel>) => {
    return (
      <div>
        <span>
          {/* // check in v3 edit res setBy is coming null */}
          {!isEmpty(get(item(), 'setBy'))
            ? item().setBy
            : userStore.user.name}{' '}
        </span>
        <span>- </span>
        <span>
          {convertUtcToLocalAndFormat(
            item().setDate as string,
            'MMM dd, yyyy h:mm a',
          )}
        </span>
      </div>
    );
  };

  const editFlag = (item: FlagViewModel, index: number) => {
    setSelectedFlag({
      item,
      index,
    });
    openModal(EDIT_BILLING_HOLD_AND_NOTE);
  };

  return (
    <Show when={orderStore.order.flags && orderStore.order.flags.length > 0}>
      <Stack spacing={1} my={1}>
        <Index each={orderStore.order.flags}>
          {(item: Accessor<FlagViewModel>, index: number) => (
            <Notification
              type={item().flagType}
              text={flagNotificationTextContent(item)}
              subText={flagNotificationSubTextContent(item)}
              onClick={() => editFlag(item(), index)}
              imgIcon={flagImgs[item().flagType]}
            />
          )}
        </Index>
      </Stack>
      <BasicModal
        id={EDIT_BILLING_HOLD_AND_NOTE}
        width="800px"
        modalStyles={modalStyles}
        header={false}
        footer={false}
        showClose={false}
        onClose={() => {
          setSelectedFlag({ item: null, index: null });
          closeModal(EDIT_BILLING_HOLD_AND_NOTE);
        }}
      >
        <EditBillingNotesModal
          modalId={EDIT_BILLING_HOLD_AND_NOTE}
          item={selectedFlag().item}
          index={selectedFlag().index!}
        />
      </BasicModal>
      <BasicModal
        id={ADD_BILLING_HOLD_NOTE}
        width="900px"
        modalStyles={modalStyles}
        header={false}
        footer={false}
        showClose={false}
      >
        <BillingHoldModal modalId={ADD_BILLING_HOLD_NOTE} />
      </BasicModal>
    </Show>
  );
};

export default BillingHoldFlag;
