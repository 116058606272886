import { apiClient } from '@api/apiClient';
import { logError } from '@utils/errorLogging';

import { FeatureFlag } from './types.js';

export const GetFeatureFlags = async (): Promise<FeatureFlag> => {
  try {
    const result = await apiClient.get('FeatureFlag/GetFeatureFlags');

    if ('value' in result) {
      return result.value as FeatureFlag;
    }
    return {} as FeatureFlag;
  } catch (e) {
    logError('GetFeatureFlags', e as Error);
    return {} as FeatureFlag;
  }
};
