import { AddressCard } from '@components';
import { PhoneInput, TextInput } from '@components/forms';
import { createForm } from '@felte/solid';
import { validator } from '@felte/validator-yup';
import {
  ILoadComments,
  fetchCustomerContact,
  orderStore,
  sendEmail,
  sendText,
} from '@store/orders';
import { userStore } from '@store/user';
import CancelIcon from '@suid/icons-material/Cancel';
import EmailIcon from '@suid/icons-material/Email';
import PhoneAndroidIcon from '@suid/icons-material/PhoneAndroid';
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  IconButton,
  Stack,
} from '@suid/material';
import { formatPhoneNumber } from '@utils/phoneNumberFormat';
import { getEligibleEntries, printLog } from '@utils/utils';
import { isEmpty, last, sortBy } from 'lodash';
import uniqueId from 'lodash/uniqueId';
import { DateTime } from 'luxon';
import { Show, createSignal, onMount } from 'solid-js';
import { effect } from 'solid-js/web';
import { ICustomerContact } from '@typeDefinitions/contactTypes';

import classes from './classes';
import { formSchema } from './validation';

type PropsT = {
  comment: ILoadComments | undefined;
  onClose?: () => void;
  excludeDate?: boolean;
};

enum ActiveTypeEnum {
  Text = 'Text',
  Email = 'Email',
}

type FormStateT = {
  isEmail: boolean;
  email: string;
  phone: string;
};

export const CustomerUpdateModal = (props: PropsT) => {
  const [activeType, setActiveType] = createSignal(ActiveTypeEnum.Email);
  const [subject, setSubject] = createSignal('');
  const [message, setMessage] = createSignal(props.comment?.comment ?? '');
  const [hasChanges, setHasChanges] = createSignal(false);
  const [selectedContacts, setSelectedContacts] = createSignal<
    ICustomerContact[]
  >([]);
  const [contacts, setContacts] = createSignal<ICustomerContact[]>([]);
  const [phone, setPhone] = createSignal('');

  const { form, setFields, reset, errors, data } = createForm<FormStateT>({
    initialValues: {
      isEmail: true,
      email: '',
      phone: '',
    },
    extend: validator({ schema: formSchema }),
    onSubmit: (value) => {
      addNewContact(value);
      reset();
    },
    onError: (errors) => {
      printLog(errors, 'errors');
    },
  });

  const constructSubject = () => {
    const orderId = orderStore.order.id;
    const referenceNumber = orderStore.order.referenceNumber;
    return `Update for shipment ${referenceNumber ?? '-'} (Order #${orderId})`;
  };

  const constructMessage = () => {
    const date = DateTime.local().toFormat('ffff');
    const load = orderStore.order.loads[orderStore.activeTab.index];
    const stops = getEligibleEntries(load.stops);
    const firstStop = stops[0];
    const lastStop = last(stops);
    if (isEmpty(firstStop) || !lastStop) return '';
    return `${constructSubject()}
Pickup: ${firstStop.city ?? '-'}, ${firstStop.state ?? '-'}
Delivery: ${lastStop.city ?? '-'}, ${lastStop.state ?? '-'}
Shipment Status: ${load.status}

${props.comment?.comment}
${Boolean(props.excludeDate) ? '' : date}`;
  };

  const onContactClick = (contact: ICustomerContact) => {
    setSelectedContacts((contacts) => {
      const newContacts = [...contacts];
      const idx = newContacts.findIndex((c) => {
        return c.id === contact.id;
      });

      if (idx >= 0) {
        newContacts.splice(idx, 1);
      } else {
        newContacts.push(contact);
      }
      return newContacts;
    });
    const updatedContacts = contacts().map((c) => {
      if (c.id === contact.id) {
        const obj = {
          ...contact,
          selected: 'selected' in contact ? !contact.selected! : true,
        };
        return obj;
      }
      return c;
    });
    setContacts(updatedContacts);
  };

  const addNewContact = (val: FormStateT) => {
    const contact: ICustomerContact = {
      phone1: '',
      name: val.hasOwnProperty('email')
        ? 'User added'
        : val.hasOwnProperty('phone')
          ? 'User added'
          : '',
      id: parseInt(uniqueId()),
      objectid: 0,
      mailerEmails: true,
      email: val.email || '',
      createdDate: new Date(),
      modifiedDate: new Date(),
      phone: val.phone || '',
      selected: !selectedContacts().some(
        (contact) => contact.email === val.email,
      ),
    };

    if (activeType() === ActiveTypeEnum.Email) {
      const existingEmailContact = selectedContacts().find(
        (c) => c.email === val.email,
      );
      if (!existingEmailContact) {
        setSelectedContacts((prev) => [
          ...prev,
          { ...contact, email: val.email },
        ]);
        setContacts((prev) => [...prev, { ...contact, email: val.email }]);
      }
    } else {
      const existingPhoneContact = selectedContacts().find(
        (c) => c.phone === val.phone,
      );
      if (!existingPhoneContact) {
        setSelectedContacts((prev) => [
          ...prev,
          { ...contact, phone: val.phone },
        ]);
      }
    }
  };

  const setNewActiveType = (newType: ActiveTypeEnum) => {
    setActiveType(newType);
    setFields('isEmail', newType === ActiveTypeEnum.Email);
  };

  const onSend = async () => {
    const isEmail = activeType() === ActiveTypeEnum.Email;
    const selectedEmail = selectedContacts().map((c) => c.email);
    const selectedPhone = selectedContacts().map((c) => c.phone);
    const to = isEmail ? selectedEmail : selectedPhone;
    const filteredTo = to.filter(
      (value) => value !== undefined && value !== '',
    );
    if (isEmail && filteredTo.length > 0) {
      await sendEmail(
        [userStore.user.email],
        userStore.user.email,
        filteredTo,
        subject(),
        message(),
      );
    } else {
      to.forEach(async (number) => {
        if (number == '' || number == null) return;
        await sendText(number, message());
      });
    }
  };

  effect(() => {
    if (hasChanges()) {
      return;
    }

    const newMessage = constructMessage();
    const newSubject = constructSubject();

    setMessage(newMessage);
    setSubject(newSubject);
  });

  const checkPhoneValidation = (e: string) => {
    const phone = e.replace(/\D/g, '');
    if (phone.length < 10) {
      setPhone('Phone number is not valid');
      setFields('phone', phone);
    } else {
      setPhone('');
      const formattedPhone = formatPhoneNumber(phone);
      setFields('phone', formattedPhone);
    }
  };

  onMount(async () => {
    try {
      const customerId =
        orderStore.order.loads[orderStore.activeTab.index]?.customerId;
      const contactsResponse: ICustomerContact[] | undefined =
        await fetchCustomerContact(customerId);
      const updatedContacts = (contactsResponse || []).map((contact) => {
        if (contact.type === 'Freight') {
          const obj = {
            ...contact,
            selected: true,
          };
          return obj;
        }
        return contact;
      });
      setContacts(sortBy(updatedContacts, 'name'));

      const selectedContactsCopy = updatedContacts.filter(
        (c) => c.selected === true,
      );
      setSelectedContacts(selectedContactsCopy);
    } catch (error) {
      printLog(error);
    }
  });

  return (
    <Show
      when={contacts().length > 0}
      fallback={
        <div class={classes.loaderStyle}>
          <CircularProgress />
        </div>
      }
    >
      <Stack direction="row" spacing={1}>
        <Box flex={2}>
          <Box class="text-sm pt-1">Communication Method</Box>
          <Box pb="8px" pt="5px">
            <ButtonGroup
              variant="outlined"
              aria-label="outlined button group"
              fullWidth
            >
              <Button
                sx={{ flex: 1 }}
                variant={
                  activeType() === ActiveTypeEnum.Text
                    ? 'contained'
                    : 'outlined'
                }
                onClick={() => setNewActiveType(ActiveTypeEnum.Text)}
              >
                Text
              </Button>
              <Button
                sx={{ flex: 1 }}
                variant={
                  activeType() === ActiveTypeEnum.Email
                    ? 'contained'
                    : 'outlined'
                }
                onClick={() => setNewActiveType(ActiveTypeEnum.Email)}
              >
                Email
              </Button>
            </ButtonGroup>
          </Box>
          <form ref={form} class="mt-4">
            {activeType() === ActiveTypeEnum.Email ? (
              <TextInput
                label={'Add an email address'}
                name="email"
                variant="outlined"
                size="small"
                error={errors().email !== null ? errors().email : ''}
              />
            ) : (
              <PhoneInput
                label={'Add a phone number'}
                name="phone"
                variant="outlined"
                size="small"
                error={phone()}
                value={data().phone}
                onChange={(e: string) => {
                  checkPhoneValidation(e);
                }}
              />
            )}
          </form>
          <Stack height="240px" overflow="auto">
            {contacts().map((contact) => {
              if (
                (activeType() === ActiveTypeEnum.Email &&
                  (contact.email === null || contact.email === undefined)) ||
                (activeType() === ActiveTypeEnum.Text &&
                  (contact.phone === null || contact.phone === undefined))
              ) {
                return <></>;
              }
              if (
                activeType() === ActiveTypeEnum.Text &&
                !['', null, undefined].includes(contact.phone)
              )
                return (
                  <Box onClick={() => onContactClick(contact)}>
                    <AddressCard
                      contact={contact}
                      mode="regular"
                      isActive={contact.selected}
                    />
                  </Box>
                );
              else if (
                activeType() === ActiveTypeEnum.Email &&
                !['', null, undefined].includes(contact.email)
              )
                return (
                  <Box onClick={() => onContactClick(contact)}>
                    <AddressCard
                      contact={contact}
                      mode="regular"
                      isActive={contact.selected}
                    />
                  </Box>
                );
            })}
          </Stack>
        </Box>
        <Box flex={3} height="363px" overflow="auto" pb="8px">
          <Stack spacing={1.1}>
            <Box maxHeight="97px" class={classes.stackStyle}>
              <Box class="text-sm flex justify-between">
                <Box>Recipients</Box>
                <Box class="cursor-pointer">
                  <IconButton
                    size="small"
                    onClick={() => {
                      setSelectedContacts([]);
                      setContacts(
                        contacts().map((c) => {
                          return {
                            ...c,
                            selected: false,
                          };
                        }),
                      );
                    }}
                    title="Clear All"
                  >
                    {selectedContacts().length === 0 ? (
                      ''
                    ) : (
                      <CancelIcon fontSize="small" />
                    )}
                  </IconButton>
                </Box>
              </Box>
              <Stack overflow="auto" maxHeight="73px" pb="8px">
                {selectedContacts().map((c) => {
                  const emailValue = c.email;
                  const phoneValue: number = c.phone;
                  const Icon =
                    activeType() === ActiveTypeEnum.Text
                      ? PhoneAndroidIcon
                      : EmailIcon;

                  let text;
                  if (activeType() === ActiveTypeEnum.Text) {
                    if (phoneValue) {
                      text = formatPhoneNumber(phoneValue);
                    } else {
                      return null;
                    }
                  } else {
                    if (Boolean(emailValue)) {
                      text = `(${emailValue})`;
                    } else {
                      return null;
                    }
                  }

                  return (
                    <Stack direction="row" justifyContent="space-between">
                      <Box>
                        <Icon fontSize="small" /> {c.name} {text}
                      </Box>
                    </Stack>
                  );
                })}
              </Stack>
              <Show when={selectedContacts().length === 0}>
                <span class={classes.noneText}>None</span>
              </Show>
            </Box>
            <Show when={activeType() === ActiveTypeEnum.Email}>
              <Box class={classes.subjectField}>
                <TextInput
                  label="Subject"
                  variant="outlined"
                  size="small"
                  value={subject()}
                  onChange={(value) => {
                    setHasChanges(true);
                    setSubject(value as string);
                  }}
                />
              </Box>
            </Show>
            <Box>
              <TextInput
                label="Message"
                value={message()}
                variant="outlined"
                size="small"
                onChange={(value) => {
                  setHasChanges(true);
                  setMessage(value as string);
                }}
                rows={8}
                multiline
              />
            </Box>
          </Stack>
        </Box>
      </Stack>
      <Box>
        <Button
          variant="contained"
          component="button"
          onClick={onSend}
          disabled={selectedContacts().length === 0}
          fullWidth
        >
          Send {activeType()}
        </Button>
      </Box>
    </Show>
  );
};
