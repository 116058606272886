import { Popover } from '@components/Popover';
import { openModal } from '@store/modals';
import { carrierStore } from '@store/orders';
import { Box, Button, Stack } from '@suid/material';
import { isAdmin, isManager } from '@utils/utils';
import { CarrierDetailsBlacklist } from '@views/carriers/carrierDetails/components/CarrierDetailsBlacklist';
import { ADD_PLAYLIST_ID } from '@views/order/constants';
import { Show } from 'solid-js';
import { ILoadEditViewModel } from '@typeDefinitions/loadTypes';

import { incrementFallout } from './utils';

type PropsT = {
  load: ILoadEditViewModel;
  tabIndex: number;
  disabled?: boolean;
};

export const ProfileFallout = (props: PropsT) => {
  const addPlaylistModalId = `${ADD_PLAYLIST_ID}-${props.tabIndex}`;

  return (
    <Stack direction="row">
      <Box>
        <Button
          onClick={() => {
            if (carrierStore.carrier[props.tabIndex]?.id) {
              void incrementFallout(
                carrierStore.carrier[props.tabIndex]?.id,
                props.tabIndex,
              );
            }
          }}
          variant="text"
          class="!text-armstrong-sm"
          sx={{
            color: '#026EA1',
            padding: '6px 4px',
          }}
          disabled={props.disabled}
        >
          Fallout
        </Button>
      </Box>
      <Box>
        <Button
          onClick={() => openModal(addPlaylistModalId)}
          variant="text"
          class="!text-armstrong-sm"
          sx={{
            color: '#026EA1',
            padding: '6px 4px',
          }}
        >
          {Boolean(carrierStore.carrier[props.tabIndex]?.id)
            ? 'Add To Playlist'
            : 'Select From Playlist'}
        </Button>
      </Box>
      <Show
        when={
          (isAdmin() || isManager()) &&
          Boolean(carrierStore.carrier[props.tabIndex]?.id)
        }
      >
        <Popover
          classes="!p-0"
          eleName={
            <Button
              variant="text"
              class="!text-armstrong-sm"
              sx={{
                color: '#026EA1',
                padding: '6px 4px',
              }}
            >
              Blacklist
            </Button>
          }
        >
          {(closePopover) => (
            <CarrierDetailsBlacklist
              closePopover={closePopover}
              carrierId={props.load.carrierId ?? undefined}
              tabIndex={props.tabIndex}
            />
          )}
        </Popover>
      </Show>
    </Stack>
  );
};
