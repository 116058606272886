import * as yup from 'yup';

function checkStringOrDate(value: unknown): boolean {
  if (typeof value === 'undefined') {
    return false;
  }
  if (
    yup.string().isValidSync(value) &&
    yup.date().isValidSync(new Date(value))
  ) {
    return true;
  }

  return false;
}

export const formSchema = yup.object({
  comment: yup.string().required('Comment is required'),
});

export const addEFSFormSchema = yup.object({
  referenceNum: yup.string().nullable(),
  checkNumber: yup.string().nullable(),
  description: yup.string().nullable(),
  amount: yup.number().required('Amount is Required'),
  paidDate: yup
    .mixed()
    .test('is-date-or-string', 'Select Paid Date', (value) =>
      checkStringOrDate(value),
    ),
  type: yup.string().nullable(),
});

export const postLoad = yup.object({
  origin: yup.string().nullable().required('origin is required'),
  destination: yup.string().nullable().required('Destination is required'),
  pickUp: yup.string().nullable().required('pick up date is required'),
  dropOff: yup.string().nullable(),
});
