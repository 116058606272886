import { BasicTable, Typography } from '@components';
import { formatAmount } from '@utils/utils';
import { Column } from '@components/BasicTable/BasicTable';
import { LineItemViewModel } from '@typeDefinitions/lineItemTypes';
import { ILoadEditViewModel } from '@typeDefinitions/loadTypes';

import classes from './classes';

type Props = {
  load: ILoadEditViewModel;
  tabIndex: number;
};

export const CarrierSettlement = (props: Props) => {
  const computeTotalLineItems = () => {
    return props.load.lineItems && props.load.lineItems.length > 0
      ? props.load.lineItems
          .filter((v) => v.operationType !== 'Delete')
          .reduce(
            (acc, item) => acc + Number(item.rate) * Number(item.quantity),
            0,
          )
      : 0;
  };

  const columns: Column<LineItemViewModel>[] = [
    {
      name: 'Type',
      key: 'type',
      renderCell: (rowData) => rowData.type,
    },
    {
      name: 'Description',
      key: 'description',
      renderCell: (rowData) => rowData.description,
    },
    {
      name: 'Amount',
      key: 'amount',
      renderCell: (rowData) => (
        <> {formatAmount(Number(rowData.quantity) * Number(rowData.rate))}</>
      ),
    },
    {
      name: 'Settled Date',
      key: 'date',
      renderCell: (rowData) => rowData.settledDate,
    },
  ];
  const footerRow = [
    {
      key: '#',
    },
    {
      key: 'link',
    },
    {
      renderCell: () => (
        <Typography variant="body1" class={classes.totalLabel}>
          Total
        </Typography>
      ),
      key: 'uploadedDoc',
    },
    {
      renderCell: () => (
        <Typography variant="body1" class={classes.totalAmount}>
          {formatAmount(computeTotalLineItems())}
        </Typography>
      ),
      key: 'uploadDoc',
    },
  ];

  return (
    <BasicTable<LineItemViewModel>
      footerRow={footerRow}
      columns={columns}
      rows={props.load.lineItems ?? []}
      tableBodyClasses={classes.tableBg}
      tableFooterClasses={classes.tableFooter}
      tableBodyRowClasses={classes.tableFooterRow}
      tableFooterRowClasses={classes.tableFooterRow}
    />
  );
};
