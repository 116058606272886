import { Box, Grid } from '@suid/material';
import { Component, Show } from 'solid-js';
import { NewATGOnlyNote } from '@views/order/components';
import { factoringCompanyDetailsStore } from '@store/factoringCompany/factoringCompanyDetails';

import { FactoringCompanyDetailsCard } from './FactoringCompanyDetailsCard';
import { FactoringCompanyPortalLoginCard } from './FactoringCompanyPortalLoginCard';
import { FactoringCompanyDetailsContactCard } from './FactoringCompanyDetailsContactCard';
import { FactoringCompanyDetailsDocumentsCard } from './FactoringCompanyDetailsDocumentsCard';

export const FactoringCompanyDetailsTab: Component = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={7.5}>
          <FactoringCompanyDetailsCard />
          <FactoringCompanyPortalLoginCard />
        </Grid>
        <Grid item xs={12} lg={4.5}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FactoringCompanyDetailsContactCard />
            </Grid>
            <Show when={!factoringCompanyDetailsStore.creationMode}>
              <Grid item xs={12}>
                <FactoringCompanyDetailsDocumentsCard />
              </Grid>
              <Grid item xs={12}>
                <NewATGOnlyNote
                  cardTitle="Internal Notes"
                  type="FactoringCompany"
                  comments={
                    factoringCompanyDetailsStore.factoringCompanyDetails
                      .comments
                  }
                  loading={factoringCompanyDetailsStore.isLoading}
                  maxLength={2000}
                />
              </Grid>
            </Show>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
