import { apiClient, v3FileUploadClient, v3ODataClient } from '@api/apiClient';
import { ToastType } from '@components/Toast';
import { AddProductDetails } from '@typeDefinitions/customerTypes';
import { LTLCatalogs, ODataApiResponse } from '@typeDefinitions/orderTypes';
import { logError } from '@utils/errorLogging';
import { createError } from '@utils/errorUtils';
import { handleToast } from '@utils/utils';

export async function fetchProductCatalog(queryParams: {
  $format?: string;
  $top?: number;
  $skip?: number;
  $orderby?: string;
  $count?: boolean;
  $filter?: string;
}) {
  try {
    const response: ODataApiResponse = await v3ODataClient.get('LTLCatalogs', {
      params: queryParams,
    });
    if ('value' in response) {
      return {
        data: response.value,
        count: response['@odata.count'],
      };
    }
    throw new Error('Failed to fetch product catalog');
  } catch (error: unknown) {
    logError('fetchProductCatalog', error as Error);
    throw createError(error, 'Failed to fetch product catalog');
  }
}

export const addProductDetails = async (payload: AddProductDetails) => {
  try {
    const response = await apiClient.post('LtlCatalog', payload);
    return response as unknown as AddProductDetails;
  } catch (error: unknown) {
    const e = createError(error, 'Failed to Add Product Details');
    handleToast(ToastType.Error, e.message);
  }
};

export const editProductDetails = async (payload: AddProductDetails) => {
  try {
    const response = await apiClient.put('LtlCatalog', payload);
    return response as unknown as AddProductDetails;
  } catch (error: unknown) {
    const e = createError(error, 'Failed to Edit Product Details');
    handleToast(ToastType.Error, e.message);
  }
};

export const deleteProductDetails = async (id: string) => {
  try {
    const res: {
      isSuccess: boolean;
    } = await apiClient.delete(`LtlCatalog/${id}`);
    if (Boolean(res.isSuccess)) {
      handleToast(ToastType.Success, 'Product Deleted Successfully');
      return res;
    }
    handleToast(ToastType.Error, 'Failed to delete product');
  } catch (error: unknown) {
    throw createError(error, 'Failed to Delete Product Details');
  }
};

export const fetchProductById = async (id: string) => {
  try {
    const response = await apiClient.get(`LtlCatalog/${id}`);
    return response as unknown as AddProductDetails;
  } catch (error: unknown) {
    handleToast(ToastType.Error, 'Failed to fetch product by id');
    throw createError(error, 'Failed to fetch product by id');
  }
};

export const uploadProductCatalog = async (id: string, file: File) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const response = await v3FileUploadClient.post(
      `LTLCatalog/Import/${id}`,
      formData,
    );
    return response as unknown as {
      success: boolean;
      message: string;
      data: {
        validEntities: LTLCatalogs[];
      };
    };
  } catch (error: unknown) {
    const e = createError(error, 'Failed to upload product catalog');
    handleToast(ToastType.Error, e.message);
  }
};
