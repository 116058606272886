import { createStore } from 'solid-js/store';

import { FeatureFlagState, FeatureFlag } from './types';
import { featureFlags } from './featureFlags';
import { GetFeatureFlags } from './service';

const [featureFlagStore, setFeatureFlagStore] = createStore<FeatureFlagState>({
  isLoading: false,
  isError: false,
  errorMessage: undefined,
  flags: {} as FeatureFlag,
});

const initializeFeatureFlags = async () => {
  setFeatureFlagStore({
    isLoading: true,
    isError: false,
    errorMessage: undefined,
  });

  try {
    const flags = await GetFeatureFlags();

    setFeatureFlagStore({
      isLoading: false,
      isError: false,
      errorMessage: undefined,
      flags,
    });
  } catch (error) {
    setFeatureFlagStore({
      isLoading: false,
      isError: true,
      errorMessage: (error as Error).message,
      flags: {} as FeatureFlag,
    });
  }
};

const isFeatureFlagEnabled = (
  value: (typeof featureFlags)[keyof typeof featureFlags],
): (() => boolean) => {
  const flagValue = Object.entries(featureFlags).find(
    ([_key, flagValue]) => flagValue === value,
  )?.[1];

  if (flagValue === undefined) {
    throw new Error(
      `Feature flag value "${value}" does not exist in featureFlags.`,
    );
  }

  return () => !!featureFlagStore.flags[flagValue as keyof FeatureFlag];
};

export { featureFlagStore, initializeFeatureFlags, isFeatureFlagEnabled };
