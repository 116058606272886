export const loadInformationClasses = {
  header: 'flex flex-row gap-10',
  headerTitle: 'text-lg font-medium',
  actionGroup: 'text-[#026EA1]',
  actionButton: 'font-roboto text-sm font-medium mr-[20px] cursor-pointer',
  textFieldContainer: 'flex my-[24px]',
  textFieldFlex: 'flex w-[60%]',
  iconGroup: 'flex gap-3 items-center',
  ratingContainer: 'flex justify-between',
  ratingColumn: 'flex flex-col',
  typographyMarginLeft: 'ml-12',
  typographyFlexColumn: 'flex flex-col ml-3',
  typographyFlexColumnML10: 'flex flex-col ml-10',
  typographyFlexColumnML20: 'flex flex-col ml-20',
  cardIcon: 'flex mt-[-18px] gap-[10px] justify-center items-center',
  assignHeader:
    'text-xs font-normal leading-3 pl-2.5 font-roboto tracking-extra-tight',
  assignContainer: 'flex-col items-center p-[10px] pt-[0px] w-[450px]',
  divider: 'bg-[#e0e0e0] !mt-2',
  reassignButton:
    'bg-[#016fa1ff] text-white w-[70px] h-[30px] text-[13px] font-medium',
  reassignContactCard: '!mb-2̦',
  cardStyle:
    'w-full h-full p-2 mr-1 ml-1 mt-1 hover:bg-[#d2e2ed80] !p-0 !shadow-none !pt-[5px]',
  accountCircleBox: '!pl-2',
  accountCircle: '!text-[#016fa1ff]',
  accountText: 'text-base font-normal p-2',
  accountBlue: '!text-[#016fa1ff] mr-1.5',
  accountGrey: '!text-[#0000008f] mr-1.5',
};

export const notificationClasses = {
  container: 'gap-1 items-center',
  flag: 'text-lg font-bold',
  date: 'text-sm',
  noteContainer:
    'whitespace-pre-wrap break-words break-all block font-sans w-full',
  flagComments:
    'w-[30px] h-[30px] rounded-3xl bg-[#1B4960] text-white text-center border-[#fff] border-2',
};

export const carrierDetailsClasses = {
  profileBtnContainer: 'flex gap-2 items-center',
  rateConfirmationLink: 'flex gap-1 items-center',
  billOfLadingLink: 'flex items-center gap-1 no-underline',
  linkStyle: '!text-[#026EA1] cursor-pointer',
  disabledLinkStyle: 'text-[#000000] opacity-40 pointer-events-none',
  subTextStyle: 'opacity-60 text-sx text-armstrong-sm',
  disabledSubTextStyle: 'text-[#000000] opacity-40 text-sx text-armstrong-sm',
};

export const carrieContactCardClasses = {
  iconLabelContentClasses: 'flex items-center',
  smallIconLabelContentClasses: 'flex items-center !text-sm',
  anchorStyleClasses: 'whitespace-pre-wrap break-words break-all block',
  iconClasses: 'text-[#026EA1] text-lg/[20px]',
  disabledIconStyle: 'text-[#026EA1] opacity-40 text-lg/[20px]',
  w100: '!w-[100%]',
  carrierMailTo: 'text-[#51607b] hover:underline',
};

export const loadStatusClasses = {
  shipmentSubmitGrid: 'flex justify-center items-center whitespace-normal',
  shipmentSubmitButton: 'w-full',
};
