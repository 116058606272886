import { isEmpty } from 'lodash';
import * as yup from 'yup';

import { ContactFormValues } from './AddNewContact';

const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/;

export const contactSchema = yup.object({
  name: yup.string().required('Name is required'),
  type: yup.string().required('Type are required').notRequired(),
  phone1: yup.string().when((value) => {
    return isEmpty(value[0])
      ? yup.string().nullable().notRequired()
      : yup.string().min(10, 'Phone number must be at least 10 digits');
  }),
  extension1: yup.string().nullable(),
  phone2: yup.string().when((value) => {
    return isEmpty(value[0])
      ? yup.string().nullable().notRequired()
      : yup.string().min(10, 'Phone number must be at least 10 digits');
  }),
  extension2: yup.string().nullable().notRequired(),
  fax: yup.string().when((value) => {
    return isEmpty(value[0])
      ? yup.string().nullable().notRequired()
      : yup.string().min(10, 'Fax number must be at least 10 digits');
  }),
  email: yup.string().nullable().email('Please enter a valid Email'),
  cpn: yup.string().notRequired(),
  emailVerified: yup.boolean().notRequired(),
  phoneVerified: yup.boolean().notRequired(),
  userName: yup
    .string()
    .nullable()
    .test({
      name: 'userName',
      test: function (value, context) {
        const { id } = context.parent as ContactFormValues;
        if (Boolean(id)) {
          return true; // Skip username validation on update
        }
        if (!isEmpty(value) && value.trim().length < 7) {
          return this.createError({
            message:
              'Username for a contact must be at least 7 characters and cannot be all whitespaces',
          });
        }
        return true;
      },
    }),
  password: yup
    .string()
    .nullable()
    .test({
      name: 'password',
      test: function (value, context) {
        const { userName, type, id } = context.parent as ContactFormValues;
        if (Boolean(id)) {
          return true; // Skip password validation on update
        }
        // if username is ther password is required
        if (
          (type === 'Billing' || type === 'Freight') &&
          Boolean(userName) &&
          !Boolean(value)
        ) {
          return this.createError({
            message: 'Password is required',
          });
        }

        if (Boolean(value) && !passwordRegex.test(value!)) {
          return this.createError({
            message:
              'Password for a contact must have one upper case letter, one lower case letter, and a digit, and be at least 8 characters in length',
          });
        }
        return true;
      },
    }),
});
