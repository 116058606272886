import { Button, Typography } from '@components';
import { closeModal } from '@store/modals';
import { updateLoadPropertyAtIndex } from '@store/orders';
import { Box, Grid } from '@suid/material';
import { Component } from 'solid-js';

import { isLTLModalClasses as classes } from './classes';

type IsLTLModalProps = {
  modalId: string;
};

const IsLTLLoad: Component<IsLTLModalProps> = (props: IsLTLModalProps) => {
  const changeMode = () => {
    updateLoadPropertyAtIndex({
      mode: 'LTL',
    });
    closeModal(props.modalId);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} px={1} pb={1} alignItems="center">
        <Grid item xs={12} textAlign="left">
          <Typography
            class={classes.titleTypography}
            variant="h6"
            component="h2"
          >
            Is LTL Load
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} px={1} mb={2}>
        <Grid item xs={12} textAlign="left">
          <Typography variant="body1" component="div">
            Is this an LTL load?
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          gap={2}
          displayRaw="flex"
          alignItems="center"
          justifyContent="end"
          class={classes.buttonFooterGrid}
        >
          <Button label="Yes" onClick={changeMode} variant="contained" />
          <Button
            label="No"
            onClick={() => closeModal(props.modalId)}
            variant="text"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default IsLTLLoad;
