import { Button, Notification, Typography } from '@components';
import {
  ltlQuoteState,
  ltlQuotingResultStore,
  ltlRateQuoteRequest,
} from '@store/ltl/store';
import { Box, Grid, Skeleton, Stack } from '@suid/material';
import ArrowBackIcon from '@suid/icons-material/ArrowBack';
import { clearLtlQuoteResults } from '@store/ltl/actions';
import { Show } from 'solid-js';
import { size } from 'lodash';
import {
  IsFeatureFlagEnabledByName,
  styleQuoteLocator,
} from '@store/Global/service';
import { featureFlags } from '@store/featureFlags/featureFlags';
import { isFeatureFlagEnabled } from '@store/featureFlags/store';

import { NewQuoteDetailsRowRender } from '../NewQuoteDetailsRowRender';
import { QuoteDetailsRowRenderLegacy } from '../QuoteDetailsRowRenderLegacy';

export const QuoteResults = (props: { setActiveView: (x: string) => void }) => {
  return (
    <div>
      {ltlQuotingResultStore.isLoading ? (
        <Stack spacing={2}>
          <Skeleton variant="text" width={'100%'} height={80} />
          <Skeleton variant="rectangular" width={'100%'} height={200} />
          <Skeleton variant="rectangular" width={'100%'} height={200} />
          <Skeleton variant="rectangular" width={'100%'} height={200} />
        </Stack>
      ) : (
        <>
          <Grid container class="!flex items-center pl-[10px]">
            <Grid item sx={{ display: 'flex' }} xs={10}>
              <Button
                sx={{
                  width: '120px',
                  marginY: '15px',
                }}
                variant="outlined"
                startIcon={<ArrowBackIcon />}
                label="Back"
                onClick={() => {
                  props.setActiveView('quote-form');
                  clearLtlQuoteResults();
                }}
              />
              <Show when={size(ltlQuotingResultStore.items) > 0}>
                <Notification
                  type={'info'}
                  text={
                    'Note: Stops will need to be edited to add address information before tendering to carrier. Please confirm delivery is not on a weekend or holiday once selecting a quote.'
                  }
                  sxProps="!ml-[24px]"
                />
              </Show>
            </Grid>
          </Grid>
          <Grid
            container
            class="!flex items-center pl-[10px]"
            sx={{ marginTop: '10px' }}
            xs={12}
          >
            <Show when={size(ltlQuotingResultStore.items) > 0}>
              <Box class="px-[5px]">
                <Typography
                  variant="body1"
                  class={'!text-xs !text-[#00000099] mb-1'}
                >
                  Quote Group #
                </Typography>
                <Typography
                  variant="body1"
                  class={'!text-base !text-wrap !break-words'}
                >
                  {Boolean(
                    IsFeatureFlagEnabledByName('UseShortQuoteGroupLocator'),
                  )
                    ? styleQuoteLocator({
                        quoteLocator:
                          ltlQuotingResultStore.items[0].quoteRequestLocator ??
                          '',
                      })
                    : ltlQuotingResultStore.items[0].quoteRequestId}
                </Typography>
              </Box>
            </Show>

            <Show when={ltlQuoteState.declaredValue}>
              <Box class="pr-[5px] pl-[5px]">
                <Typography
                  variant="body1"
                  class={'!text-xs !text-[#00000099] mb-1'}
                >
                  Declared Value
                </Typography>
                <Typography
                  variant="body1"
                  class={'!text-base !text-wrap !break-words'}
                >
                  ${ltlQuoteState.declaredValue?.toLocaleString()}
                </Typography>
              </Box>
            </Show>
          </Grid>
        </>
      )}
      <Show when={size(ltlQuotingResultStore.items) === 0}>
        <Notification
          type={'info'}
          text={'No Rate Quotes Currently Available'}
        />
      </Show>
      <Show
        when={isFeatureFlagEnabled(featureFlags.falvey)()}
        fallback={ltlQuotingResultStore.items.map((item) => (
          <QuoteDetailsRowRenderLegacy
            data={item}
            isGetQuote={true}
            request={ltlRateQuoteRequest.items}
            customerId={ltlQuotingResultStore.customerId}
          />
        ))}
      >
        {ltlQuotingResultStore.items.map((item) => {
          let additionalParams = {};

          if (ltlQuoteState.falveyPricingQuote) {
            const { insurancePremium, serviceFee } =
              ltlQuoteState.falveyPricingQuote;
            additionalParams = {
              falveyInsuranceVals: {
                quotePremium: insurancePremium,
                quoteServiceFee: serviceFee,
                shippingSumInsured: ltlQuoteState.declaredValue,
              },
            };
          }
          return (
            <NewQuoteDetailsRowRender
              data={item}
              request={ltlRateQuoteRequest.items}
              customerId={ltlQuotingResultStore.customerId}
              declaredValue={ltlQuoteState.declaredValue}
              isGetQuote={true}
              {...additionalParams}
            />
          );
        })}
      </Show>
    </div>
  );
};
