import { currencyTypes } from '@common/commonLists';
import { BasicModal, EmptyData, TableRowErrors, Typography } from '@components';
import { DialogBox } from '@components/DialogBox';
import { MenuItemType, SelectField, TextInput } from '@components/forms';
import { openDialogBox } from '@store/dialogBox';
import { openModal } from '@store/modals';
import {
  customerStore,
  ediSettingsValues,
  orderStore,
  updateOrderState,
  validateFieldForOrder,
} from '@store/orders';
import { isFalveyInsuranceCommitted } from '@store/orders/helper';
import { AddCircleOutlineOutlined, Delete } from '@suid/icons-material';
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@suid/material';
import { SelectChangeEvent } from '@suid/material/Select';
import {
  capitalizeWord,
  formatAmount,
  isAdmin,
  isSuperAdmin,
  orderTableErrors,
  trimDecimalValue,
} from '@utils/utils';
import { Accessor, Index, Show, createEffect, createSignal } from 'solid-js';
import { OperationTypeV4 } from '@typeDefinitions/operationTypes';
import { OrderLineItem } from '@typeDefinitions/lineItemTypes';

import { removeTableRow } from '../../carrier/stops/stopsEditor/utilFunctions';
import { LineItemsLockedModel } from '../../models';
import classes from './classes';

export const CustomerPay = () => {
  createEffect(() => {
    if (
      orderStore.order.lineItems.length === 0 &&
      orderStore.isCreate &&
      Boolean(orderStore.order.customerId)
    ) {
      addRow();
      updateLineItems('type', 'Line Haul', 0);
    }
  });
  const [deleteRow, setDeleteRow] = createSignal<number | null>(null);

  const editLineItems = 'editLineItems';

  //TODO: MAKE REUSABLE FUNCTION SIMILAR TO REMOVE ROW FROM TABLE
  const addRow = () => {
    const lineItems = [...orderStore.order.lineItems];
    lineItems.push({
      operationType: 'Insert',
      quantity: 1,
      type: '',
      description: '',
      rate: 0,
      //remove all not required once
      id: 0,
      estimate: false,
      readOnly: false,
      synched: false,
      adjustment: false,
      stopId: null,
    });
    updateOrderState({ lineItems: lineItems });
  };

  const headers = ['', 'Type', 'Desc.', 'Qty', 'Rate', 'Total'];

  const cellStyles = {
    padding: '1px 1px',
    borderBottom: '0px !important',
  };

  const computeTotalLineItems = () => {
    return orderStore.order.lineItems.length > 0
      ? orderStore.order.lineItems
          .filter((v) => v.operationType !== 'Delete')
          .reduce((acc, item) => {
            let rate = item.rate;
            if (rate === '' || rate === '.' || rate === '-') {
              rate = '0';
            }
            return acc + Number(rate) * Number(item.quantity);
          }, 0)
      : 0;
  };

  //TODO: MAKE REUSABLE FUNCTION SIMILAR TO REMOVE ROW FROM TABLE
  const updateLineItems = (
    key: string,
    value: string | number,
    index: number,
  ) => {
    const lineItems = [...orderStore.order.lineItems];
    let opType = 'Insert' as OperationTypeV4;
    if (lineItems[index].id > 0) {
      opType = 'Update' as OperationTypeV4;
    }
    lineItems[index] = {
      ...lineItems[index],
      [key]: value,
      operationType: opType,
    };
    updateOrderState({ lineItems: lineItems });
  };

  let filteredOptions: MenuItemType[] = [];
  for (const prop in ediSettingsValues.ediSettings) {
    if (
      Object.prototype.hasOwnProperty.call(ediSettingsValues.ediSettings, prop)
    ) {
      filteredOptions.push({
        label: capitalizeWord(prop),
        value: prop,
        disabled: true,
      });
      const filteredOthers = (
        ediSettingsValues.ediSettings[prop] as MenuItemType[]
      )
        .map((e) => {
          return { label: e.label, value: e.value } as MenuItemType;
        })
        .sort((a, b) => String(a.label).localeCompare(String(b.label)));
      filteredOptions = filteredOptions.concat(filteredOthers);
    }
  }

  return (
    <>
      {customerStore.customer.invoiceMethod === 'Canadian' && (
        <Box class="flex items-center justify-end gap-3 ">
          <Typography variant="body1">Customer Currency Type</Typography>
          <SelectField
            name="currencyType"
            label=""
            menuItems={currencyTypes}
            value={orderStore.order.currencyType}
            onChange={(event) => {
              updateOrderState({ currencyType: event.target.value });
            }}
            width={'160px'}
            size="small"
            disabled={orderStore.isReadOnly}
          />
        </Box>
      )}
      <Box class="py-2"></Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((item) => (
                <TableCell sx={{ padding: '1px' }} class="!text-xs">
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <Show
            when={
              orderStore.order.lineItems.filter(
                (v) => v.operationType !== 'Delete',
              ).length === 0
            }
          >
            <TableBody class={classes.emptyTable}>
              <TableRow>
                <TableCell colspan={headers.length + 1} style={{ border: 0 }}>
                  <EmptyData />
                </TableCell>
              </TableRow>
            </TableBody>
          </Show>
          <Show when={orderStore.order.lineItems.length > 0}>
            <TableBody class={classes.tableBg}>
              <Index each={orderStore.order.lineItems}>
                {/* eslint-disable-next-line complexity */}
                {(item: Accessor<OrderLineItem>, index) => (
                  <Show when={item().operationType !== 'Delete'}>
                    <TableRow
                      class={classes.tableRow}
                      sx={{
                        cursor: item().synched ? 'not-allowed' : 'default',
                      }}
                    >
                      <TableCell sx={{ padding: '0px' }}>
                        <Show when={item().type !== 'LTL Insurance'}>
                          <IconButton
                            disabled={
                              item().synched ||
                              item().type === 'LTL Insurance' ||
                              orderStore.isReadOnly
                            }
                            onClick={() => {
                              setDeleteRow(index);
                              openDialogBox('deleteLineItem');
                            }}
                          >
                            <Delete
                              class={classes.deleteIcon}
                              fontSize="small"
                            />
                          </IconButton>
                        </Show>
                      </TableCell>
                      <TableCell
                        sx={{
                          ...cellStyles,
                        }}
                      >
                        <div class="tablet:max-w-[80px] netbook:max-w-[160px] netbook:min-w-[80px]">
                          <SelectField
                            label=""
                            placeholder="Select Type"
                            value={item().type}
                            menuItems={filteredOptions}
                            menuItemGrow
                            onChange={(e: SelectChangeEvent) => {
                              updateLineItems('type', e.target.value, index);
                            }}
                            onBlur={() =>
                              validateFieldForOrder(
                                `lineItems.${index}.type`,
                                `lineItems[${index}].type`,
                              )
                            }
                            error={
                              orderStore.orderFormError &&
                              orderStore.orderFormError[
                                `lineItems[${index}].type`
                              ]
                            }
                            noErrorMessage
                            disabled={
                              item().synched ||
                              item().type === 'LTL Insurance' ||
                              orderStore.isReadOnly
                            }
                            renderValue={(value) => {
                              return value;
                            }}
                            size="small"
                          />
                        </div>
                      </TableCell>
                      <TableCell sx={{ ...cellStyles }}>
                        <div class=" tablet:max-w-[50px] netbook:max-w-[500px]">
                          <TextInput
                            value={item().description}
                            onChange={(value: string) => {
                              updateLineItems('description', value, index);
                            }}
                            classes={'bg-white rounded-md'}
                            label=""
                            disabled={
                              item().synched ||
                              item().type === '' ||
                              item().type === 'LTL Insurance' ||
                              orderStore.isReadOnly
                            }
                            placeholder="Description"
                            size="small"
                          />
                        </div>
                      </TableCell>
                      <TableCell
                        sx={{
                          ...cellStyles,
                        }}
                      >
                        <TextInput
                          label=""
                          type="number"
                          significantDigits={3}
                          placeholder="Qty"
                          value={item().quantity}
                          onChange={(value: string) => {
                            updateLineItems('quantity', value, index);
                          }}
                          onBlur={() =>
                            validateFieldForOrder(
                              `lineItems.${index}.quantity`,
                              `lineItems[${index}].quantity`,
                            )
                          }
                          error={
                            orderStore.orderFormError &&
                            orderStore.orderFormError[
                              `lineItems[${index}].quantity`
                            ]
                          }
                          noErrorMessage
                          classes={'bg-white rounded-md '}
                          disabled={
                            item().synched ||
                            item().type === 'LTL Insurance' ||
                            orderStore.isReadOnly
                          }
                          sxProps={{
                            maxWidth: '60px',
                          }}
                          size="small"
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          ...cellStyles,
                          maxWidth: '100px',
                        }}
                      >
                        <div class="tablet:max-w-[50px] netbook:max-w-[200px]">
                          <TextInput
                            placeholder="Rate"
                            type="number"
                            significantDigits={6}
                            value={item().rate}
                            onChange={(value: string) => {
                              const val = trimDecimalValue(value, 7, 6, true);
                              updateLineItems('rate', val, index);
                            }}
                            onBlur={() =>
                              validateFieldForOrder(
                                `lineItems.${index}.rate`,
                                `lineItems[${index}].rate`,
                              )
                            }
                            error={
                              orderStore.orderFormError &&
                              orderStore.orderFormError[
                                `lineItems[${index}].rate`
                              ]
                            }
                            noErrorMessage
                            classes={'bg-white rounded-md'}
                            label=""
                            disabled={
                              item().synched ||
                              (item().type === 'LTL Insurance' &&
                                (isFalveyInsuranceCommitted() || !isAdmin())) ||
                              orderStore.isReadOnly
                            }
                            size="small"
                          />
                        </div>
                      </TableCell>
                      <TableCell sx={cellStyles}>
                        <Typography
                          variant="body2"
                          class={`${classes.totalAmount} !font-normal`}
                        >
                          {formatAmount(
                            Number(
                              item().quantity === '' ||
                                item().quantity === '.' ||
                                item().quantity === '-'
                                ? '0'
                                : item().quantity,
                            ) *
                              Number(
                                item().rate === '' ||
                                  item().rate === '.' ||
                                  item().rate === '-'
                                  ? '0'
                                  : item().rate,
                              ),
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRowErrors
                      columnsLength={headers.length}
                      tableErrors={orderTableErrors(
                        ['rate', 'quantity', 'type', 'description'],
                        'lineItems[{index}].{field}',
                        index,
                      )}
                    />
                  </Show>
                )}
              </Index>
            </TableBody>
          </Show>
          <TableFooter class={classes.tableFooter}>
            <TableRow class={classes.tableFooterRow}>
              <TableCell
                sx={{
                  ...cellStyles,
                  textAlign: 'center',
                }}
              >
                <IconButton
                  onClick={addRow}
                  class="!p-0"
                  disabled={
                    (orderStore.isCreate &&
                      !Boolean(orderStore.order.customerId)) ||
                    ((orderStore.order.status === 'Paid' ||
                      orderStore.order.status === 'Invoiced') &&
                      (!isAdmin() || !isSuperAdmin()))
                  }
                >
                  <AddCircleOutlineOutlined
                    fontSize="small"
                    class={classes.addIcon}
                  />
                </IconButton>
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>
                <Typography variant="body2" class={classes.totalLabel}>
                  Total
                </Typography>
              </TableCell>
              <TableCell sx={cellStyles}>
                <Typography variant="body2" class={classes.totalAmount}>
                  {formatAmount(computeTotalLineItems())}
                </Typography>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <Show when={orderStore.order.synched}>
        <Box class="mt-2">
          <span
            class={classes.editLineItems}
            onClick={() => openModal(editLineItems)}
          >
            Why can’t I edit my Line Items?
          </span>
        </Box>
      </Show>
      <BasicModal
        id={editLineItems}
        width={'800px'}
        footer={false}
        header={false}
      >
        <LineItemsLockedModel modalId={editLineItems} />
      </BasicModal>
      <DialogBox
        id="deleteLineItem"
        title={'Are you sure you want to delete this line item?'}
        onSubmit={() => {
          removeTableRow(deleteRow()!, orderStore.order, 'order', 'lineItems');
          setDeleteRow(null);
        }}
        onSubmitText="Delete"
      />
    </>
  );
};
