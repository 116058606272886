import { Button, AutoComplete } from '@components';
import { ReassignContactCard } from '@components/Contacts/ReassignContactCard';
import { UserInfoModel } from '@store/orders';
import { AccountCircle } from '@suid/icons-material';
import { Box, Card, Divider } from '@suid/material';
import { get } from 'lodash';
import { createSignal, Show } from 'solid-js';
import { UserAutoComplete } from '@typeDefinitions/userTypes';

import { loadInformationClasses as classes } from '../carrier/loads/classes';

type ReassignProps = {
  type: string;
  contactInfo: UserInfoModel | undefined;
  isLoading: () => boolean;
  onButtonClick: (id: number, type: string) => Promise<void>;
  closePopover?: () => void;
  disabled?: boolean;
};

const ReassignCard = (props: ReassignProps) => {
  const [value, setValue] = createSignal('');
  const [autoCompleteValue, setAutoCompleteValue] = createSignal<
    UserInfoModel | undefined | null
  >();
  const ReassignButton = () => (
    <Button
      variant="contained"
      label="Assign"
      onClick={async () => {
        await props.onButtonClick(
          autoCompleteValue()?.id as number,
          props.type,
        );
        if (props.closePopover !== undefined) props.closePopover();
      }}
      type="submit"
      class={classes.reassignButton}
      isLoading={props.isLoading()}
      disabled={!autoCompleteValue() || props.disabled}
    />
  );

  const handleAutoCompleteChange = (item: UserInfoModel | undefined | null) => {
    setAutoCompleteValue(item);
  };

  const handleValueCheck = (e: Event) => {
    const automCompleteValue = get(e.target, 'value');
    setValue(String(automCompleteValue));
  };
  return (
    <Box>
      <Show when={props.contactInfo}>
        <span class={classes.assignHeader}>
          Current <span>{props.type}</span>
        </span>
      </Show>
      <Box class={classes.assignContainer}>
        <ReassignContactCard
          contact={props.contactInfo}
          showIcon
          isLoading={props.isLoading()}
        />
        <Divider class={classes.divider} />
        <AutoComplete
          variant="standard"
          endpoint="user/autocomplete?term"
          onItemSelect={(item) => handleAutoCompleteChange(item)}
          id="auto-complete-reassign"
          label="Assign New Order Rep"
          placeholder="Search by name"
          customButton={ReassignButton}
          defaultValue=""
          value={autoCompleteValue()?.name ?? ''}
          onInput={handleValueCheck}
          renderItemStyle={{ padding: '3px', height: '45px' }}
          renderItem={(item) => (
            <Card
              class={classes.cardStyle}
              sx={{
                '&': {
                  paddingTop: '5px',
                  boxShadow: 'none',
                  border: '1px solid rgba(0, 0, 0, 0.23)',
                },
              }}
            >
              <Box class={classes.accountCircleBox}>
                <AccountCircle class={classes.accountCircle} />
                <span class={classes.accountText}>
                  {(item as UserAutoComplete).name}
                </span>
              </Box>
            </Card>
          )}
          startAdornment={
            autoCompleteValue() ? (
              <AccountCircle class={classes.accountBlue} />
            ) : value() ? null : (
              <AccountCircle class={classes.accountGrey} />
            )
          }
        />
      </Box>
    </Box>
  );
};

export default ReassignCard;
