import { lazy } from 'solid-js';

const NewProspectPage = lazy(() => import('./NewProspectPage'));
const ProspectPage = lazy(() => import('./ProspectPage'));
const ProspectDetails = lazy(() => import('./prospectDetails/ProspectDetails'));

export const NewProspectRoute = {
  path: '/prospect/Create',
  component: NewProspectPage,
};

export const ProspectRoute = {
  path: '/prospect',
  component: ProspectPage,
};

export const ProspectDetailsRoute = {
  path: '/prospect/details/:id',
  component: ProspectDetails,
};
