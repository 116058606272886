import { DateTime } from 'luxon';

export const isDateLessThanCurrentDate = (templateDate: DateTime) => {
  const currentDate = DateTime.now();
  return (
    templateDate.year < currentDate.year ||
    (templateDate.year === currentDate.year &&
      templateDate.month < currentDate.month) ||
    (templateDate.year === currentDate.year &&
      templateDate.month === currentDate.month &&
      templateDate.day < currentDate.day)
  );
};
