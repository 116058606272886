import { TempQuoteModel } from '@store/ltl/types';
import { customerStore, PickUpDropOffItem } from '@store/orders';
import { OperationTypeV4 } from '@typeDefinitions/operationTypes';
import { LastUsedTopStopModel } from '@typeDefinitions/stopTypes';
import { BuildOrderFromQuote } from '@utils/ltl/LTLOrderBuilder';
import { printLog } from '@utils/utils';
import { DateTime } from 'luxon';
import {
  LineItemViewModel,
  OrderLineItem,
} from '@typeDefinitions/lineItemTypes';
import { IOrderViewModel } from '@typeDefinitions/orderTypes';
import { ILoadEditViewModel } from '@typeDefinitions/loadTypes';

export const mergeOrderQuote = (
  quote: TempQuoteModel,
  existingOrder: IOrderViewModel,
): IOrderViewModel => {
  const newOrderFromQuote = BuildOrderFromQuote(quote);
  const mergeOrder = {
    id: existingOrder.id,
    customerId: customerStore.customer.id,
    operationType:
      existingOrder.id <= 0
        ? 'Insert'
        : existingOrder.customerId === customerStore.customer.id
          ? ('None' as OperationTypeV4)
          : ('Update' as OperationTypeV4),
    loads: mergeLoads(newOrderFromQuote.loads, existingOrder.loads),
    lineItems: mergeOrderLineItems(
      newOrderFromQuote.lineItems,
      existingOrder.lineItems,
    ),
    payments: existingOrder.payments,
    assignedId: existingOrder.assignedId,
    chargeCreditCardFee: existingOrder.chargeCreditCardFee,
    customerCredit: existingOrder.customerCredit,
    billingHold: existingOrder.billingHold,
    agingOut: existingOrder.agingOut,
    adjustments: existingOrder.adjustments,
    availableServices: existingOrder.availableServices,
    editable: existingOrder.editable,
    synched: existingOrder.synched,
    private: existingOrder.private,
    edi: existingOrder.edi,
    trackLoads: existingOrder.trackLoads,
    show214: existingOrder.show214,
    daysPastDue: existingOrder.daysPastDue,
    canVoidOrder: existingOrder.canVoidOrder,
    createdDate: existingOrder.createdDate,
    createdBy: existingOrder.createdBy,
    isDeleted: existingOrder.isDeleted,
    loadInformation: existingOrder.loadInformation,
    hideCustomerName: existingOrder.hideCustomerName,
  };
  return mergeOrder;
};

//handle order specific merging here
const mergeOrderLineItems = (
  quotedLineItems: OrderLineItem[],
  existingLineItems: OrderLineItem[],
) => {
  const originalLineItems = existingLineItems.map((x) => {
    return { ...x, operationType: 'Delete' } as OrderLineItem;
  });
  const newLines = [...quotedLineItems, ...originalLineItems];
  return newLines;
};

//handle load specific merging here
const mergeLoads = (
  quotedLoads: ILoadEditViewModel[],
  existingLoads: ILoadEditViewModel[],
) => {
  //Add logic here to associate each load with the one being quoted based on ID, will need to update LTLOrderBuilder to guard against
  // any potential issues with this
  const requotedLoad = existingLoads.find((x) => x.id == quotedLoads[0].id);
  if (requotedLoad) {
    const mergedLoad = mergeLoad(quotedLoads[0], requotedLoad);
    return [
      mergedLoad,
      ...existingLoads.filter((x) => x.id !== quotedLoads[0].id),
    ];
  }
  return existingLoads;
};

const mergeLoad = (
  quotedLoad: ILoadEditViewModel,
  existingLoad: ILoadEditViewModel,
) => {
  const newRequotedLoad = {
    ...existingLoad,
    items: getQuoteItems(existingLoad, quotedLoad),
    operationType: existingLoad.id <= 0 ? 'Insert' : 'Update',
    status: 'Assigned',
    ltlquoteId: quotedLoad.ltlQuote?.id ?? existingLoad.ltlquoteId,
    ltlQuote: {
      ...quotedLoad.ltlQuote,
      operationType: 'Insert',
      quoteItems: quotedLoad.ltlQuote?.ltlQuoteLoadItems,
    },
    quoteId: quotedLoad.quoteId,
    lineItems: mergeLineItems(quotedLoad.lineItems, existingLoad.lineItems),
    carrierId: quotedLoad.carrierId,
    stops: getQuoteStops(quotedLoad.stops, existingLoad.stops),
  } as ILoadEditViewModel;
  return newRequotedLoad;
};
const getQuoteStops = (
  quoteStops: LastUsedTopStopModel[] | undefined,
  existingStops: LastUsedTopStopModel[] | undefined,
) => {
  return existingStops?.map((x) => {
    const matchingQuoteStop = quoteStops?.find(
      (qs) =>
        (qs.pickUp && qs.pickUp === x.pickUp) ||
        (qs.dropOff && qs.dropOff === x.dropOff),
    );
    const zipChanged = matchingQuoteStop
      ? matchingQuoteStop.zip !== x.zip
      : false;
    const times = getStopDates(quoteStops, x);

    return {
      ...x,
      locationName: zipChanged ? '' : x.locationName,
      address1: zipChanged ? '' : x.address1,
      address2: zipChanged ? '' : x.address2,
      city: zipChanged ? matchingQuoteStop?.city : x.city,
      state: zipChanged ? matchingQuoteStop?.state : x.state,
      country: zipChanged ? matchingQuoteStop?.countryCode : x.countryCode,
      zip: zipChanged ? matchingQuoteStop?.zip : x.zip,
      driverInTime: times.driverInTime?.toString(),
      driverOutTime: times.driverOutTime?.toString(),
      stopDateTime: times.stopDateTime?.toString(),
      operationType: x.id <= 0 ? 'Insert' : 'Update',
    };
  });
};
const getStopDates = (
  quotedStops: LastUsedTopStopModel[] | undefined,
  existingStop: LastUsedTopStopModel,
) => {
  if (quotedStops === undefined) {
    return {
      driverInTime: existingStop.driverInTime,
      driverOutTime: existingStop.driverOutTime,
      stopDateTime: existingStop.stopDateTime,
    };
  }

  const quotedStop = quotedStops.find((x) => x.pickUp === existingStop.pickUp);

  if (quotedStop === undefined) {
    return {
      driverInTime: existingStop.driverInTime,
      driverOutTime: existingStop.driverOutTime,
      stopDateTime: existingStop.stopDateTime,
    };
  }

  const zipChanged = quotedStop.zip !== existingStop.zip;
  const driverInTime = zipChanged
    ? Boolean(quotedStop.driverInTime)
      ? quotedStop.driverInTime
      : existingStop.driverInTime
    : existingStop.driverInTime;
  const driverOutTime = zipChanged
    ? Boolean(quotedStop.driverOutTime)
      ? quotedStop.driverOutTime
      : existingStop.driverOutTime
    : existingStop.driverOutTime;
  return {
    driverInTime: getDriverInOrOutTime(driverInTime, existingStop.driverInTime),
    driverOutTime: getDriverInOrOutTime(
      driverOutTime,
      existingStop.driverOutTime,
    ),
    stopDateTime: zipChanged
      ? quotedStop.stopDateTime
      : existingStop.stopDateTime,
  };
};
const getDriverInOrOutTime = (
  driverTime: string | null | undefined,
  existingDriverTime: string | null | undefined,
) => {
  let adjustedDriverInTime = driverTime;
  if (driverTime != null && existingDriverTime != null) {
    const formattedDriverTime = DateTime.fromISO(existingDriverTime, {
      zone: 'utc',
    });
    const q = DateTime.fromISO(driverTime);
    adjustedDriverInTime = q
      .toUTC()
      .set({
        hour: formattedDriverTime.hour,
        minute: formattedDriverTime.minute,
      })
      .toLocal()
      .set({
        day: q.day,
        month: q.month,
        year: q.year,
      })
      .toISO();
  }
  return adjustedDriverInTime;
};
const getQuoteItems = (
  existingLoad: ILoadEditViewModel,
  quotedLoad: ILoadEditViewModel,
) => {
  printLog('existingLoad', existingLoad);
  printLog('quotedLoad', quotedLoad);
  const dropOffId = existingLoad.stops?.find((x) => !x.pickUp)?.id;
  const pickupId = existingLoad.stops?.find((x) => x.pickUp)?.id;
  if (Number(dropOffId) > 0 && Number(pickupId)) {
    const quotedItems = quotedLoad.items?.map((x, i) => {
      return {
        ...x,
        id: -(i + 1),
        item: x.type ?? x.item,
        type: x.type ?? x.item,
        dropOffId: dropOffId,
        pickUpId: pickupId,
        operationType: 'Insert',
      };
    }) as PickUpDropOffItem[];
    const existingItems = existingLoad.items?.map((x) => {
      return {
        ...x,
        operationType: 'Delete',
      };
    }) as PickUpDropOffItem[];
    printLog('existingItems', existingItems);
    return [...quotedItems, ...existingItems];
  }
  return quotedLoad.items;
};

const mergeLineItems = (
  quotedLineItems?: LineItemViewModel[],
  existingLineItems?: LineItemViewModel[],
) => {
  const originalLineItems = existingLineItems?.map((x) => {
    return { ...x, operationType: 'Delete' } as LineItemViewModel;
  });
  if (originalLineItems && quotedLineItems) {
    return [...quotedLineItems, ...originalLineItems];
  }
  if (!originalLineItems && quotedLineItems) {
    return quotedLineItems;
  }
  return existingLineItems;
};
