import { Button } from '@components/Button';
import { Menu } from '@components/Menu';
import { ltlUrls } from '@constants/urls';
import { useNavigate } from '@solidjs/router';
import { LtlQuoteParametersViewModel } from '@store/orders';
import { openModal } from '@store/modals';
import MoreVertIcon from '@suid/icons-material/MoreVert';
import OpenInNewIcon from '@suid/icons-material/OpenInNew';
import RequestQuoteIcon from '@suid/icons-material/RequestQuote';
import { Box } from '@suid/material';
import { copyQuoteEvent } from '@utils/copyQuoteEvent';
import { routeOrderDetails } from '@utils/routeOrderDetails';
import { LtlQuotingModal } from '@views/order/components/ltlQuotingModal';

import { savedLTLQuotesActionsClasses as cls } from './classes';

interface SavedQuoteActionButtonProps {
  quoteParameters: LtlQuoteParametersViewModel;
  isHistory?: boolean;
}

export const LTLQuotesActionCellButton = (
  data: SavedQuoteActionButtonProps,
) => {
  const navigate = useNavigate();

  if (Boolean(data.quoteParameters) && Boolean(data.quoteParameters.id)) {
    return (
      <Box gap={0.5} displayRaw="flex">
        {Boolean(data.isHistory) &&
          data.quoteParameters.assignedOrderId != null && (
            <Button
              size="small"
              startIcon={<OpenInNewIcon />}
              class={cls.orderBtn}
              onClick={() => {
                if ((data.quoteParameters.assignedOrderId ?? 0) > 0) {
                  const isNative = routeOrderDetails(
                    data.quoteParameters.assignedOrderId ?? 0,
                  );
                  if (isNative) {
                    navigate(
                      `/order/details/${data.quoteParameters.assignedOrderId}`,
                    );
                  }
                }
              }}
              label={String(data.quoteParameters.assignedOrderId)}
            />
          )}
        <LtlQuotingModal id={data.quoteParameters.id.toString()} />
        <Button
          size="small"
          startIcon={<RequestQuoteIcon />}
          class={cls.buttonBgColor}
          label="Requote"
          onClick={() => {
            openModal(`ltl-quoting-modal-${data.quoteParameters.id}`);
          }}
        />
        {data.isHistory !== true &&
          Boolean(data.quoteParameters.customerName) && (
            <Menu
              menuItems={['Copy Quote Info', 'Download Quote Info PDF']}
              menuButtonLabel={<MoreVertIcon class={cls.moreVertiIcon} />}
              onMenuItemClick={(item: unknown) => {
                if (item === 'Copy Quote Info') {
                  copyQuoteEvent(data.quoteParameters.quoteRequestLocator);
                }
                if (item === 'Download Quote Info PDF') {
                  window.open(
                    ltlUrls.quoteInfoPDF(
                      data.quoteParameters.quoteRequestLocator,
                    ),
                    '_blank',
                  );
                }
              }}
              downArrowShowHide={false}
              width={200}
            />
          )}
      </Box>
    );
  }
};
