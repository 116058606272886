import { AutoComplete } from '@components';
import { MenuItemType, SelectField } from '@components/forms';
import { createForm } from '@felte/solid';
import { fetchOfficeGroups, updateLoadPropertyAtIndex } from '@store/orders';
import { Button, Grid } from '@suid/material';
import { SelectChangeEvent } from '@suid/material/Select';
import { getDataValue } from '@utils/formUtils';
import { Show, createSignal, onMount } from 'solid-js';
import { userStore } from '@store/user';
import { UserAutoComplete } from '@typeDefinitions/userTypes';
import { ATGAutoCompleteItem } from '@components/Autocomplete/types';
import { ILoadEditViewModel } from '@typeDefinitions/loadTypes';

import cls from './classes';

export const AssignLoad = (props: {
  onClose: () => void;
  load: ILoadEditViewModel;
  tabIndex: number;
}) => {
  const [assignLoadOptions, setAssignLoadOptions] =
    createSignal<MenuItemType[]>();

  const { form, data, setData } = createForm({
    initialValues: {
      assignedToUser: props.load.assignedToUser,
      assignedToUserId: props.load.assignedToUserId,
      assignedToGroupId: props.load.assignedToGroupId,
      assignedToGroup: props.load.assignedToGroup,
    },
    onSubmit: (values) => {
      const {
        assignedToUser,
        assignedToUserId,
        assignedToGroup,
        assignedToGroupId,
      } = values;
      updateLoadPropertyAtIndex({
        assignedToUser: assignedToUser,
        assignedToUserId: assignedToUserId,
        assignedToGroup: assignedToGroup,
        assignedToGroupId: assignedToGroupId,
      });
      props.onClose();
    },
  });

  const handleAssignUser = (item: ATGAutoCompleteItem) => {
    const item2 = item as UserAutoComplete;
    setData('assignedToUserId', item2.id);
    setData('assignedToUser', item2.name);
  };

  const handleAssignGroupChange = (e: SelectChangeEvent) => {
    setData('assignedToGroupId', e.target.value);
    setData('assignedToGroup', () => {
      const group = assignLoadOptions()?.find(
        (item) => item.value === e.target.value,
      );
      return group?.label ?? '';
    });
  };

  const fetchAssignGroupData = async () => {
    const res = await fetchOfficeGroups(userStore.user.officeId);
    setAssignLoadOptions(
      res.length
        ? res.map((item) => ({
            label: item.name.toString(),
            value: item.id,
          }))
        : ([] as MenuItemType[]),
    );
  };

  onMount(() => {
    void fetchAssignGroupData();
  });

  return (
    <form ref={form}>
      <Grid container spacing={2} class={cls.assignLoadContentContainer}>
        <AutoComplete
          id={`reassign-autocomplete-${props.tabIndex}`}
          endpoint="user/autocomplete?term"
          defaultValue=""
          label="Assign to Person"
          value={getDataValue(data, 'assignedToUser')?.toString()}
          onItemSelect={handleAssignUser}
          renderItem={(item) => {
            const item2 = item as UserAutoComplete;
            return <div>{item2.name}</div>;
          }}
        />
        <Show when={assignLoadOptions()?.length}>
          <SelectField
            label="Assign Group"
            menuItems={assignLoadOptions() as MenuItemType[]}
            value={
              Boolean(getDataValue(data, 'assignedToGroupId'))
                ? getDataValue(data, 'assignedToGroupId')
                : ''
            }
            onChange={handleAssignGroupChange}
          />
        </Show>
      </Grid>
      <Grid class={cls.buttonsContainer}>
        <Button class={cls.btn} type="submit" variant="contained">
          Assign Load
        </Button>
        <Button
          class={cls.btn}
          onClick={() => props.onClose()}
          variant="outlined"
        >
          Cancel
        </Button>
      </Grid>
    </form>
  );
};
