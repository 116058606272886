import { Layout } from '@layouts/index';
import { Box, Stack } from '@suid/material';
import { Component, createEffect, onCleanup, Show } from 'solid-js';
import {
  factoringCompanyDetailsStore,
  getFactoringCompanyData,
  setFactoringCompanyDetailsStore,
} from '@store/factoringCompany/factoringCompanyDetails';
import { useParams } from '@solidjs/router';
import { CarrierDetailsHeaderSkeleton } from '@views/carriers/carrierDetails/components';
import { produce } from 'solid-js/store';
import { FactoringCompany } from '@store/carriers';

import {
  FactoringCompanyDetailsHeader,
  FactoringCompanyDetailsTabPanel,
} from './components';

export const FactoringCompanyDetails: Component = () => {
  const params = useParams();

  createEffect(async () => {
    if (params.id) {
      await getFactoringCompanyData(params.id);
      setFactoringCompanyDetailsStore('creationMode', false);
    } else {
      setFactoringCompanyDetailsStore(
        produce((state) => {
          state.factoringCompanyDetails = {} as FactoringCompany;
          state.creationMode = true;
        }),
      );
    }
    updateTabPanelHeight();
    window.addEventListener('resize', updateTabPanelHeight);
  });

  const updateTabPanelHeight = () => {
    const header = document.getElementById('factoring-company-details-header');
    const tabPanel = document.getElementById(
      'factoring-company-details-tab-panel',
    );
    if (header && tabPanel) {
      const headerHeight = header.offsetHeight;
      tabPanel.style.height = `calc(100vh - ${headerHeight}px)`;
    }
  };

  onCleanup(() => {
    setFactoringCompanyDetailsStore(
      produce((state) => {
        state.factoringCompanyDetails = {} as FactoringCompany;
        state.creationMode = true;
        state.factoringCompanyDetailsErrors = null;
      }),
    );
    window.removeEventListener('resize', updateTabPanelHeight);
  });

  return (
    <Layout>
      <Box
        class="flex justify-between items-center bg-white sticky shadow-lg top-0 z-10"
        id="factoring-company-details-header"
      >
        <Show
          when={!factoringCompanyDetailsStore.isLoading}
          fallback={<CarrierDetailsHeaderSkeleton />}
        >
          <FactoringCompanyDetailsHeader />
        </Show>
      </Box>
      <Stack
        class="bg-[#E1ECF2] overflow-scroll"
        id="factoring-company-details-tab-panel"
      >
        <Box class="m-2 p-2 bg-white max-md:mb-[80px]">
          <FactoringCompanyDetailsTabPanel />
        </Box>
      </Stack>
    </Layout>
  );
};
