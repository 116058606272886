import { LTLRateQuoteResponse } from '@store/ltl';
import { FalveyInsuranceQuoteInLoad } from '@store/ltl/types';
import { Box, Grid, Typography } from '@suid/material';

import { CostDetailCard } from './components/CostDetailCard';
interface ViewCostDetailsPopover {
  data: LTLRateQuoteResponse;
  withInsurance?: boolean;
  ltlInsuranceVals?: FalveyInsuranceQuoteInLoad;
}
export const ViewCostDetailsPopover = (props: ViewCostDetailsPopover) => {
  return (
    <Box class="max-w-[600px]">
      <Grid container spacing={2} class="p-4">
        <Grid item xs={6}>
          <Typography variant="h6" class="pl-4">
            Carrier Cost
          </Typography>
          <CostDetailCard
            data={props.data}
            isCarrier={true}
            withInsurance={props.withInsurance}
          />
        </Grid>
        {props.data.customerRateQuote && (
          <Grid item xs={6}>
            <Typography variant="h6" class="pl-4">
              Customer Cost
            </Typography>
            <CostDetailCard
              data={props.data.customerRateQuote}
              withInsurance={props.withInsurance}
              ltlInsuranceVals={props.ltlInsuranceVals}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default ViewCostDetailsPopover;
